import React from 'react';

interface Props {}

const NotFound = (props: Props) => {
  return (
    <div>
      <h1>404 Error</h1>
      <h1>Page Not Found</h1>
    </div>
  );
};

export default NotFound;
