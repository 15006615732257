/* eslint-disable react-hooks/exhaustive-deps */
import LoadingContent from 'components/LoadingContent';
import { useFormik } from 'formik';
import React from 'react';
import TopProcessBar from 'screen/selectCollege/TopProcessBar';
import { Container, Form, Row, Spinner, Button } from 'react-bootstrap';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import Cookies from 'js-cookie';
import { useHistory } from 'react-router-dom';
import { routers } from 'router/router';
import { logout } from 'app/basicInformationSlice/basicInformationSlice';
import { authenticateSettings } from 'components/helper/appConfig';
import CalculateOutlinedIcon from '@mui/icons-material/CalculateOutlined';
import LeavingExamination from './components/LeavingExamination';
import AdvanceLevelExams from './components/AdvanceLevelExams';
import {
  BoolToString,
  checkEnableParseJSON,
  formatBoolean,
  setArrayLength,
} from 'components/helper/helper';
import { getOtherTest, updateOtherTest } from 'app/testingSlice/testingSlice';
import { HtmlTooltip } from 'screen/selectCollege/SelecCollegePage';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { debounce } from 'lodash';

interface Props {}

const OtherTest = (props: Props) => {
  const [loading, setLoading] = React.useState(false);
  const [advanceExam, setAdvanceExam] = React.useState<any[]>([]);
  const [predictedAdvanceExam, setPredictedAdvanceExam] = React.useState<any[]>([]);
  const testing = useAppSelector((store) => store.testing);
  const dispatch = useAppDispatch();
  const history = useHistory();  
  let studentId = Cookies.get('userId');
  let token = Cookies.get(authenticateSettings.tokenName);

  const formik = useFormik({
    initialValues: {
      is_not_required_toefl_test: true,
      number_of_time_taken_senior_secondary_exam: 0,
      senior_secondary_exam_date_taken: [],
      examination_board: [],
      specify_other: [],
      academic_subject: [],
      score: [],
      score_type: [],
      is_have_advanced_level_exam_wish_report: '',
      number_of_advanced_level_exam_report: '0',
      advanced_level_exam_array: '',
      is_have_predicted_advanced_level_exam_wish_report: '',
      number_of_predicted_advanced_level_exam_report: '0',
      predicted_advanced_level_exam_array: '',
    },
    onSubmit: async (values) => {
      const newValues = {
        ...values,
        senior_secondary_exam_date_taken: JSON.stringify(values.senior_secondary_exam_date_taken),
        examination_board: JSON.stringify(values.examination_board),
        specify_other: JSON.stringify(values.specify_other),
        academic_subject: JSON.stringify(values.academic_subject),
        score: JSON.stringify(values.score),
        score_type: JSON.stringify(values.score_type),
        is_have_advanced_level_exam_wish_report: formatBoolean(
          values.is_have_advanced_level_exam_wish_report
        ),
        is_have_predicted_advanced_level_exam_wish_report: formatBoolean(
          values.is_have_predicted_advanced_level_exam_wish_report
        ),
        advanced_level_exam_array: JSON.stringify(advanceExam),
        predicted_advanced_level_exam_array: JSON.stringify(predictedAdvanceExam),
        student_id: studentId,
      };
      await dispatch(updateOtherTest(newValues));
    },
  });

  const handleLogout = () => {
    dispatch(logout());
    Cookies.remove(authenticateSettings.tokenName);
    Cookies.remove('userId');
    history.push(routers.login.path);
  };

  const handleContinue = () => {
    formik.handleSubmit();
    history.push(routers.activities.path);
  };

  const getDetail = async () => {
    studentId = Cookies.get('userId');
    token = Cookies.get(authenticateSettings.tokenName);
    if (token && studentId) {
      setLoading(true);
      let res = await dispatch(getOtherTest(studentId));
      const dataLoaded: any = res.payload;
      const senior_secondary_exam_date_taken = checkEnableParseJSON(
        dataLoaded.senior_secondary_exam_date_taken
      );
      const examination_board = checkEnableParseJSON(dataLoaded.examination_board);
      const specify_other = checkEnableParseJSON(dataLoaded.specify_other);
      const academic_subject = checkEnableParseJSON(dataLoaded.academic_subject);
      const score = checkEnableParseJSON(dataLoaded.score);
      const score_type = checkEnableParseJSON(dataLoaded.score_type);
  
      if (dataLoaded) {
        const values: any = {
          ...dataLoaded,
          senior_secondary_exam_date_taken: Array.isArray(senior_secondary_exam_date_taken)
            ? senior_secondary_exam_date_taken
            : [],
          examination_board: Array.isArray(examination_board) ? examination_board : [],
          specify_other: Array.isArray(specify_other) ? specify_other : [],
          academic_subject: Array.isArray(academic_subject) ? academic_subject : [],
          score: Array.isArray(score) ? score : [],
          score_type: Array.isArray(score_type) ? score_type : [],
          is_have_advanced_level_exam_wish_report: BoolToString(
            dataLoaded.is_have_advanced_level_exam_wish_report
          ),
          is_have_predicted_advanced_level_exam_wish_report: BoolToString(
            dataLoaded.is_have_predicted_advanced_level_exam_wish_report
          ),
        };
        formik.setValues(values);
        setAdvanceExam(JSON.parse(dataLoaded.advanced_level_exam_array));
        setPredictedAdvanceExam(JSON.parse(dataLoaded.predicted_advanced_level_exam_array));
      }
      setLoading(false);
      formik.setStatus('idle');      
    }
    else {
      handleLogout();
    }
  };

  React.useEffect(() => {
    getDetail();
  }, []);

  React.useEffect(() => {
    setAdvanceExam(setArrayLength(advanceExam, formik.values.number_of_advanced_level_exam_report));
  }, [formik.values.number_of_advanced_level_exam_report]);

  React.useEffect(() => {
    setPredictedAdvanceExam(
      setArrayLength(
        predictedAdvanceExam,
        formik.values.number_of_predicted_advanced_level_exam_report
      )
    );
  }, [formik.values.number_of_predicted_advanced_level_exam_report]);

  React.useEffect(() => {
    if (formik.dirty) {
      const formValues: any = formik.values;
      [
        'senior_secondary_exam_date_taken',
        'examination_board',
        'specify_other',
        'academic_subject',
        'score',
        'score_type',
      ].map((key: any) =>
        formik.setFieldValue(
          key,
          setArrayLength(
            formValues[key],
            formik.values.number_of_time_taken_senior_secondary_exam,
            ''
          )
        )
      );
    }
  }, [formik.values.number_of_time_taken_senior_secondary_exam]);
  // Auto save function ==========
  const notInitialRender = React.useRef(false);
  React.useEffect(() => {
    debounceSaving();
  }, [formik.values, predictedAdvanceExam, advanceExam]);
  const debounceSaving = React.useCallback(
    debounce(() => {
      if (notInitialRender.current) {
        if (!loading) formik.handleSubmit();
      } else {
        notInitialRender.current = true;
      }
    }, 1500),
    []
  );
  // Auto save function ==========
  return loading ? (
    <LoadingContent />
  ) : (
    <>
      <TopProcessBar
        stepProcess={[
          { title: 'Senior Secondary Leaving Examinations', percentOnProcess: 30 },
          { title: 'Advanced Level Exams', percentOnProcess: 75 },
        ]}
        title="Standardized Testing"
        process={1}
        icon={<CalculateOutlinedIcon />}
      />

      <Container className="main-container">
        <div className="px-2">
          <Form style={{ maxWidth: '900px' }} onSubmit={formik.handleSubmit}>
            <div id="SeniorSecondaryLeavingExaminations" />
            <HtmlTooltip
              title={
                <React.Fragment>
                  <div className="fs-6 text-dark my-2 fw-bold">
                    <InfoOutlinedIcon fontSize="small" className="mx-1" />
                    Senior Secondary Leaving Examinations
                  </div>
                  <div className="d-flex flex-wrap text-field-light">
                    For international students - the testing policies for specific colleges can
                    vary. Check with the colleges you intend to apply to in advance to determine the
                    tests, if any, you'll need to take. Note, be sure to do in your 1st or 2nd year
                    of high school so you can plan accordingly when to take your test(s).
                  </div>
                </React.Fragment>
              }
              placement="right-start"
            >
              <Row className="m-0 common-flag">
                <div className="text-field-mid fs-3 mb-4">
                  Senior Secondary Leaving Examinations
                </div>

                <LeavingExamination formik={formik} />
                <div className="border w-100 my-3" />
              </Row>
            </HtmlTooltip>
            <div id="AdvancedLevelExams" />

            <HtmlTooltip
              title={
                <React.Fragment>
                  <div className="fs-6 text-dark my-2 fw-bold">
                    <InfoOutlinedIcon fontSize="small" className="mx-1" />
                    Advanced Level Exams
                  </div>
                  <div className="d-flex flex-wrap text-field-light">
                    For international students - the testing policies for specific colleges can
                    vary. Check with the colleges you intend to apply to in advance to determine the
                    tests, if any, you'll need to take. Note, be sure to do in your 1st or 2nd year
                    of high school so you can plan accordingly when to take your test(s).
                  </div>
                </React.Fragment>
              }
              placement="right-start"
            >
              <Row className="m-0 coallition-flag">
                <div className="text-field-mid fs-3 mb-4">Advanced Level Exams</div>
                <AdvanceLevelExams
                  formik={formik}
                  setPredictedAdvanceExam={(values) => setPredictedAdvanceExam(values)}
                  predictedAdvanceExam={predictedAdvanceExam}
                  advanceExam={advanceExam}
                  setAdvanceExam={(values) => setAdvanceExam(values)}
                />
              </Row>
            </HtmlTooltip>

            <div className="my-3 d-flex justify-content-end border-top pt-3">
              <div className="col col-md-6 text-center text-md-end">
                <Button variant="primary" type="submit" className="p-2 px-5 mx-2">
                  {testing.status === 'loading' ? (
                    <Spinner size="sm" animation="border" variant="light" />
                  ) : (
                    'Save'
                  )}
                </Button>
                <Button variant="primary" className="p-2 px-5" onClick={() => handleContinue()}>
                  Continue
                </Button>
              </div>
            </div>
          </Form>
        </div>
      </Container>
    </>
  );
};

export default OtherTest;
