import { FormControlLabel, Radio } from '@mui/material';
import React from 'react';
// import { Form } from 'react-bootstrap';

interface Props {
  isActive: boolean;
  name: string;
  label: string;
  value: any;
  disabled?: boolean;
  logo?: string;
  handleClickToChange?: any;
}

const RadioInputRounded = (props: Props) => {
  const [checkValue, setCheckValue] = React.useState<boolean>(false);
  const randomId = Math.random();
  React.useEffect(() => setCheckValue(props.isActive), [props.isActive]);
  return (
    <>
      <FormControlLabel
        onChange={props.handleClickToChange}
        className={
          props.isActive
            ? 'border rounded ps-1 pe-3 d-inline-block radio-checked-style'
            : 'border rounded ps-1 pe-3 d-inline-block radio-unchecked-style'
        }
        control={<Radio />}
        checked={checkValue}
        label={props.label}
        name={props.name}
        value={props.value}
        id={props.name + randomId}
        disabled={props.disabled}
      />

      <style>{`
        .radio-unchecked-style {
          margin-left:-8px!important;
          min-width: 90px !important;

        }
        .radio-checked-style {
          min-width: 90px !important;
          border-color: #4D88F0 !important;
          background-color: #4D88F0 !important;
          color: white !important;
          margin-left:-8px!important;
        }
        .radio-checked-style > .Mui-checked {
          color: white !important;
        }
        .form-check-input:checked {
          background-color: #4D88F0 !important;
          border-color: #4D88F0 !important;
        }
      `}</style>
    </>
  );
};

export default RadioInputRounded;
