/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import './App.css';
import { HashRouter as Router } from 'react-router-dom';
import { Userpilot } from 'userpilot';
import RouterConfig from 'router';


declare global {
  interface Window {
    Intercom: any;
  }
}

function App() {
  React.useEffect(() => { 
    if (window.location?.hostname !== 'localhost' && window.location?.hostname !== '3.14.196.144') {
      Userpilot.initialize(process.env.REACT_APP_USERPILOTOKEN || 'STG-NX-9ce94dc6');
    }
  }, []);

  return (
    <Router>
      <RouterConfig />
    </Router>
  );
}

export default App;
