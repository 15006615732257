import React from 'react';
import { Row } from 'react-bootstrap';
import ItemContent from './ItemContent';
type Props = { data: any };

const EducationalProgramrSum = ({ data }: Props) => {
  return (
    <Row>
      <div className="text-dart p-2">Work Information</div>
      <ItemContent
        mdCol={12}
        label="What was the average number of hours worked per week over the most recent 36 months?"
        values={data?.average_hours_worked_per_week}
      />
      <ItemContent
        mdCol={12}
        hidden={
          data.average_hours_worked_per_week !== '1-5 hours' &&
          data.average_hours_worked_per_week !== '6-10 hours' &&
          data.average_hours_worked_per_week !== '11-15 hours'
        }
        label="Was more than 25% of your work hours related to your major of choice?"
        values={data?.is_more_25_percent_work_related_major}
      />
      
      <div className="border w-100 my-3" />
      <div className="text-dart p-2">
        Extracurricular Programs Hours of Participation and Leadership
      </div>
      <div className="text-field-light px-2">
        Extracurricular Programs and leadership participation includes activities such as arts,
        Asian American Club, Boy Scouts/Girl Scouts, Boy’s Week/Girl’s Week, cheerleading,
        drama/theater, inter-scholastic athletics, marching band, MEChA, Pride Club, science club,
        student government, yearbook, Young Democrats/Young Republicans and all other
        extracurricular activities.
      </div>
      <ItemContent
        mdCol={12}
        label="Select the average number of hours per week you are/were involved in activities during the last 36 months."
        values={data?.average_hours_activities_per_week}
      />


      <ItemContent
        mdCol={12}
        hidden={
          data?.average_hours_worked_per_week === 'Select' ||
          data?.average_hours_worked_per_week === '0 hours' ||
          data?.average_hours_worked_per_week === ''
        }
        label="Did you hold or have you held any leadership positions in those activities, e.g. class president, club president, team captain, yearbook editor, etc.?"
        values={data?.is_leadership_positions}
      />
      <div className="border w-100 my-3" />
      <div className="text-dart px-2">EOP</div>
      <div className="text-dart px-2">Important Reminder</div>
      <div className="text-field-light my-2 px-2">
        Applicants may complete and submit their Cal State Apply application and then later return
        to complete the EOP portion of the application. To ensure that you submit your EOP
        application on time, please refer to the EOP Admission by Term page for campus specific
        information.
      </div>

      <div className="text-field-light my-2 px-2">
        If you are applying to EOP after you have already submitted your main application, you must
        return to the dashboard and click on the blue 'submit my changes' button in order for your
        updates to be received by Cal State Apply staff. Only updates to your EOP application will
        be considered after your initial submission.
      </div>

      <div className="text-dart px-2">Educational Opportunity Program (EOP)</div>
      <div className="text-field-light my-2 px-2">
        The primary goal of the CSU Educational Opportunity Program is to improve access of
        low-income, first-generation, and historically disadvantaged students from California with
        potential for academic success by making higher education a possibility.
      </div>
      <div className="text-field-light my-2 px-2">
        Visit the EOP Admissions site to learn more about EOP, eligibility criteria, and to view EOP
        application deadlines.
      </div>
      <div className="text-field-light my-2 px-2">
        Select Yes to apply to EOP. Your EOP application is not considered complete until you
        complete the three EOP sections and provide two recommenders.
      </div>
      <div className="text-field-light my-2 px-2">
        Visit the Applicant Help Center for more information.
      </div>
      <div className="text-field-light my-2 px-2">
        If you have yet to submit any applications for fall 2023, you must complete all EOP sections
        below before submitting your application. You cannot return later to complete these EOP
        questions. EOP recommendations are due two-weeks after the campus deadline.
      </div>
      <div className="text-field-light my-2 px-2">
        If you already submitted a campus application and would like to apply to an EOP program that
        is still accepting EOP applications, contact the campus EOP office directly for instructions
        on how to apply.
      </div>
      <ItemContent
        mdCol={12}
        label="Do you wish to apply to the Educational Opportunity Program (EOP)?"
        values={data?.wish_to_apply_EOP}
      />
      <div className="text-field-light px-2">
        Selecting No after you have saved responses on this page will result in the loss of your
        saved responses.
      </div>
      <ItemContent
        mdCol={12}
        label="If you attended a CSU were you enrolled in an EOP program?"
        values={data?.is_enrolled_EOP}
      />
      {/* {data?.is_enrolled_EOP === 'Yes' && (
        <ItemContent label="Select the campus:" values={data?.campus_enrolled_EOP} />
      )} */}
      <ItemContent
        mdCol={12}
        label="Where do you plan to live while attending college? (Select one):"
        values={data?.where_plan_to_live}
      />
      <ItemContent
        mdCol={4}
        label="How many brothers and sisters are currently attending a K-12 school?"
        values={data?.number_brothers_and_sisters_k12}
      />
      <ItemContent
        mdCol={4}
        label="How many brothers and sisters are currently attending or attended college?"
        values={data?.number_brothers_and_sisters_college}
      />
      <ItemContent
        mdCol={4}
        label="How many brothers and sisters received a bachelor's degree?"
        values={data?.number_brothers_and_sisters_received_bachelor_degree}
      />
      <ItemContent
        mdCol={12}
        label="What languages are spoken in your home?"
        values={data?.languages_spoken_in_home}
      />

      <ItemContent
        mdCol={12}
        values={data?.is_received_income_from_public_assistance_program}
        label="During the past 10 years, have you or your family received any income from a public assistance program such as CalWorks, CalFresh, welfare, AFDC, TANF, Social Security, disability, etc.?"
      />

      {
        data?.is_received_income_from_public_assistance_program && (
          <>
            <ItemContent
              mdCol={6}
              label="If yes, how many years?"
              values={data?.number_year_received_income}
            />
            <ItemContent mdCol={6} label="Type(s) of aid:" values={data?.type_of_aid} />
          </>
        )
      }
      <ItemContent
        mdCol={12}
        values={data?.is_participated_in_publicly_funded_programs}
        label="Have you or your family ever participated in any publicly funded programs such as subsidized housing (public or affordable housing programs, Section 8) employment/training programs (i.e., CETA), school lunch programs, etc.?"
      />
      {data?.is_participated_in_publicly_funded_programs && (
        <>
          <ItemContent  mdCol={6} label="If yes, list program(s):" values={data?.publicly_funded_programs} />
          <ItemContent  mdCol={6} label='If yes, explain' values={data?.work_primarily_to_contribute}/>
        </>
          
        )}

      <ItemContent
        mdCol={12}
        values={data?.is_work_primarily_to_contribute}
        label="Do you or any other children in your family work primarily to contribute to household expenses?"
      />
      {/* <Row className="p-3">
        <ItemContent mdCol={12} label="AVID" values={data?.avid} />
        {data?.avid === 'true' ? (
          <ItemContent
            mdCol={12}
            label="Number of years you participated in AVID during High School:"
            values={data?.year_participated_in_AVID}
          />
        ) : (
          <></>
        )}
        <ItemContent label="Upward Bound" values={data?.upward_bound} />
        {data?.upward_bound === 'true' ? (
          <ItemContent
            mdCol={12}
            label="Number of years you participated in Upward Bound during High School:"
            values={data?.year_participated_in_upward_bound}
          />
        ) : (
          <></>
        )}
        <ItemContent
          label="Talent Search or Educational Opportunity Center (EOC)"
          values={data?.talent_search}
        />
        {data?.talent_search === 'true' ? (
          <ItemContent
            mdCol={12}
            label="Number of years you participated in Talent Search or Educational Opportunity Center (EOC) during high school"
            values={data?.year_participated_in_talent_search}
          />
        ) : (
          <></>
        )}
        <ItemContent mdCol={6} label="Puente Project" values={data?.puente_project} />
        {data?.puente_project === 'true' ? (
          <ItemContent
            mdCol={12}
            label="Number of years you participated in Puente Project"
            values={data?.year_participated_in_puente_project}
          />
        ) : (
          <></>
        )}
        <ItemContent mdCol={6} label="Independent Living Project (ILP)" values={data?.ilp} />
        {data?.ilp === 'true' ? (
          <ItemContent
            mdCol={12}
            label="Number of years you participated in Independent Living Project (ILP)"
            values={data?.year_participated_in_ilp}
          />
        ) : (
          <></>
        )}
        <ItemContent mdCol={6} label="MESA Project" values={data?.MESA_project} />
        {data?.MESA_project === 'true' ? (
          <ItemContent
            mdCol={12}
            label="Number of years you participated in MESA Project"
            values={data?.year_participated_in_MESA_project}
          />
        ) : (
          <></>
        )}
        <ItemContent
          mdCol={12}
          label="Other (CAMP, EAOP, EOP&S/CARE GEAR-UP, TRIO, or Foster youth support programs)"
          values={data?.other_program}
        />
        {data?.other_program === 'true' ? (
          <ItemContent
            mdCol={12}
            label="Number of years you participated in Gear-up, CAMP, Foster youth support programs, Student Support Services Programs (SSSP)"
            values={data?.year_participated_in_other_program}
          />
        ) : (
          <></>
        )}

        <div className="border w-100 my-3" />
      </Row>

      <Row className="p-3">
        <ItemContent
          mdCol={6}
          hidden={
            data?.average_hours_worked_per_week !== '1-5 hours' &&
            data?.average_hours_worked_per_week !== '6-10 hours' &&
            data?.average_hours_worked_per_week !== '11-15 hours'
          }
          label="Was more than 25% of your work hours related to your major of choice?"
          values={data?.is_more_25_percent_work_related_major}
        />
        <div className="border w-100 my-3" />
      </Row>
      <Row className="p-3"></Row>

      <Row className="p-3">
        {data?.is_received_income_from_public_assistance_program === 'true' && (
          <>
            <ItemContent
              mdCol={6}
              label="If yes, how many years?"
              values={data?.number_year_received_income}
            />
            <ItemContent mdCol={6} label="Type(s) of aid:" values={data?.type_of_aid} />
          </>
        )}

        
        {data?.is_work_primarily_to_contribute === 'true' && (
          <ItemContent
            mdCol={12}
            label="If yes, explain:"
            values={data?.work_primarily_to_contribute}
          />
        )}
      </Row> */}
    </Row>
  );
};

export default EducationalProgramrSum;
