import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import { store } from './app/store';
import { Provider } from 'react-redux';
import * as serviceWorker from './serviceWorker';
import './styles/custom.scss';
import { createTheme, ThemeProvider } from '@mui/material';
import { ToastProvider } from 'react-toast-notifications';
import { Toaster } from 'react-hot-toast';
import ModalPassword from 'screen/ModalPassword';
import { ConfirmProvider } from 'material-ui-confirm';
import { ErrorBoundary } from 'screen/Error';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
const theme = createTheme({
  typography: {
    body1: {
      fontWeight: 500,
    },
    fontFamily: ['Manrope', 'sans-serif'].join(','),
    button: {
      textTransform: 'none',
    },
  },
  palette: {
    primary: { main: '#4D88F0' },
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: `
        @font-face {
          font-family: 'Manrope';
          font-style: normal;
          font-weight: 500;
          font-size: 12px;
        }
      `,
    },
  },
});
ReactDOM.render(
  <React.StrictMode>
    <ErrorBoundary>
      <Provider store={store}>
        <ThemeProvider theme={theme}>
          <ConfirmProvider>
            <LocalizationProvider dateAdapter={AdapterMoment}>
              <ToastProvider autoDismiss autoDismissTimeout={3500} placement="top-right">
                <div>
                  <Toaster position="top-right" reverseOrder={false} />
                </div>
                <ModalPassword />
                <App />
              </ToastProvider>
            </LocalizationProvider>
          </ConfirmProvider>
        </ThemeProvider>
      </Provider>
    </ErrorBoundary>
    <script
      type="text/javascript"
      src="https://api.useberry.com/integrations/liveUrl/scripts/useberryScript.js"
    ></script>
  </React.StrictMode>,
  document.getElementById('root')
);

serviceWorker.unregister();
