/* eslint-disable jsx-a11y/img-redundant-alt */
import { Container } from 'react-bootstrap';
import { Switch } from 'react-router';
import ProtectedRoute from './ProtectedRoute';
import { appRoutes } from './router';
import Header from 'components/layout/header';
import MobileHeader from 'components/layout/MobileHeader';
import SideBar from 'components/layout/sideBar';

const AppLayout = () => {
  return (
    <Container fluid className="vh-100 overflow-hidden px-0">
      <div className="d-none d-sm-block w-100" style={{ minHeight: '58px' }}>
        <Header title="Application" />
      </div>
      <div className="d-block d-sm-none w-100">
        <MobileHeader />
      </div>
      <div className="gm-content">
        <div className="d-none d-sm-block border-end" style={{ width: '290px', height: '100%' }}>
          <SideBar />
        </div>
        <div className="h-100 overflow-auto pb-5 content-styled">
          <Switch>
            {Object.keys(appRoutes).map((route, index) => (
              <ProtectedRoute
                key={index}
                path={appRoutes[route].path}
                component={appRoutes[route].component}
                exact
              />
            ))}
          </Switch>
        </div>
      </div>
      <style>{`
      .content-styled {
        width: calc(100vw - 290px) 
      }
      @media screen and (max-width: 600px) {
        .content-styled {
          width: 100vw !important
        }
      }
      `}</style>
    </Container>
  );
};

export default AppLayout;
