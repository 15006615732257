/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { Col, Form } from 'react-bootstrap';
import moment from 'moment';
import { CssTextField, formatTime } from 'components/helper/helperInut';
import { DatePicker } from '@mui/x-date-pickers';
interface Props {
  mdCol?: string;
  label?: string;
  name: string;
  values: string | null | undefined;
  onChange: (e: any) => void;
  isValid?: boolean;
  feedback?: any;
  placeholder?: any;
  disabled?: boolean;
  className?: string;
  maxDate?: any;
  format?: string;
  hidden?: boolean;
  logo?: string;
}

const DatePickerInput = (props: Props) => {
  const [notValidLabel, setNotValidLabel] = React.useState<string | undefined>();
  const handleChange = (e: any) => {
    if (moment(e).isValid()) {
      props.onChange(formatTime(e));
      setNotValidLabel(undefined);
    } else {
      setNotValidLabel('Invalid date');
    }
  };

  return !props.hidden ? (
    <Form.Group
      className={`d-flex flex-column justify-content-end p-1 p-md-1 ${
        props.className ?? ''
      }`.trim()}
      as={Col}
      md={props.mdCol || '6'}
      controlId={`${props.name}-ID`}
    >
      <Form.Label className={`select-label label-14px ${props.logo ?? ''}`.trim()}>{props.label}</Form.Label>
      <div
        style={{
          backgroundColor: 'white',
          borderRadius: '0.5rem',
          fontSize: '16px',
        }}
      >
        <DatePicker
          disabled={props.disabled}
          label="Date desktop"
          inputFormat={props.format ?? 'MM/DD/yyyy'}
          value={props.values ? props.values : ' '}
          onChange={handleChange}
          maxDate={props.maxDate}
          renderInput={(params) => {
            let newParams: any = {
              ...params,
              placeholder: props.placeholder,
              label: undefined,
            };
            return (
              <>
                <CssTextField id={`${props.name}-ID`} {...newParams} error={false} />
              </>
            );
          }}
        />
      </div>
      <div className="text-field-light sized70 w-100 d-flex justify-content-between mh-1rem">
        {props.feedback}
      </div>
      {notValidLabel && <div className="sized70 text-danger text-end">{notValidLabel}</div>}
    </Form.Group>
  ) : null;
};
export default DatePickerInput;
