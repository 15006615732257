/* eslint-disable react-hooks/exhaustive-deps */
import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { Box } from '@mui/material';
import collegeServices from 'services/collegeServices';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { removeDuplicate } from 'components/helper/helper';
import SelectInput from 'components/input/SelectInput';
import { Row } from 'react-bootstrap';
import TextInput from 'components/input/textInput';
import { useFormik } from 'formik';

export default function ModalMajor({ open, setOpen, formik }: any) {
  const [result, setResult] = React.useState<
    { cip_code: string; major_name: string; category1: string }[]
  >([]);
  const [majors, setMajors] = React.useState<
    { cip_code: string; major_name: string; category1: string }[]
  >([]);
  const [categories, setCategories] = React.useState<string[]>([]);
  const [categorySelected, setCategorySelected] = React.useState<string | undefined>();
  const [checkedResult, setCheckedResult] = React.useState<
    { cip_code: string; major_name: string }[]
  >([]);
  const formMajor = useFormik({
    initialValues: {
      keyword: '',
    },
    onSubmit: () => {},
  });
  React.useEffect(() => {
    if (formMajor.values.keyword) {
      const newResult = result.filter((i) =>
        i.major_name.toLowerCase().includes(formMajor.values.keyword.toLowerCase())
      );
      if (categorySelected) {
        setMajors(newResult.filter((i) => i.category1 === categorySelected));
      } else {
        setMajors(newResult);
      }
    }
  }, [formMajor.values.keyword]);

  const onChecked = (
    checked: boolean,
    result: { cip_code: string; major_name: string; category1: string }
  ) => {
    checked
      ? setCheckedResult(removeDuplicate([...checkedResult, result]))
      : setCheckedResult(checkedResult.filter((item) => item.cip_code !== result.cip_code));
  };

  React.useEffect(() => {
    const getMajor = async () => {
      const res: any = await collegeServices.getUSCollegeMajorV1({ keyword: '' });
      setResult(res);
      setCategories(
        removeDuplicate(res.map((item: { category1: string }) => item?.category1)).sort()
      );
    };
    getMajor();
  }, []);

  React.useEffect(() => {
    if (categorySelected) {
      setMajors(result.filter((i) => i.category1 === categorySelected));
    } else {
      setMajors(result);
    }
  }, [categorySelected]);
  return (
    <React.Fragment>
      <Dialog open={open} onClose={() => setOpen(false)} keepMounted fullWidth>
        <DialogTitle className="fw-bold fs-3">Major</DialogTitle>
        <DialogContent>
          <Row>
            <SelectInput
              mdCol="12"
              label="Category"
              option={categories}
              name="categories"
              values={categorySelected}
              onChange={(e) => setCategorySelected(e.target.value)}
            />
          </Row>
          <Row>
            <TextInput
              label="Major"
              mdCol={12}
              onChange={formMajor.handleChange}
              name="keyword"
              values={formMajor.values.keyword}
            />
          </Row>

          <Box sx={{ maxHeight: '50vh' }}>
            {(majors ?? []).map((item, index) => (
              <FormControlLabel
                className="w-100 m-0"
                control={<Checkbox onChange={(e) => onChecked(e.target.checked, item)} />}
                label={item.major_name}
              />
            ))}
          </Box>
        </DialogContent>
        <DialogActions className="p-4">
          <Button
            variant="contained"
            onClick={() => {
              formik.setFieldValue(
                'cip_ids',
                checkedResult.map((item) => item.cip_code)
              );
              formik.handleSubmit();
              setOpen(false);
            }}
          >
            Show Colleges
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
