import { jobCategory, occupationOptions, optionValues } from 'components/helper/helper';
import GroupRadioButton from 'components/input/GroupRadioButton';
import SelectInput from 'components/input/SelectInput';
import SelectSearchInput from 'components/input/SelectSearchInput';
import TextInput from 'components/input/textInput';
import React from 'react';
import collegeServices from 'services/collegeServices';

interface Props {
  formik: any;
  parentNumber?: number;
}

const Employment = (props: Props) => {
  const [collegeData, setCollegeData] = React.useState<
    {
      id: number;
      label: any;
      value: any;
      code: string;
    }[]
  >();
  const handleSearchCollege = async (keyword: string) => {
    const res: any = await collegeServices.getCollege({ keyword: keyword.trim() });
    const data = res.map((item: any) => ({ id: item.id, label: item.name, value: item.name }));
    setCollegeData(data);
  };
  React.useEffect(() => {
    if (props.formik.values.college_or_university) {
      setCollegeData([
        {
          id: props.formik.values.college_or_university,
          label: props.formik.values.college_or_university,
          value: props.formik.values.college_or_university,
          code: props.formik.values.college_or_university,
        },
      ]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <>
      <div className="my-2 fs-5 p-1">Employment</div>
      <GroupRadioButton
        label="What is this parent's current job status?"
        logo="span-uc-logo"
        mdCol={6}
        options={[
          { value: 'Employed' },
          { value: 'Stay-at-home parent/homemaker' },
          { value: 'Not-employed' },
          { value: 'Retired' },
        ]}
        name="parent_current_job_status"
        values={props.formik.values.parent_current_job_status}
        onChange={props.formik.handleChange}
      />
      <SelectInput
        logo="span-cm-logo"
        form={props.formik}
        name="employment_status"
        values={props.formik.values.employment_status}
        option={[
          { value: 'Employed' },
          { value: 'Unemployed' },
          { value: 'Retired' },
          { value: 'Self-Employed' },
        ]}
        label="Employment status*"
        onChange={props.formik.handleChange}
      />
      {/* {['Never employed at a college/university', 'Retired from a college/university'].includes(
        props.formik.values.parent_currently_employed_or_retire_from_college
      ) && ( */}
      <TextInput
        hidden={props.formik.values.parent_current_job_status !== 'Employed'}
        label="Current Employer"
        name="current_employer"
        countText={40}
        values={props.formik.values.current_employer}
        onChange={props.formik.handleChange}
      />
      {/* )} */}
      <div />
      {props.formik.values.parent_current_job_status === 'Stay-at-home parent/homemaker' && (
        <SelectInput
          option={optionValues(Array.from({ length: 70 }, (_, i) => i + 1))}
          label="How many years has this parent been a stay-at-home parent?"
          logo="span-uc-logo"
          name="number_of_year_at_home"
          values={props.formik.values.number_of_year_at_home}
          onChange={props.formik.handleChange}
        />
      )}
      {props.formik.values.parent_current_job_status === 'Not-employed' && (
        <SelectInput
          option={optionValues(Array.from({ length: 70 }, (_, i) => i + 1))}
          label="How many years has this parent not been employed?"
          logo="span-uc-logo"
          name="number_of_year_not_employed"
          values={props.formik.values.number_of_year_not_employed}
          onChange={props.formik.handleChange}
        />
      )}
      {props.formik.values.parent_current_job_status === 'Retired' && (
        <SelectInput
          option={optionValues(Array.from({ length: 70 }, (_, i) => i + 1))}
          label="How many years has this parent been retired?"
          logo="span-uc-logo"
          name="number_of_year_retired"
          values={props.formik.values.number_of_year_retired}
          onChange={props.formik.handleChange}
        />
      )}

      <div className="p-1">Most recent/Former job held by this parent info:</div>
      <SelectInput
        className={`common-flag ${
          props.formik.values.parent_current_job_status === 'Employed' && 'end-flag'
        }`}
        form={props.formik}
        option={occupationOptions}
        label="Occupation (former occupation, if retired or deceased)"
        name="occupation"
        values={props.formik.values.occupation}
        onChange={props.formik.handleChange}
      />
      <SelectInput
        logo="span-uc-logo"
        label="Job Category"
        name="job_category"
        values={props.formik.values.job_category}
        onChange={props.formik.handleChange}
        option={jobCategory}
      />
      <TextInput
        label="Job title"
        countText={20}
        name="job_title"
        values={props.formik.values.job_title}
        onChange={props.formik.handleChange}
      />
      <SelectInput
        option={optionValues(Array.from({ length: 70 }, (_, i) => i + 1))}
        logo="span-uc-logo"
        label="Number of years at job"
        name="number_of_year_at_job"
        values={props.formik.values.number_of_year_at_job}
        onChange={props.formik.handleChange}
      />

      <div />

      <div />
      {props.formik.values.occupation === 'Other' && (
        <TextInput
          mdCol="12"
          label="Other Occupation Details"
          name="other_occupation_detail"
          values={props.formik.values.other_occupation_detail}
          onChange={props.formik.handleChange}
          countText={50}
        />
      )}

      {['Employed', 'Retired'].includes(props.formik.values.employment_status) && (
        <>
          <GroupRadioButton
            label="Is parent currently employed by or retired from a college or university?"
            logo="span-cm-logo"
            name="parent_currently_employed_or_retire_from_college"
            values={props.formik.values.parent_currently_employed_or_retire_from_college}
            onChange={props.formik.handleChange}
            mdCol={12}
            options={[
              { value: 'Never employed at a college/university' },
              { value: 'Currently employed at a college/university' },
              { value: 'Retired from a college/university' },
            ]}
          />
          {[
            'Currently employed at a college/university',
            'Retired from a college/university',
          ].includes(props.formik.values.parent_currently_employed_or_retire_from_college) && (
            <SelectSearchInput
              form={props.formik}
              values={props.formik.values.college_or_university}
              name="college_or_university"
              label="College/University employer lookup"
              option={collegeData ?? []}
              onSearch={(keyword) => handleSearchCollege(keyword)}
              logo="span-cm-logo"
            />
          )}
        </>
      )}
    </>
  );
};

export default Employment;
