import { csuCountry } from 'components/helper/helper';
import { stateAndCounty } from 'components/helper/stateAndCounty';
import DatePickerInput from 'components/input/DatePicker';
import SelectInput from 'components/input/SelectInput';
import YesNoSwitch from 'components/input/YesNoSwitch';
import moment from 'moment';
import React from 'react';
import { Row } from 'react-bootstrap';

type Props = {
  formik: any;
};

const Residency = ({ formik }: Props) => {
  return (
    <Row>
      <div className="border w-100 my-3" />
      <div className="my-2 fs-5 p-1">Residency</div>
      <YesNoSwitch
        label="Is your parent/guardian a current permanent resident of California?"
        values={formik.values.csu_info?.isCurrentPermanentResidentOfCalifornia}
        name="csu_info.isCurrentPermanentResidentOfCalifornia"
        onChange={formik.handleChange}
        logo="span-csu-logo"
      />
      <YesNoSwitch
        hidden={formik.values.csu_info?.isCurrentPermanentResidentOfCalifornia !== 'true'}
        label="Has your parent/guardian lived in California continuously since birth?"
        values={formik.values.csu_info?.isLivedInCaliforniaContinuouslySinceBirth}
        name="csu_info.isLivedInCaliforniaContinuouslySinceBirth"
        onChange={formik.handleChange}
        logo="span-csu-logo"
      />
      <DatePickerInput
        hidden={formik.values.csu_info?.isLivedInCaliforniaContinuouslySinceBirth !== 'false'}
        label="When did his/her current stay in California begin?"
        name="csu_info.whenStayInCaliforniaBegin"
        values={formik.values.csu_info?.whenStayInCaliforniaBegin}
        onChange={(value) => formik.setFieldValue('csu_info.whenStayInCaliforniaBegin', value)}
        logo="span-csu-logo"
      />
      <div />
      <SelectInput
        hidden={formik.values.csu_info?.isLivedInCaliforniaContinuouslySinceBirth !== 'false'}
        label="Prior Country"
        option={csuCountry}
        values={formik.values.csu_info?.priorCountry}
        name="csu_info.priorCountry"
        onChange={formik.handleChange}
        logo="span-csu-logo"
      />
      <SelectInput
        logo="span-csu-logo"
        hidden={formik.values.csu_info?.isLivedInCaliforniaContinuouslySinceBirth !== 'false'}
        label="Prior State/Territory"
        option={Object.keys(stateAndCounty)}
        values={formik.values.csu_info?.priorState}
        name="csu_info.priorState"
        onChange={formik.handleChange}
      />
      <DatePickerInput
        logo="span-csu-logo"
        hidden={formik.values.csu_info?.isLivedInCaliforniaContinuouslySinceBirth !== 'false'}
        label="From when to when did he/she live there"
        name="csu_info.fromWhenToWhenLiveThere"
        values={formik.values.csu_info?.fromWhenToWhenLiveThere}
        onChange={(value) => formik.setFieldValue('csu_info.fromWhenToWhenLiveThere', value)}
      />
      <DatePickerInput
        logo="span-csu-logo"
        hidden={formik.values.csu_info?.isLivedInCaliforniaContinuouslySinceBirth !== 'false'}
        label="To"
        name="csu_info.ToWhenToWhenLiveThere"
        values={formik.values.csu_info?.ToWhenToWhenLiveThere}
        onChange={(value) => formik.setFieldValue('csu_info.ToWhenToWhenLiveThere', value)}
      />
      <SelectInput
        logo="span-csu-logo"
        hidden={formik.values.csu_info?.isCurrentPermanentResidentOfCalifornia !== 'false'}
        label="Which U.S. State/possession does your parent/guardian regard as her permanent home?"
        option={Object.keys(stateAndCounty)}
        values={formik.values.csu_info?.stateRegardAsPermanentHome}
        name="csu_info.stateRegardAsPermanentHome"
        onChange={formik.handleChange}
      />

      {formik.values.csu_info?.isCurrentPermanentResidentOfCalifornia === 'false' &&
        moment(formik.values.csu_info?.ToWhenToWhenLiveThere)
          .subtract(6, 'months')
          .isBefore(moment(formik.values.csu_info?.fromWhenToWhenLiveThere)) &&
        moment(formik.values.csu_info?.ToWhenToWhenLiveThere).isAfter(
          moment(formik.values.csu_info?.fromWhenToWhenLiveThere)
        ) && (
          <>
            <div>Second Prior Residency (For prior residencies lasting less than 6 months)</div>
            <SelectInput
              logo="span-csu-logo"
              label="Prior Country"
              option={csuCountry}
              name="csu_info.priorCountry_secondPriorResidency"
              values={formik.values.csu_info?.priorCountry_secondPriorResidency}
              onChange={formik.handleChange}
            />
            <SelectInput
              logo="span-csu-logo"
              label="Prior State/Territory"
              option={Object.keys(stateAndCounty)}
              name="csu_info.priorState_secondPriorResidency"
              values={formik.values.csu_info?.priorState_secondPriorResidency}
              onChange={formik.handleChange}
            />
            <DatePickerInput
              logo="span-csu-logo"
              label="From when to when did he/she live there"
              name="csu_info.fromWhenToWhenLiveThere_secondPriorResidency"
              values={formik.values.csu_info?.fromWhenToWhenLiveThere_secondPriorResidency}
              onChange={(value) =>
                formik.setFieldValue('csu_info.fromWhenToWhenLiveThere_secondPriorResidency', value)
              }
            />
            <DatePickerInput
              logo="span-csu-logo"
              label="To"
              name="csu_info.ToWhenToWhenLiveThere_secondPriorResidency"
              values={formik.values.csu_info?.ToWhenToWhenLiveThere_secondPriorResidency}
              onChange={(value) =>
                formik.setFieldValue('csu_info.ToWhenToWhenLiveThere_secondPriorResidency', value)
              }
            />
          </>
        )}
    </Row>
  );
};

export default Residency;
