/* eslint-disable react-hooks/exhaustive-deps */
import LoadingContent from 'components/LoadingContent';
import { useFormik } from 'formik';
import React from 'react';
import TopProcessBar from 'screen/selectCollege/TopProcessBar';
import { Container, Form, Row, Spinner, Button } from 'react-bootstrap';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import Cookies from 'js-cookie';
import { useHistory } from 'react-router-dom';
import { routers } from 'router/router';
import { logout } from 'app/basicInformationSlice/basicInformationSlice';
import { authenticateSettings } from 'components/helper/appConfig';
import CalculateOutlinedIcon from '@mui/icons-material/CalculateOutlined';
import YesNoSwitch from 'components/input/YesNoSwitch';
import SelectInput from 'components/input/SelectInput';
import SATSubjectConfig from './components/SATSubjectConfig';
import {
  BoolToString,
  formatBoolean,
  optionValues,
  setArrayLength,
} from 'components/helper/helper';
import { getSATSubjectTest, updateSATSubjectTest } from 'app/testingSlice/testingSlice';
import { HtmlTooltip } from 'screen/selectCollege/SelecCollegePage';
import { Typography } from '@mui/material';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

interface Props {}

const SATSubjectTest = (props: Props) => {
  const [loading, setLoading] = React.useState(false);
  const testing = useAppSelector((store) => store.testing);
  const dispatch = useAppDispatch();
  const history = useHistory();  
  let studentId = Cookies.get('userId');
  let token = Cookies.get(authenticateSettings.tokenName);
  const [SATData, setSATData] = React.useState<any[]>([]);

  const formik = useFormik({
    initialValues: {
      is_have_sat_subject_report: '',
      number_of_sat_subject_report: '0',
      sat_subject_test_array: '',
    },
    onSubmit: async (values) => {
      const newValues = {
        ...values,
        student_id: studentId,
        is_have_sat_subject_report: formatBoolean(values.is_have_sat_subject_report),
        sat_subject_test_array: JSON.stringify(SATData),
      };
      await dispatch(updateSATSubjectTest(newValues));
    },
  });

  const handleLogout = () => {
    dispatch(logout());
    Cookies.remove(authenticateSettings.tokenName);
    Cookies.remove('userId');
    history.push(routers.login.path);
  };
  
  const handleContinue = () => {
    formik.handleSubmit();
    history.push(routers.actTest.path);
  };

  const getDetail = async () => {
    studentId = Cookies.get('userId');
    token = Cookies.get(authenticateSettings.tokenName);
    if (token && studentId) {
      setLoading(true);
      let res = await dispatch(getSATSubjectTest(studentId));
      const dataLoaded: any = res.payload;
      if (dataLoaded) {
        const values: any = {
          ...dataLoaded,
          is_have_sat_subject_report: BoolToString(dataLoaded.is_have_sat_subject_report),
        };
        formik.setValues(values);
        setSATData(JSON.parse(dataLoaded.sat_subject_test_array));
      }
      setLoading(false);
      formik.setStatus('idle');
    }
    else {
      handleLogout();
    }
  };

  React.useEffect(() => {
    getDetail();
  }, []);
  
  React.useEffect(() => {
    setSATData(setArrayLength(SATData, formik.values.number_of_sat_subject_report));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.values.number_of_sat_subject_report]);

  return loading ? (
    <LoadingContent />
  ) : (
    <>
      <TopProcessBar
        stepProcess={[]}
        title="Standardized Testing"
        process={1}
        icon={<CalculateOutlinedIcon />}
      />

      <Container className="main-container">
        <div className="px-2">
          <Form style={{ maxWidth: '900px' }} onSubmit={formik.handleSubmit}>
            <HtmlTooltip
              title={
                <React.Fragment>
                  <Typography className="fs-6 text-dark my-2 fw-bold">
                    <InfoOutlinedIcon fontSize="small" className="mx-1" />
                    SAT Subject Tests
                  </Typography>
                  <div className="d-flex flex-wrap text-field-light">
                    Good news! On Jan. 19th, 2021, SAT subject tests were discontinued. If you have
                    taken any of the tests in the past, you can include them here. Since they are
                    optional now, be sure to check to see if your score is within the range of what
                    a specific school accepts and then include the scores here.⁤
                  </div>
                </React.Fragment>
              }
              placement="right-start"
            >
              <Row className="m-0">
                <div className="text-field-mid fs-3 mb-4">SAT Subject Tests</div>
                <div className="text-field-light my-3">
                  Do you have any SAT Subject Tests you wish to report? *
                </div>
                <YesNoSwitch
                  className="uc-coallition-flag mb-3"
                  name="is_have_sat_subject_report"
                  values={formik.values.is_have_sat_subject_report}
                  onChange={formik.handleChange}
                />
                <SelectInput
                  className="common-coallition-flag"
                  form={formik}
                  label="Number of SAT Subject Tests you wish to report, including tests you expect to take*"
                  mdCol={'12'}
                  option={optionValues(Array.from(Array(11).keys()))}
                  values={formik.values.number_of_sat_subject_report}
                  name="number_of_sat_subject_report"
                  onChange={formik.handleChange}
                />
                {(SATData || []).map((item, index) => (
                  <SATSubjectConfig
                    handleSave={(values) => {
                      const newSATData = [...SATData];
                      newSATData.splice(index, 1, values);
                      setSATData(newSATData);
                    }}
                    item={item}
                  />
                ))}
              </Row>
            </HtmlTooltip>

            <div className="my-3 d-flex justify-content-end border-top pt-3">
              <div className="col col-md-6 text-center text-md-end">
                <Button variant="primary" type="submit" className="p-2 px-5 mx-2">
                  {testing.status === 'loading' ? (
                    <Spinner size="sm" animation="border" variant="light" />
                  ) : (
                    'Save'
                  )}
                </Button>
                <Button variant="primary" className="p-2 px-5" onClick={() => handleContinue()}>
                  Continue
                </Button>
              </div>
            </div>
          </Form>
        </div>
      </Container>
    </>
  );
};

export default SATSubjectTest;
