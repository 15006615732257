import { countryOptions } from 'components/helper/countryPhoneCode';
import GroupRadioButton from 'components/input/GroupRadioButton';
import SelectInput from 'components/input/SelectInput';
import React from 'react';
import collegeServices from 'services/collegeServices';

type Props = {
  formik: any;
};

const CollegeInfo = ({ formik }: Props) => {
  const [stateOptions, setStateOptions] = React.useState<any[]>([]);
  React.useEffect(() => {
    // handleSearchCollege('');  UserSnap #505
    collegeServices.getState().then((res: any) => {
      const states = res.map((item: any) => {
        return item.descr;
      });
      setStateOptions(states);
    });
    return () => {
      setStateOptions([]);
    };
  }, []);

  return (
    <>
      <GroupRadioButton
        name="college_info.where_attend_college"
        onChange={formik.handleChange}
        values={formik.values.college_info?.where_attend_college}
        mdCol={4}
        label="Where did you attend college? *"
        options={['In California', 'Outside California (in the U.S.)', 'Outside the U.S.']}
      />
      {formik.values.college_info?.where_attend_college === 'Outside California (in the U.S.)' && (
        <>
          <SelectInput
            name="college_info.state"
            values={formik.values.college_info?.state}
            onChange={formik.handleChange}
            label="State/U.S. territory"
            option={stateOptions}
          />
        </>
      )}
      {formik.values.college_info?.where_attend_college === 'Outside the U.S.' && (
        <SelectInput
          name="college_info.country"
          values={formik.values.college_info?.country}
          onChange={formik.handleChange}
          label="Select a country *"
          option={countryOptions}
        />
      )}
    </>
  );
};

export default CollegeInfo;
