import { resetPassword } from 'app/authorization/authorizationSlice';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { mediumPasswordRegexp, strongPasswordRegexp } from 'components/helper/helper';
import PasswordInput from 'components/input/PasswordInput';
import { useFormik } from 'formik';
import React from 'react';
import { Form, Row, Button, Image, Card, CloseButton } from 'react-bootstrap';
import { useHistory } from 'react-router';
import { routers } from 'router/router';
import { passwordStrengthProps } from 'screen/auth/register/FormRegister';
import * as Yup from 'yup';

interface Props {
  setStep: (value: any) => void;
}

const FormInputNewPassword = (props: Props) => {
  const history = useHistory();
  const dispatch = useAppDispatch();
  const authorizationStore = useAppSelector((store) => store.authorization);
  const [passwordInputed, setPasswordInputed] = React.useState(false);
  const [passwordStrength, setPasswordStrength] = React.useState<passwordStrengthProps | undefined>(
    undefined
  );
  const formik = useFormik({
    initialValues: {
      password: '',
      password_confirm: '',
    },
    validationSchema: Yup.object({
      password: Yup.string().min(6, 'Minlength is 6').required('Required'),
      password_confirm: Yup.string()
        .oneOf([Yup.ref('password'), null], 'Passwords must match')
        .required('Required'),
    }),
    onSubmit: async (values) => {
      const res = await dispatch(
        resetPassword({
          email: authorizationStore.myAuthorizationEmail,
          verification_code: authorizationStore.verifyCode,
          password: values.password,
        })
      );
      if (res.payload) setPasswordInputed(true);
    },
  });

  React.useEffect(() => {
    if (formik.values.password.length > 2) {
      if (strongPasswordRegexp.test(formik.values.password)) {
        setPasswordStrength({
          title: 'Strong Password',
          content: 'Great! This would do, now just don’t forget it.',
          status: 'text-success',
        });
      } else if (mediumPasswordRegexp.test(formik.values.password)) {
        setPasswordStrength({
          title: 'Medium Password',
          content:
            'This password look good. Add one lowercase letter , one uppercase letter , one digit , one special character for stronger.',
          status: 'text-warning',
        });
      } else {
        setPasswordStrength({
          title: 'Weak Password',
          content: 'You password too weak.',
          status: 'text-danger',
        });
      }
    }
  }, [formik.values.password]);
  return (
    <>
      {passwordInputed ? (
        <>
          <div className="d-flex flex-column justify-content-center align-items-center">
            <Image src={'/assets/images/image-12.png'} />
            <p className="h3 mt-5">Password updated!</p>
            <p className="h6">Your password has been updated succesfully!</p>
            <Button
              className="w-25 p-3 m-3"
              variant="primary"
              onClick={() => {
                history.push(routers.login.path);
              }}
            >
              <span className="h5 fw-bold">Login</span>
            </Button>
          </div>
        </>
      ) : (
        <>
          <h2 className="m-3">Restore my password</h2>
          <div className="m-3 d-flex justify-content-between align-items-center">
            <h5 className="m-3">Create a new password</h5>
          </div>
          <div className="w-50 m-3">
            <Form onSubmit={formik.handleSubmit}>
              <Row className="mb-1">
                <PasswordInput
                  mdCol={'12'}
                  label={'Password'}
                  name={'password'}
                  values={formik.values.password}
                  onChange={formik.handleChange}
                  feedback={
                    formik.touched.password &&
                    formik.errors.password && (
                      <span className="text-danger">{formik.errors.password}</span>
                    )
                  }
                />
                <PasswordInput
                  mdCol={'12'}
                  label={'Password Confirmation'}
                  name={'password_confirm'}
                  values={formik.values.password_confirm}
                  onChange={formik.handleChange}
                  feedback={
                    formik.touched.password_confirm &&
                    formik.errors.password_confirm && (
                      <span className="text-danger">{formik.errors.password_confirm}</span>
                    )
                  }
                />
              </Row>
              {passwordStrength && (
                <Card className="my-3 p-1 py-0 border" body>
                  <div className="d-flex justify-content-between">
                    <span className={`h5 ${passwordStrength.status}`}>
                      {passwordStrength.title}
                    </span>
                    <CloseButton onClick={() => setPasswordStrength(undefined)} />
                  </div>
                  <p className="h6">{passwordStrength.content}</p>
                </Card>
              )}
              <Button className="w-100 p-2 my-3" variant="primary" type="submit">
                <span className="h5 fw-bold">Update Password</span>
              </Button>
            </Form>
          </div>
        </>
      )}
    </>
  );
};

export default FormInputNewPassword;
