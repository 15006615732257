import http from './http';

const partnerServices = {
  async createPartner(body: any): Promise<any> {
    const res = await http.post('api/partner', body);
    return res;
  },

  async inviteCounselor(student_id: any, email: any): Promise<any> {
    let postData = {
      student_id: student_id,
      email: email,
    };

    const res = await http.post('api/partner/invite-counselor', postData, {
      headers: {
        'content-type': 'application/json;charset=UTF-8',
        'Access-Control-Allow-Origin': '*',
      },
    });
    return res;
  },

  async getCurrentCounselor(student_id: any): Promise<any> {
    let postData = {
      student_id: student_id,
    };

    const res = await http.post('api/partner/get-current-counselor', postData, {
      headers: {
        'content-type': 'application/json;charset=UTF-8',
        'Access-Control-Allow-Origin': '*',
      },
    });
    return res;
  },

  async getInvitePartnerList(student_id: any): Promise<any> {
    let postData = {
      partner_id: student_id,
    };

    const res = await http.post('api/partner/get-invite-partner-list', postData, {
      headers: {
        'content-type': 'application/json;charset=UTF-8',
        'Access-Control-Allow-Origin': '*',
      },
    });
    return res;
  },

  async updateCurrentCounselor(body: any): Promise<any> {
    const res = await http.put('api/partner', body);
    return res;
  },

  async updateParnerStatus(student_id: any, email: string, status_code: string): Promise<any> {
    let postData = {
      student_id: student_id,
      email: email,
      status_code: status_code,
    };

    const res = await http.post('api/partner/update-partner-status', postData, {
      headers: {
        'content-type': 'application/json;charset=UTF-8',
        'Access-Control-Allow-Origin': '*',
      },
    });
    return res;
  },
};

export default partnerServices;
