import * as React from 'react';
import { authenticateSettings } from 'components/helper/appConfig';
import ListSubheader from '@mui/material/ListSubheader';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import Collapse from '@mui/material/Collapse';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import { appMenuGroups, icons } from 'router/router';
import SaveOutlinedIcon from '@mui/icons-material/SaveOutlined';
import { useHistory, useLocation } from 'react-router';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { getHousehold } from 'app/family/familySlice';
import Cookies from 'js-cookie';
import { getGeneral } from 'app/testingSlice/testingSlice';
import { routers } from 'router/router';
import { logout } from 'app/basicInformationSlice/basicInformationSlice';

export default function NestedList() {
  const roleFamily = useAppSelector((store) => store.family.roleFamily);
  const roleTest = useAppSelector((store) => store.testing.roleTest);
  const [open, setOpen] = React.useState<string | null>(null);
  let history = useHistory();

  let location = useLocation();
  React.useEffect(() => {
    appMenuGroups.forEach((route: any) => {
      if (
        route.children.find((e: any) => location?.pathname?.includes(e?.path?.replace('/:id', '')))
      ) {
        setOpen(route.name);
      }
    });
  }, [location]);

  const handleLogout = () => {
    dispatch(logout());
    history.replace(routers.login.path);
  };

  const handleClick = (route: any) => {
    if (open === route.name) return setOpen(null);
    setOpen(route.name);
    if (route.isGroup === false) {
      history.push(route.route.path);
    }
  };
  const studentId = Cookies.get('userId');
  const token = Cookies.get(authenticateSettings.tokenName);
  const dispatch = useAppDispatch();
  React.useEffect(() => {
    async function getData(){
      if (token && studentId) {
        try {
          await dispatch(getHousehold(studentId)).unwrap();
          await dispatch(getGeneral(studentId)).unwrap();
        }
        catch(ex){
           // force logout
          handleLogout();
        }
      }
      else {
        // force logout
        handleLogout();
      }
    }
    getData();
   
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <List
      sx={{
        width: '100%',
        maxWidth: 360,
        bgcolor: 'background.paper',
        height: '100%',
        overflow: 'auto',
      }}
      component="nav"
      subheader={
        <ListSubheader component="div" className="text-field-light text-center">
          {/* All changes saved <SaveOutlinedIcon fontSize="small" /> */}
        </ListSubheader>
      }
    >
      {appMenuGroups.map((route: any, index: any) => {
        const IconBefore = icons[route.icon] || 'Component';
        return (
          <div key={index}>
            <ListItemButton
              onClick={() => handleClick(route)}
              className={`px-1 my-1 ${open === route.name && 'bg-light fw-bold'}`}
            >
              <IconBefore fontSize="small" className="me-2 text-secondary" />
              <span className="text-field-mid">{route.name}</span>
              {route.isGroup &&
                (open === route.name ? (
                  <ExpandLess fontSize="small" className="text-field-light" />
                ) : (
                  <ExpandMore fontSize="small" className="text-field-light" />
                ))}
            </ListItemButton>
            {route.isGroup && (
              <Collapse in={open === route.name} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                  {route.children.map((child: any, i: number) => {
                    const disabled =
                      child.permission && !{ ...roleFamily, ...roleTest }[child.permission];
                    return disabled ? null : (
                      <ListItemButton
                        key={i}
                        sx={{ pl: 2, pt: 2 }}
                        onClick={() => history.push(child?.path.replace(':id', studentId))}
                        disabled={disabled}
                      >
                        <span className="text-secondary">{child?.icon}</span>
                        <span
                          className={
                            location.pathname.includes(child?.path.replace(':id', ''))
                              ? 'text-primary fw-bold'
                              : 'text-field-mid'
                          }
                        >
                          {child?.name}
                        </span>
                      </ListItemButton>
                    );
                  })}
                </List>
              </Collapse>
            )}
          </div>
        );
      })}
    </List>
  );
}
