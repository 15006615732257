import { optionValues } from 'components/helper/helper';
import SelectInput from 'components/input/SelectInput';
import YesNoSwitch from 'components/input/YesNoSwitch';
import React from 'react';
import AdvanceExamConfig from './AdvanceExamConfig';
import PredictedAdvanceExamConfig from './PredictedAdvanceExamConfig';

interface Props {
  formik: any;
  setPredictedAdvanceExam: (values: any) => void;
  predictedAdvanceExam: any[];
  advanceExam: any[];
  setAdvanceExam: (values: any) => void;
}

const AdvanceLevelExams = (props: Props) => {
  return (
    <>
      <YesNoSwitch
        className="mb-3"
        label="Do you have any Advanced Level Exams you wish to report? *"
        name="is_have_advanced_level_exam_wish_report"
        values={props.formik.values.is_have_advanced_level_exam_wish_report}
        onChange={props.formik.handleChange}
      />
      {props.formik.values.is_have_advanced_level_exam_wish_report === 'true' && (
        <>
          <SelectInput
            form={props.formik}
            label="How many Advanced Level Exams would you like to report?"
            option={optionValues(Array.from(Array(7).keys()))}
            name="number_of_advanced_level_exam_report"
            values={props.formik.values.number_of_advanced_level_exam_report}
            onChange={props.formik.handleChange}
          />
          {(props.advanceExam || []).map((item, index) => (
            <AdvanceExamConfig
              key={index}
              handleSave={(values) => {
                const newAdvanceExam = [...props.advanceExam];
                newAdvanceExam.splice(index, 1, values);
                props.setAdvanceExam(newAdvanceExam);
              }}
              index={index + 1}
              item={item}
              count={`${index + 1}/${props.advanceExam.length}`}
            />
          ))}
          <div className="text-field-light">
            Do you have any Predicted Advanced Level Exam Grades you wish to report?
          </div>
          <YesNoSwitch
            className="mb-3"
            name="is_have_predicted_advanced_level_exam_wish_report"
            values={props.formik.values.is_have_predicted_advanced_level_exam_wish_report}
            onChange={props.formik.handleChange}
          />
          <div />
          <SelectInput
            form={props.formik}
            label="How many Predicted Advanced Level Exams would you like to report? "
            option={optionValues(Array.from(Array(7).keys()))}
            name="number_of_predicted_advanced_level_exam_report"
            values={props.formik.values.number_of_predicted_advanced_level_exam_report}
            onChange={props.formik.handleChange}
          />
          {(props.predictedAdvanceExam || []).map((item, index) => (
            <PredictedAdvanceExamConfig
              key={index}
              handleSave={(values) => {
                const newPredictedAdvanceExam = [...props.predictedAdvanceExam];
                newPredictedAdvanceExam.splice(index, 1, values);
                props.setPredictedAdvanceExam(newPredictedAdvanceExam);
              }}
              index={index + 1}
              count={`${index + 1}/${props.predictedAdvanceExam.length}`}
              item={item}
            />
          ))}
        </>
      )}
    </>
  );
};

export default AdvanceLevelExams;
