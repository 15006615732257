/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { Container, Form, Spinner, Button } from 'react-bootstrap';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import TopProcessBar from 'screen/selectCollege/TopProcessBar';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { useFormik } from 'formik';
import {
  BoolToString,
  checkEnableParseJSON,
  formatBoolean,
  userpilotDomain,
} from 'components/helper/helper';
import {
  getCBOList,
  getCitizenship,
  updateCitizenship,
} from 'app/basicInformationSlice/basicInformationSlice';
import Cookies from 'js-cookie';
import { useHistory } from 'react-router-dom';
import { routers } from 'router/router';
import { logout } from 'app/basicInformationSlice/basicInformationSlice';
import { authenticateSettings } from 'components/helper/appConfig';

import * as Yup from 'yup';
import LoadingContent from 'components/LoadingContent';
import { phoneRegExp } from 'components/helper/appConfig';
import { debounce } from 'lodash';
import EnrolimentStatus from 'screen/personal/citizenship/EnrolimentStatus';
import { Userpilot } from 'userpilot';
// import ReleaseStatement from './components/ReleaseStatement';

interface Props {}

const EnrolimentStatusPage = (props: Props) => {
  const [loading, setLoading] = React.useState(false);
  // const [CBOData, setCBOData] = React.useState<any>();
  const dispatch = useAppDispatch();
  const basicInformationStore = useAppSelector((store) => store.basicInformation);
  const history = useHistory();  
  let studentId = Cookies.get('userId');
  let token = Cookies.get(authenticateSettings.tokenName);
  const handleLogout = () => {
    dispatch(logout());
    Cookies.remove(authenticateSettings.tokenName);
    Cookies.remove('userId');
    history.push(routers.login.path);
  };  
  const formik = useFormik({
    initialValues: {
      csu_info: {
        data: '',
        country: '',
        visa_date_issued: '',
        year_move_to_us: '',
        degree_applying_for: '',
        educational_status: '',
        is_take_college_credit: '',
        is_certify: false,
        hereby_authorize_CD_release_CAASPP: '',
        is_authorize_CSU_release_contact_information: false,
        is_authorize_CSU_release_my_application: false,
        is_interested_on_campus_space_available: '',
        in_foster_care: '',
        is_emancipated_minor: '',
        is_homeless_liaison_determine: '',
        is_director_or_transitional_determine: '',
        is_director_of_runaway_or_homeless_youth_basic: '',
        ssid: '',
        attended_California_public_school: '',
        authorize_release_CASSID_for_tracking_UC_application: false,
        is_in_good_standing: '',
        is_disciplined_for_academic_performance: '',
        is_academically_disqualified: '',
        is_disciplined_for_student_conduct_violations: '',
        explain_for_each_violation: '',
        interest_in_teacher_or_educational_credential: '',
        is_interested_eligibility_assistance_CalFresh: '',
        how_hear_about_CalState: '',
        us_state_you_regard_as_permanent_home: '',
        visa_you_have_at_CSU_date_issued: '',
        visa_you_have_at_CSU: '',
        require_F1_Visa_or_J1_visa: '',
        current_working_with_representative_or_agency: '',
        representative_type: '',
        representative_name: '',
        representative_street_address: '',
        representative_street_address_line_2: '',
        representative_city: '',
        representative_state: '',
        representative_postal_code: '',
        representative_country: '',
        representative_phone: '',
        representative_email: '',
        year_move_to_US: '',
        state_your_parent_as_her_permanent_home: '',
      },
      country: '',
      is_have_us_social_security_number: '',
      social_security_number: '',
      is_graduated_california_high_school: '',
      is_three_or_more_year_graduated_california_high_school: '',
      is_currently_live_california: '',
      is_live_california_entire_life: '',
      month_live_here_since: '',
      year_live_here_since: 0,
      country_birthplace: '',
      city_birthplace: '',
      state_birthplace: '',
      number_of_year_in_us: 0,
      number_of_year_outside_us: 0,
      current_enrollment_status: '',
      when_graduate_high_school: '',
      year_plan_college_studies: 0,
      is_participate_cbo: '',
      your_cbo_array: '',
      indicate_number_community_programs: 0,
      cbo_info_array: '',
      advisor_number_community_programs: '',
      advisor_prefix: '',
      advisor_first_name: '',
      advisor_last_name: '',
      advisor_phone_number: '',
      advisor_email: '',
      is_financial_qualify_fee_waiver: '',
      indicator_economic_fee_waiver_array: '',
      fee_waiver_signature: '',
      answer_coalition_colleges_array: '',
      is_one_of_parent_permanent_resident_california: '',
      is_parent_employee_university_california: '',
      month_parent_become_permanent_residence: '',
      year_parent_become_permanent_residence: 0,
      citizenship_status: '',
      have_us_visa: '',
      type_of_apply_for_us_visa: '',
      type_of_current_us_visa: '',
      will_you_have_different_visa: '',
      planned_visa_type: '',
      current_citizenship_status: '',
      is_dual_citizenship: '',
      list_citizenship: [],
      statement_legal_resident: {
        is_like_to_be_evaluated_for_tuition_purposes: '',
        is_physically_present_for_3_years: '',
        is_attend_highschool_for_3_more_years: '',
        is_established_as_permanent_home: '',
        is_parent_1_physically_present: '',
        is_parent_1_US_citizen: '',
        is_parent_2_physically_present: '',
        is_parent_2_US_citizen: '',
        is_declare_under_penalty_of_perjury: false,
        is_understood_to_be_asked_submit_document: false,
        is_parent_1_married_to_parent_2: '',
        is_parents_live_together: '',
        have_lived_in_California_continuously_since_birth: '',
        when_your_present_stay_in_California_begin: '',
      },
    },
    validationSchema: Yup.object({
      advisor_phone_number: Yup.string().matches(phoneRegExp, 'Phone number is not valid'),
    }),

    onSubmit: async (values) => {
      Userpilot.identify((studentId ?? 'undefined') + userpilotDomain, {
        graduationYear: values?.year_plan_college_studies,
      });

      window.Intercom('update', {
        graduation_year: values?.year_plan_college_studies,
      });

      const newValues = {
        ...values,
        csu_info: JSON.stringify(values.csu_info),
        statement_legal_resident: JSON.stringify(values.statement_legal_resident),
        is_one_of_parent_permanent_resident_california: formatBoolean(
          values.is_one_of_parent_permanent_resident_california
        ),
        is_parent_employee_university_california: formatBoolean(
          values.is_parent_employee_university_california
        ),
        is_have_us_social_security_number: formatBoolean(values.is_have_us_social_security_number),
        is_graduated_california_high_school: formatBoolean(
          values.is_graduated_california_high_school
        ),
        is_three_or_more_year_graduated_california_high_school: formatBoolean(
          values.is_three_or_more_year_graduated_california_high_school
        ),
        is_currently_live_california: formatBoolean(values.is_currently_live_california),
        is_live_california_entire_life: formatBoolean(values.is_live_california_entire_life),
        is_financial_qualify_fee_waiver: formatBoolean(values.is_financial_qualify_fee_waiver),
        is_participate_cbo: formatBoolean(values.is_participate_cbo),
        indicator_economic_fee_waiver_array: JSON.stringify(formFeeWeiver.values),
        answer_coalition_colleges_array: JSON.stringify(formAnswerFeeWeiver.values),
        student_id: parseInt(studentId || ''),
        list_citizenship: JSON.stringify(values.list_citizenship),
      };
      await dispatch(updateCitizenship(newValues));
    },
  });
  const handleContinue = () => {
    formik.handleSubmit();
    history.push(routers.highSchool.path);
  };
  const formFeeWeiver = useFormik({
    initialValues: {
      is_in_free_and_reduced_program: false,
      is_college_free: false,
      is_ACT_free: false,
      is_NACAC_free: false,
      is_Pell_Grant: false,
      is_TRIO: false,
      is_none_fee_waiver: false,
      is_like_to_receive_information_from_strive_for_college: '',
    },
    onSubmit: (values) => {},
  });
  const formAnswerFeeWeiver = useFormik({
    initialValues: {
      answer_is_in_free_and_reduced_program: false,
      answer_is_college_free: false,
      answer_is_ACT_free: false,
      answer_is_NACAC_free: false,
      answer_is_Pell_Grant: false,
      answer_is_TRIO: false,
      answer_is_none_fee_waiver: false,
    },
    onSubmit: (values) => {},
  });

  const getDetail = async () => {
    studentId = Cookies.get('userId');
    token = Cookies.get(authenticateSettings.tokenName);
    if (token && studentId) {
      setLoading(true);
      let res = await dispatch(getCitizenship(studentId));
      const dataLoaded: any = res.payload;
      if (res && res.payload) {
        const values: any = {
          ...dataLoaded,
          csu_info: {
            ...formik.values.csu_info,
            ...checkEnableParseJSON(dataLoaded.csu_info),
          },
          statement_legal_resident: {
            ...formik.values.statement_legal_resident,
            ...checkEnableParseJSON(dataLoaded.statement_legal_resident),
          },
          list_citizenship: checkEnableParseJSON(dataLoaded.list_citizenship) || [],
          is_have_us_social_security_number: BoolToString(
            dataLoaded.is_have_us_social_security_number
          ),
          is_one_of_parent_permanent_resident_california: BoolToString(
            dataLoaded.is_one_of_parent_permanent_resident_california
          ),
          is_parent_employee_university_california: BoolToString(
            dataLoaded.is_parent_employee_university_california
          ),
          is_graduated_california_high_school: BoolToString(
            dataLoaded.is_graduated_california_high_school
          ),
          is_three_or_more_year_graduated_california_high_school: BoolToString(
            dataLoaded.is_three_or_more_year_graduated_california_high_school
          ),
          is_currently_live_california: BoolToString(dataLoaded.is_currently_live_california),
          is_live_california_entire_life: BoolToString(dataLoaded.is_live_california_entire_life),
          is_financial_qualify_fee_waiver: BoolToString(dataLoaded.is_financial_qualify_fee_waiver),
          is_participate_cbo: BoolToString(dataLoaded.is_participate_cbo),
          year_plan_college_studies: dataLoaded.year_plan_college_studies,
        };
        formik.setValues({ ...formik.values, ...values });
        if (dataLoaded.indicator_economic_fee_waiver_array) {
          formFeeWeiver.setValues({
            ...formFeeWeiver.values,
            ...JSON.parse(dataLoaded.indicator_economic_fee_waiver_array),
          });
          formAnswerFeeWeiver.setValues({
            ...formAnswerFeeWeiver.values,
            ...JSON.parse(dataLoaded.answer_coalition_colleges_array),
          });
        }
      }
      setLoading(false);
      formik.setStatus('idle');      
    }
    else {
      handleLogout();
    }
  };

  React.useEffect(() => {
    getDetail();
    if (!basicInformationStore.CBOList.length) {
      dispatch(getCBOList());
    }
  }, []);
  // Auto save function ==========
  React.useEffect(() => {
    if (!!formik.status) debounceSaving();
  }, [formik.values, formAnswerFeeWeiver.values, formFeeWeiver.values]);
  const debounceSaving = React.useCallback(
    debounce(() => {
      if (!loading) formik.handleSubmit();
    }, 1500),
    []
  );
  // Auto save function ==========
  return loading ? (
    <LoadingContent />
  ) : (
    <div className="w-100">
      <TopProcessBar
        stepProcess={[
          {
            title: 'Enrollment Status',
            percentOnProcess: 34,
            specialId: 'EnrolimentStatus',
          },
        ]}
        title="Education"
        process={1}
        icon={<PersonOutlineOutlinedIcon />}
      />
      <Container className="main-container">
        <div className="px-2">
          <Form style={{ maxWidth: '900px' }} onSubmit={formik.handleSubmit}>
            <EnrolimentStatus formik={formik} />
            <div className="my-3 d-flex justify-content-end border-top pt-3">
              <div className="col col-md-6 text-center text-md-end">
                <Button variant="primary" type="submit" className="p-2 px-5 mx-2">
                  {basicInformationStore.status === 'loading' ? (
                    <Spinner size="sm" animation="border" variant="light" />
                  ) : (
                    'Save'
                  )}
                </Button>
                <Button variant="primary" className="p-2 px-5" onClick={() => handleContinue()}>
                  Continue
                </Button>
              </div>
            </div>
          </Form>
        </div>
      </Container>
    </div>
  );
};

export default EnrolimentStatusPage;
