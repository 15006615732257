import NotificationsOutlinedIcon from '@mui/icons-material/NotificationsOutlined';
import { Button, Dropdown, ButtonGroup } from 'react-bootstrap';
import { routers } from 'router/router';
import { useHistory } from 'react-router';
import { useAppDispatch } from 'app/hooks';
import { logout } from 'app/basicInformationSlice/basicInformationSlice';
import MobileMenu from './MobileMenu';
import MenuIcon from '@mui/icons-material/Menu';
import toast from 'react-hot-toast';

interface Props {}
const MobileHeader = (props: Props) => {
  const dispatch = useAppDispatch();
  let history = useHistory();
  const handleLogout = () => {
    dispatch(logout());
    toast.success("You have been successfully logged out!");
    history.push(routers.login.path);
  };
  return (
    <div className="gm-topbar">
      <span>
        <Dropdown as={ButtonGroup}>
          <Dropdown.Toggle variant="white" id="dropdown-split-basic">
            <MenuIcon />
          </Dropdown.Toggle>

          <Dropdown.Menu>
            <Dropdown.Item onClick={() => history.push(routers.settings.path)}>
              Settings
            </Dropdown.Item>
            <Dropdown.Item onClick={() => handleLogout()}>Logout</Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </span>
      <span>
        <MobileMenu />
      </span>
      <span className="mx-3">
        <Button variant="outline-dart" className="border rounded" size="sm">
          <NotificationsOutlinedIcon />
        </Button>
      </span>
    </div>
  );
};

export default MobileHeader;
