/* eslint-disable react-hooks/exhaustive-deps */
import * as React from 'react';
import { styled } from '@mui/material/styles';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Collapse from '@mui/material/Collapse';
import IconButton, { IconButtonProps } from '@mui/material/IconButton';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Col, Row, Button } from 'react-bootstrap';
import { useFormik } from 'formik';
import DatePickerInput from 'components/input/DatePicker';
import SelectInput from 'components/input/SelectInput';
import { dateFormat } from 'components/helper/helper';
import moment from 'moment';
import GroupRadioButtonRounded from 'components/input/GroupRadioButtonRounded';
import YesNoSwitch from 'components/input/YesNoSwitch';
import {
  ibSubjectOption,
  ucibSubjectOption,
  //cnibSubjectOption,
  ibSubjectHaveLanguageQuestion,
  csu_ibSubjectOption,
} from 'components/helper/appConfig';
import GroupRadioButton from 'components/input/GroupRadioButton';
import GroupCheckbox from 'components/input/GroupCheckbox';
import TextInput from 'components/input/textInput';
interface ExpandMoreProps extends IconButtonProps {
  expand: boolean;
}

const ExpandMore = styled((props: ExpandMoreProps) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
  marginLeft: 'auto',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest,
  }),
}));

interface Props {
  handleSave: (value: any) => void;
  item: any;
  indexText: string;
}

const IBSubjectConfig = (props: Props) => {
  const formItem = useFormik({
    initialValues: {
      isReceivedScore: '',
      examStatus: 'Planned',
      subject: '',
      dateTaken: null,
      score: '',
      ibExam: '',
      ibExamName: '',                     // for UC App only
      level: '',
      ibExamSubjectName: '',              // for UC App only
      ibExamSubjectNameDescribeOther: '', // for UC App only
      ibExamSubjectNameCSUApp: '',        // for CSU App only
      ibExamSpecifyLanguageCSUApp: '',    // for CSU App only
      language: '',
      isIncludeIntoCommonApp: false,
      isIncludeIntoUCApp: false,
      isIncludeIntoCSUApp: false,
    },
    onSubmit: (values) => {},
  });
  const [expanded, setExpanded] = React.useState(false);
  const handleExpandClick = () => {
    setExpanded(!expanded);
  };
  React.useEffect(() => {
    if (props.item) formItem.setValues({ ...formItem.values, ...props.item });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [ucibExcamOption, setUcibExcamOption] = React.useState<string[]>([]);
  React.useEffect(() => {
    let optionData = ucibSubjectOption.find((e) => e.value === formItem.values.ibExamSubjectName);
    let examOption =
      formItem.values.level === 'Higher Level (HL)'
        ? optionData?.optionHL || []
        : optionData?.optionSL || [];
    setUcibExcamOption(examOption);
    if (!examOption.includes(formItem.values.ibExamName) && formItem.dirty) {
      formItem.setFieldValue('ibExamName', '');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formItem.values.ibExamSubjectName, formItem.values.level]);
  

  React.useEffect(() => {
    if (formItem.dirty) {
      props.handleSave({ ...formItem.values });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formItem.values]);

  const renderFillAppStatus = () => {
    return (
      <span>
        {formItem.values.isIncludeIntoCommonApp && (
          <img
            alt="cm"
            src="/assets/icons/icon-cm-5.svg"
            style={{ marginTop: '-8px', marginLeft: '4px' }}
          />
        )}
        {formItem.values.isIncludeIntoUCApp && (
          <img
            alt="uc"
            src="/assets/icons/icon-uc-5.svg"
            style={{ marginTop: '-8px', marginLeft: '4px' }}
          />
        )}
        {formItem.values.isIncludeIntoCSUApp && (
          <img
            alt="csu"
            src="./assets/icons/icon-csu-5.svg"
            style={{ marginTop: '-8px', marginLeft: '4px' }}
          />
        )}
      </span>
    );
  };
  return (
    <Card className="w-100 my-3 px-0">
      <CardActions
        disableSpacing
        onClick={handleExpandClick}
        sx={{
          backgroundColor: expanded ? '#AAEBF7' : 'none',
          borderBottom: expanded ? '2px solid #4D88F0' : 'none',

          marginLeft: '-8px',
        }}
      >
        <div className="w-100 d-flex justify-content-between ms-2">
          <span className={`p-1 `}>
            {formItem.values.subject}
            {formItem.values.score && <>- {formItem.values.score}</>}
            {formItem.values.dateTaken && (
              <>({moment(formItem.values.dateTaken).format(dateFormat)})</>
            )}
            {renderFillAppStatus()}
          </span>
          <strong className="text-field-light">{props.indexText}</strong>
        </div>
        <ExpandMore
          expand={expanded}
          onClick={handleExpandClick}
          aria-expanded={expanded}
          aria-label="show more"
        >
          <ExpandMoreIcon />
        </ExpandMore>
      </CardActions>
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <CardContent>
          <Row className="m-2">
            <Row>
              <Col md={6} xs={12}>
                <GroupRadioButtonRounded
                  name="examStatus"
                  label="Is this exam completed or planned?"
                  values={formItem.values.examStatus}
                  onChange={formItem.handleChange}
                  options={[{ value: 'Completed' }, { value: 'Planned' }]}
                />
              </Col>
              <Col md={6}>
                <YesNoSwitch
                  className="mb-3"
                  label="Have you received your score yet? *"
                  name="isReceivedScore"
                  values={formItem.values.isReceivedScore}
                  onChange={(e: any) => {
                    formItem.handleChange(e);
                    if (e.target.value === 'false') {
                      formItem.setFieldValue('score', 'Score Not Yet Received');
                    }
                  }}
                />
              </Col>
            </Row>
            <SelectInput
              form={formItem}
              option={ibSubjectOption}
              label="Subject"
              name="subject"
              className="common-flag"
              values={formItem.values.subject}
              onChange={formItem.handleChange}
              logo="span-cm-logo"
            />
            <SelectInput
              mdCol='6'
              form={formItem}
              option={['Standard Level (SL)', 'Higher Level (HL)']}
              label="Level (select the one that applies)*"
              name="level"
              values={formItem.values.level}
              onChange={formItem.handleChange}
            />            
            <GroupRadioButton
              mdCol={2}
              name="language"
              values={formItem.values.language}
              onClear={() => formItem.setFieldValue('language', '')}
              onChange={formItem.handleChange}
              options={[
                { value: 'English' },
                { value: 'French' },
                { value: 'Portuguese' },
                { value: 'Spanish' },
                { value: 'Turkish' },
              ]}
              label="Language (select the one that applies)*"
              hidden={!ibSubjectHaveLanguageQuestion.includes(formItem.values.subject)}
            />

            {/* <SelectInput
              form={formItem}
              option={cnibSubjectOption}
              label="IB exam *"
              name="ibExam"
              className="coallition-flag end-flag d-none"
              values={formItem.values.ibExam}
              onChange={formItem.handleChange}
            /> */}
            <SelectInput
              form={formItem}
              option={ucibSubjectOption}
              label="IB exam subject area"
              name="ibExamSubjectName"
              className="uc-flag"
              logo="span-uc-logo"
              values={formItem.values.ibExamSubjectName}
              onChange={formItem.handleChange}
            /> 
            <SelectInput
              form={formItem}
              option={ucibExcamOption}
              logo="span-uc-logo"
              label="IB exam name *"
              name="ibExamName"
              className="uc-flag end-flag"
              values={formItem.values.ibExamName}
              onChange={formItem.handleChange}
            />
            {formItem.values.ibExamName === 'Other' && (
              <>
              <div className="col-md-6">&nbsp;</div>
              <TextInput
                countText={30}
                label="Describe other *"
                className="uc-flag end-flag"
                logo="span-uc-logo"          
                name="ibExamSubjectNameDescribeOther"
                values={formItem.values.ibExamSubjectNameDescribeOther} 
                onChange={formItem.handleChange}
              />
              </>
            )}

            <SelectInput
              form={formItem}
              option={csu_ibSubjectOption}
              label="IB exam subject area"
              name="ibExamSubjectNameCSUApp"
              className="csu-flag"
              logo="span-csu-logo"
              values={formItem.values.ibExamSubjectNameCSUApp}
              onChange={formItem.handleChange}
            /> 

            <TextInput
              countText={50}
              label="Specify Language"
              className="csu-flag"
              logo="span-csu-logo"              
              name="ibExamSpecifyLanguageCSUApp"
              values={formItem.values.ibExamSpecifyLanguageCSUApp} 
              onChange={formItem.handleChange}
            />

            <DatePickerInput
              label={'Date taken or planned*'}
              name="dateTaken"
              values={formItem.values.dateTaken}
              onChange={(value) => formItem.setFieldValue('dateTaken', value)}
            />

            {formItem.values.isReceivedScore === 'true' && (
              <SelectInput
                form={formItem}
                label="Score *"
                name="score"
                option={['1', '2', '3', '4', '5', '6', '7'].reverse()}
                values={formItem.values.score === 'Score Not Yet Received' ? '1' : formItem.values.score}
                onChange={formItem.handleChange}
              />
            )}
            {formItem.values.isReceivedScore === 'false' && (
              <SelectInput
                form={formItem}
                className="csu-flag"
                logo="span-csu-logo" 
                label="Score *"
                name="score"
                option={['Score Not Yet Received']}
                values={formItem.values.score}
                onChange={formItem.handleChange}
              />
            )}

            <GroupCheckbox
              label="Include test info into these applications"
              form={formItem}
              mdCol={3}
              options={[
                {
                  value: 'isIncludeIntoCommonApp',
                  label: 'Common App',
                },
                {
                  value: 'isIncludeIntoUCApp',
                  label: 'UC App',
                },
                {
                  value: 'isIncludeIntoCSUApp',
                  label: 'CSU App',
                },
              ]}
            />
            <Col xs={6} md={9} />
            <Col xs={6} md={3} className="p-3">
              <Button
                className="w-100"
                onClick={() => {
                  props.handleSave(formItem.values);
                  setExpanded(false);
                }}
              >
                Save Info
              </Button>
            </Col>
          </Row>
        </CardContent>
      </Collapse>
    </Card>
  );
};

export default IBSubjectConfig;
