import http from './http';

const activitiesAndHonorsServices = {
  async getEducationalProgram(student_id: any) {
    const res = await http.get(`api/educational-program-participation/${student_id}`);
    return res;
  },

  async createEducationalProgram(body: any): Promise<any> {
    const res = await http.post('api/educational-program-participation', body);
    return res;
  },

  async updateEducationalProgram(body: any): Promise<any> {
    const res = await http.put('api/educational-program-participation', body);
    return res;
  },

  async getActivitiesAndHonors(student_id: any) {
    const res = await http.get(`api/activity-honor/${student_id}`);
    return res;
  },

  async createActivitiesAndHonors(body: any): Promise<any> {
    const res = await http.post('api/activity-honor', body);
    return res;
  },

  async updateActivitiesAndHonors(body: any): Promise<any> {
    const res = await http.put('api/activity-honor', body);
    return res;
  },

  async getAdditionalInfo(student_id: any) {
    const res = await http.get(`api/additional-information/${student_id}`);
    return res;
  },

  async createAdditionalInfo(body: any): Promise<any> {
    const res = await http.post('api/additional-information', body);
    return res;
  },

  async updateAdditionalInfo(body: any): Promise<any> {
    const res = await http.put('api/additional-information', body);
    return res;
  },
};

export default activitiesAndHonorsServices;
