import * as React from 'react';
import { styled } from '@mui/material/styles';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Collapse from '@mui/material/Collapse';
import IconButton, { IconButtonProps } from '@mui/material/IconButton';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Col, Row, Button } from 'react-bootstrap';
import { useFormik } from 'formik';
import TextInput from 'components/input/textInput';
import DatePickerInput from 'components/input/DatePicker';
import GroupCheckbox from 'components/input/GroupCheckbox';
import SelectInput from 'components/input/SelectInput';
import { Chip } from '@mui/material';
import { optionValues2 } from 'components/helper/helper';
import moment from 'moment';
import YesNoSwitch from 'components/input/YesNoSwitch';
import collegeServices from 'services/collegeServices';
import CollegeInfo from './CollegeInfo';
import GroupRadioButton from 'components/input/GroupRadioButton';
import {
  collegeDetailEarnedOption,
  majorOption,
  minorOption,
  earnDegreeYears,
} from './CollegeConstant';
import CheckboxInput from 'components/input/CheckboxInput';
import { debounce } from 'lodash';
import AutocompleteInput from 'components/input/AutoCompleteInput';
import FormCreateCollegeModal from './FormCreateCollegeModal';
interface ExpandMoreProps extends IconButtonProps {
  expand: boolean;
}

const ExpandMore = styled((props: ExpandMoreProps) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
  marginLeft: 'auto',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest,
  }),
}));

interface Props {
  handleSave: (value: any) => void;
  item: any;
  index: string;
}

export const initialCollegeValues = {
  college_info: {
    where_attend_college: '',
    state: '',
    country: '',
    college_name: '',
    detail_degree_earned: '',
    when_you_earn_degree_year: '',
    when_you_earn_degree_month: '',
    your_major: '',
    your_minor: '',
    have_double_major: false,
    your_second_major: '',
    type_of_term_systerm: '',
    type_of_term_systerm_uc: '',
    your_tuition_status: '',
  },
  where_attend_college: '',
  state: '',
  country: '',
  college_name: '',
  fullName: '',
  college_code: '',
  college_city: '',
  college_ceeb: '',
  college_state: '',
  csu_name: '',
  fromDate: '',
  toDate: '',
  college_is_dual_enrollment: false,
  college_is_credit_awarded: false,
  college_is_summer_program: false,
  degree_earned: 'No degree earned',
  degree_info: '',
  expect_to_earn_degree_from_San_Diego_Mesa: '',
  grades: [],
  grades_fall: [],
  grades_winter: [],
  grades_spring: [],
  grades_summer: [],
  college_grading_system_alphabet: false,
  college_grading_system_100: false,
  college_grading_system_7: false,
  college_grading_system_other: false,
  lowest_number_grade: '',
  highest_number_grade: '',
  other_grading_system: '',
  isStillAttendingThisCollege: false,
};

const CollegeConfig = (props: Props) => {
  const formItem = useFormik({
    initialValues: initialCollegeValues,
    onSubmit: (values) => {},
  });
  const [expanded, setExpanded] = React.useState(false);
  let searchCtrl = new AbortController();

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  React.useEffect(() => {
    if (props.item)
      formItem.resetForm({
        values: {
          ...formItem.values,
          ...props.item,
          grades: Array.isArray(props.item?.grades) ? props.item?.grades : [props.item?.grades],
          grades_fall: Array.isArray(props.item?.grades_fall)
            ? props.item?.grades_fall
            : [props.item?.grades_fall],
          grades_winter: Array.isArray(props.item?.grades_winter)
            ? props.item?.grades_winter
            : [props.item?.grades_winter],
          grades_spring: Array.isArray(props.item?.grades_spring)
            ? props.item?.grades_spring
            : [props.item?.grades_spring],
          grades_summer: Array.isArray(props.item?.grades_summer)
            ? props.item?.grades_summer
            : [props.item?.grades_summer],
        },
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
 
  const [gradeOptions, setGradeOptions] = React.useState<any[]>([]);

  React.useEffect(() => {
    if (moment(formItem.values.fromDate).year() && moment(formItem.values.toDate).year()) {
      let dateRange =
        moment(formItem.values.toDate).year() - moment(formItem.values.fromDate).year() + 1;
      if (dateRange > 0) {
        const newGradeOptions = optionValues2(dateRange).map((item) => {
          return { value: item.value + moment(formItem.values.fromDate).year() };
        });
        setGradeOptions([' ', ...newGradeOptions]);
      }
    }
  }, [formItem.values.fromDate, formItem.values.toDate]);
  
  const [collegeData, setCollegeData] = React.useState<any[]>([]);
  const handleSearchCollege = async (keyword: string) => {
    try {
      if (keyword && keyword.length>0) {
        searchCtrl.abort();
        searchCtrl = new AbortController();
         // remove ' (code.... ) in keyword
       let indexOfFirst = keyword.indexOf(` - (`);
       if (indexOfFirst>0) {
         keyword = keyword.substring(0,indexOfFirst);
       }
       indexOfFirst = keyword.indexOf(` -`);
       if (indexOfFirst>0) {
         keyword = keyword.substring(0,indexOfFirst);
       }

       keyword= keyword.trim();

      const res: any = await collegeServices.getCollegeEx({ keyword: keyword}, searchCtrl );
      const data = res.map((item: any) => ({
        id: item.id,
        label: item.name,
        value: item.ceeb ? item.name + ` - (${('000' + item.ceeb).slice(-4)})` : item.name,
        code: item.zip_code,
        ceeb: item.ceeb,
        state: item.state,
        city: item.city,
        csu_name: item.csu_name,
      }));

      setCollegeData(data);
      }     
    }
    catch(ex){
      console.log('Error: ', ex);
    }

  };
  React.useEffect(() => {
    if (formItem.dirty) {
      props.handleSave({ ...formItem.values });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formItem.values]);

  const handleChangeItem = (item: any) => {
    formItem.setFieldValue('college_code', item?.code);
    formItem.setFieldValue('college_state', item?.state);
    formItem.setFieldValue('college_city', item?.city);
    formItem.setFieldValue('college_ceeb', item?.ceeb);
    formItem.setFieldValue('csu_name', item?.csu_name);
  };

  const handleAddTerm = () => {
    if (formItem.values.grades.length > 3) return;
    formItem.setFieldValue('grades', [...formItem.values.grades, ' ']);
    formItem.setFieldValue('grades_fall', [...formItem.values.grades_fall, ' ']);
    formItem.setFieldValue('grades_winter', [...formItem.values.grades_winter, ' ']);
    formItem.setFieldValue('grades_summer', [...formItem.values.grades_summer, ' ']);
    formItem.setFieldValue('grades_spring', [...formItem.values.grades_spring, ' ']);
  };

  const handleRemoveTerm = (index: number) => {
    formItem.setFieldValue(
      'grades',
      formItem.values.grades.filter((item: any, i: number) => i !== index)
    );
    formItem.setFieldValue(
      'grades_fall',
      formItem.values.grades_fall.filter((item: any, i: number) => i !== index)
    );
    formItem.setFieldValue(
      'grades_winter',
      formItem.values.grades_winter.filter((item: any, i: number) => i !== index)
    );
    formItem.setFieldValue(
      'grades_summer',
      formItem.values.grades_summer.filter((item: any, i: number) => i !== index)
    );
    formItem.setFieldValue(
      'grades_spring',
      formItem.values.grades_spring.filter((item: any, i: number) => i !== index)
    );
  };

  const [openFormCreate, setOpenFormCreate] = React.useState(false);
  const handleSaveCollege = (values: any) => {
    formItem.setFieldValue('college_code', values?.ceeb);
    formItem.setFieldValue('college_city', values?.city);
    formItem.setFieldValue('college_ceeb', values?.ceeb);
    formItem.setFieldValue('csu_name', values?.label);
    formItem.setFieldValue('fullName', values?.label);
    setOpenFormCreate(false);
  };

  return (
    <Card className="w-100 my-3 px-0">
      <CardActions
        disableSpacing
        onClick={handleExpandClick}
        sx={{
          ml: '-8px',
          backgroundColor: expanded ? '#AAEBF7' : 'none',
          borderBottom: expanded ? '2px solid #4D88F0' : 'none',
        }}
      >
        <div className="w-100 d-flex justify-content-between ps-3">
          <span className="p-1">{formItem.values.fullName}</span>
          <strong className="text-field-light">{props.index}</strong>
        </div>
        <ExpandMore
          expand={expanded}
          onClick={handleExpandClick}
          aria-expanded={expanded}
          aria-label="show more"
        >
          <ExpandMoreIcon />
        </ExpandMore>
      </CardActions>
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <CardContent>
          <Row className="px-3">
            <CollegeInfo formik={formItem} />
            {/* <SelectSearchInput
              logo="span-cm-logo"
              mdCol="12"
              form={formItem}
              label="College Name"
              name="fullName"
              values={formItem.values.fullName}
              option={collegeData}
              onSearch={debounce((keyword) => handleSearchCollege(keyword), 500)}
              onChangeItem={handleChangeItem}
            /> */}
            <AutocompleteInput              
              logo="span-cm-logo"
              mdCol="12"
              form={formItem}
              label="College Name"
              name="fullName"
              values={formItem.values.fullName}
              option={collegeData.map((item) => item.value)}
              onInputChange={debounce((keyword) => handleSearchCollege(keyword.trim()), 500)}
              onCreateNew={() => setOpenFormCreate(true)}
              onChange={(e) => {
                const item = collegeData.find((i) => i.value === e);
                handleChangeItem(item);
              }}
            />
            <DatePickerInput
              label={`College "${props.index.slice(0, 1)}" from date`}
              name="fromDate"
              values={formItem.values.fromDate}
              onChange={(value) => formItem.setFieldValue('fromDate', value)}
            />
            <DatePickerInput
              label={`College "${props.index.slice(0, 1)}" to date`}
              name="toDate"
              values={formItem.values.toDate}
              onChange={(value) => formItem.setFieldValue('toDate', value)}
            />
            <CheckboxInput
              logo="span-csu-logo"
              label="Check if you are still attending this college or university"
              name="isStillAttendingThisCollege"
              values={formItem.values.isStillAttendingThisCollege}
              onChange={formItem.handleChange}
            />

            <Row className="common-flag mt-5">
              <GroupCheckbox
                label="Course Details"
                logo="span-cm-logo"
                options={[
                  {
                    value: 'college_is_dual_enrollment',
                    label: 'Dual enrollment with high school,',
                  },
                  {
                    value: 'college_is_credit_awarded',
                    label: 'Credit awarded directly by college ',
                  },
                  { value: 'college_is_summer_program', label: 'Summer program' },
                ]}
                form={formItem}
              />

              <YesNoSwitch
                className="py-3"
                label={`Do you expect to earn a degree from ${formItem.values.fullName}? *`}
                logo="span-cm-logo"
                name="expect_to_earn_degree_from_San_Diego_Mesa"
                values={formItem.values.expect_to_earn_degree_from_San_Diego_Mesa}
                onChange={formItem.handleChange}
              />

              {formItem.values.expect_to_earn_degree_from_San_Diego_Mesa === 'true' && (
                <>
                  <Col md={6}>
                    <GroupRadioButton
                      mdCol={12}
                      label="Degree Info"
                      logo="span-csu-logo"
                      name="degree_info"
                      values={formItem.values.degree_info}
                      onChange={formItem.handleChange}
                      options={['Degree Awarded', 'Degree In Progress']}
                    />
                  </Col>

                  <SelectInput
                    logo="span-cm-logo"
                    label="Degree in progress or will be earned"
                    name="degree_earned"
                    values={formItem.values.degree_earned}
                    onChange={formItem.handleChange}
                    option={[
                      { value: 'AA' },
                      { value: 'AS' },
                      { value: 'BA' },
                      { value: 'BS' },
                      { value: '' },
                    ]}
                  />
                  <SelectInput
                    logo="span-csu-logo"
                    label="Details of degree in progress or will be earned"
                    name="college_info.detail_degree_earned"
                    values={formItem.values.college_info?.detail_degree_earned}
                    onChange={formItem.handleChange}
                    option={collegeDetailEarnedOption}
                  />
                  <div className="fs-6 text-dark span-csu-logo">When did you earn that degree?</div>
                  <SelectInput
                    label="Select Month"
                    name="college_info.when_you_earn_degree_month"
                    values={formItem.values.college_info?.when_you_earn_degree_month}
                    onChange={formItem.handleChange}
                    option={[
                      'January',
                      'February',
                      'March',
                      'April',
                      'May',
                      'June',
                      'July',
                      'August',
                      'September',
                      'October',
                      'November',
                      'December',
                    ]}
                  />
                  <SelectInput
                    label="Select Year"
                    name="college_info.when_you_earn_degree_year"
                    values={formItem.values.college_info?.when_you_earn_degree_year}
                    onChange={formItem.handleChange}
                    option={earnDegreeYears}
                  />
                  <SelectInput
                    logo="span-csu-logo"
                    label="What was your major?"
                    name="college_info.your_major"
                    values={formItem.values.college_info?.your_major}
                    onChange={formItem.handleChange}
                    option={majorOption}
                  />
                  <SelectInput
                    logo="span-csu-logo"
                    label="What was your minor?"
                    name="college_info.your_minor"
                    values={formItem.values.college_info?.your_minor}
                    onChange={formItem.handleChange}
                    option={minorOption}
                  />
                  <CheckboxInput
                    className="py-3"
                    logo="span-csu-logo"
                    label="Check if you were a double major"
                    name="college_info.have_double_major"
                    values={formItem.values.college_info?.have_double_major}
                    onChange={(e) => {
                      formItem.setFieldValue('college_info.have_double_major', e.target.checked);
                    }}
                  />
                  {formItem.values.college_info?.have_double_major === true && (
                    <SelectInput
                      logo="span-csu-logo"
                      label="Select Second Major?"
                      name="college_info.your_second_major"
                      values={formItem.values.college_info?.your_second_major}
                      onChange={formItem.handleChange}
                      option={majorOption}
                    />
                  )}
                </>
              )}
            </Row>
            <GroupCheckbox
              className="pt-3"
              label={`What is this college's grading system? *`}
              logo="span-uc-logo"
              form={formItem}
              mdCol={6}
              options={[
                {
                  value: 'college_grading_system_alphabet',
                  label: 'A B C D F',
                },
                { value: 'college_grading_system_other', label: `Other` },
              ]}
            />
            {formItem.values.college_grading_system_other && (
              <>
                <TextInput
                  className="py-3"
                  logo="span-uc-logo"
                  countText={16}
                  label="Specify the grading system *"
                  values={formItem.values.other_grading_system}
                  onChange={formItem.handleChange}
                  name="other_grading_system"
                />
              </>
            )}
            <GroupRadioButton
              className="py-3"
              label="What type of term system does this college or university use?"
              logo="span-csu-logo"
              options={['Quarter', 'Semester', 'Trimester']}
              values={formItem.values.college_info?.type_of_term_systerm}
              onChange={formItem.handleChange}
              name="college_info.type_of_term_systerm"
            />
            <GroupRadioButton
              className="py-3"
              label="What is this college's term system?"
              logo="span-uc-logo"              
              options={['Quarter', 'Semester', 'Semester with Winter Session']}
              values={formItem.values.college_info?.type_of_term_systerm_uc}
              onClear={() => formItem.setFieldValue('college_info.type_of_term_systerm_uc', '')}
              onChange={formItem.handleChange}
              name="college_info.type_of_term_systerm_uc"
            />

            <GroupRadioButton
              label="What was your tuition status (resident or non-resident) at this college or university during the last term you attended?"
              options={['In-State Tuition', 'Out-of-State Tuition', 'Not applicable']}
              values={formItem.values.college_info?.your_tuition_status}
              onChange={formItem.handleChange}
              name="college_info.your_tuition_status"
            />
            <div>During which grades and terms did you attend this college?</div>
            {(formItem.values.grades ?? []).map((item, index) => (
              <Row key={index}>
                <SelectInput
                  logo="span-uc-logo"
                  form={formItem}
                  option={[
                    { value: '' },
                    { value: '9th' },
                    { value: '10th' },
                    { value: '11th' },
                    { value: '12th' },
                  ]}
                  label="Grades"
                  name={`grades[${index}]`}
                  values={formItem.values.grades[index]}
                  onChange={formItem.handleChange}
                />
                <Col md={6} className="d-flex align-items-center justify-content-start">
                  <Chip
                    label="Remove term"
                    className="ms-3 pointer"
                    onClick={() => handleRemoveTerm(index)}
                  />
                </Col>
                <SelectInput
                  mdCol="3"
                  form={formItem}
                  option={gradeOptions}
                  label="Fall"
                  name={`grades_fall[${index}]`}
                  values={formItem.values.grades_fall[index]}
                  onChange={formItem.handleChange}
                />
                <SelectInput
                  mdCol="3"
                  form={formItem}
                  option={gradeOptions}
                  label="Winter"
                  name={`grades_winter[${index}]`}
                  values={formItem.values.grades_winter[index]}
                  onChange={formItem.handleChange}
                />
                <SelectInput
                  mdCol="3"
                  form={formItem}
                  option={gradeOptions}
                  label="Spring"
                  name={`grades_spring[${index}]`}
                  values={formItem.values.grades_spring[index]}
                  onChange={formItem.handleChange}
                />
                <SelectInput
                  mdCol="3"
                  form={formItem}
                  option={gradeOptions}
                  label="Summer"
                  name={`grades_summer[${index}]`}
                  values={formItem.values.grades_summer[index]}
                  onChange={formItem.handleChange}
                />
              </Row>
            ))}
            {formItem.values.grades.length < 4 && (
              <div className="my-3 w-100 d-flex flex-column align-items-center">
                <div className="text-field-light sized70">You can add up to 4 term/year</div>
                <Button variant="primary" className="p-2 px-5" onClick={handleAddTerm}>
                  Add another term/year
                </Button>
              </div>
            )}

            <Col xs={6} md={9} />
            <Col xs={6} md={3} className="p-3">
              <Button
                className="w-100"
                onClick={() => {
                  props.handleSave(formItem.values);
                  setExpanded(false);
                }}
              >
                Save Info
              </Button>
            </Col>
          </Row>
        </CardContent>
      </Collapse>
      <FormCreateCollegeModal
        open={openFormCreate}
        onClose={() => setOpenFormCreate(false)}
        handleSave={handleSaveCollege}
      />
    </Card>
  );
};

export default CollegeConfig;
