import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';
import { activitiesAndHonorsSlice } from './activitiesAndHonors/activitiesAndHonorsSlice';
import { appSlice } from './appSlice';
import { authorizationSlice } from './authorization/authorizationSlice';
import { basicInformationSlice } from './basicInformationSlice/basicInformationSlice';
import { courseSlice } from './course/courseSlice';
import { dashboardSlice } from './dashboardSlice';
import { familySlice } from './family/familySlice';
import { highSchoolSlice } from './highSchoolSlice/highSchoolSlice';
import { testingSlice } from './testingSlice/testingSlice';
import { counselorSlice } from './counselorSlice';

export const store = configureStore({
  reducer: {
    appStore: appSlice.reducer,
    basicInformation: basicInformationSlice.reducer,
    authorization: authorizationSlice.reducer,
    family: familySlice.reducer,
    highSchool: highSchoolSlice.reducer,
    testing: testingSlice.reducer,
    course: courseSlice.reducer,
    activitiesAndHonors: activitiesAndHonorsSlice.reducer,
    dashboard: dashboardSlice.reducer,
    counselor: counselorSlice.reducer,
  },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
