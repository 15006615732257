/* eslint-disable no-unreachable */
/* eslint-disable react-hooks/exhaustive-deps */
import toast from 'react-hot-toast';
import React from 'react';
import TopProcessBar, { StepProcessProps } from './TopProcessBar';
import SchoolOutlinedIcon from '@mui/icons-material/SchoolOutlined';
import { Container, Form, Button, Row, Spinner } from 'react-bootstrap';
import { useFormik } from 'formik';
import TextIconInput from 'components/input/TextIconInput';
import SearchIcon from '@mui/icons-material/Search';
import TuneIcon from '@mui/icons-material/Tune';
import { Card, Chip, styled, Typography } from '@mui/material';
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import debounce from 'lodash/debounce';
import CircleOutlinedIcon from '@mui/icons-material/CircleOutlined';
import CircleIcon from '@mui/icons-material/Circle';
import TextInput from 'components/input/textInput';
import HighlightOffRoundedIcon from '@mui/icons-material/HighlightOffRounded';
import SelectInput from 'components/input/SelectInput';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import collegeServices from 'services/collegeServices';
import { MajorCard } from './MajorCard';
import Cookies from 'js-cookie';
import { useHistory } from 'react-router-dom';
import { routers } from 'router/router';
import { logout } from 'app/basicInformationSlice/basicInformationSlice';
import { authenticateSettings } from 'components/helper/appConfig';
import { checkEnableParseJSON } from 'components/helper/helper';

interface Props {}

export const HtmlTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip classes={{ popper: className }} placement="right-start" {...props} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    // display: window.innerWidth > 1500 ? 'block' : 'none',
    display: 'none',
    position: 'fix',
    backgroundColor: 'white',
    color: 'rgba(0, 0, 0, 0.87)',
    marginTop: 108,
    marginLeft: 0,
    maxWidth: 240,
    padding: 8,
    border: '1px solid #EFF1F5',
    boxShadow: '3px 4px 6px #CCCCCC',
  },
}));

const SelecCollegePage = (props: Props) => {
  const stepProcess: StepProcessProps[] = [
    { title: 'Select College', percentOnProcess: 12 },
    { title: 'Select Major', percentOnProcess: 75 },
  ];
  const basicInformationStore = useAppSelector((store) => store.basicInformation);
  const [schoolOptions, setSchoolOptions] = React.useState<any[]>([]);
  const [showFilter, setShowFilter] = React.useState(false);
  const [stateOptions, setStateOptions] = React.useState<any[]>([]);
  const [publicOptions, setPublicOptions] = React.useState<any[]>([]);
  const [selectedCollege, setSelectedCollege] = React.useState<any[]>([]);
  const dispatch = useAppDispatch();
  const history = useHistory();  
  let studentId = Cookies.get('userId');
  let token = Cookies.get(authenticateSettings.tokenName);

  const formSearch = useFormik({
    initialValues: {
      zip_code: '',
      state: '',
      type: '',
      keyword: '',
    },
    onSubmit: async (values) => {
      // alert(values);
    },
  });

  const handleLogout = () => {
    dispatch(logout());
    Cookies.remove(authenticateSettings.tokenName);
    Cookies.remove('userId');
    history.push(routers.login.path);
  }; 

  const handleDeleteMajor = (index: number) => {
    const newMajorArray = formik.values.major_array;
    newMajorArray.splice(index, 1);
    formik.setFieldValue('major_array', newMajorArray);
  };
  const handleAddMajor = () => {
    formik.setFieldValue('major_array', [
      {
        collegeName: pickupCollege?.name,
        location: pickupCollege?.location,
      },
      ...formik.values.major_array,
    ]);
  };
  const formik: any = useFormik({
    initialValues: {
      major_array: [],
    },
    onSubmit: async (values) => {
      const res: any = !formik.values.id
        ? await collegeServices.createDetail({
            student_id: studentId,
            college_array: JSON.stringify(selectedCollege),
            major_array: JSON.stringify(formik.values.major_array),
          })
        : await collegeServices.updateDetail({
            student_id: studentId,
            college_array: JSON.stringify(selectedCollege),
            major_array: JSON.stringify(formik.values.major_array),
          });
      toast.success(res?.message);
    },
  });

  const getDetail = async () => {
    studentId = Cookies.get('userId');
    token = Cookies.get(authenticateSettings.tokenName);
    if (token && studentId) {
      const data: any = await collegeServices.getDetail(studentId);
      if (data) {
        const college = checkEnableParseJSON(data.college_array);
        if (college && Array.isArray(college)) {
          // setSchoolOptions(college);
          setSelectedCollege(college);
        }
        formik.setValues({ ...data, major_array: checkEnableParseJSON(data.major_array) });
      }      
    }
    else {
      handleLogout();
    }
  };
  React.useEffect(() => {
    collegeServices.getState().then((res: any) => {
      const states = res.map((item: any) => {
        return { name: item.descr, value: item.state_code };
      });
      setStateOptions(states);
      getDetail();
    });
    setPublicOptions([{ value: 'All' }, { value: 'Public' }, { value: 'Private' }]);
  }, []);

  const handleSelectCollege = (school: any) => {
    const isIncludes = selectedCollege.filter((sc) => sc.id === school.id).length;
    if (isIncludes) {
      setSelectedCollege(selectedCollege.filter((sc) => sc.id !== school.id));
    } else {
      setSelectedCollege([...selectedCollege, school]);
    }
  };

  const handleSearchSchool = debounce(async (e) => {
    let newSchools: any = await collegeServices.getCollege({
      ...formSearch.values,
      keyword: e.target.value.trim(),
    });
    setSchoolOptions(newSchools);
  }, 400);

  React.useEffect(() => {
    collegeServices
      .getCollege({ ...formSearch.values })
      .then((newSchools: any) => setSchoolOptions(newSchools));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formSearch.values.type, formSearch.values.state, formSearch.values.zip_code]);

  const handleContinue = async () => {
    await formik.handleSubmit();
    history.push(routers.basicInformation.path);
  };
  const [pickupCollege, setPickupCollege] = React.useState<any>();
  return (
    <>
      <TopProcessBar
        stepProcess={stepProcess}
        title="College"
        process={stepProcess[0].percentOnProcess}
        icon={<SchoolOutlinedIcon />}
      />
      <Container className="main-container">
        <Row className="px-2">
          <Form style={{ maxWidth: '900px' }} onSubmit={formSearch.handleSubmit}>
            <HtmlTooltip
              title={
                <React.Fragment>
                  <Typography className="fs-6 text-dark my-2 fw-bold">
                    <InfoOutlinedIcon fontSize="small" className="mx-1" />
                    Selecting Colleges
                  </Typography>
                  <div className="d-flex flex-wrap text-field-light">
                    Select colleges that you are interested in applying to and your majors of
                    interest. This will help us pull the application requirements for the colleges
                    so you can see what they'll be asking on the applications!
                  </div>
                </React.Fragment>
              }
              placement="right-start"
            >
              <div>
                <div className="text-dart" style={{ fontWeight: 'bolder', fontSize: '1.5rem' }}>
                  What colleges are you interested in?
                </div>
                <div className="text-field-light sized70 fw-regular mb-2">
                  Don't worry, you can change them later if you'd like.
                </div>
                <div className="text-field-light">
                  Add the colleges you have an eye on and the majors your interested in applying
                  into
                </div>

                <div className="w-100 d-flex align-items-center justìy-content-center">
                  <div style={{ width: 'calc(100% - 60px' }}>
                    <TextIconInput
                      mdCol={'12'}
                      icon={<SearchIcon />}
                      onChange={handleSearchSchool}
                    />
                  </div>

                  <Button
                    variant="light"
                    className="mx-1 mt-4"
                    style={{ width: '40px', height: '40px' }}
                    onClick={() => setShowFilter(true)}
                  >
                    <TuneIcon />
                  </Button>
                </div>
                {showFilter && (
                  <div className="bg-light w-100 rounded p-3 d-flex flex-wrap justify-content justify-content-between">
                    <div>Use these filters to find schools that fit you:</div>
                    <div>
                      <span
                        className="pointer text-primary"
                        onClick={() => {
                          formSearch.resetForm();
                        }}
                      >
                        Clear filter
                      </span>
                      <HighlightOffRoundedIcon
                        className="pointer text-field-light mx-2"
                        onClick={() => {
                          setShowFilter(false);
                          formSearch.resetForm();
                        }}
                      />
                    </div>
                    <Row className="w-100">
                      <SelectInput
                        form={formSearch}
                        mdCol={'4'}
                        label="US State"
                        option={stateOptions}
                        name="state"
                        values={formSearch.values.state}
                        onChange={formSearch.handleChange}
                      />
                      <SelectInput
                        form={formSearch}
                        mdCol={'4'}
                        label="Type of College"
                        option={publicOptions}
                        name="type"
                        values={formSearch.values.type}
                        onChange={formSearch.handleChange}
                      />
                      <TextInput
                        mdCol={'4'}
                        label="Zip Code"
                        name="zip_code"
                        placeholder="Label"
                        values={formSearch.values.zip_code}
                        onChange={formSearch.handleChange}
                      />
                    </Row>
                  </div>
                )}

                <div className="w-100 d-flex flex-wrap justify-content-between border my-1 p-1 rounded">
                  {(schoolOptions || []).map((school, index) => {
                    const isIncludes = selectedCollege.filter((sc) => sc.id === school.id).length;
                    return (
                      <Card
                        key={index}
                        style={{
                          width: '270px',
                          height: '92px',
                          border: isIncludes ? '2px solid #4D88F0' : '',
                        }}
                        className="rounded d-flex align-items-center my-3 mx-auto mx-md-0 me-md-3 position-relative pointer"
                        onClick={() => handleSelectCollege(school)}
                      >
                        <div className="p-2 mx-3">
                          <div>{school.name}</div>
                          <div className="sized70 text-field-light">{school.address_line_1}</div>
                          <div className="sized70 text-field-light">{school.phone_number_1}</div>
                        </div>
                        {isIncludes ? (
                          <CircleIcon className="position-absolute top-0 end-0 text-primary mt-2 me-2 sized70" />
                        ) : (
                          <CircleOutlinedIcon className="position-absolute top-0 end-0 rounded-circle sized70 text-field-light mt-2 me-2" />
                        )}
                      </Card>
                    );
                  })}
                </div>

                <div className="w-100 my-3 text-center">{schoolOptions.length} results</div>
              </div>
            </HtmlTooltip>

            <div className="w-100 border my-3" />
            <div className="text-dart" style={{ fontWeight: 'bolder', fontSize: '1.5rem' }}>
              Add a major
            </div>
            <div className="text-field-light">
              Add a major - don't stress, you can change it later, if necessary.
            </div>
            <div>
              <div className="text-field-light mt-3">Selected College</div>
              {selectedCollege.map((item, index) => (
                <div className="position-relative" key={index}>
                  <Card
                    key={index + item.id}
                    className="rounded d-flex align-items-center my-3 mx-auto mx-md-0 me-md-3 position-relative pointer"
                    style={{
                      border: item.id === pickupCollege?.id ? '2px solid #4D88F0' : '',
                    }}
                    onClick={() => setPickupCollege(item)}
                  >
                    <div className="p-2 mx-3">
                      <div>{item.name}</div>
                      <div className="sized70 text-field-light">{item.address_line_1}</div>
                      <div className="sized70 text-field-light">{item.phone_number_1}</div>
                    </div>
                    {item.id === pickupCollege?.id ? (
                      <CircleIcon className="position-absolute top-0 end-0 text-primary mt-2 me-2 sized70" />
                    ) : (
                      <CircleOutlinedIcon className="position-absolute top-0 end-0 rounded-circle sized70 text-field-light mt-2 me-2" />
                    )}
                  </Card>
                  <Chip
                    label="Remove"
                    style={{ right: -60, top: 4 }}
                    className="position-absolute"
                    onClick={() => {
                      const newCollege = [...selectedCollege].filter((i) => i.id !== item.id);
                      setSelectedCollege(newCollege);
                    }}
                  />
                </div>
              ))}
            </div>
            <HtmlTooltip
              title={
                <React.Fragment>
                  <Typography className="fs-6 text-dark my-2 fw-bold">
                    <InfoOutlinedIcon fontSize="small" className="mx-1" />
                    Why add majors of interest?
                  </Typography>
                  <div className="d-flex flex-wrap text-field-light">
                    Colleges might have different questions for students that are interested in
                    different majors at their school.
                  </div>
                </React.Fragment>
              }
              placement="right-start"
            >
              <div>
                <div className="w-100 d-flex justify-content-center">
                  <Button
                    className="m-3 p-2 px-5"
                    onClick={handleAddMajor}
                    disabled={!pickupCollege?.id}
                  >
                    Add Major
                  </Button>
                </div>
                {selectedCollege.length ? (
                  (formik.values.major_array || []).map((major: any, index: number) => (
                    <MajorCard
                      key={index}
                      formik={formik}
                      index={index}
                      major={major}
                      selectedCollege={selectedCollege}
                      handleDeleteMajor={() => handleDeleteMajor(index)}
                    />
                  ))
                ) : (
                  <div
                    className="bg-light rounded d-flex flex-column justify-content-center align-items-center w-100 my-3"
                    style={{ height: '120px', border: '2px dashed lightgrey' }}
                  >
                    <div className="fs-5 p-1 text-dart mb-2">No Colleges added, yet</div>
                    <div>
                      Select the colleges that you would like to apply to, and continue the process.
                    </div>
                  </div>
                )}
              </div>
            </HtmlTooltip>

            <div className="my-3 d-flex justify-content-end border-top pt-3">
              <div className="col col-md-6 text-center text-md-end">
                <Button
                  variant="primary"
                  onClick={() => formik.handleSubmit()}
                  className="p-2 px-5 mx-2"
                >
                  {basicInformationStore.status === 'loading' ? (
                    <Spinner size="sm" animation="border" variant="light" />
                  ) : (
                    'Save'
                  )}
                </Button>
                <Button variant="primary" className="p-2 px-5" onClick={() => handleContinue()}>
                  Continue
                </Button>
              </div>
            </div>
          </Form>
        </Row>
      </Container>
    </>
  );
};

export default SelecCollegePage;
