/* eslint-disable react-hooks/exhaustive-deps */
import { Grid } from '@mui/material';
import React from 'react';
import { useAppDispatch } from 'app/hooks';
import Cookies from 'js-cookie';
import { useHistory } from 'react-router-dom';
import { routers } from 'router/router';
import { logout } from 'app/basicInformationSlice/basicInformationSlice';
import { authenticateSettings } from 'components/helper/appConfig';
import counselorServices from 'services/counselorServices';
import RFamily from 'screen/counselor/components/RFamily';
import { ConversationCard } from './ReviewPersonal';
import './sumary.scss';

const ReviewFamily = () => {
  const dispatch = useAppDispatch();
  const history = useHistory();  
  let studentId = Cookies.get('userId');
  let token = Cookies.get(authenticateSettings.tokenName);
  const [listCounselorConnection, setListCounselorConnection] = React.useState<any[]>([]);
  const [currentModuleId, setCurrentModuleId] = React.useState('2.1');

  const handleLogout = () => {
    dispatch(logout());
    Cookies.remove(authenticateSettings.tokenName);
    Cookies.remove('userId');
    history.push(routers.login.path);
  };

  const getData = () => {
    studentId = Cookies.get('userId');
    token = Cookies.get(authenticateSettings.tokenName);
    if (token && studentId) {
      counselorServices.getCounselor(studentId).then((res: any[]) => {
        setListCounselorConnection(res ?? []);
      });      
    }
    else {
      handleLogout();
    }
  }

  React.useEffect(() => {
    getData();
  }, []);

  return (
    <Grid container sx={{ width: '100%' }}>
      <Grid item xs={12} md={8}>
        <RFamily setCurrentModuleId={setCurrentModuleId} />
      </Grid>
      <Grid item xs={12} md={4} sx={{ marginTop: { md: '48px' } }}>
        {listCounselorConnection.map((item, index) => (
          <ConversationCard conversationItem={item} currentModuleId={currentModuleId} key={index} />
        ))}
      </Grid>
    </Grid>
  );
};

export default ReviewFamily;
