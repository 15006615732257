import React from 'react';
import { Col } from 'react-bootstrap';
import { capitalizedString } from '../PersonalSum';
import CheckboxInput from 'components/input/CheckboxInput';
import { v4 as uuidv4 } from 'uuid';

interface Props {
  mdCol?: number;
  label: string;
  values: any;
  secondValues?: string;
  hidden?: boolean;
  options?: any[];
}

const ItemContent = (props: Props) => {
  const renderText = (props.options ?? []).find((i) => i.value === props.values)?.label;
  return props.hidden ? null : (
    <Col xs={12} md={props.mdCol || 3} className="p-2 d-flex flex-column justify-content-between">
      <div className="text-field-light">{props.label}</div>
      {props.options?.length ? (
        renderText
      ) : Array.isArray(props.values) ? (
        props.values.map((e, index) => <div key={index}>{e}</div>)
      ) : (
        <div style={{ wordWrap: 'break-word' }}>
          {typeof props.values === 'string' ? capitalizedString(props.values) : props.values ?? '-'}
          {props.values === true && 'Yes'}
          {props.values === false && 'No'}
          {props.secondValues !== undefined && (
            <div style={{ minHeight: '2rem' }}>{props.secondValues}</div>
          )}
        </div>
      )}
    </Col>
  );
};

export const ItemParagraph = (props: Props) => {
  return typeof props.values !== 'string' ? (<></>) : (
    <Col xs={12} md={props.mdCol || 3} className="p-2">
      <div className="text-field-light">{props.label}</div>
      <div>
        {props.values.split('\n').map((e: any, index: any) => (
          <div key={index}>{e}</div>
        ))}
      </div>
    </Col>
  );
};

export const CheckboxContent = ({
  col,
  label,
  values,
}: {
  col?: number;
  label: string;
  values: boolean;
}) => {
  return (
    <Col xs={12} md={col || 12} className="px-1">
      <CheckboxInput
        name={label + uuidv4()}
        readOnly
        label={<span className="mt-1 text-dark">{label}</span>}
        values={values}
      />
    </Col>
  );
};
export const CheckboxContentGroup = ({
  col,
  label,
  options,
  form,
}: {
  col?: number;
  label: string;
  options: any[];
  form: any;
}) => {
  return (
    <>
      <div>{label}</div>
      {(options ?? []).map((option, index) => (
        <Col xs={12} md={col || 12} className="px-1" key={index}>
          <CheckboxInput
            name={option.label + uuidv4()}
            readOnly
            label={<span className="mt-1 text-dark">{option.label}</span>}
            values={form[option.value]}
          />
        </Col>
      ))}
    </>
  );
};

export default ItemContent;
