import { Select, TextField } from '@mui/material';
import { styled } from '@mui/system';
import moment from 'moment';

export const formatTime = (e: any) => {
  return moment(e).isValid() ? moment(e).format('yyyy-MM-DD') : null;
};

export const keyDownOnlyNumber = (input: any) => {
  if (
    ((input.keyCode < 48 && input.keyCode !== 8) || input.keyCode > 57) &&
    !input.ctrlKey &&
    !input.metaKey
  ) {
    input.preventDefault();
  }
};
export const CssTextField = styled(TextField)({
  '&': {
    display: 'block',
    width: '100%',
    backgroundColor: 'white',
  },
  '& label': {
    display: 'none',
  },
  '& .MuiTextField-root': {
    borderColor: '#8894aa',
  },
  '& .MuiOutlinedInput-notchedOutline': {
    borderColor: '#8894aa',
    borderWidth: '1px !important',
  },
  '& .MuiOutlinedInput-root': {
    borderColor: '#8894aa',
    width: '100%',
    height: '43px',
    borderRadius: '0.5rem',
    '& input': {
      paddingX: '9px !important',
      margin: 0,
      fontSize: '16px',
    },
    '& fieldset .MuiOutlinedInput-notchedOutline': {
      borderColor: '#8894aa !important',
      borderWidth: '1px !important',
    },
    '&:hover fieldset': {
      borderColor: '#8894aa',
    },
    '&.Mui-focused fieldset': {
      borderColor: '#8894aa',
    },
  },
});

export const CssSelect = styled(Select)({
  '&': {
    display: 'block',
    width: '100%',
    borderColor: '#8A93A8',
    height: '43px',
    // marginTop: '5px',
    borderRadius: '0.5rem',
    background: 'white',
    paddingTop: '2px!important',
    color: '#0c2551 !important',
    '& .MuiSelect-select': {
      maxWidth: 'calc(100% - 46px)',
      paddingTop: '8px',
      fontSize: '16px',
      fontWeight: '500',
    },

    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: '#8A93A8 !important',
      borderWidth: '1px !important',

    },
    '&:hover fieldset': {
      borderColor: '#8A93A8',
    },
    '&.Mui-focused fieldset': {
      borderColor: '#8A93A8',
    },
  },
});
