import http from './http';

const notificationServices = {
    async createNotification(body: any): Promise<any> {
        const res = await http.post('api/notification', body);
        return res;
    },

    async updateNotification(body: any): Promise<any> {
        const res = await http.put('api/notification', body);
        return res;
    },

    async getNotification(user_id: any) {
        const res = await http.get(`api/notification/${user_id}`);
        return res;
    },

};

export default notificationServices;