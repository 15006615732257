/* eslint-disable react-hooks/exhaustive-deps */
import LoadingContent from 'components/LoadingContent';
import { useFormik } from 'formik';
import React from 'react';
import { Row } from 'react-bootstrap';
import { useAppDispatch } from 'app/hooks';
import Cookies from 'js-cookie';
import { useHistory } from 'react-router-dom';
import { routers } from 'router/router';
import { logout } from 'app/basicInformationSlice/basicInformationSlice';
import { authenticateSettings } from 'components/helper/appConfig';
import YesNoSwitch from 'components/input/YesNoSwitch';
import TextInput from 'components/input/textInput';
import * as Yup from 'yup';
import { checkEnableParseJSON } from 'components/helper/helper';
import { debounce } from 'lodash';
import {
  getEducationalProgram,
  updateEducationalProgram,
} from 'app/activitiesAndHonors/activitiesAndHonorsSlice';
const rules = Yup.object({
  year_participated_in_AVID: Yup.number().min(1, 'Invalid').max(99, 'Invalid'),
  year_participated_in_upward_bound: Yup.number().min(1, 'Invalid').max(99, 'Invalid'),
  year_participated_in_talent_search: Yup.number().min(1, 'Invalid').max(99, 'Invalid'),
  year_participated_in_puente_project: Yup.number().min(1, 'Invalid').max(99, 'Invalid'),
  year_participated_in_ilp: Yup.number().min(1, 'Invalid').max(99, 'Invalid'),
  year_participated_in_MESA_project: Yup.number().min(1, 'Invalid').max(99, 'Invalid'),
  year_participated_in_other_program: Yup.number().min(1, 'Invalid').max(99, 'Invalid'),
  number_brothers_and_sisters_k12: Yup.number().min(0, 'Invalid').integer('Invalid'),
  number_brothers_and_sisters_college: Yup.number().min(0, 'Invalid').integer('Invalid'),
  number_brothers_and_sisters_received_bachelor_degree: Yup.number()
    .min(0, 'Invalid')
    .integer('Invalid'),
  number_year_received_income: Yup.number().min(1, 'Invalid').max(99, 'Invalid').integer('Invalid'),
});

const EducationalProgramsOnly = () => {
  const [loading, setLoading] = React.useState(false);
  const dispatch = useAppDispatch();
  const history = useHistory();
  let studentId = Cookies.get('userId');
  let token = Cookies.get(authenticateSettings.tokenName);
  const formik = useFormik({
    initialValues: {
      avid: '',
      year_participated_in_AVID: '',
      upward_bound: '',
      year_participated_in_upward_bound: '',
      talent_search: '',
      year_participated_in_talent_search: '',
      puente_project: '',
      year_participated_in_puente_project: '',
      ilp: '',
      year_participated_in_ilp: '',
      MESA_project: '',
      year_participated_in_MESA_project: '',
      other_program: '',
      year_participated_in_other_program: '',
      average_hours_worked_per_week: '',
      is_more_25_percent_work_related_major: '',
      average_hours_activities_per_week: '',
      is_leadership_positions: '',
      wish_to_apply_EOP: '',
      is_enrolled_EOP: '',
      where_plan_to_live: '',
      number_brothers_and_sisters_k12: '',
      number_brothers_and_sisters_college: '',
      number_brothers_and_sisters_received_bachelor_degree: '',
      languages_spoken_in_home: '',
      is_received_income_from_public_assistance_program: '',
      number_year_received_income: '',
      type_of_aid: '',
      is_participated_in_publicly_funded_programs: '',
      publicly_funded_programs: '',
      is_work_primarily_to_contribute: '',
      work_primarily_to_contribute: '',
      campus_enrolled_EOP: '',
    },
    validationSchema: rules,
    onSubmit: async (values) => {
      studentId = Cookies.get('userId');
      token = Cookies.get(authenticateSettings.tokenName);
      if (token && studentId) {
        const newValues = {
          csu_info: JSON.stringify(values),
          student_id: studentId,
        };
        await dispatch(updateEducationalProgram(newValues));
      } else {
        handleLogout();
      }
    },
  });

  const handleLogout = () => {
    dispatch(logout());
    Cookies.remove(authenticateSettings.tokenName);
    Cookies.remove('userId');
    history.push(routers.login.path);
  };

  const getDetail = async () => {
    studentId = Cookies.get('userId');
    token = Cookies.get(authenticateSettings.tokenName);
    if (token && studentId) {
      setLoading(true);
      let res = await dispatch(getEducationalProgram(studentId));
      const dataLoaded: any = res.payload;
      if (dataLoaded) {
        const values: any = checkEnableParseJSON(dataLoaded.csu_info);
        formik.resetForm({ values: { ...formik.values, ...values } });
      }
      setLoading(false);
      formik.setStatus('idle');
    } else {
      // force logout
      handleLogout();
    }
  };

  React.useEffect(() => {
    getDetail();
  }, []);
  // Auto save function ==========
  React.useEffect(() => {
    formik.dirty && debounceSaving();
  }, [formik.values]);
  const debounceSaving = React.useCallback(
    debounce(() => {
      if (!loading) formik.handleSubmit();
    }, 1000),
    []
  );
  // Auto save function ==========
  return loading ? (
    <LoadingContent />
  ) : (
    <Row className="m-0">
      <div className="text-field-mid fs-3 mb-4">Educational Programs Participation</div>
      <div className="text-dart span-csu-logo">
        Have you participated in any of these programs in high school and beyond?
      </div>
      <YesNoSwitch
        className='py-3'
        label="AVID"
        values={formik.values.avid}
        name="avid"
        onChange={formik.handleChange}
      />
      {formik.values.avid === 'true' ? (
        <TextInput
          type="number"
          mdCol={12}
          label="Number of years you participated in AVID during High School:"
          values={formik.values.year_participated_in_AVID}
          name="year_participated_in_AVID"
          onChange={formik.handleChange}
          feedback={<span className="text-danger">{formik.errors.year_participated_in_AVID}</span>}
        />
      ) : (
        <></>
      )}
      <YesNoSwitch
        className='pb-3'
        label="Upward Bound"
        values={formik.values.upward_bound}
        name="upward_bound"
        onChange={formik.handleChange}
      />
      {formik.values.upward_bound === 'true' ? (
        <TextInput
          type="number"
          mdCol={12}
          label="Number of years you participated in Upward Bound during High School:"
          values={formik.values.year_participated_in_upward_bound}
          name="year_participated_in_upward_bound"
          onChange={formik.handleChange}
          feedback={
            <span className="text-danger">{formik.errors.year_participated_in_upward_bound}</span>
          }
        />
      ) : (
        <></>
      )}
      <YesNoSwitch
        className='pb-3'
        label="Talent Search or Educational Opportunity Center (EOC)"
        values={formik.values.talent_search}
        name="talent_search"
        onChange={formik.handleChange}
      />
      {formik.values.talent_search === 'true' ? (
        <TextInput
          type="number"
          mdCol={12}
          label="Number of years you participated in Talent Search or Educational Opportunity Center (EOC) during high school"
          values={formik.values.year_participated_in_talent_search}
          name="year_participated_in_talent_search"
          onChange={formik.handleChange}
          feedback={
            <span className="text-danger">{formik.errors.year_participated_in_talent_search}</span>
          }
        />
      ) : (
        <></>
      )}
      <YesNoSwitch
        className='pb-3'
        label="Puente Project"
        values={formik.values.puente_project}
        name="puente_project"
        onChange={formik.handleChange}
      />
      {formik.values.puente_project === 'true' ? (
        <TextInput
          type="number"
          mdCol={12}
          label="Number of years you participated in Puente Project"
          values={formik.values.year_participated_in_puente_project}
          name="year_participated_in_puente_project"
          onChange={formik.handleChange}
          feedback={
            <span className="text-danger">{formik.errors.year_participated_in_puente_project}</span>
          }
        />
      ) : (
        <></>
      )}
      <YesNoSwitch
        className='pb-3'
        label="Independent Living Project (ILP)"
        values={formik.values.ilp}
        name="ilp"
        onChange={formik.handleChange}
      />
      {formik.values.ilp === 'true' ? (
        <TextInput
          type="number"
          mdCol={12}
          label="Number of years you participated in Independent Living Project (ILP)"
          values={formik.values.year_participated_in_ilp}
          name="year_participated_in_ilp"
          onChange={formik.handleChange}
          feedback={<span className="text-danger">{formik.errors.year_participated_in_ilp}</span>}
        />
      ) : (
        <></>
      )}
      <YesNoSwitch
        className='pb-3'
        label="MESA Project"
        values={formik.values.MESA_project}
        name="MESA_project"
        onChange={formik.handleChange}
      />
      {formik.values.MESA_project === 'true' ? (
        <TextInput
          type="number"
          mdCol={12}
          label="Number of years you participated in MESA Project"
          values={formik.values.year_participated_in_MESA_project}
          name="year_participated_in_MESA_project"
          onChange={formik.handleChange}
          feedback={
            <span className="text-danger">{formik.errors.year_participated_in_MESA_project}</span>
          }
        />
      ) : (
        <></>
      )}
      <YesNoSwitch
        className='pb-3'
        label="Other (CAMP, EAOP, EOP&S/CARE GEAR-UP, TRIO, or Foster youth support programs)"
        values={formik.values.other_program}
        name="other_program"
        onChange={formik.handleChange}
      />
      {formik.values.other_program === 'true' ? (
        <TextInput
          type="number"
          mdCol={12}
          label="Number of years you participated in Gear-up, CAMP, Foster youth support programs, Student Support Services Programs (SSSP)"
          values={formik.values.year_participated_in_other_program}
          name="year_participated_in_other_program"
          onChange={formik.handleChange}
          feedback={
            <span className="text-danger">{formik.errors.year_participated_in_other_program}</span>
          }
        />
      ) : (
        <></>
      )}

      <div className="border w-100 my-3" />
    </Row>
  );
};

export default EducationalProgramsOnly;
