import React from 'react';
import { useParams } from 'react-router-dom';
import { Tab, Tabs } from '@mui/material';
import CustomTabPanel from './TabPanel';
import CounselorCollegeSearch from './CounselorCollegeSearch';
import CounselorCollegeView from './CounselorCollegeView';
const RCollege = ({ setCurrentModuleId }: { setCurrentModuleId: (id: string) => void }) => {
  const params: { id?: string | number } = useParams();

  React.useEffect(() => {
    const getData = async () => {
      // const res: any = await activitiesAndHonorsServices.getAdditionalInfo(params.id);
      // setMyAdditionalInfo(res);
    };
    params.id !== ':id' && getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params.id]);
  const [value, setValue] = React.useState(1);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
    setCurrentModuleId(`8.${newValue}`);
  };
  return (
    <div>
      <Tabs value={value} onChange={handleChange} variant="scrollable" scrollButtons="auto">
        <Tab value={1} label="College Search" />
        <Tab value={2} label="College List" />
      </Tabs>
      <CustomTabPanel value={value} index={1}>
        <CounselorCollegeSearch />
      </CustomTabPanel>
      <CustomTabPanel value={value} index={2}>
        <CounselorCollegeView />
      </CustomTabPanel>
    </div>
  );
};

export default RCollege;
