import TextInput from 'components/input/textInput';
import YesNoSwitch from 'components/input/YesNoSwitch';
import React from 'react';
import { Col, Row } from 'react-bootstrap';

interface Props {
  formik: any;
  formAnswerFeeWeiver: any;
  formFeeWeiver: any;
}
export const economicOptions = [
  {
    key: 'is_in_free_and_reduced_program',
    value:
      'My annual family income falls within the Income Eligibility Guidelines* for the Federal Free and Reduced Lunch Program.',
  },
  { key: 'is_college_free', value: 'I have received a College Board fee waiver.' },
  { key: 'is_ACT_free', value: 'I have received an ACT fee waiver.' },
  { key: 'is_NACAC_free', value: 'I have received a NACAC fee waiver.' },
  { key: 'is_Pell_Grant', value: 'I am eligible for a Pell Grant.' },
  {
    key: 'is_TRIO',
    value: 'I have participated (or am participating) in TRIO programs (such as Upward Bound).',
  },
  { key: 'is_none_fee_waiver', value: 'None of these apply to me.' },
];
const FeeWaiver = (props: Props) => {
  return (
    <>
      <Row className="m-0 common-flag">
        <div className="fs-5 p-1">Fee Waiver</div>
        <div>
          You are eligible for application fee waivers if you meet one or more of the following
          criteria:
        </div>
        <ul className="m-3 text-field-light">
          <li>
            You are enrolled in or eligible to participate in the federal free or reduced price
            lunch program.
          </li>
          <li>You have received or are eligible to receive an SAT or ACT fee waiver.</li>
          <li>
            Your annual family income falls within the income eligibility guidelines set by the USDA
            Food and Nutrition Service.
          </li>
          <li>Your family receives public assistance.</li>
          <li>
            You are enrolled in a federal, state, or local program that aids students from
            low-income families (e.g., GEAR UP, TRIO such as Upward Bound or others).
          </li>
          <li>You live in a federally subsidized public housing, a foster home or are homeless.</li>
          <li>You have received or are eligible to receive a Pell Grant.</li>
          <li>You are a ward of the state or an orphan.</li>
          <li>
            You can provide a supporting statement from a school official, college access counselor,
            financial aid officer, or community leader.
          </li>
        </ul>

        <YesNoSwitch
          className='py-3'
          onClear={() => props.formik.setFieldValue('is_financial_qualify_fee_waiver', '')}
          label="Do you meet one or more of the Common App fee waiver, Opens in new tab, eligibility criteria?*"
          name="is_financial_qualify_fee_waiver"
          values={props.formik.values.is_financial_qualify_fee_waiver}
          onChange={props.formik.handleChange}
        />
        {props.formik.values.is_financial_qualify_fee_waiver === 'true' && (
          <>
            {/* <div className="text-field-light my-1">
              You must meet at least one of the following indicators of economic need to qualify for
              an application fee waiver. Select all that apply:*
            </div>
            {economicOptions.map((option) => (
              <Col md={12} key={option.key}>
                <CheckboxInput
                  label={<span className="mt-2 text-field-light">{option.value}</span>}
                  name={option.key}
                  values={props.formFeeWeiver.values[`${option.key}`]}
                  onChange={props.formFeeWeiver.handleChange}
                />
              </Col>
            ))} */}

            <Col md={12} className="mb-1">
              <div className="text-field-light ms-1">
                I certify that I understand and meet the eligibility requirements to request an
                admission application fee waiver. I also understand if I am a first year student my
                counselor will be asked to verify my eligibility. Fee Waiver signature
              </div>
              <TextInput
                mdCol="12"
                name="fee_waiver_signature"
                values={props.formik.values.fee_waiver_signature}
                onChange={props.formik.handleChange}
                placeholder="Your Signature"
                countText={50}
              />
            </Col>
          </>
        )}
        <div>
          UStrive is a Common App partner that connects you with free, qualified mentors online.
          UStrive mentors can help you:
        </div>
        <ul className="mx-3 my-1 text-field-light">
          <li>Apply to college</li>
          <li>Navigate the financial aid process</li>
          <li>Transition into college</li>
          <li>Get your degree</li>
          <li>Find a job after graduation</li>
        </ul>
        <YesNoSwitch
          className='py-3'
          label="Would you like to connect with a UStrive mentor?"
          name="is_like_to_receive_information_from_strive_for_college"
          values={props.formFeeWeiver.values.is_like_to_receive_information_from_strive_for_college}
          onChange={props.formFeeWeiver.handleChange}
          onClear={() =>
            props.formFeeWeiver.setFieldValue(
              'is_like_to_receive_information_from_strive_for_college',
              ''
            )
          }
        />
      </Row>
    </>
  );
};

export default FeeWaiver;
