import SelectInput from 'components/input/SelectInput';
import ReuseLoginLayout from 'components/layout/ReuseLoginLayout';
import React from 'react';
import { Button } from 'react-bootstrap';
import { useHistory } from 'react-router';
import { routers } from 'router/router';
import FormAuthEmail from './FormAuthEmail';
import FormForgotPassword from './FormForgotPassword';
import FormInputNewPassword from './FormInputNewPassword';

interface Props {}

const ForgotPasswordPage = (props: Props) => {
  const history = useHistory();
  const [step, setStep] = React.useState<'inputEmail' | 'inputCode' | 'inputNewPassword'>(
    'inputEmail'
  );
  return (
    <ReuseLoginLayout>
      <div className="mx-3">
        <div className="d-flex flex-wrap pb-md-5 mb-md-5 justify-content-between align-items-center">
          <span>
            <SelectInput
              values={'English (USA)'}
              borderless
              mdCol={'12'}
              option={['English (USA)']}
            />
          </span>
          <span className="mt-2">
            <span>Don’t have an account?</span>
            <Button
              className="p-0 mx-1 pb-1"
              variant="link"
              onClick={() => history.push(routers.register.path)}
            >
              Sign Up
            </Button>
          </span>
        </div>
        {step === 'inputEmail' && <FormForgotPassword setStep={setStep} />}
        {step === 'inputCode' && <FormAuthEmail setStep={setStep} />}
        {step === 'inputNewPassword' && <FormInputNewPassword setStep={setStep} />}
      </div>
    </ReuseLoginLayout>
  );
};

export default ForgotPasswordPage;
