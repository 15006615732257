/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { Button, Spinner } from 'react-bootstrap';
import LoadingContent from 'components/LoadingContent';
import { checkEnableParseJSON, removeDuplicate, userpilotDomain } from 'components/helper/helper';
import { useAppDispatch } from 'app/hooks';
import Cookies from 'js-cookie';
import { useHistory } from 'react-router-dom';
import { routers } from 'router/router';
import { logout } from 'app/basicInformationSlice/basicInformationSlice';
import { authenticateSettings } from 'components/helper/appConfig';
import { useFormik } from 'formik';
import { USER_ROLE } from 'constants/common';
import AutocompleteInput from 'components/input/AutoCompleteInput';
import collegeServices from 'services/collegeServices';
import authorizationServices from 'services/authorizationServices';
import SchoolOutlinedIcon from '@mui/icons-material/SchoolOutlined';
import toast from 'react-hot-toast';
import { Userpilot } from 'userpilot';

const SettingsMajor = () => {
  const [loading, setLoading] = React.useState(false);
  const [waiting, setWaiting] = React.useState(false);
  const [majorOptions, setMajorOptions] = React.useState<any[]>([]);
  const dispatch = useAppDispatch();
  const history = useHistory();
  let studentId = Cookies.get('userId');
  let token = Cookies.get(authenticateSettings.tokenName);

  const searchMajor = async (keyword: string) => {
    const res: any = await collegeServices.getUSCollegeMajorV1({ keyword });
    if (res?.length) {
      const result = removeDuplicate(
        res.map((item: { category1: string }) => item?.category1)
      ).sort();
      setMajorOptions(result);
    }
  };
  React.useEffect(() => {
    searchMajor('');
  }, []);

  const formCreateInfo = useFormik({
    initialValues: {
      email: '',
      first_name: '',
      last_name: '',
      user_role: USER_ROLE.student,
      school_or_organization_work_with: '',
      year_finish_high_school: '',
      password: '',
      password_confirm: '',
      additional_info: {
        where_attend_high_school: '',
        school_system: '',
        high_school_name: '',
        majors_interest_1: '',
        majors_interest_2: '',
        year_plan_college_studies: '',
      },
    },
    onSubmit: async (values) => {
      try {
        setWaiting(true);
        localStorage.getItem('userRole') === USER_ROLE.student &&
          (await authorizationServices.updateStudent(studentId, {
            ...values,
            additional_info: JSON.stringify(formCreateInfo.values.additional_info),
          }));
        Userpilot.identify((studentId ?? 'undefined') + userpilotDomain, {
          majors_interest_1:
            formCreateInfo.values.additional_info?.majors_interest_1 ?? 'undefined',
          majors_interest_2:
            formCreateInfo.values.additional_info?.majors_interest_2 ?? 'undefined',
        });

        window.Intercom('update', {
          major_1: formCreateInfo.values.additional_info?.majors_interest_1 ?? '',
          major_2: formCreateInfo.values.additional_info?.majors_interest_2 ?? '',
        });

        toast.success('Save successfully');
      } catch (ex) {
        console.log('Error: ', ex);
      }
      setWaiting(false);
    },
  });

  const handleLogout = () => {
    dispatch(logout());
    Cookies.remove(authenticateSettings.tokenName);
    Cookies.remove('userId');
    history.push(routers.login.path);
  };

  React.useEffect(() => {
    studentId = Cookies.get('userId');
    token = Cookies.get(authenticateSettings.tokenName);
    if (token && studentId) {
      setLoading(true);
      authorizationServices.getStudent(studentId).then((res: any) => {
        setLoading(false);
        if (res) {
          formCreateInfo.resetForm({
            values: {
              ...res,
              additional_info: checkEnableParseJSON(res.additional_info),
            },
          });
        }
      });
    } else {
      handleLogout();
    }
  }, []);

  return loading ? (
    <LoadingContent />
  ) : (
    <div className="tab-wrap-panel">
      <div className="tab-panel">
        <div className="text-left" style={{ paddingBottom: '16px' }}>
          <div className="row">
            <div className="col-md-12">
              <div className="d-flex align-items-center">
                <div
                  className="text-primary p-2 rounded-circle bg-primary-light d-flex align-items-center justify-content-center"
                  style={{ width: '35px', height: '35px', marginRight: '8px' }}
                >
                  <SchoolOutlinedIcon
                    className="text-primray-light"
                    width={'18px'}
                    height={'18px'}
                  />
                </div>
                <div className="text-primary fs-4 fw-bold mx-1">Majors of Interests</div>
              </div>
            </div>
          </div>

          <div className="row mt-3">
            {localStorage.getItem('userRole') === USER_ROLE.student && majorOptions?.length ? (
              <div className="row">
                <AutocompleteInput
                  onChange={(val: any) => {
                    formCreateInfo.setFieldValue('additional_info.majors_interest_1', val);
                  }}
                  option={majorOptions}
                  label="Major of interest 1"
                  name="additional_info.majors_interest_1"
                  values={formCreateInfo.values.additional_info?.majors_interest_1}
                  mdCol={'6'}
                />
                <AutocompleteInput
                  onChange={(val: any) => {
                    formCreateInfo.setFieldValue('additional_info.majors_interest_2', val);
                  }}
                  option={majorOptions}
                  label="Major of interest 2"
                  name="additional_info.majors_interest_2"
                  values={formCreateInfo.values.additional_info?.majors_interest_2}
                  mdCol={'6'}
                />
              </div>
            ) : null}

            <div className="row">
              <div className="my-3 d-md-flex justify-content-end" style={{ paddingLeft: 0 }}>
                <Button
                  variant="primary"
                  onClick={() => formCreateInfo.handleSubmit()}
                  className="btn-update"
                >
                  {waiting ? <Spinner size="sm" animation="border" variant="white" /> : 'Update'}
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SettingsMajor;
