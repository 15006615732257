/* eslint-disable no-empty-pattern */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import { checkEnableParseJSON } from 'components/helper/helper';
import Cookies from 'js-cookie';
import toast from 'react-hot-toast';
import highSchoolServices from 'services/highSchoolServices';

export interface FamilyState {
  highSchoolData: {
    id: string;
    code: string | number;
    start_date: string;
    end_date: string;
    other: any;
  }[];
  highschoolListObject: any[];
  collegeData: any[];
  myHighSchool: any;
  myCollege: any;
  myFuturePlan: any;
  status: 'idle' | 'loading' | 'failed';
}

const initialState: FamilyState = {
  highSchoolData: [],
  highschoolListObject: [],
  collegeData: [],
  myHighSchool: null,
  myCollege: null,
  myFuturePlan: null,
  status: 'idle',
};
export const getHighSchoolData: any = createAsyncThunk(
  'highSchool/getHighSchoolData',
  async (studentId: any, {rejectWithValue}) => {
    try {
      const response: any = await highSchoolServices.getHighSchool(studentId);
      const highSchool = checkEnableParseJSON(response.high_school_array);
      return { ...response, highschoolData: [...highSchool] };
    }
    catch (err) {
      const error = err as AxiosError; 
      if (!error.response) {
        throw err;
      }
      return rejectWithValue(error.response.data);
    }
  }
);
export const getCollegeData: any = createAsyncThunk(
  'highSchool/getCollegeData',
  async (studentId: any, {rejectWithValue}) => {
    try {
      const response: any = await highSchoolServices.getCollege(studentId);
      const college = checkEnableParseJSON(response.colleges_taken_coursework_array);
      const res = college.map((item: any) => item.fullName);
      return res;
    }
    catch (err) {
      const error = err as AxiosError; 
      if (!error.response) {
        throw err;
      }
      return rejectWithValue(error.response.data);
    }
  }
);
export const getHighSchool: any = createAsyncThunk(
  'highSchool/getHighSchool',
  async (studentId: any, {rejectWithValue}) => {
    try {
      const response = await highSchoolServices.getHighSchool(studentId);
      return response;
    }
    catch (err) {
      const error = err as AxiosError; 
      if (!error.response) {
        throw err;
      }
      return rejectWithValue(error.response.data);
    }
  }
);
export const updateHighSchool: any = createAsyncThunk(
  'highSchool/updateHighSchool',
  async (payload: any, { getState, rejectWithValue }) => {
    const state: any = getState();
    const myHighSchool = state.highSchool.myHighSchool;
    try {
      if (myHighSchool) {
        const response = await highSchoolServices.updateHighSchool(payload);
        return response;
      } else {
        const response = await highSchoolServices.createHighSchool(payload);
        return response;
      }
    }
    catch (err) {
      const error = err as AxiosError; 
      if (!error.response) {
        throw err;
      }
      return rejectWithValue(error.response.data);
    }
  }
);
export const updateHighSchoolFromCourse: any = createAsyncThunk(
  'highSchool/updateHighSchoolFromCourse',
  async (payload: any, { getState, rejectWithValue }) => {
    const state: any = getState();
    const myHighSchool = state.highSchool.myHighSchool;
    try {
      if (myHighSchool) {
        await highSchoolServices.updateHighSchool({ ...payload, student_id: Cookies.get('userId') });
        return payload;
      }
    }
    catch (err) {
      const error = err as AxiosError; 
      if (!error.response) {
        throw err;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

export const getCollege: any = createAsyncThunk('highSchool/getCollege', async (studentId: any, {rejectWithValue}) => {
  try {
    const response = await highSchoolServices.getCollege(studentId);
    return response;
  }
  catch (err) {
    const error = err as AxiosError; 
    if (!error.response) {
      throw err;
    }
    return rejectWithValue(error.response.data);
  }
});

export const updateCollege: any = createAsyncThunk(
  'highSchool/updateCollege',
  async (payload: any, { getState, rejectWithValue }) => {
    const state: any = getState();
    const myCollege = state.highSchool.myCollege;
    try {
      if (myCollege) {
        const response = await highSchoolServices.updateCollege(payload);
        return response;
      } else {
        const response = await highSchoolServices.createCollege(payload);
        return response;
      }
    }
    catch (err) {
      const error = err as AxiosError; 
      if (!error.response) {
        throw err;
      }
      return rejectWithValue(error.response.data);
    }
  }
);
export const getFuturePlan: any = createAsyncThunk(
  'highSchool/getFuturePlan',
  async (studentId: any, {rejectWithValue}) => {
    try {
      const response = await highSchoolServices.getFuturePlan(studentId);
      return response;
    }
    catch (err) {
      const error = err as AxiosError; 
      if (!error.response) {
        throw err;
      }
      return rejectWithValue(error.response.data);
    }
  }
);
export const updateFuturePlan: any = createAsyncThunk(
  'highSchool/updateFuturePlan',
  async (payload: any, { getState, rejectWithValue }) => {
    const state: any = await getState();
    const myFuturePlan = state?.highSchool?.myFuturePlan;
    try {
      if (myFuturePlan) {
        const response = await highSchoolServices.updateFuturePlanThunk(payload);
        return response;
      } else {
        const response = await highSchoolServices.createFuturePlanThunk(payload);
        return response;
      }
    }
    catch (err) {
      const error = err as AxiosError; 
      if (!error.response) {
        throw err;
      }
      return rejectWithValue(error.response.data);
    }
  }
);
export const highSchoolSlice = createSlice({
  name: 'highSchool',
  initialState,
  reducers: {},
  extraReducers: {
    [getHighSchoolData.pending]: (state) => {
      state.status = 'loading';
    },
    [getHighSchoolData.rejected]: (state) => {
      state.status = 'failed';
    },
    [getHighSchoolData.fulfilled]: (state, action) => {
      state.highSchoolData = (action.payload?.highschoolData ?? [])?.map((item: any) => ({
        id: item.high_school_name || item.fullName,
        code:
          item.high_school_code ||
          item.high_school_object?.college_board_code ||
          item.high_school_object?.code,
        start_date: item?.start_date,
        end_date: item?.end_date,
        other: { ...item },
      }));
      state.highschoolListObject = action.payload?.highschoolData ?? [];
      state.myHighSchool = action.payload;
      state.status = 'idle';
    },
    [getCollegeData.pending]: (state) => {
      state.status = 'loading';
    },
    [getCollegeData.rejected]: (state) => {
      state.status = 'failed';
    },
    [getCollegeData.fulfilled]: (state, action) => {
      state.collegeData = action.payload;
      state.status = 'idle';
    },
    //
    [getHighSchool.pending]: (state) => {
      state.status = 'loading';
    },
    [getHighSchool.rejected]: (state) => {
      state.status = 'failed';
    },
    [getHighSchool.fulfilled]: (state, action) => {
      state.myHighSchool = action.payload;
      state.status = 'idle';
    },
    //
    [updateHighSchool.pending]: (state) => {
      state.status = 'loading';
    },
    [updateHighSchool.rejected]: (state) => {
      state.status = 'failed';
    },
    [updateHighSchool.fulfilled]: (state, action) => {
      toast.success(action.payload.message);
      state.myHighSchool = action.payload;
      state.status = 'idle';
    },
    [updateHighSchoolFromCourse.fulfilled]: (state, action) => {
      state.myHighSchool = action.payload;
    },

    //
    [getCollege.pending]: (state) => {
      state.status = 'loading';
    },
    [getCollege.rejected]: (state) => {
      state.status = 'failed';
    },
    [getCollege.fulfilled]: (state, action) => {
      state.myCollege = action.payload;
      state.status = 'idle';
    },
    //
    [updateCollege.pending]: (state) => {
      state.status = 'loading';
    },
    [updateCollege.rejected]: (state) => {
      state.status = 'failed';
    },
    [updateCollege.fulfilled]: (state, action) => {
      toast.success(action.payload.message);
      state.myCollege = action.payload;
      state.status = 'idle';
    },
    //
    [getFuturePlan.pending]: (state) => {
      state.status = 'loading';
    },
    [getFuturePlan.rejected]: (state) => {
      state.status = 'failed';
    },
    [getFuturePlan.fulfilled]: (state, action) => {
      state.myFuturePlan = action.payload;
      state.status = 'idle';
    },
    //
    [updateFuturePlan.pending]: (state) => {
      state.status = 'loading';
    },
    [updateFuturePlan.rejected]: (state) => {
      state.status = 'failed';
    },
    [updateFuturePlan.fulfilled]: (state, action) => {
      toast.success(action.payload.message);
      state.myFuturePlan = action.payload;
      state.status = 'idle';
    },
  },
});

export const {} = highSchoolSlice.actions;
