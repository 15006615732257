import React from 'react';
import { Form, Row, Button } from 'react-bootstrap';
import CheckboxInput from 'components/input/CheckboxInput';
import GroupRadioButton from 'components/input/GroupRadioButton';
import SelectInput from 'components/input/SelectInput';
import courseServices from 'services/courseServices';
import { states_usa } from 'components/helper/state_usa';
import axios from 'axios';
import SelectSearchInput from 'components/input/SelectSearchInput';
import collegeServices from 'services/collegeServices';
import AutocompleteInput from 'components/input/AutoCompleteInput';
import { removeDuplicate } from 'components/helper/helper';
interface Props {
  onNextStep: (step: number) => void;
  formik: any;
}

export interface passwordStrengthProps {
  title: string;
  content: string;
  status: string;
}

const optionWhere = [
  { value: 'IN_CA', label: 'In California' },
  { value: 'OUTSIDE_CA', label: 'Outside California (in the U.S.)' },
  { value: 'OUTSIDE_USA', label: 'Outside the U.S.' },
];

const FormRegisterSchool = ({ formik, onNextStep }: Props) => {
  const [agreeTerm, setAgreeTerm] = React.useState(false);
  let searchCtrl = new AbortController();

  const [highSchoolData, setHighSchoolData] = React.useState<any[]>([]);

  
  const handleSearchHighSchool = async (keyword: string) => {
    if (keyword.length < 1) return;
    searchCtrl.abort();
    searchCtrl = new AbortController();
    try {
      let res: any = await courseServices.getSchoolSuggest(
        { school_name: keyword.trim() },
        formik.values.additional_info.where_attend_high_school,
        searchCtrl
      );
      const data = res.map((item: any) => ({
        ...item,
        label:
          `${item.high_school_point ?? item.school_name}` +
          `(code: ${item.high_school_code ?? item?.college_board_code ?? ''}, city: ${
            item.city
          }, state: ${item.state})`,
        value: item.high_school_point ?? item.school_name,
        code: item.high_school_code,
      }));
      setHighSchoolData(data);
    } catch (error: any) {
      if (axios.isCancel(error)) {
      } else {
      }
    }
  };

  const handleChangeItem = (item: any) => {
    const getStateName = (stateCode: string) => {
      for (let i = 0; i < states_usa.length; i++) {
        if (states_usa[i].value === stateCode) return states_usa[i].name;
      }
      return '';
    };
    try {
      formik.setFieldValue('high_school_code', item?.code);
      formik.setFieldValue('high_school_object', item);
      formik.setFieldValue('schoolId', item?.id);

      switch (formik.values.additional_info?.where_attend_high_school) {
        case ('In California'):
          formik.setFieldValue('state', getStateName('CA'));
          formik.setFieldValue('city', item?.city);
          formik.setFieldValue('csu_city', item?.city);
          break;
        case ('Outside California (in the U.S.)'):
          formik.setFieldValue('state', getStateName(item?.state));
          formik.setFieldValue('city', item?.city);
          formik.setFieldValue('csu_city', item?.city);
          break;
        case 'Outside the U.S.':
          break;
      }
    } catch (ex) {}
  };

  const [majorOptions, setMajorOptions] = React.useState<any[]>([]);

  const searchMajor = async (keyword: string) => {
    const res: any = await collegeServices.getUSCollegeMajorV1({ keyword });
    if (res?.length) {
      const result = removeDuplicate(
        res.map((item: { category1: string }) => item?.category1)
      ).sort();
      setMajorOptions(result);
    }
  };
  React.useEffect(() => {
    searchMajor('');
  }, []);
  return (
    <>
      <Form>
        <Row className="m-3 p-0">
          <SelectInput
            // icon={<PersonOutlineIcon />}
            mdCol={'6'}
            option={['In California', 'Outside California (in the U.S.)', 'Outside the U.S.']}
            label={'Where do/did you attend high school?'}
            name={'additional_info.where_attend_high_school'}
            values={
              optionWhere.find((item) => item.value === formik.values.additional_info?.where_attend_high_school)
                ?.label
            }
            onChange={(e) => {
              let code = '';
              switch (e.target.value) {
                case 'In California':
                  code = 'IN_CA';
                  break;
                case 'Outside California (in the U.S.)':
                  code = 'OUTSIDE_CA';
                  break;
                case 'Outside the U.S.':
                  code = 'OUTSIDE_USA';
                  break;
              }
              formik.setFieldValue('additional_info.where_attend_high_school', code);
            }}

          />
          <SelectInput
            // icon={<PersonOutlineIcon />}
            option={[
              'Full year (1 final grade per year)',
              'Semester (2 final grades per year)',
              'Trimester (3 final grades per year)',
              'Quarter (4 final grades per year)',
            ]}
            mdCol={'6'}
            label={`What's its school system?`}
            name={'additional_info.school_system'}
            values={formik.values.additional_info?.school_system}
            onChange={formik.handleChange}
          />

          <SelectSearchInput
            mdCol="12"
            form={formik}
            values={formik.values.additional_info?.high_school_name}
            name="additional_info.high_school_name"
            label={'Current or Most Recent Secondary/High School'}
            feedback="Search by CEEB code, City, County/Region/Territory, High School Name"
            option={highSchoolData}
            onChangeItem={handleChangeItem}
            onSearch={(keyword: string) => handleSearchHighSchool(keyword)}
            logo="span-uc-logo"
          />
          <AutocompleteInput
            onChange={(val: any) => {
              formik.setFieldValue('additional_info.majors_interest_1', val);
            }}
            option={majorOptions}
            label="Majors of interest 1"
            name="additional_info.majors_interest_1"
            values={formik.values.additional_info?.majors_interest_1}
            mdCol={'6'}
          />
          <AutocompleteInput
            onChange={(val: any) => {
              formik.setFieldValue('additional_info.majors_interest_2', val);
            }}
            option={majorOptions}
            label="Majors of interest 2"
            name="additional_info.majors_interest_2"
            values={formik.values.additional_info?.majors_interest_2}
            mdCol={'6'}
          />
          <GroupRadioButton
            label="Year entering college"
            options={['2025', '2026', '2027', '2028']}
            mdCol={3}
            name="additional_info.year_plan_college_studies"
            values={formik.values.additional_info?.year_plan_college_studies}
            onChange={formik.handleChange}
          />
        </Row>
        <div className="mx-3">
          <CheckboxInput
            mdCol={'6'}
            label={
              <span>
                By creating an account you agree with our
                <a
                  className="p-0 mx-1 pb-1"
                  href="#/public/privacy-policies"
                  target="_blank"
                  style={{ textDecoration: 'none' }}
                >
                  terms and conditions.
                </a>
              </span>
            }
            name={'password_confirm'}
            values={agreeTerm}
            onChange={(e) => setAgreeTerm(e.target.checked)}
          />
        </div>
        <div className="mx-3 mb-5">
          <Button
            disabled={!agreeTerm}
            className="w-100 p-2 my-3"
            variant="primary"
            onClick={() => {
              formik.handleSubmit();
              onNextStep(4);
            }}
          >
            <span className="h5 fw-bold">Next</span>
          </Button>
        </div>
      </Form>
    </>
  );
};

export default FormRegisterSchool;
