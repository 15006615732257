/* eslint-disable no-empty-pattern */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface ActivitiesAndHonorsState {
  unsaveForm: boolean;
  sectionPermissionDenied: string[];
  status: 'idle' | 'loading' | 'failed';
}

const initialState: ActivitiesAndHonorsState = {
  unsaveForm: false,
  sectionPermissionDenied: [],
  status: 'idle',
};

export const appSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    setUnsaveTrue: (state) => {
      state.unsaveForm = true;
    },
    setUnsaveFalse: (state) => {
      state.unsaveForm = false;
    },
    setSectionPermissionDenied: (state, action: PayloadAction<string[]>) => {
      state.sectionPermissionDenied = action.payload;
    },
  },
});

export const { setUnsaveTrue, setUnsaveFalse, setSectionPermissionDenied } = appSlice.actions;
