import TextInput from 'components/input/textInput';

interface Props {
  formik: any;
}

const CounselorInformation = (props: Props) => {
  return (
    <>
      <div className="fs-5 p-1">High School Counselor Information</div>

      <TextInput
        countText={60}
        className="coallition-flag"
        name="counselor_first_name"
        onChange={props.formik.handleChange}
        values={props.formik.values.counselor_first_name}
        label="Counselor's First Name*"
      />

      <TextInput
        countText={60}
        className="coallition-flag end-flag"
        name="counselor_last_name"
        onChange={props.formik.handleChange}
        values={props.formik.values.counselor_last_name}
        label="Counselor's Last Name*"
      />

      <TextInput
        name="counselor_email"
        countText={80}
        className="coallition-flag"
        onChange={props.formik.handleChange}
        values={props.formik.values.counselor_email}
        label="Counselor's Email"
      />
    </>
  );
};

export default CounselorInformation;
