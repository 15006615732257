import { Checkbox, FormControlLabel } from '@mui/material';
import React from 'react';
import { Col, Form, Row } from 'react-bootstrap';

interface Props {
  mdCol?: number;
  options: Array<{
    value: string;
    label: string;
    mdCol?: number;
    disabled?: boolean;
    onChecked?: (e: boolean) => void;
    subContent?: any;
    activeContent?: any;
  }>;
  form: any;
  tooltips?: string;
  className?: string;
  label?: string;
  logo?: string;
}

const GroupCheckbox = (props: Props) => {
  return (
    <Row className={`${props.className} p-1 p-md-1`}>
      <Form.Label className={`label-14px ${props.logo ?? ''}`.trim()}>{props.label}</Form.Label>
      <div className="sized70 text-field-light">{props.tooltips}</div>
      {props.options.map((option) => {
        let handleChange = (e: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
          props.form.setFieldValue(option.value, checked);
          option.onChecked && option.onChecked(checked);
        };
        const groupValues = option.value.split('.');
        const isChecked = option.value.includes('.')
          ? props.form.values[groupValues[0]][groupValues[1]]
          : props.form.values[option.value];
        return (
          <Col md={option.mdCol || props.mdCol || 12} key={option.value}>
            {isChecked && option.activeContent && (
              <div className="sized70">{option.activeContent}</div>
            )}
            <FormControlLabel
              className="text-field-mid"
              control={
                <Checkbox
                  name={option.value}
                  disabled={option.disabled}
                  checked={isChecked}
                  onChange={handleChange}
                />
              }
              label={option.label}
            />
            {option.subContent}
          </Col>
        );
      })}
    </Row>
  );
};

export default GroupCheckbox;
