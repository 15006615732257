import Typography from '@mui/material/Typography';
import React from 'react';
import { Row } from 'react-bootstrap';
import { CollegeInformationDetail } from './CounselorCollegeSearch';

import collegeServices from 'services/collegeServices';
import { checkEnableParseJSON } from 'components/helper/helper';
import { Box } from '@mui/material';
import { useParams } from 'react-router-dom';
import { useAppSelector } from 'app/hooks';

type Props = {};

const CounselorCollegeView = (props: Props) => {
  const params: { id?: string | number } = useParams();

  const [myCurrentList, setMyCurrentList] = React.useState<any[]>([]);
  // eslint-disable-next-line eqeqeq
  const menu = useAppSelector((store) => store.counselor.studentList);
  // eslint-disable-next-line eqeqeq
  const student = (menu ?? []).find((item) => item?.student_id == params?.id)?.student_info;
  const studentName = student?.first_name;
  React.useEffect(() => {
    params?.id &&
      collegeServices.getMyCollege(params?.id).then((res: any) => {
        if (!!res?.colleges_array) {
          const colleges_array = checkEnableParseJSON(res?.colleges_array, []);

          const likedColleges: any = [];
          colleges_array.forEach((element: any) => {
            (element?.collegeList ?? []).forEach((college: any) => {
              college.is_saved && likedColleges.push(college);
            });
          });
          setMyCurrentList(likedColleges);
        }
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <Row className="px-2">
      <Typography
        sx={{ width: '100%', textAlign: 'center', fontWeight: 'bold', fontSize: '1.5rem' }}
      >
        Colleges {studentName} is Applying To
      </Typography>
      <Box sx={{ padding: '0 84px !important' }}>
        {myCurrentList.map((miniItem, index) => (
          <div key={index}>
            <CollegeInformationDetail miniItem={miniItem} index={index} item={myCurrentList} />
          </div>
        ))}
      </Box>
    </Row>
  );
};

export default CounselorCollegeView;
