/* eslint-disable react-hooks/exhaustive-deps */
import { countWordInString } from 'components/helper/helper';
import React from 'react';
import { Col, Form } from 'react-bootstrap';

interface Props {
  hidden?: boolean;
  tooltip?: string;
  mdCol?: string;
  label?: string;
  name: string;
  values: any;
  onChange?: (e: any) => void;
  isValid?: boolean;
  feedback?: any;
  placeholder?: string;
  disabled?: boolean;
  countText?: number;
  rows?: number;
  className?: string;
  countWord?: number;
  maxLenght?: number;
  logo?: string;
}

const TextAreaInput = (props: Props) => {
  const [counter, setCounter] = React.useState(0);
  const [counterWord, setCounterWord] = React.useState(0);
  React.useEffect(() => {
    const spaces = countWordInString(props.values);
    if (props.values?.length) {
      setCounter(props.values.length ?? 0);
    } else if (props.countWord) {
      setCounterWord(spaces ?? 0);
    }
  }, [props.values]);
  const handleChange = async (e: any) => {
    if (props.countText && e.target.value.length > props.countText) return;
    const spaces = countWordInString(e.target.value);
    if (props.countWord && spaces >= props.countWord) return;
    props.onChange && props.onChange(e);
  };
  return props.hidden ? null : (
    <Form.Group
      name={props.name}
      className={`p-1 p-md-1 ${props.className ?? ''}`.trim()}
      as={Col}
      md={props.mdCol || '6'}
    >
      {props.label && (
        <Form.Label style={{ fontSize: '1rem', color: '#4E5A6F' }} className={props.logo}>
          {props.label}
        </Form.Label>
      )}
      <div className="text-field-light" style={{ fontSize: '0.8rem' }}>
        {props.tooltip}
      </div>
      <Form.Control
        disabled={props.disabled}
        placeholder={props.placeholder}
        name={props.name}
        value={props.values ?? ''}
        // maxLength={props.countText}
        {...(props.countText ? { maxLength: props.countText } : {})}
        onChange={handleChange}
        isValid={props.isValid}
        as="textarea"
        rows={props.rows || 3}
      />
      <div className="sized70 text-field-light w-100 d-flex justify-content-between mh-1rem">
        <span>{props.feedback}</span>

        <span>
          {counter}
          {props.countText ? `/${props.countText}` : ''}
        </span>
        
        {props.countWord && (
          <span>
            {counterWord}/{props.countWord}
          </span>
        )}
      </div>
    </Form.Group>
  );
};

export default TextAreaInput;
