
interface priceProp { 
    onSelect:any,
    isSelected: boolean
    isRecommended: boolean
  }

// export const PriceSilverMonth = (props: priceProp) =>{
//     const Amount = "14.99";
//     const CurrencySign = "$"
//     const HighlightBadge =  props.isRecommended? "Recommended" : "";  
//     const ProductName = "Silver Plan";
//     const PriceDescription = "";
//     const StaringDescripton = "";
//     const CurrencyAmount = `${CurrencySign}${Amount}`;
//     const PriceMmeteredDescription = "$14.99/month.";
//     const ButtonText = "Subcribe";
//     const isPricePreview = false;  
//     const isHightlight = props.isSelected ? "is-highlight" : "";
//     let isHighlightBadge  = HighlightBadge === ''? '': "is-highlightBadge";
  
//     return (       
//         <div className={`PriceColumn ${isHightlight} flex-container direction-column justify-content-flex-start align-items-center`}
//           onClick={props.onSelect}
//         >
//           <div className="PriceColumn-content flex-container direction-column justify-content-flex-start align-items-center">
          
//             <div className="PriceColumn-aboutProduct flex-container direction-column">
//               <div className="PriceColumn-badge flex-container">
                
//                 <div className={`Badge ${isHighlightBadge} flex-container align-items-center`}>
//                   <div className="Badge-text Text Text-color--default Text-fontSize--12 Text-fontWeight--500">
//                   {HighlightBadge}
//                   </div>
//                 </div>
                
//               </div>
  
//               <div className="PriceColumn-textAndProductImage flex-container" style={{marginBottom:'24px'}} >
//                 <div className="PriceColumn-textContainerHeightAligner" style={{height:"8px"}}>
//                   <div className="PriceColumn-textContainer flex-container direction-column">
//                     <div className="PriceColumn-name flex-container">
//                       <span className="Text Text-color--default Text-fontSize--20 Text-fontWeight--600">
//                       {ProductName}
//                       </span>
//                     </div>
  
//                     <span className="PriceColumn-description Text Text-color--default Text-fontSize--14">
//                       {PriceDescription}
//                     </span> 
//                   </div>
//                 </div>
//               </div>    
//             </div>
  
//             <div className="PriceColumn-priceAndButton flex-container direction-column">
//               <div className='PriceColumn-priceContainerHeightAligner'>
//                 <div className="PriceColumn-priceContainer">
//                   <div className="flex-container spacing-4 direction-column align-items-flex-start">
//                     {StaringDescripton && (
//                       <span className="PriceColumn-startingAtDescription Text Text-color--default Text-fontSize--13">
//                         {StaringDescripton}
//                       </span>                    
//                     )}
  
//                     <div className="flex-container align-items-center">
//                       <div className="PriceColumn-price Text Text-color--default Text-fontSize--36 Text-fontWeight--700">
//                         <span className="CurrencyAmount" style={{color:'#4D88F0'}} >{CurrencyAmount}</span>
//                       </div>
//                       {/* <span className="PriceColumn-interval Text Text-color--default Text-fontSize--13">
//                         {PriceInterval_line1}<br/>
//                         {PriceInterval_line2}
//                       </span> */}
//                       <span className="Text Text-color--default Text-fontSize--13">
//                         <br/>
//                         <strong style={{color:'#000',fontWeight:'bold'}}>/Month</strong>
//                       </span>
//                     </div>
  
//                     {PriceMmeteredDescription && (
//                       <div className="PriceColumn-meteredDescription Text Text-color--default Text-fontSize--13"
//                         style={{color:'#172640', opacity:'1'}}
//                       >
//                         {PriceMmeteredDescription}
//                       </div>
//                     )}
  
//                     {isPricePreview && (
//                       <div className="Tooltip-Context TierPricingPreview-tooltipContext" style={{padding:'padding: 0px;'}}>
//                         <div className="flex-container direction-row align-items-center">
//                           <svg className="InlineSVG Icon Icon--sm" focusable="false" fill="#1a1a1a" color="#1a1a1a" fillOpacity="0.5" height="16" viewBox="0 0 16 16" width="16"><path d="m8 14c-4.418278 0-8-4.6862915-8-6s3.581722-6 8-6 8 4.6862915 8 6-3.581722 6-8 6zm0-2c2.209139 0 4-1.790861 4-4s-1.790861-4-4-4-4 1.790861-4 4 1.790861 4 4 4zm0-2c-1.1045695 0-2-.8954305-2-2s.8954305-2 2-2 2 .8954305 2 2-.8954305 2-2 2z" fillRule="evenodd"></path></svg>
//                           <div className="TierPricingPreview-context Text Text-color--default Text-fontSize--13">Pricing preview</div>
//                         </div>
//                       </div>
//                     )}
  
//                 </div>
//               </div>
//             </div>
  
//             <div className="PriceColumn-tierSelectPlaceholderHeight" style={{height:'8px'}}></div>
  
//             <button className="Button PriceColumn-button Button--primary Button--lg" type="submit" 
//                 style={{backgroundColor: "rgb(62, 115, 240)", borderColor: "rgb(62, 115, 240)"}}
//             >
//               <div className="flex-container justify-content-center align-items-center">
//                 <span className="Text Text-color--default Text-fontWeight--500 Text--truncate">{ButtonText}</span>
//               </div>
//             </button>
//             </div>
//           </div>
  
//           <div className="PriceColumn-featureListContainer flex-container direction-column">
              
//               <div className="PriceColumn-featureListHeader Text Text-color--default Text-fontSize--14 mt-4 mb-4"
//                 style={{height:'1px', width:'100%'}}
//               >
//                 <hr style={{color:'#4D88F0', width:'100%', height:'2px', opacity:'1', margin: '0' }} ></hr>
//               </div>
//               <div className='row'>
//                 <div className='col-mt-12'>
//                   <h2>Organize</h2>
//                   <h3>Digital Portfolio</h3>
  
//                   <ul className="elementor-icon-list-items">
//                     <li className="elementor-icon-list-item">
//                         <span className="elementor-icon-list-icon">
//                           <svg aria-hidden="true" className="e-font-icon-svg e-fas-check" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg"><path d="M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z"></path></svg></span>
//                           <span className="elementor-icon-list-text">Profile</span>
//                         </li>
//                       <li className="elementor-icon-list-item">
//                           <span className="elementor-icon-list-icon">
//                             <svg aria-hidden="true" className="e-font-icon-svg e-fas-check" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg"><path d="M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z"></path></svg>
//                           </span>
//                           <span className="elementor-icon-list-text">Family</span>
//                         </li>
//                       <li className="elementor-icon-list-item">
//                           <span className="elementor-icon-list-icon">
//                             <svg aria-hidden="true" className="e-font-icon-svg e-fas-check" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg"><path d="M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z"></path></svg>
//                           </span>
//                           <span className="elementor-icon-list-text">Education</span>
//                         </li>
//                       <li className="elementor-icon-list-item">
//                           <span className="elementor-icon-list-icon">
//                             <svg aria-hidden="true" className="e-font-icon-svg e-fas-check" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg"><path d="M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z"></path></svg>
//                           </span>
//                           <span className="elementor-icon-list-text">Courses &amp; Grades</span>
//                         </li>
//                       <li className="elementor-icon-list-item">
//                           <span className="elementor-icon-list-icon">
//                             <svg aria-hidden="true" className="e-font-icon-svg e-fas-check" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg"><path d="M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z"></path></svg>
//                           </span>
//                           <span className="elementor-icon-list-text">Testing</span>
//                         </li>
//                       <li className="elementor-icon-list-item">
//                           <span className="elementor-icon-list-icon">
//                             <svg aria-hidden="true" className="e-font-icon-svg e-fas-check" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg"><path d="M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z"></path></svg>
//                           </span>
//                           <span className="elementor-icon-list-text">Activities &amp; Honors</span>
//                         </li>
//                       <li className="elementor-icon-list-item">
//                           <span className="elementor-icon-list-icon">
//                             <svg aria-hidden="true" className="e-font-icon-svg e-fas-check" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg"><path d="M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z"></path></svg>
//                           </span>
//                           <span className="elementor-icon-list-text">Additional Info</span>
//                         </li>
//                   </ul>
//                   <hr style={{color:'#4D88F0', width:'100%', height:'2px', opacity:'1' }} ></hr>
                 
//                   <h3>Colleges</h3>
//                   <ul className="elementor-icon-list-items">
//                     <li className="elementor-icon-list-item">
//                             <span className="elementor-icon-list-icon">
//                     <svg aria-hidden="true" className="e-font-icon-svg e-fas-check" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg"><path d="M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z"></path></svg>						</span>
//                           <span className="elementor-icon-list-text">College Search</span>
//                         </li>
//                       <li className="elementor-icon-list-item">
//                             <span className="elementor-icon-list-icon">
//                     <svg aria-hidden="true" className="e-font-icon-svg e-fas-check" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg"><path d="M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z"></path></svg>						</span>
//                           <span className="elementor-icon-list-text">Create Multiple College Lists</span>
//                         </li>
//                   </ul>                
//                   <hr style={{color:'#4D88F0', width:'100%', height:'2px', opacity:'1' }} ></hr>
//                   <h3>Summary</h3>
//                   <ul className="elementor-icon-list-items">
//                     <li className="elementor-icon-list-item">
//                         <span className="elementor-icon-list-icon"><svg aria-hidden="true" className="e-font-icon-svg e-fas-check" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg"><path d="M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z"></path></svg></span>
//                         <span className="elementor-icon-list-text">Page-by-page view mode</span>
//                     </li>
//                   </ul>
//                   <hr style={{color:'#4D88F0', width:'100%', height:'2px', opacity:'1' }} ></hr>
                  
//                   <h2>Optimize</h2>
//                   <h3>AI PUSH Chatbot Guidance</h3>
//                   <ul className="elementor-icon-list-items">
//                     <li className="elementor-icon-list-item">
//                       <span className="elementor-icon-list-icon red"><svg aria-hidden="true" className="e-font-icon-svg e-fas-times-circle" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg"><path d="M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8zm121.6 313.1c4.7 4.7 4.7 12.3 0 17L338 377.6c-4.7 4.7-12.3 4.7-17 0L256 312l-65.1 65.6c-4.7 4.7-12.3 4.7-17 0L134.4 338c-4.7-4.7-4.7-12.3 0-17l65.6-65-65.6-65.1c-4.7-4.7-4.7-12.3 0-17l39.6-39.6c4.7-4.7 12.3-4.7 17 0l65 65.7 65.1-65.6c4.7-4.7 12.3-4.7 17 0l39.6 39.6c4.7 4.7 4.7 12.3 0 17L312 256l65.6 65.1z"></path></svg></span>
//                       <span className="elementor-icon-list-text">Expertly trained by college counselors</span>
//                     </li>
//                     <li className="elementor-icon-list-item">
//                       <span className="elementor-icon-list-icon red"><svg aria-hidden="true" className="e-font-icon-svg e-fas-times-circle" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg"><path d="M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8zm121.6 313.1c4.7 4.7 4.7 12.3 0 17L338 377.6c-4.7 4.7-12.3 4.7-17 0L256 312l-65.1 65.6c-4.7 4.7-12.3 4.7-17 0L134.4 338c-4.7-4.7-4.7-12.3 0-17l65.6-65-65.6-65.1c-4.7-4.7-4.7-12.3 0-17l39.6-39.6c4.7-4.7 12.3-4.7 17 0l65 65.7 65.1-65.6c4.7-4.7 12.3-4.7 17 0l39.6 39.6c4.7 4.7 4.7 12.3 0 17L312 256l65.6 65.1z"></path></svg></span>
//                       <span className="elementor-icon-list-text">Profile building guidance</span>
//                      </li>
//                     <li className="elementor-icon-list-item">
//                       <span className="elementor-icon-list-icon red"><svg aria-hidden="true" className="e-font-icon-svg e-fas-times-circle" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg"><path d="M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8zm121.6 313.1c4.7 4.7 4.7 12.3 0 17L338 377.6c-4.7 4.7-12.3 4.7-17 0L256 312l-65.1 65.6c-4.7 4.7-12.3 4.7-17 0L134.4 338c-4.7-4.7-4.7-12.3 0-17l65.6-65-65.6-65.1c-4.7-4.7-4.7-12.3 0-17l39.6-39.6c4.7-4.7 12.3-4.7 17 0l65 65.7 65.1-65.6c4.7-4.7 12.3-4.7 17 0l39.6 39.6c4.7 4.7 4.7 12.3 0 17L312 256l65.6 65.1z"></path></svg></span>
//                       <span className="elementor-icon-list-text">Passion project ideas and outlines</span>
//                     </li>
//                     <li className="elementor-icon-list-item">
//                       <span className="elementor-icon-list-icon red"><svg aria-hidden="true" className="e-font-icon-svg e-fas-times-circle" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg"><path d="M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8zm121.6 313.1c4.7 4.7 4.7 12.3 0 17L338 377.6c-4.7 4.7-12.3 4.7-17 0L256 312l-65.1 65.6c-4.7 4.7-12.3 4.7-17 0L134.4 338c-4.7-4.7-4.7-12.3 0-17l65.6-65-65.6-65.1c-4.7-4.7-4.7-12.3 0-17l39.6-39.6c4.7-4.7 12.3-4.7 17 0l65 65.7 65.1-65.6c4.7-4.7 12.3-4.7 17 0l39.6 39.6c4.7 4.7 4.7 12.3 0 17L312 256l65.6 65.1z"></path></svg></span>
//                       <span className="elementor-icon-list-text">High school activity support</span>
//                     </li>
//                     <li className="elementor-icon-list-item">
//                       <span className="elementor-icon-list-icon red"><svg aria-hidden="true" className="e-font-icon-svg e-fas-times-circle" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg"><path d="M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8zm121.6 313.1c4.7 4.7 4.7 12.3 0 17L338 377.6c-4.7 4.7-12.3 4.7-17 0L256 312l-65.1 65.6c-4.7 4.7-12.3 4.7-17 0L134.4 338c-4.7-4.7-4.7-12.3 0-17l65.6-65-65.6-65.1c-4.7-4.7-4.7-12.3 0-17l39.6-39.6c4.7-4.7 12.3-4.7 17 0l65 65.7 65.1-65.6c4.7-4.7 12.3-4.7 17 0l39.6 39.6c4.7 4.7 4.7 12.3 0 17L312 256l65.6 65.1z"></path></svg></span>
//                       <span className="elementor-icon-list-text">College application support</span>
//                     </li>
//                   </ul>
//                   <hr style={{color:'#4D88F0', width:'100%', height:'2px', opacity:'1' }} ></hr>
  
//                   <h3>Counselor</h3>
//                   <ul className="elementor-icon-list-items">
//                       <li className="elementor-icon-list-item">
//                         <span className="elementor-icon-list-icon"><svg aria-hidden="true" className="e-font-icon-svg e-fas-check" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg"><path d="M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z"></path></svg></span>
//                         <span className="elementor-icon-list-text">Add your trusted counselor</span>
//                       </li>
//                       <li className="elementor-icon-list-item">
//                         <span className="elementor-icon-list-icon"><svg aria-hidden="true" className="e-font-icon-svg e-fas-check" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg"><path d="M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z"></path></svg></span>
//                         <span className="elementor-icon-list-text">Chat with your counselor</span>
//                       </li>
//                       <li className="elementor-icon-list-item">
//                         <span className="elementor-icon-list-icon"><svg aria-hidden="true" className="e-font-icon-svg e-fas-check" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg"><path d="M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z"></path></svg></span>
//                         <span className="elementor-icon-list-text">Suggested List Access w/ Likelihood Status</span>
//                       </li>
//                       <li className="elementor-icon-list-item">
//                         <span className="elementor-icon-list-icon"><svg aria-hidden="true" className="e-font-icon-svg e-fas-check" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg"><path d="M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z"></path></svg></span>
//                         <span className="elementor-icon-list-text">Two-Way Summary Approval</span>
//                       </li>
//                   </ul>
//                   <hr style={{color:'#4D88F0', width:'100%', height:'2px', opacity:'1' }} ></hr>
  
//                   <h3>College Planning Resources</h3>
//                   <ul className="elementor-icon-list-items">
//                     <li className="elementor-icon-list-item">
//                         <span className="elementor-icon-list-icon red"><svg aria-hidden="true" className="e-font-icon-svg e-fas-times-circle" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg"><path d="M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8zm121.6 313.1c4.7 4.7 4.7 12.3 0 17L338 377.6c-4.7 4.7-12.3 4.7-17 0L256 312l-65.1 65.6c-4.7 4.7-12.3 4.7-17 0L134.4 338c-4.7-4.7-4.7-12.3 0-17l65.6-65-65.6-65.1c-4.7-4.7-4.7-12.3 0-17l39.6-39.6c4.7-4.7 12.3-4.7 17 0l65 65.7 65.1-65.6c4.7-4.7 12.3-4.7 17 0l39.6 39.6c4.7 4.7 4.7 12.3 0 17L312 256l65.6 65.1z"></path></svg></span>
//                         <span className="elementor-icon-list-text">Search/Access Knowledge Base </span>
//                       </li>
//                       <li className="elementor-icon-list-item">
//                         <span className="elementor-icon-list-icon red"><svg aria-hidden="true" className="e-font-icon-svg e-fas-times-circle" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg"><path d="M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8zm121.6 313.1c4.7 4.7 4.7 12.3 0 17L338 377.6c-4.7 4.7-12.3 4.7-17 0L256 312l-65.1 65.6c-4.7 4.7-12.3 4.7-17 0L134.4 338c-4.7-4.7-4.7-12.3 0-17l65.6-65-65.6-65.1c-4.7-4.7-4.7-12.3 0-17l39.6-39.6c4.7-4.7 12.3-4.7 17 0l65 65.7 65.1-65.6c4.7-4.7 12.3-4.7 17 0l39.6 39.6c4.7 4.7 4.7 12.3 0 17L312 256l65.6 65.1z"></path></svg></span>
//                         <span className="elementor-icon-list-text">Major-based Suggestions</span>
//                       </li>
//                       <li className="elementor-icon-list-item">
//                         <span className="elementor-icon-list-icon red"><svg aria-hidden="true" className="e-font-icon-svg e-fas-times-circle" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg"><path d="M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8zm121.6 313.1c4.7 4.7 4.7 12.3 0 17L338 377.6c-4.7 4.7-12.3 4.7-17 0L256 312l-65.1 65.6c-4.7 4.7-12.3 4.7-17 0L134.4 338c-4.7-4.7-4.7-12.3 0-17l65.6-65-65.6-65.1c-4.7-4.7-4.7-12.3 0-17l39.6-39.6c4.7-4.7 12.3-4.7 17 0l65 65.7 65.1-65.6c4.7-4.7 12.3-4.7 17 0l39.6 39.6c4.7 4.7 4.7 12.3 0 17L312 256l65.6 65.1z"></path></svg></span>
//                         <span className="elementor-icon-list-text">Parent &amp; Student Educational Video Library</span>
//                       </li>
//                   </ul>
//                   <hr style={{color:'#4D88F0', width:'100%', height:'2px', opacity:'1' }} ></hr>
  
//                   <h2>Automate</h2>
//                   <ul className="elementor-icon-list-items">
//                     <li className="elementor-icon-list-item">
//                       <span className="elementor-icon-list-icon red"><svg aria-hidden="true" className="e-font-icon-svg e-fas-times-circle" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg"><path d="M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8zm121.6 313.1c4.7 4.7 4.7 12.3 0 17L338 377.6c-4.7 4.7-12.3 4.7-17 0L256 312l-65.1 65.6c-4.7 4.7-12.3 4.7-17 0L134.4 338c-4.7-4.7-4.7-12.3 0-17l65.6-65-65.6-65.1c-4.7-4.7-4.7-12.3 0-17l39.6-39.6c4.7-4.7 12.3-4.7 17 0l65 65.7 65.1-65.6c4.7-4.7 12.3-4.7 17 0l39.6 39.6c4.7 4.7 4.7 12.3 0 17L312 256l65.6 65.1z"></path></svg></span>
//                       <span className="elementor-icon-list-text">Chrome Extension Access to Auto-fill CSU app</span>
//                     </li>
//                     <li className="elementor-icon-list-item">
//                         <span className="elementor-icon-list-icon red"><svg aria-hidden="true" className="e-font-icon-svg e-fas-times-circle" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg"><path d="M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8zm121.6 313.1c4.7 4.7 4.7 12.3 0 17L338 377.6c-4.7 4.7-12.3 4.7-17 0L256 312l-65.1 65.6c-4.7 4.7-12.3 4.7-17 0L134.4 338c-4.7-4.7-4.7-12.3 0-17l65.6-65-65.6-65.1c-4.7-4.7-4.7-12.3 0-17l39.6-39.6c4.7-4.7 12.3-4.7 17 0l65 65.7 65.1-65.6c4.7-4.7 12.3-4.7 17 0l39.6 39.6c4.7 4.7 4.7 12.3 0 17L312 256l65.6 65.1z"></path></svg></span>
//                           <span className="elementor-icon-list-text">Chrome Extension Access to Auto-fill UC app</span>
//                     </li>
//                     <li className="elementor-icon-list-item">
//                       <span className="elementor-icon-list-icon red"><svg aria-hidden="true" className="e-font-icon-svg e-fas-times-circle" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg"><path d="M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8zm121.6 313.1c4.7 4.7 4.7 12.3 0 17L338 377.6c-4.7 4.7-12.3 4.7-17 0L256 312l-65.1 65.6c-4.7 4.7-12.3 4.7-17 0L134.4 338c-4.7-4.7-4.7-12.3 0-17l65.6-65-65.6-65.1c-4.7-4.7-4.7-12.3 0-17l39.6-39.6c4.7-4.7 12.3-4.7 17 0l65 65.7 65.1-65.6c4.7-4.7 12.3-4.7 17 0l39.6 39.6c4.7 4.7 4.7 12.3 0 17L312 256l65.6 65.1z"></path></svg></span>
//                       <span className="elementor-icon-list-text">Chrome Extension Access to Auto-fill Common App</span>
//                       </li>
//                   </ul>
//                   <hr style={{color:'#4D88F0', width:'100%', height:'2px', opacity:'1' }} ></hr>
  
//                   <h2>Expert Chat Support</h2>
//                   <ul className="elementor-icon-list-items">
//                     <li className="elementor-icon-list-item">
//                       <span className="elementor-icon-list-icon red"><svg aria-hidden="true" className="e-font-icon-svg e-fas-times-circle" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg"><path d="M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8zm121.6 313.1c4.7 4.7 4.7 12.3 0 17L338 377.6c-4.7 4.7-12.3 4.7-17 0L256 312l-65.1 65.6c-4.7 4.7-12.3 4.7-17 0L134.4 338c-4.7-4.7-4.7-12.3 0-17l65.6-65-65.6-65.1c-4.7-4.7-4.7-12.3 0-17l39.6-39.6c4.7-4.7 12.3-4.7 17 0l65 65.7 65.1-65.6c4.7-4.7 12.3-4.7 17 0l39.6 39.6c4.7 4.7 4.7 12.3 0 17L312 256l65.6 65.1z"></path></svg></span>
//                       <span className="elementor-icon-list-text">Expert Chat Support</span>
//                     </li>
//                   </ul>
  
//                 </div>
//               </div>
  
//           </div>
//         </div>
  
//     );
  
//  }
// export const PriceSilverYear =(props: priceProp) =>{
//     const Amount = "119.00";
//     const CurrencySign = "$"
//     const HighlightBadge =  props.isRecommended? "Recommended" : "";  
//     const ProductName = "Silver Plan";
//     const PriceDescription = "";
//     const StaringDescripton = "";
//     const CurrencyAmount = `${CurrencySign}${Amount}`;
//     const PriceMmeteredDescription = "$9.99/month. ($119.00 billed annually)";
//     const ButtonText = "Subcribe";
//     const isPricePreview = false;  
//     const isHightlight = props.isSelected ? "is-highlight" : "";
//     let isHighlightBadge  = HighlightBadge === ''? '': "is-highlightBadge";
    
//     return (       
//         <div className={`PriceColumn ${isHightlight} flex-container direction-column justify-content-flex-start align-items-center`}
//           onClick={props.onSelect}
//         >
//           <div className="PriceColumn-content flex-container direction-column justify-content-flex-start align-items-center">
          
//             <div className="PriceColumn-aboutProduct flex-container direction-column">
//               <div className="PriceColumn-badge flex-container">
                
//                 <div className={`Badge ${isHighlightBadge} flex-container align-items-center`}>
//                   <div className="Badge-text Text Text-color--default Text-fontSize--12 Text-fontWeight--500">
//                   {HighlightBadge}
//                   </div>
//                 </div>
                
//               </div>
  
//               <div className="PriceColumn-textAndProductImage flex-container" style={{marginBottom:'24px'}} >
//                 <div className="PriceColumn-textContainerHeightAligner" style={{height:"8px"}}>
//                   <div className="PriceColumn-textContainer flex-container direction-column">
//                     <div className="PriceColumn-name flex-container">
//                       <span className="Text Text-color--default Text-fontSize--20 Text-fontWeight--600">
//                       {ProductName}
//                       </span>
//                     </div>
  
//                     <span className="PriceColumn-description Text Text-color--default Text-fontSize--14">
//                       {PriceDescription}
//                     </span> 
//                   </div>
//                 </div>
//               </div>    
//             </div>
  
//             <div className="PriceColumn-priceAndButton flex-container direction-column">
//               <div className='PriceColumn-priceContainerHeightAligner'>
//                 <div className="PriceColumn-priceContainer">
//                   <div className="flex-container spacing-4 direction-column align-items-flex-start">
//                     {StaringDescripton && (
//                       <span className="PriceColumn-startingAtDescription Text Text-color--default Text-fontSize--13">
//                         {StaringDescripton}
//                       </span>                    
//                     )}
  
//                     <div className="flex-container align-items-center">
//                       <div className="PriceColumn-price Text Text-color--default Text-fontSize--36 Text-fontWeight--700">
//                         <span className="CurrencyAmount" style={{color:'#4D88F0'}} >{CurrencyAmount}</span>
//                       </div>
//                       {/* <span className="PriceColumn-interval Text Text-color--default Text-fontSize--13">
//                         {PriceInterval_line1}<br/>
//                         {PriceInterval_line2}
//                       </span> */}
//                       <span className="Text Text-color--default Text-fontSize--13">
//                         <br/>
//                         <strong style={{color:'#000',fontWeight:'bold'}}>/Year</strong>
//                       </span>
//                     </div>
  
//                     {PriceMmeteredDescription && (
//                       <div className="PriceColumn-meteredDescription Text Text-color--default Text-fontSize--13"
//                         style={{color:'#172640', opacity:'1'}}
//                       >
//                         {PriceMmeteredDescription}
//                       </div>
//                     )}
  
//                     {isPricePreview && (
//                       <div className="Tooltip-Context TierPricingPreview-tooltipContext" style={{padding:'padding: 0px;'}}>
//                         <div className="flex-container direction-row align-items-center">
//                           <svg className="InlineSVG Icon Icon--sm" focusable="false" fill="#1a1a1a" color="#1a1a1a" fillOpacity="0.5" height="16" viewBox="0 0 16 16" width="16"><path d="m8 14c-4.418278 0-8-4.6862915-8-6s3.581722-6 8-6 8 4.6862915 8 6-3.581722 6-8 6zm0-2c2.209139 0 4-1.790861 4-4s-1.790861-4-4-4-4 1.790861-4 4 1.790861 4 4 4zm0-2c-1.1045695 0-2-.8954305-2-2s.8954305-2 2-2 2 .8954305 2 2-.8954305 2-2 2z" fillRule="evenodd"></path></svg>
//                           <div className="TierPricingPreview-context Text Text-color--default Text-fontSize--13">Pricing preview</div>
//                         </div>
//                       </div>
//                     )}
  
//                 </div>
//               </div>
//             </div>
  
//             <div className="PriceColumn-tierSelectPlaceholderHeight" style={{height:'8px'}}></div>
  
//             <button className="Button PriceColumn-button Button--primary Button--lg" type="submit" 
//                 style={{backgroundColor: "rgb(62, 115, 240)", borderColor: "rgb(62, 115, 240)"}}
//             >
//               <div className="flex-container justify-content-center align-items-center">
//                 <span className="Text Text-color--default Text-fontWeight--500 Text--truncate">{ButtonText}</span>
//               </div>
//             </button>
//             </div>
//           </div>
  
//           <div className="PriceColumn-featureListContainer flex-container direction-column">
              
//               <div className="PriceColumn-featureListHeader Text Text-color--default Text-fontSize--14 mt-4 mb-4"
//                 style={{height:'1px', width:'100%'}}
//               >
//                 {/* <div className='text-center'
//                   style={{border:'1px solid #4D88F0', width:'100%', height:'1px' }}>
//                 </div> */}
//                 <hr style={{color:'#4D88F0', width:'100%', height:'2px', opacity:'1', margin: '0' }} ></hr>
//               </div>
//               <div className='row'>
//                 <div className='col-mt-12'>
//                   <h2>Organize</h2>
//                   <h3>Digital Portfolio</h3>
  
//                   <ul className="elementor-icon-list-items">
//                     <li className="elementor-icon-list-item">
//                         <span className="elementor-icon-list-icon">
//                           <svg aria-hidden="true" className="e-font-icon-svg e-fas-check" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg"><path d="M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z"></path></svg></span>
//                           <span className="elementor-icon-list-text">Profile</span>
//                         </li>
//                       <li className="elementor-icon-list-item">
//                           <span className="elementor-icon-list-icon">
//                             <svg aria-hidden="true" className="e-font-icon-svg e-fas-check" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg"><path d="M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z"></path></svg>
//                           </span>
//                           <span className="elementor-icon-list-text">Family</span>
//                         </li>
//                       <li className="elementor-icon-list-item">
//                           <span className="elementor-icon-list-icon">
//                             <svg aria-hidden="true" className="e-font-icon-svg e-fas-check" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg"><path d="M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z"></path></svg>
//                           </span>
//                           <span className="elementor-icon-list-text">Education</span>
//                         </li>
//                       <li className="elementor-icon-list-item">
//                           <span className="elementor-icon-list-icon">
//                             <svg aria-hidden="true" className="e-font-icon-svg e-fas-check" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg"><path d="M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z"></path></svg>
//                           </span>
//                           <span className="elementor-icon-list-text">Courses &amp; Grades</span>
//                         </li>
//                       <li className="elementor-icon-list-item">
//                           <span className="elementor-icon-list-icon">
//                             <svg aria-hidden="true" className="e-font-icon-svg e-fas-check" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg"><path d="M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z"></path></svg>
//                           </span>
//                           <span className="elementor-icon-list-text">Testing</span>
//                         </li>
//                       <li className="elementor-icon-list-item">
//                           <span className="elementor-icon-list-icon">
//                             <svg aria-hidden="true" className="e-font-icon-svg e-fas-check" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg"><path d="M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z"></path></svg>
//                           </span>
//                           <span className="elementor-icon-list-text">Activities &amp; Honors</span>
//                         </li>
//                       <li className="elementor-icon-list-item">
//                           <span className="elementor-icon-list-icon">
//                             <svg aria-hidden="true" className="e-font-icon-svg e-fas-check" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg"><path d="M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z"></path></svg>
//                           </span>
//                           <span className="elementor-icon-list-text">Additional Info</span>
//                         </li>
//                   </ul>
//                   <hr style={{color:'#4D88F0', width:'100%', height:'2px', opacity:'1' }} ></hr>
                 
//                   <h3>Colleges</h3>
//                   <ul className="elementor-icon-list-items">
//                     <li className="elementor-icon-list-item">
//                             <span className="elementor-icon-list-icon">
//                     <svg aria-hidden="true" className="e-font-icon-svg e-fas-check" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg"><path d="M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z"></path></svg>						</span>
//                           <span className="elementor-icon-list-text">College Search</span>
//                         </li>
//                       <li className="elementor-icon-list-item">
//                             <span className="elementor-icon-list-icon">
//                     <svg aria-hidden="true" className="e-font-icon-svg e-fas-check" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg"><path d="M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z"></path></svg>						</span>
//                           <span className="elementor-icon-list-text">Create Multiple College Lists</span>
//                         </li>
//                   </ul>                
//                   <hr style={{color:'#4D88F0', width:'100%', height:'2px', opacity:'1' }} ></hr>
//                   <h3>Summary</h3>
//                   <ul className="elementor-icon-list-items">
//                     <li className="elementor-icon-list-item">
//                         <span className="elementor-icon-list-icon"><svg aria-hidden="true" className="e-font-icon-svg e-fas-check" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg"><path d="M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z"></path></svg></span>
//                         <span className="elementor-icon-list-text">Page-by-page view mode</span>
//                     </li>
//                   </ul>
//                   <hr style={{color:'#4D88F0', width:'100%', height:'2px', opacity:'1' }} ></hr>
                  
//                   <h2>Optimize</h2>
//                   <h3>AI PUSH Chatbot Guidance</h3>
//                   <ul className="elementor-icon-list-items">
//                     <li className="elementor-icon-list-item">
//                       <span className="elementor-icon-list-icon"><svg aria-hidden="true" className="e-font-icon-svg e-fas-check" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg"><path d="M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z"></path></svg></span>
//                       <span className="elementor-icon-list-text">Expertly trained by college counselors</span>
//                     </li>
//                     <li className="elementor-icon-list-item">
//                         <span className="elementor-icon-list-icon"><svg aria-hidden="true" className="e-font-icon-svg e-fas-check" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg"><path d="M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z"></path></svg></span>
//                         <span className="elementor-icon-list-text">Profile building guidance</span>
//                       </li>
//                     <li className="elementor-icon-list-item">
//                         <span className="elementor-icon-list-icon"><svg aria-hidden="true" className="e-font-icon-svg e-fas-check" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg"><path d="M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z"></path></svg></span>
//                         <span className="elementor-icon-list-text">Passion project ideas and outlines</span>
//                       </li>
//                     <li className="elementor-icon-list-item">
//                         <span className="elementor-icon-list-icon"><svg aria-hidden="true" className="e-font-icon-svg e-fas-check" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg"><path d="M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z"></path></svg></span>
//                         <span className="elementor-icon-list-text">High school activity support</span>
//                       </li>
//                     <li className="elementor-icon-list-item">
//                         <span className="elementor-icon-list-icon"><svg aria-hidden="true" className="e-font-icon-svg e-fas-check" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg"><path d="M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z"></path></svg></span>
//                         <span className="elementor-icon-list-text">College application support</span>
//                     </li>
//                   </ul>
//                   <hr style={{color:'#4D88F0', width:'100%', height:'2px', opacity:'1' }} ></hr>
  
//                   <h3>Counselor</h3>
//                   <ul className="elementor-icon-list-items">
//                       <li className="elementor-icon-list-item">
//                         <span className="elementor-icon-list-icon"><svg aria-hidden="true" className="e-font-icon-svg e-fas-check" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg"><path d="M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z"></path></svg></span>
//                         <span className="elementor-icon-list-text">Add your trusted counselor</span>
//                       </li>
//                       <li className="elementor-icon-list-item">
//                         <span className="elementor-icon-list-icon"><svg aria-hidden="true" className="e-font-icon-svg e-fas-check" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg"><path d="M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z"></path></svg></span>
//                         <span className="elementor-icon-list-text">Chat with your counselor</span>
//                       </li>
//                       <li className="elementor-icon-list-item">
//                         <span className="elementor-icon-list-icon"><svg aria-hidden="true" className="e-font-icon-svg e-fas-check" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg"><path d="M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z"></path></svg></span>
//                         <span className="elementor-icon-list-text">Suggested List Access w/ Likelihood Status</span>
//                       </li>
//                       <li className="elementor-icon-list-item">
//                         <span className="elementor-icon-list-icon"><svg aria-hidden="true" className="e-font-icon-svg e-fas-check" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg"><path d="M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z"></path></svg></span>
//                         <span className="elementor-icon-list-text">Two-Way Summary Approval</span>
//                       </li>
//                   </ul>
//                   <hr style={{color:'#4D88F0', width:'100%', height:'2px', opacity:'1' }} ></hr>
  
//                   <h3>College Planning Resources</h3>
//                   <ul className="elementor-icon-list-items">
//                     <li className="elementor-icon-list-item">
//                         <span className="elementor-icon-list-icon"><svg aria-hidden="true" className="e-font-icon-svg e-fas-check" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg"><path d="M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z"></path></svg></span>
//                         <span className="elementor-icon-list-text">Search/Access Knowledge Base </span>
//                       </li>
//                       <li className="elementor-icon-list-item">
//                         <span className="elementor-icon-list-icon"><svg aria-hidden="true" className="e-font-icon-svg e-fas-check" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg"><path d="M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z"></path></svg></span>
//                         <span className="elementor-icon-list-text">Major-based Suggestions</span>
//                       </li>
//                       <li className="elementor-icon-list-item">
//                         <span className="elementor-icon-list-icon"><svg aria-hidden="true" className="e-font-icon-svg e-fas-check" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg"><path d="M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z"></path></svg></span>
//                         <span className="elementor-icon-list-text">Parent &amp; Student Educational Video Library</span>
//                       </li>
//                   </ul>
//                   <hr style={{color:'#4D88F0', width:'100%', height:'2px', opacity:'1' }} ></hr>
  
//                   <h2>Automate</h2>
//                   <ul className="elementor-icon-list-items">
//                     <li className="elementor-icon-list-item">
//                       <span className="elementor-icon-list-icon red"><svg aria-hidden="true" className="e-font-icon-svg e-fas-times-circle" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg"><path d="M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8zm121.6 313.1c4.7 4.7 4.7 12.3 0 17L338 377.6c-4.7 4.7-12.3 4.7-17 0L256 312l-65.1 65.6c-4.7 4.7-12.3 4.7-17 0L134.4 338c-4.7-4.7-4.7-12.3 0-17l65.6-65-65.6-65.1c-4.7-4.7-4.7-12.3 0-17l39.6-39.6c4.7-4.7 12.3-4.7 17 0l65 65.7 65.1-65.6c4.7-4.7 12.3-4.7 17 0l39.6 39.6c4.7 4.7 4.7 12.3 0 17L312 256l65.6 65.1z"></path></svg></span>
//                           <span className="elementor-icon-list-text">Chrome Extension Access to Auto-fill CSU app</span>
//                       </li>
//                     <li className="elementor-icon-list-item">
//                         <span className="elementor-icon-list-icon red"><svg aria-hidden="true" className="e-font-icon-svg e-fas-times-circle" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg"><path d="M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8zm121.6 313.1c4.7 4.7 4.7 12.3 0 17L338 377.6c-4.7 4.7-12.3 4.7-17 0L256 312l-65.1 65.6c-4.7 4.7-12.3 4.7-17 0L134.4 338c-4.7-4.7-4.7-12.3 0-17l65.6-65-65.6-65.1c-4.7-4.7-4.7-12.3 0-17l39.6-39.6c4.7-4.7 12.3-4.7 17 0l65 65.7 65.1-65.6c4.7-4.7 12.3-4.7 17 0l39.6 39.6c4.7 4.7 4.7 12.3 0 17L312 256l65.6 65.1z"></path></svg></span>
//                           <span className="elementor-icon-list-text">Chrome Extension Access to Auto-fill UC app</span>
//                     </li>
//                     <li className="elementor-icon-list-item">
//                       <span className="elementor-icon-list-icon red"><svg aria-hidden="true" className="e-font-icon-svg e-fas-times-circle" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg"><path d="M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8zm121.6 313.1c4.7 4.7 4.7 12.3 0 17L338 377.6c-4.7 4.7-12.3 4.7-17 0L256 312l-65.1 65.6c-4.7 4.7-12.3 4.7-17 0L134.4 338c-4.7-4.7-4.7-12.3 0-17l65.6-65-65.6-65.1c-4.7-4.7-4.7-12.3 0-17l39.6-39.6c4.7-4.7 12.3-4.7 17 0l65 65.7 65.1-65.6c4.7-4.7 12.3-4.7 17 0l39.6 39.6c4.7 4.7 4.7 12.3 0 17L312 256l65.6 65.1z"></path></svg></span>
//                       <span className="elementor-icon-list-text">Chrome Extension Access to Auto-fill Common App</span>
//                       </li>
//                   </ul>
//                   <hr style={{color:'#4D88F0', width:'100%', height:'2px', opacity:'1' }} ></hr>
  
//                   <h2>Expert Chat Support</h2>
//                   <ul className="elementor-icon-list-items">
//                     <li className="elementor-icon-list-item">
//                       <span className="elementor-icon-list-icon red"><svg aria-hidden="true" className="e-font-icon-svg e-fas-times-circle" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg"><path d="M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8zm121.6 313.1c4.7 4.7 4.7 12.3 0 17L338 377.6c-4.7 4.7-12.3 4.7-17 0L256 312l-65.1 65.6c-4.7 4.7-12.3 4.7-17 0L134.4 338c-4.7-4.7-4.7-12.3 0-17l65.6-65-65.6-65.1c-4.7-4.7-4.7-12.3 0-17l39.6-39.6c4.7-4.7 12.3-4.7 17 0l65 65.7 65.1-65.6c4.7-4.7 12.3-4.7 17 0l39.6 39.6c4.7 4.7 4.7 12.3 0 17L312 256l65.6 65.1z"></path></svg></span>
//                       <span className="elementor-icon-list-text">Expert Chat Support</span>
//                     </li>
//                   </ul>
  
//                 </div>
//               </div>
  
//           </div>
//         </div>
  
//     );
  
// }
  
export const PriceGoldMonth =(props: priceProp) =>{
    const Amount = "79.00";
    const CurrencySign = "$"
    const HighlightBadge =  props.isRecommended? "Recommended" : "";
    const ProductName = "Gold Plan";
    const PriceDescription = "";
    const StaringDescripton = "";
    const CurrencyAmount = `${CurrencySign}${Amount}`;
    const PerMonthYear = 'Month';
    const PriceMmeteredDescription = "$79.00/month.";
    const ButtonText = "Subcribe";
    const isPricePreview = false;  
    const isHightlight = props.isSelected ? "is-highlight" : "";
    let isHighlightBadge  = HighlightBadge === ''? '': "is-highlightBadge";
  
    return (       
        <div className={`PriceColumn ${isHightlight} flex-container direction-column justify-content-flex-start align-items-center`}
        onClick={props.onSelect}
        >
          <div className="PriceColumn-content flex-container direction-column justify-content-flex-start align-items-center">
          
            <div className="PriceColumn-aboutProduct flex-container direction-column">
              <div className="PriceColumn-badge flex-container">
                
                <div className={`Badge ${isHighlightBadge} flex-container align-items-center`}>
                  <div className="Badge-text Text Text-color--default Text-fontSize--12 Text-fontWeight--500">
                  {HighlightBadge}
                  </div>
                </div>
                
              </div>
  
              <div className="PriceColumn-textAndProductImage flex-container" style={{marginBottom:'24px'}} >
                <div className="PriceColumn-textContainerHeightAligner" style={{height:"8px"}}>
                  <div className="PriceColumn-textContainer flex-container direction-column">
                    <div className="PriceColumn-name flex-container">
                      <span className="Text Text-color--default Text-fontSize--20 Text-fontWeight--600">
                      {ProductName}
                      </span>
                    </div>
  
                    <span className="PriceColumn-description Text Text-color--default Text-fontSize--14">
                      {PriceDescription}
                    </span> 
                  </div>
                </div>
              </div>    
            </div>
  
            <div className="PriceColumn-priceAndButton flex-container direction-column">
              <div className='PriceColumn-priceContainerHeightAligner'>
                <div className="PriceColumn-priceContainer">
                  <div className="flex-container spacing-4 direction-column align-items-flex-start">
                    {StaringDescripton && (
                      <span className="PriceColumn-startingAtDescription Text Text-color--default Text-fontSize--13">
                        {StaringDescripton}
                      </span>                    
                    )}
  
                    <div className="flex-container align-items-center">
                      <div className="PriceColumn-price Text Text-color--default Text-fontSize--36 Text-fontWeight--700">
                        <span className="CurrencyAmount" style={{color:'#4D88F0'}} >{CurrencyAmount}</span>
                      </div>
                      <span className="Text Text-color--default Text-fontSize--13">
                        <br/>
                        <strong style={{color:'#000',fontWeight:'bold'}}>/{PerMonthYear}</strong>
                      </span>
                    </div>
  
                    {PriceMmeteredDescription && (
                      <div className="PriceColumn-meteredDescription Text Text-color--default Text-fontSize--13"
                        style={{color:'#172640', opacity:'1'}}
                      >
                        {PriceMmeteredDescription}
                      </div>
                    )}
  
                    {isPricePreview && (
                      <div className="Tooltip-Context TierPricingPreview-tooltipContext" style={{padding:'padding: 0px;'}}>
                        <div className="flex-container direction-row align-items-center">
                          <svg className="InlineSVG Icon Icon--sm" focusable="false" fill="#1a1a1a" color="#1a1a1a" fillOpacity="0.5" height="16" viewBox="0 0 16 16" width="16"><path d="m8 14c-4.418278 0-8-4.6862915-8-6s3.581722-6 8-6 8 4.6862915 8 6-3.581722 6-8 6zm0-2c2.209139 0 4-1.790861 4-4s-1.790861-4-4-4-4 1.790861-4 4 1.790861 4 4 4zm0-2c-1.1045695 0-2-.8954305-2-2s.8954305-2 2-2 2 .8954305 2 2-.8954305 2-2 2z" fillRule="evenodd"></path></svg>
                          <div className="TierPricingPreview-context Text Text-color--default Text-fontSize--13">Pricing preview</div>
                        </div>
                      </div>
                    )}
  
                </div>
              </div>
            </div>
  
            <div className="PriceColumn-tierSelectPlaceholderHeight" style={{height:'8px'}}></div>
  
            <button className="Button PriceColumn-button Button--primary Button--lg" type="submit" 
                style={{backgroundColor: "rgb(62, 115, 240)", borderColor: "rgb(62, 115, 240)"}}
            >
              <div className="flex-container justify-content-center align-items-center">
                <span className="Text Text-color--default Text-fontWeight--500 Text--truncate">{ButtonText}</span>
              </div>
            </button>
            </div>
          </div>
  
          <div className="PriceColumn-featureListContainer flex-container direction-column">
              
              <div className="PriceColumn-featureListHeader Text Text-color--default Text-fontSize--14 mt-4 mb-4"
                style={{height:'1px', width:'100%', opacity:'1'}}
              >
                <hr style={{color:'#4D88F0', width:'100%', height:'2px', opacity:'1', margin: '0' }} ></hr>
              </div>
              <div className='row'>
                <div className='col-mt-12'>
                  <h2>Organize</h2>
                  <h3>Digital Portfolio</h3>
  
                  <ul className="elementor-icon-list-items">
                    <li className="elementor-icon-list-item">
                        <span className="elementor-icon-list-icon">
                          <svg aria-hidden="true" className="e-font-icon-svg e-fas-check" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg"><path d="M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z"></path></svg></span>
                          <span className="elementor-icon-list-text">Profile</span>
                        </li>
                      <li className="elementor-icon-list-item">
                          <span className="elementor-icon-list-icon">
                            <svg aria-hidden="true" className="e-font-icon-svg e-fas-check" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg"><path d="M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z"></path></svg>
                          </span>
                          <span className="elementor-icon-list-text">Family</span>
                        </li>
                      <li className="elementor-icon-list-item">
                          <span className="elementor-icon-list-icon">
                            <svg aria-hidden="true" className="e-font-icon-svg e-fas-check" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg"><path d="M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z"></path></svg>
                          </span>
                          <span className="elementor-icon-list-text">Education</span>
                        </li>
                      <li className="elementor-icon-list-item">
                          <span className="elementor-icon-list-icon">
                            <svg aria-hidden="true" className="e-font-icon-svg e-fas-check" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg"><path d="M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z"></path></svg>
                          </span>
                          <span className="elementor-icon-list-text">Courses &amp; Grades</span>
                        </li>
                      <li className="elementor-icon-list-item">
                          <span className="elementor-icon-list-icon">
                            <svg aria-hidden="true" className="e-font-icon-svg e-fas-check" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg"><path d="M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z"></path></svg>
                          </span>
                          <span className="elementor-icon-list-text">Testing</span>
                        </li>
                      <li className="elementor-icon-list-item">
                          <span className="elementor-icon-list-icon">
                            <svg aria-hidden="true" className="e-font-icon-svg e-fas-check" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg"><path d="M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z"></path></svg>
                          </span>
                          <span className="elementor-icon-list-text">Activities &amp; Honors</span>
                        </li>
                      <li className="elementor-icon-list-item">
                          <span className="elementor-icon-list-icon">
                            <svg aria-hidden="true" className="e-font-icon-svg e-fas-check" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg"><path d="M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z"></path></svg>
                          </span>
                          <span className="elementor-icon-list-text">Additional Info</span>
                        </li>
                  </ul>
                  <hr style={{color:'#4D88F0', width:'100%', height:'2px', opacity:'1' }} ></hr>
                 
                  <h3>Colleges</h3>
                  <ul className="elementor-icon-list-items">
                    <li className="elementor-icon-list-item">
                            <span className="elementor-icon-list-icon">
                    <svg aria-hidden="true" className="e-font-icon-svg e-fas-check" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg"><path d="M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z"></path></svg>						</span>
                          <span className="elementor-icon-list-text">College Search</span>
                        </li>
                      <li className="elementor-icon-list-item">
                            <span className="elementor-icon-list-icon">
                    <svg aria-hidden="true" className="e-font-icon-svg e-fas-check" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg"><path d="M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z"></path></svg>						</span>
                          <span className="elementor-icon-list-text">Create Multiple College Lists</span>
                        </li>
                  </ul>                
                  <hr style={{color:'#4D88F0', width:'100%', height:'2px', opacity:'1' }} ></hr>
                  <h3>Summary</h3>
                  <ul className="elementor-icon-list-items">
                    <li className="elementor-icon-list-item">
                        <span className="elementor-icon-list-icon"><svg aria-hidden="true" className="e-font-icon-svg e-fas-check" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg"><path d="M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z"></path></svg></span>
                        <span className="elementor-icon-list-text">Page-by-page view mode</span>
                    </li>
                  </ul>
                  <hr style={{color:'#4D88F0', width:'100%', height:'2px', opacity:'1' }} ></hr>
                  
                  <h2>Optimize</h2>
                  <h3>AI PUSH Chatbot Guidance</h3>
                  <ul className="elementor-icon-list-items">
                    <li className="elementor-icon-list-item">
                      <span className="elementor-icon-list-icon"><svg aria-hidden="true" className="e-font-icon-svg e-fas-check" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg"><path d="M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z"></path></svg></span>
                      <span className="elementor-icon-list-text">Expertly trained by college counselors</span>
                    </li>
                    <li className="elementor-icon-list-item">
                        <span className="elementor-icon-list-icon"><svg aria-hidden="true" className="e-font-icon-svg e-fas-check" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg"><path d="M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z"></path></svg></span>
                        <span className="elementor-icon-list-text">Profile building guidance</span>
                      </li>
                    <li className="elementor-icon-list-item">
                        <span className="elementor-icon-list-icon"><svg aria-hidden="true" className="e-font-icon-svg e-fas-check" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg"><path d="M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z"></path></svg></span>
                        <span className="elementor-icon-list-text">Passion project ideas and outlines</span>
                      </li>
                    <li className="elementor-icon-list-item">
                        <span className="elementor-icon-list-icon"><svg aria-hidden="true" className="e-font-icon-svg e-fas-check" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg"><path d="M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z"></path></svg></span>
                        <span className="elementor-icon-list-text">High school activity support</span>
                      </li>
                    <li className="elementor-icon-list-item">
                        <span className="elementor-icon-list-icon"><svg aria-hidden="true" className="e-font-icon-svg e-fas-check" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg"><path d="M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z"></path></svg></span>
                        <span className="elementor-icon-list-text">College application support</span>
                    </li>
                  </ul>
                  <hr style={{color:'#4D88F0', width:'100%', height:'2px', opacity:'1' }} ></hr>
  
                  <h3>Counselor</h3>
                  <ul className="elementor-icon-list-items">
                      <li className="elementor-icon-list-item">
                        <span className="elementor-icon-list-icon"><svg aria-hidden="true" className="e-font-icon-svg e-fas-check" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg"><path d="M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z"></path></svg></span>
                        <span className="elementor-icon-list-text">Add your trusted counselor</span>
                      </li>
                      <li className="elementor-icon-list-item">
                        <span className="elementor-icon-list-icon"><svg aria-hidden="true" className="e-font-icon-svg e-fas-check" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg"><path d="M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z"></path></svg></span>
                        <span className="elementor-icon-list-text">Chat with your counselor</span>
                      </li>
                      <li className="elementor-icon-list-item">
                        <span className="elementor-icon-list-icon"><svg aria-hidden="true" className="e-font-icon-svg e-fas-check" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg"><path d="M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z"></path></svg></span>
                        <span className="elementor-icon-list-text">Suggested List Access w/ Likelihood Status</span>
                      </li>
                      <li className="elementor-icon-list-item">
                        <span className="elementor-icon-list-icon"><svg aria-hidden="true" className="e-font-icon-svg e-fas-check" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg"><path d="M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z"></path></svg></span>
                        <span className="elementor-icon-list-text">Two-Way Summary Approval</span>
                      </li>
                  </ul>
                  <hr style={{color:'#4D88F0', width:'100%', height:'2px', opacity:'1' }} ></hr>
  
                  <h3>College Planning Resources</h3>
                  <ul className="elementor-icon-list-items">
                    <li className="elementor-icon-list-item">
                        <span className="elementor-icon-list-icon"><svg aria-hidden="true" className="e-font-icon-svg e-fas-check" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg"><path d="M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z"></path></svg></span>
                        <span className="elementor-icon-list-text">Search/Access Knowledge Base </span>
                      </li>
                      <li className="elementor-icon-list-item">
                        <span className="elementor-icon-list-icon"><svg aria-hidden="true" className="e-font-icon-svg e-fas-check" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg"><path d="M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z"></path></svg></span>
                        <span className="elementor-icon-list-text">Major-based Suggestions</span>
                      </li>
                      <li className="elementor-icon-list-item">
                        <span className="elementor-icon-list-icon"><svg aria-hidden="true" className="e-font-icon-svg e-fas-check" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg"><path d="M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z"></path></svg></span>
                        <span className="elementor-icon-list-text">Parent &amp; Student Educational Video Library</span>
                      </li>
                  </ul>
                  <hr style={{color:'#4D88F0', width:'100%', height:'2px', opacity:'1' }} ></hr>
  
                  <h2>Automate</h2>
                  <ul className="elementor-icon-list-items">
                    <li className="elementor-icon-list-item">
                        <span className="elementor-icon-list-icon"><svg aria-hidden="true" className="e-font-icon-svg e-fas-check" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg"><path d="M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z"></path></svg></span>
                        <span className="elementor-icon-list-text">Chrome Extension Access to Auto-fill CSU app</span>
                      </li>
                      <li className="elementor-icon-list-item">
                        <span className="elementor-icon-list-icon"><svg aria-hidden="true" className="e-font-icon-svg e-fas-check" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg"><path d="M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z"></path></svg></span>
                        <span className="elementor-icon-list-text">Chrome Extension Access to Auto-fill UC app</span>
                      </li>
                      <li className="elementor-icon-list-item">
                        <span className="elementor-icon-list-icon"><svg aria-hidden="true" className="e-font-icon-svg e-fas-check" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg"><path d="M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z"></path></svg></span>
                        <span className="elementor-icon-list-text">Chrome Extension Access to Auto-fill Common App</span>
                      </li>
                  </ul>
                  <hr style={{color:'#4D88F0', width:'100%', height:'2px', opacity:'1' }} ></hr>
  
                  <h2>Expert Chat Support</h2>
                  <ul className="elementor-icon-list-items">
                    <li className="elementor-icon-list-item">
                      <span className="elementor-icon-list-icon"><svg aria-hidden="true" className="e-font-icon-svg e-fas-check" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg"><path d="M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z"></path></svg></span>
                      <span className="elementor-icon-list-text">Expert Chat Support</span>
                    </li>
                  </ul>
  
                </div>
              </div>            
          </div>
        </div>
  
    );
  
}
  
export const PriceGoldYear =(props: priceProp) =>{
    const Amount = "468.00";
    const CurrencySign = "$"
    const HighlightBadge =  props.isRecommended? "Recommended" : "";
    const ProductName = "Gold Plan";
    const PriceDescription = "";
    const StaringDescripton = "";
    const CurrencyAmount = `${CurrencySign}${Amount}`;
    const PerMonthYear = "Year";
    const PriceMmeteredDescription = "$39.00/month. ($468 billed annually)";
    const ButtonText = "Subcribe";
    const isPricePreview = false;  
    const isHightlight = props.isSelected ? "is-highlight" : "";
    let isHighlightBadge  = HighlightBadge === ''? '': "is-highlightBadge";
  
    return (       
        <div className={`PriceColumn ${isHightlight} flex-container direction-column justify-content-flex-start align-items-center`}
        onClick={props.onSelect}
        >
          <div className="PriceColumn-content flex-container direction-column justify-content-flex-start align-items-center">
          
            <div className="PriceColumn-aboutProduct flex-container direction-column">
              <div className="PriceColumn-badge flex-container">
                
                <div className={`Badge ${isHighlightBadge} flex-container align-items-center`}>
                  <div className="Badge-text Text Text-color--default Text-fontSize--12 Text-fontWeight--500">
                  {HighlightBadge}
                  </div>
                </div>
                
              </div>
  
              <div className="PriceColumn-textAndProductImage flex-container" style={{marginBottom:'24px'}} >
                <div className="PriceColumn-textContainerHeightAligner" style={{height:"8px"}}>
                  <div className="PriceColumn-textContainer flex-container direction-column">
                    <div className="PriceColumn-name flex-container">
                      <span className="Text Text-color--default Text-fontSize--20 Text-fontWeight--600">
                      {ProductName}
                      </span>
                    </div>
  
                    <span className="PriceColumn-description Text Text-color--default Text-fontSize--14">
                      {PriceDescription}
                    </span> 
                  </div>
                </div>
              </div>    
            </div>
  
            <div className="PriceColumn-priceAndButton flex-container direction-column">
              <div className='PriceColumn-priceContainerHeightAligner'>
                <div className="PriceColumn-priceContainer">
                  <div className="flex-container spacing-4 direction-column align-items-flex-start">
                    {StaringDescripton && (
                      <span className="PriceColumn-startingAtDescription Text Text-color--default Text-fontSize--13">
                        {StaringDescripton}
                      </span>                    
                    )}
  
                    <div className="flex-container align-items-center">
                      <div className="PriceColumn-price Text Text-color--default Text-fontSize--36 Text-fontWeight--700">
                        <span className="CurrencyAmount" style={{color:'#4D88F0'}} >{CurrencyAmount}</span>
                      </div>
                      <span className="Text Text-color--default Text-fontSize--13">
                        <br/>
                        <strong style={{color:'#000',fontWeight:'bold'}}>/{PerMonthYear}</strong>
                      </span>
                    </div>
  
                    {PriceMmeteredDescription && (
                      <div className="PriceColumn-meteredDescription Text Text-color--default Text-fontSize--13"
                        style={{color:'#172640', opacity:'1'}}
                      >
                        {PriceMmeteredDescription}
                      </div>
                    )}
  
                    {isPricePreview && (
                      <div className="Tooltip-Context TierPricingPreview-tooltipContext" style={{padding:'padding: 0px;'}}>
                        <div className="flex-container direction-row align-items-center">
                          <svg className="InlineSVG Icon Icon--sm" focusable="false" fill="#1a1a1a" color="#1a1a1a" fillOpacity="0.5" height="16" viewBox="0 0 16 16" width="16"><path d="m8 14c-4.418278 0-8-4.6862915-8-6s3.581722-6 8-6 8 4.6862915 8 6-3.581722 6-8 6zm0-2c2.209139 0 4-1.790861 4-4s-1.790861-4-4-4-4 1.790861-4 4 1.790861 4 4 4zm0-2c-1.1045695 0-2-.8954305-2-2s.8954305-2 2-2 2 .8954305 2 2-.8954305 2-2 2z" fillRule="evenodd"></path></svg>
                          <div className="TierPricingPreview-context Text Text-color--default Text-fontSize--13">Pricing preview</div>
                        </div>
                      </div>
                    )}
  
                </div>
              </div>
            </div>
  
            <div className="PriceColumn-tierSelectPlaceholderHeight" style={{height:'8px'}}></div>
  
            <button className="Button PriceColumn-button Button--primary Button--lg" type="submit" 
                style={{backgroundColor: "rgb(62, 115, 240)", borderColor: "rgb(62, 115, 240)"}}
            >
              <div className="flex-container justify-content-center align-items-center">
                <span className="Text Text-color--default Text-fontWeight--500 Text--truncate">{ButtonText}</span>
              </div>
            </button>
            </div>
          </div>
  
          <div className="PriceColumn-featureListContainer flex-container direction-column">
              
              <div className="PriceColumn-featureListHeader Text Text-color--default Text-fontSize--14 mt-4 mb-4"
                style={{height:'1px', width:'100%', opacity:'1'}}
              >
                <hr style={{color:'#4D88F0', width:'100%', height:'2px', opacity:'1', margin: '0' }} ></hr>
              </div>
              <div className='row'>
                <div className='col-mt-12'>
                  <h2>Organize</h2>
                  <h3>Digital Portfolio</h3>
  
                  <ul className="elementor-icon-list-items">
                    <li className="elementor-icon-list-item">
                        <span className="elementor-icon-list-icon">
                          <svg aria-hidden="true" className="e-font-icon-svg e-fas-check" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg"><path d="M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z"></path></svg></span>
                          <span className="elementor-icon-list-text">Profile</span>
                        </li>
                      <li className="elementor-icon-list-item">
                          <span className="elementor-icon-list-icon">
                            <svg aria-hidden="true" className="e-font-icon-svg e-fas-check" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg"><path d="M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z"></path></svg>
                          </span>
                          <span className="elementor-icon-list-text">Family</span>
                        </li>
                      <li className="elementor-icon-list-item">
                          <span className="elementor-icon-list-icon">
                            <svg aria-hidden="true" className="e-font-icon-svg e-fas-check" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg"><path d="M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z"></path></svg>
                          </span>
                          <span className="elementor-icon-list-text">Education</span>
                        </li>
                      <li className="elementor-icon-list-item">
                          <span className="elementor-icon-list-icon">
                            <svg aria-hidden="true" className="e-font-icon-svg e-fas-check" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg"><path d="M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z"></path></svg>
                          </span>
                          <span className="elementor-icon-list-text">Courses &amp; Grades</span>
                        </li>
                      <li className="elementor-icon-list-item">
                          <span className="elementor-icon-list-icon">
                            <svg aria-hidden="true" className="e-font-icon-svg e-fas-check" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg"><path d="M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z"></path></svg>
                          </span>
                          <span className="elementor-icon-list-text">Testing</span>
                        </li>
                      <li className="elementor-icon-list-item">
                          <span className="elementor-icon-list-icon">
                            <svg aria-hidden="true" className="e-font-icon-svg e-fas-check" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg"><path d="M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z"></path></svg>
                          </span>
                          <span className="elementor-icon-list-text">Activities &amp; Honors</span>
                        </li>
                      <li className="elementor-icon-list-item">
                          <span className="elementor-icon-list-icon">
                            <svg aria-hidden="true" className="e-font-icon-svg e-fas-check" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg"><path d="M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z"></path></svg>
                          </span>
                          <span className="elementor-icon-list-text">Additional Info</span>
                        </li>
                  </ul>
                  <hr style={{color:'#4D88F0', width:'100%', height:'2px', opacity:'1' }} ></hr>
                 
                  <h3>Colleges</h3>
                  <ul className="elementor-icon-list-items">
                    <li className="elementor-icon-list-item">
                            <span className="elementor-icon-list-icon">
                    <svg aria-hidden="true" className="e-font-icon-svg e-fas-check" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg"><path d="M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z"></path></svg>						</span>
                          <span className="elementor-icon-list-text">College Search</span>
                        </li>
                      <li className="elementor-icon-list-item">
                            <span className="elementor-icon-list-icon">
                    <svg aria-hidden="true" className="e-font-icon-svg e-fas-check" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg"><path d="M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z"></path></svg>						</span>
                          <span className="elementor-icon-list-text">Create Multiple College Lists</span>
                        </li>
                  </ul>                
                  <hr style={{color:'#4D88F0', width:'100%', height:'2px', opacity:'1' }} ></hr>
                  <h3>Summary</h3>
                  <ul className="elementor-icon-list-items">
                    <li className="elementor-icon-list-item">
                        <span className="elementor-icon-list-icon"><svg aria-hidden="true" className="e-font-icon-svg e-fas-check" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg"><path d="M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z"></path></svg></span>
                        <span className="elementor-icon-list-text">Page-by-page view mode</span>
                    </li>
                  </ul>
                  <hr style={{color:'#4D88F0', width:'100%', height:'2px', opacity:'1' }} ></hr>
                  
                  <h2>Optimize</h2>
                  <h3>AI PUSH Chatbot Guidance</h3>
                  <ul className="elementor-icon-list-items">
                    <li className="elementor-icon-list-item">
                      <span className="elementor-icon-list-icon"><svg aria-hidden="true" className="e-font-icon-svg e-fas-check" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg"><path d="M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z"></path></svg></span>
                      <span className="elementor-icon-list-text">Expertly trained by college counselors</span>
                    </li>
                    <li className="elementor-icon-list-item">
                        <span className="elementor-icon-list-icon"><svg aria-hidden="true" className="e-font-icon-svg e-fas-check" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg"><path d="M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z"></path></svg></span>
                        <span className="elementor-icon-list-text">Profile building guidance</span>
                      </li>
                    <li className="elementor-icon-list-item">
                        <span className="elementor-icon-list-icon"><svg aria-hidden="true" className="e-font-icon-svg e-fas-check" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg"><path d="M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z"></path></svg></span>
                        <span className="elementor-icon-list-text">Passion project ideas and outlines</span>
                      </li>
                    <li className="elementor-icon-list-item">
                        <span className="elementor-icon-list-icon"><svg aria-hidden="true" className="e-font-icon-svg e-fas-check" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg"><path d="M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z"></path></svg></span>
                        <span className="elementor-icon-list-text">High school activity support</span>
                      </li>
                    <li className="elementor-icon-list-item">
                        <span className="elementor-icon-list-icon"><svg aria-hidden="true" className="e-font-icon-svg e-fas-check" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg"><path d="M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z"></path></svg></span>
                        <span className="elementor-icon-list-text">College application support</span>
                    </li>
                  </ul>
                  <hr style={{color:'#4D88F0', width:'100%', height:'2px', opacity:'1' }} ></hr>
  
                  <h3>Counselor</h3>
                  <ul className="elementor-icon-list-items">
                      <li className="elementor-icon-list-item">
                        <span className="elementor-icon-list-icon"><svg aria-hidden="true" className="e-font-icon-svg e-fas-check" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg"><path d="M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z"></path></svg></span>
                        <span className="elementor-icon-list-text">Add your trusted counselor</span>
                      </li>
                      <li className="elementor-icon-list-item">
                        <span className="elementor-icon-list-icon"><svg aria-hidden="true" className="e-font-icon-svg e-fas-check" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg"><path d="M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z"></path></svg></span>
                        <span className="elementor-icon-list-text">Chat with your counselor</span>
                      </li>
                      <li className="elementor-icon-list-item">
                        <span className="elementor-icon-list-icon"><svg aria-hidden="true" className="e-font-icon-svg e-fas-check" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg"><path d="M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z"></path></svg></span>
                        <span className="elementor-icon-list-text">Suggested List Access w/ Likelihood Status</span>
                      </li>
                      <li className="elementor-icon-list-item">
                        <span className="elementor-icon-list-icon"><svg aria-hidden="true" className="e-font-icon-svg e-fas-check" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg"><path d="M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z"></path></svg></span>
                        <span className="elementor-icon-list-text">Two-Way Summary Approval</span>
                      </li>
                  </ul>
                  <hr style={{color:'#4D88F0', width:'100%', height:'2px', opacity:'1' }} ></hr>
  
                  <h3>College Planning Resources</h3>
                  <ul className="elementor-icon-list-items">
                    <li className="elementor-icon-list-item">
                        <span className="elementor-icon-list-icon"><svg aria-hidden="true" className="e-font-icon-svg e-fas-check" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg"><path d="M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z"></path></svg></span>
                        <span className="elementor-icon-list-text">Search/Access Knowledge Base </span>
                      </li>
                      <li className="elementor-icon-list-item">
                        <span className="elementor-icon-list-icon"><svg aria-hidden="true" className="e-font-icon-svg e-fas-check" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg"><path d="M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z"></path></svg></span>
                        <span className="elementor-icon-list-text">Major-based Suggestions</span>
                      </li>
                      <li className="elementor-icon-list-item">
                        <span className="elementor-icon-list-icon"><svg aria-hidden="true" className="e-font-icon-svg e-fas-check" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg"><path d="M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z"></path></svg></span>
                        <span className="elementor-icon-list-text">Parent &amp; Student Educational Video Library</span>
                      </li>
                  </ul>
                  <hr style={{color:'#4D88F0', width:'100%', height:'2px', opacity:'1' }} ></hr>
  
                  <h2>Automate</h2>
                  <ul className="elementor-icon-list-items">
                    <li className="elementor-icon-list-item">
                        <span className="elementor-icon-list-icon"><svg aria-hidden="true" className="e-font-icon-svg e-fas-check" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg"><path d="M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z"></path></svg></span>
                        <span className="elementor-icon-list-text">Chrome Extension Access to Auto-fill CSU app</span>
                      </li>
                      <li className="elementor-icon-list-item">
                        <span className="elementor-icon-list-icon"><svg aria-hidden="true" className="e-font-icon-svg e-fas-check" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg"><path d="M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z"></path></svg></span>
                        <span className="elementor-icon-list-text">Chrome Extension Access to Auto-fill UC app</span>
                      </li>
                      <li className="elementor-icon-list-item">
                        <span className="elementor-icon-list-icon"><svg aria-hidden="true" className="e-font-icon-svg e-fas-check" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg"><path d="M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z"></path></svg></span>
                        <span className="elementor-icon-list-text">Chrome Extension Access to Auto-fill Common App</span>
                      </li>
                  </ul>
                  <hr style={{color:'#4D88F0', width:'100%', height:'2px', opacity:'1' }} ></hr>
  
                  <h2>Expert Chat Support</h2>
                  <ul className="elementor-icon-list-items">
                    <li className="elementor-icon-list-item">
                      <span className="elementor-icon-list-icon"><svg aria-hidden="true" className="e-font-icon-svg e-fas-check" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg"><path d="M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z"></path></svg></span>
                      <span className="elementor-icon-list-text">Expert Chat Support</span>
                    </li>
                  </ul>
  
                </div>
              </div>            
          </div>
        </div>
  
    );
  
}
  
export const PriceSilverPlusMonth = (props: priceProp) =>{
    const Amount = "24.99";
    const CurrencySign = "$"
    const HighlightBadge =  props.isRecommended? "Recommended" : "";  
    const ProductName = "Silver Plus Plan";
    const PriceDescription = "";
    const StaringDescripton = "";
    const CurrencyAmount = `${CurrencySign}${Amount}`;
    const PerMonthYear = 'Month';
    const PriceMmeteredDescription = "$24.99/month.";
    const ButtonText = "Subcribe";
    const isPricePreview = false;  
    const isHightlight = props.isSelected ? "is-highlight" : "";
    let isHighlightBadge  = HighlightBadge === ''? '': "is-highlightBadge";
  
    return (       
        <div className={`PriceColumn ${isHightlight} flex-container direction-column justify-content-flex-start align-items-center`}
          onClick={props.onSelect}
        >
          <div className="PriceColumn-content flex-container direction-column justify-content-flex-start align-items-center">
          
            <div className="PriceColumn-aboutProduct flex-container direction-column">
              <div className="PriceColumn-badge flex-container">
                
                <div className={`Badge ${isHighlightBadge} flex-container align-items-center`}>
                  <div className="Badge-text Text Text-color--default Text-fontSize--12 Text-fontWeight--500">
                  {HighlightBadge}
                  </div>
                </div>
                
              </div>
  
              <div className="PriceColumn-textAndProductImage flex-container" style={{marginBottom:'24px'}} >
                <div className="PriceColumn-textContainerHeightAligner" style={{height:"8px"}}>
                  <div className="PriceColumn-textContainer flex-container direction-column">
                    <div className="PriceColumn-name flex-container">
                      <span className="Text Text-color--default Text-fontSize--20 Text-fontWeight--600">
                      {ProductName}
                      </span>
                    </div>
  
                    <span className="PriceColumn-description Text Text-color--default Text-fontSize--14">
                      {PriceDescription}
                    </span> 
                  </div>
                </div>
              </div>    
            </div>
  
            <div className="PriceColumn-priceAndButton flex-container direction-column">
              <div className='PriceColumn-priceContainerHeightAligner'>
                <div className="PriceColumn-priceContainer">
                  <div className="flex-container spacing-4 direction-column align-items-flex-start">
                    {StaringDescripton && (
                      <span className="PriceColumn-startingAtDescription Text Text-color--default Text-fontSize--13">
                        {StaringDescripton}
                      </span>                    
                    )}
  
                    <div className="flex-container align-items-center">
                      <div className="PriceColumn-price Text Text-color--default Text-fontSize--36 Text-fontWeight--700">
                        <span className="CurrencyAmount" style={{color:'#4D88F0'}} >{CurrencyAmount}</span>
                      </div>
                      {/* <span className="PriceColumn-interval Text Text-color--default Text-fontSize--13">
                        {PriceInterval_line1}<br/>
                        {PriceInterval_line2}
                      </span> */}
                      <span className="Text Text-color--default Text-fontSize--13">
                        <br/>
                        <strong style={{color:'#000',fontWeight:'bold'}}>/{PerMonthYear}</strong>
                      </span>
                    </div>
  
                    {PriceMmeteredDescription && (
                      <div className="PriceColumn-meteredDescription Text Text-color--default Text-fontSize--13"
                        style={{color:'#172640', opacity:'1'}}
                      >
                        {PriceMmeteredDescription}
                      </div>
                    )}
  
                    {isPricePreview && (
                      <div className="Tooltip-Context TierPricingPreview-tooltipContext" style={{padding:'padding: 0px;'}}>
                        <div className="flex-container direction-row align-items-center">
                          <svg className="InlineSVG Icon Icon--sm" focusable="false" fill="#1a1a1a" color="#1a1a1a" fillOpacity="0.5" height="16" viewBox="0 0 16 16" width="16"><path d="m8 14c-4.418278 0-8-4.6862915-8-6s3.581722-6 8-6 8 4.6862915 8 6-3.581722 6-8 6zm0-2c2.209139 0 4-1.790861 4-4s-1.790861-4-4-4-4 1.790861-4 4 1.790861 4 4 4zm0-2c-1.1045695 0-2-.8954305-2-2s.8954305-2 2-2 2 .8954305 2 2-.8954305 2-2 2z" fillRule="evenodd"></path></svg>
                          <div className="TierPricingPreview-context Text Text-color--default Text-fontSize--13">Pricing preview</div>
                        </div>
                      </div>
                    )}
  
                </div>
              </div>
            </div>
  
            <div className="PriceColumn-tierSelectPlaceholderHeight" style={{height:'8px'}}></div>
  
            <button className="Button PriceColumn-button Button--primary Button--lg" type="submit" 
                style={{backgroundColor: "rgb(62, 115, 240)", borderColor: "rgb(62, 115, 240)"}}
            >
              <div className="flex-container justify-content-center align-items-center">
                <span className="Text Text-color--default Text-fontWeight--500 Text--truncate">{ButtonText}</span>
              </div>
            </button>
            </div>
          </div>
  
          <div className="PriceColumn-featureListContainer flex-container direction-column">
              
              <div className="PriceColumn-featureListHeader Text Text-color--default Text-fontSize--14 mt-4 mb-4"
                style={{height:'1px', width:'100%'}}
              >
                <hr style={{color:'#4D88F0', width:'100%', height:'2px', opacity:'1', margin: '0' }} ></hr>
              </div>
              <div className='row'>
                <div className='col-mt-12'>
                  <h2>Organize</h2>
                  <h3>Digital Portfolio</h3>
  
                  <ul className="elementor-icon-list-items">
                    <li className="elementor-icon-list-item">
                        <span className="elementor-icon-list-icon">
                          <svg aria-hidden="true" className="e-font-icon-svg e-fas-check" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg"><path d="M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z"></path></svg></span>
                          <span className="elementor-icon-list-text">Profile</span>
                        </li>
                      <li className="elementor-icon-list-item">
                          <span className="elementor-icon-list-icon">
                            <svg aria-hidden="true" className="e-font-icon-svg e-fas-check" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg"><path d="M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z"></path></svg>
                          </span>
                          <span className="elementor-icon-list-text">Family</span>
                        </li>
                      <li className="elementor-icon-list-item">
                          <span className="elementor-icon-list-icon">
                            <svg aria-hidden="true" className="e-font-icon-svg e-fas-check" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg"><path d="M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z"></path></svg>
                          </span>
                          <span className="elementor-icon-list-text">Education</span>
                        </li>
                      <li className="elementor-icon-list-item">
                          <span className="elementor-icon-list-icon">
                            <svg aria-hidden="true" className="e-font-icon-svg e-fas-check" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg"><path d="M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z"></path></svg>
                          </span>
                          <span className="elementor-icon-list-text">Courses &amp; Grades</span>
                        </li>
                      <li className="elementor-icon-list-item">
                          <span className="elementor-icon-list-icon">
                            <svg aria-hidden="true" className="e-font-icon-svg e-fas-check" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg"><path d="M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z"></path></svg>
                          </span>
                          <span className="elementor-icon-list-text">Testing</span>
                        </li>
                      <li className="elementor-icon-list-item">
                          <span className="elementor-icon-list-icon">
                            <svg aria-hidden="true" className="e-font-icon-svg e-fas-check" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg"><path d="M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z"></path></svg>
                          </span>
                          <span className="elementor-icon-list-text">Activities &amp; Honors</span>
                        </li>
                      <li className="elementor-icon-list-item">
                          <span className="elementor-icon-list-icon">
                            <svg aria-hidden="true" className="e-font-icon-svg e-fas-check" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg"><path d="M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z"></path></svg>
                          </span>
                          <span className="elementor-icon-list-text">Additional Info</span>
                        </li>
                  </ul>
                  <hr style={{color:'#4D88F0', width:'100%', height:'2px', opacity:'1' }} ></hr>
                 
                  <h3>Colleges</h3>
                  <ul className="elementor-icon-list-items">
                    <li className="elementor-icon-list-item">
                            <span className="elementor-icon-list-icon">
                    <svg aria-hidden="true" className="e-font-icon-svg e-fas-check" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg"><path d="M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z"></path></svg>						</span>
                          <span className="elementor-icon-list-text">College Search</span>
                        </li>
                      <li className="elementor-icon-list-item">
                            <span className="elementor-icon-list-icon">
                    <svg aria-hidden="true" className="e-font-icon-svg e-fas-check" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg"><path d="M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z"></path></svg>						</span>
                          <span className="elementor-icon-list-text">Create Multiple College Lists</span>
                        </li>
                  </ul>                
                  <hr style={{color:'#4D88F0', width:'100%', height:'2px', opacity:'1' }} ></hr>
                  <h3>Summary</h3>
                  <ul className="elementor-icon-list-items">
                    <li className="elementor-icon-list-item">
                        <span className="elementor-icon-list-icon"><svg aria-hidden="true" className="e-font-icon-svg e-fas-check" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg"><path d="M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z"></path></svg></span>
                        <span className="elementor-icon-list-text">Page-by-page view mode</span>
                    </li>
                  </ul>
                  <hr style={{color:'#4D88F0', width:'100%', height:'2px', opacity:'1' }} ></hr>
                  
                  <h2>Optimize</h2>
                  <h3>AI PUSH Chatbot Guidance</h3>
                  <ul className="elementor-icon-list-items">
                    <li className="elementor-icon-list-item">
                      <span className="elementor-icon-list-icon"><svg aria-hidden="true" className="e-font-icon-svg e-fas-check" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg"><path d="M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z"></path></svg></span>
                      <span className="elementor-icon-list-text">Expertly trained by college counselors</span>
                    </li>
                    <li className="elementor-icon-list-item">
                        <span className="elementor-icon-list-icon"><svg aria-hidden="true" className="e-font-icon-svg e-fas-check" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg"><path d="M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z"></path></svg></span>
                        <span className="elementor-icon-list-text">Profile building guidance</span>
                      </li>
                    <li className="elementor-icon-list-item">
                        <span className="elementor-icon-list-icon"><svg aria-hidden="true" className="e-font-icon-svg e-fas-check" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg"><path d="M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z"></path></svg></span>
                        <span className="elementor-icon-list-text">Passion project ideas and outlines</span>
                      </li>
                    <li className="elementor-icon-list-item">
                        <span className="elementor-icon-list-icon"><svg aria-hidden="true" className="e-font-icon-svg e-fas-check" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg"><path d="M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z"></path></svg></span>
                        <span className="elementor-icon-list-text">High school activity support</span>
                      </li>
                    <li className="elementor-icon-list-item">
                        <span className="elementor-icon-list-icon"><svg aria-hidden="true" className="e-font-icon-svg e-fas-check" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg"><path d="M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z"></path></svg></span>
                        <span className="elementor-icon-list-text">College application support</span>
                    </li>
                  </ul>
                  <hr style={{color:'#4D88F0', width:'100%', height:'2px', opacity:'1' }} ></hr>
  
                  <h3>Counselor</h3>
                  <ul className="elementor-icon-list-items">
                      <li className="elementor-icon-list-item">
                        <span className="elementor-icon-list-icon"><svg aria-hidden="true" className="e-font-icon-svg e-fas-check" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg"><path d="M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z"></path></svg></span>
                        <span className="elementor-icon-list-text">Add your trusted counselor</span>
                      </li>
                      <li className="elementor-icon-list-item">
                        <span className="elementor-icon-list-icon"><svg aria-hidden="true" className="e-font-icon-svg e-fas-check" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg"><path d="M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z"></path></svg></span>
                        <span className="elementor-icon-list-text">Chat with your counselor</span>
                      </li>
                      <li className="elementor-icon-list-item">
                        <span className="elementor-icon-list-icon"><svg aria-hidden="true" className="e-font-icon-svg e-fas-check" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg"><path d="M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z"></path></svg></span>
                        <span className="elementor-icon-list-text">Suggested List Access w/ Likelihood Status</span>
                      </li>
                      <li className="elementor-icon-list-item">
                        <span className="elementor-icon-list-icon"><svg aria-hidden="true" className="e-font-icon-svg e-fas-check" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg"><path d="M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z"></path></svg></span>
                        <span className="elementor-icon-list-text">Two-Way Summary Approval</span>
                      </li>
                  </ul>
                  <hr style={{color:'#4D88F0', width:'100%', height:'2px', opacity:'1' }} ></hr>
  
                  <h3>College Planning Resources</h3>
                  <ul className="elementor-icon-list-items">
                    <li className="elementor-icon-list-item">
                        <span className="elementor-icon-list-icon red"><svg aria-hidden="true" className="e-font-icon-svg e-fas-times-circle" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg"><path d="M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8zm121.6 313.1c4.7 4.7 4.7 12.3 0 17L338 377.6c-4.7 4.7-12.3 4.7-17 0L256 312l-65.1 65.6c-4.7 4.7-12.3 4.7-17 0L134.4 338c-4.7-4.7-4.7-12.3 0-17l65.6-65-65.6-65.1c-4.7-4.7-4.7-12.3 0-17l39.6-39.6c4.7-4.7 12.3-4.7 17 0l65 65.7 65.1-65.6c4.7-4.7 12.3-4.7 17 0l39.6 39.6c4.7 4.7 4.7 12.3 0 17L312 256l65.6 65.1z"></path></svg></span>
                        <span className="elementor-icon-list-text">Search/Access Knowledge Base </span>
                      </li>
                      <li className="elementor-icon-list-item">
                        <span className="elementor-icon-list-icon red"><svg aria-hidden="true" className="e-font-icon-svg e-fas-times-circle" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg"><path d="M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8zm121.6 313.1c4.7 4.7 4.7 12.3 0 17L338 377.6c-4.7 4.7-12.3 4.7-17 0L256 312l-65.1 65.6c-4.7 4.7-12.3 4.7-17 0L134.4 338c-4.7-4.7-4.7-12.3 0-17l65.6-65-65.6-65.1c-4.7-4.7-4.7-12.3 0-17l39.6-39.6c4.7-4.7 12.3-4.7 17 0l65 65.7 65.1-65.6c4.7-4.7 12.3-4.7 17 0l39.6 39.6c4.7 4.7 4.7 12.3 0 17L312 256l65.6 65.1z"></path></svg></span>
                        <span className="elementor-icon-list-text">Major-based Suggestions</span>
                      </li>
                      <li className="elementor-icon-list-item">
                        <span className="elementor-icon-list-icon red"><svg aria-hidden="true" className="e-font-icon-svg e-fas-times-circle" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg"><path d="M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8zm121.6 313.1c4.7 4.7 4.7 12.3 0 17L338 377.6c-4.7 4.7-12.3 4.7-17 0L256 312l-65.1 65.6c-4.7 4.7-12.3 4.7-17 0L134.4 338c-4.7-4.7-4.7-12.3 0-17l65.6-65-65.6-65.1c-4.7-4.7-4.7-12.3 0-17l39.6-39.6c4.7-4.7 12.3-4.7 17 0l65 65.7 65.1-65.6c4.7-4.7 12.3-4.7 17 0l39.6 39.6c4.7 4.7 4.7 12.3 0 17L312 256l65.6 65.1z"></path></svg></span>
                        <span className="elementor-icon-list-text">Parent &amp; Student Educational Video Library</span>
                      </li>
                  </ul>
                  <hr style={{color:'#4D88F0', width:'100%', height:'2px', opacity:'1' }} ></hr>
  
                  <h2>Automate</h2>
                  <ul className="elementor-icon-list-items">
                    <li className="elementor-icon-list-item">
                        <span className="elementor-icon-list-icon"><svg aria-hidden="true" className="e-font-icon-svg e-fas-check" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg"><path d="M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z"></path></svg></span>
                        <span className="elementor-icon-list-text">Chrome Extension Access to Auto-fill CSU app</span>
                      </li>
                      <li className="elementor-icon-list-item">
                        <span className="elementor-icon-list-icon"><svg aria-hidden="true" className="e-font-icon-svg e-fas-check" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg"><path d="M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z"></path></svg></span>
                        <span className="elementor-icon-list-text">Chrome Extension Access to Auto-fill UC app</span>
                      </li>
                      <li className="elementor-icon-list-item">
                        <span className="elementor-icon-list-icon"><svg aria-hidden="true" className="e-font-icon-svg e-fas-check" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg"><path d="M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z"></path></svg></span>
                        <span className="elementor-icon-list-text">Chrome Extension Access to Auto-fill Common App</span>
                      </li>
                  </ul>
                  <hr style={{color:'#4D88F0', width:'100%', height:'2px', opacity:'1' }} ></hr>
  
                  <h2>Expert Chat Support</h2>
                  <ul className="elementor-icon-list-items">
                    <li className="elementor-icon-list-item">
                      <span className="elementor-icon-list-icon red"><svg aria-hidden="true" className="e-font-icon-svg e-fas-times-circle" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg"><path d="M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8zm121.6 313.1c4.7 4.7 4.7 12.3 0 17L338 377.6c-4.7 4.7-12.3 4.7-17 0L256 312l-65.1 65.6c-4.7 4.7-12.3 4.7-17 0L134.4 338c-4.7-4.7-4.7-12.3 0-17l65.6-65-65.6-65.1c-4.7-4.7-4.7-12.3 0-17l39.6-39.6c4.7-4.7 12.3-4.7 17 0l65 65.7 65.1-65.6c4.7-4.7 12.3-4.7 17 0l39.6 39.6c4.7 4.7 4.7 12.3 0 17L312 256l65.6 65.1z"></path></svg></span>
                      <span className="elementor-icon-list-text">Expert Chat Support</span>
                    </li>
                  </ul>
  
                </div>
              </div>
  
          </div>
        </div>
    );
  
}
  
export const PriceSilverPlusYear = (props: priceProp) =>{
    const Amount = "204.00";
    const CurrencySign = "$"
    const HighlightBadge =  props.isRecommended? "Recommended" : "";  
    const ProductName = "Silver Plus Plan";
    const PriceDescription = "";
    const StaringDescripton = "";
    const CurrencyAmount = `${CurrencySign}${Amount}`;
    const PerMonthYear = "Year";
    const PriceMmeteredDescription = "$16.99/month. ($204 billed annually)";
    const ButtonText = "Subcribe";
    const isPricePreview = false;  
    const isHightlight = props.isSelected ? "is-highlight" : "";
    let isHighlightBadge  = HighlightBadge === ''? '': "is-highlightBadge";
  
    return (       
        <div className={`PriceColumn ${isHightlight} flex-container direction-column justify-content-flex-start align-items-center`}
          onClick={props.onSelect}
        >
          <div className="PriceColumn-content flex-container direction-column justify-content-flex-start align-items-center">
          
            <div className="PriceColumn-aboutProduct flex-container direction-column">
              <div className="PriceColumn-badge flex-container">
                
                <div className={`Badge ${isHighlightBadge} flex-container align-items-center`}>
                  <div className="Badge-text Text Text-color--default Text-fontSize--12 Text-fontWeight--500">
                  {HighlightBadge}
                  </div>
                </div>
                
              </div>
  
              <div className="PriceColumn-textAndProductImage flex-container" style={{marginBottom:'24px'}} >
                <div className="PriceColumn-textContainerHeightAligner" style={{height:"8px"}}>
                  <div className="PriceColumn-textContainer flex-container direction-column">
                    <div className="PriceColumn-name flex-container">
                      <span className="Text Text-color--default Text-fontSize--20 Text-fontWeight--600">
                      {ProductName}
                      </span>
                    </div>
  
                    <span className="PriceColumn-description Text Text-color--default Text-fontSize--14">
                      {PriceDescription}
                    </span> 
                  </div>
                </div>
              </div>    
            </div>
  
            <div className="PriceColumn-priceAndButton flex-container direction-column">
              <div className='PriceColumn-priceContainerHeightAligner'>
                <div className="PriceColumn-priceContainer">
                  <div className="flex-container spacing-4 direction-column align-items-flex-start">
                    {StaringDescripton && (
                      <span className="PriceColumn-startingAtDescription Text Text-color--default Text-fontSize--13">
                        {StaringDescripton}
                      </span>                    
                    )}
  
                    <div className="flex-container align-items-center">
                      <div className="PriceColumn-price Text Text-color--default Text-fontSize--36 Text-fontWeight--700">
                        <span className="CurrencyAmount" style={{color:'#4D88F0'}} >{CurrencyAmount}</span>
                      </div>
                      {/* <span className="PriceColumn-interval Text Text-color--default Text-fontSize--13">
                        {PriceInterval_line1}<br/>
                        {PriceInterval_line2}
                      </span> */}
                      <span className="Text Text-color--default Text-fontSize--13">
                        <br/>
                        <strong style={{color:'#000',fontWeight:'bold'}}>/{PerMonthYear}</strong>
                      </span>
                    </div>
  
                    {PriceMmeteredDescription && (
                      <div className="PriceColumn-meteredDescription Text Text-color--default Text-fontSize--13"
                        style={{color:'#172640', opacity:'1'}}
                      >
                        {PriceMmeteredDescription}
                      </div>
                    )}
  
                    {isPricePreview && (
                      <div className="Tooltip-Context TierPricingPreview-tooltipContext" style={{padding:'padding: 0px;'}}>
                        <div className="flex-container direction-row align-items-center">
                          <svg className="InlineSVG Icon Icon--sm" focusable="false" fill="#1a1a1a" color="#1a1a1a" fillOpacity="0.5" height="16" viewBox="0 0 16 16" width="16"><path d="m8 14c-4.418278 0-8-4.6862915-8-6s3.581722-6 8-6 8 4.6862915 8 6-3.581722 6-8 6zm0-2c2.209139 0 4-1.790861 4-4s-1.790861-4-4-4-4 1.790861-4 4 1.790861 4 4 4zm0-2c-1.1045695 0-2-.8954305-2-2s.8954305-2 2-2 2 .8954305 2 2-.8954305 2-2 2z" fillRule="evenodd"></path></svg>
                          <div className="TierPricingPreview-context Text Text-color--default Text-fontSize--13">Pricing preview</div>
                        </div>
                      </div>
                    )}
  
                </div>
              </div>
            </div>
  
            <div className="PriceColumn-tierSelectPlaceholderHeight" style={{height:'8px'}}></div>
  
            <button className="Button PriceColumn-button Button--primary Button--lg" type="submit" 
                style={{backgroundColor: "rgb(62, 115, 240)", borderColor: "rgb(62, 115, 240)"}}
            >
              <div className="flex-container justify-content-center align-items-center">
                <span className="Text Text-color--default Text-fontWeight--500 Text--truncate">{ButtonText}</span>
              </div>
            </button>
            </div>
          </div>
  
          <div className="PriceColumn-featureListContainer flex-container direction-column">
              
              <div className="PriceColumn-featureListHeader Text Text-color--default Text-fontSize--14 mt-4 mb-4"
                style={{height:'1px', width:'100%'}}
              >
                {/* <div className='text-center'
                  style={{border:'1px solid #4D88F0', width:'100%', height:'1px' }}>
                </div> */}
                <hr style={{color:'#4D88F0', width:'100%', height:'2px', opacity:'1', margin: '0' }} ></hr>
              </div>
              <div className='row'>
                <div className='col-mt-12'>
                  <h2>Organize</h2>
                  <h3>Digital Portfolio</h3>
  
                  <ul className="elementor-icon-list-items">
                    <li className="elementor-icon-list-item">
                        <span className="elementor-icon-list-icon">
                          <svg aria-hidden="true" className="e-font-icon-svg e-fas-check" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg"><path d="M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z"></path></svg></span>
                          <span className="elementor-icon-list-text">Profile</span>
                        </li>
                      <li className="elementor-icon-list-item">
                          <span className="elementor-icon-list-icon">
                            <svg aria-hidden="true" className="e-font-icon-svg e-fas-check" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg"><path d="M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z"></path></svg>
                          </span>
                          <span className="elementor-icon-list-text">Family</span>
                        </li>
                      <li className="elementor-icon-list-item">
                          <span className="elementor-icon-list-icon">
                            <svg aria-hidden="true" className="e-font-icon-svg e-fas-check" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg"><path d="M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z"></path></svg>
                          </span>
                          <span className="elementor-icon-list-text">Education</span>
                        </li>
                      <li className="elementor-icon-list-item">
                          <span className="elementor-icon-list-icon">
                            <svg aria-hidden="true" className="e-font-icon-svg e-fas-check" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg"><path d="M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z"></path></svg>
                          </span>
                          <span className="elementor-icon-list-text">Courses &amp; Grades</span>
                        </li>
                      <li className="elementor-icon-list-item">
                          <span className="elementor-icon-list-icon">
                            <svg aria-hidden="true" className="e-font-icon-svg e-fas-check" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg"><path d="M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z"></path></svg>
                          </span>
                          <span className="elementor-icon-list-text">Testing</span>
                        </li>
                      <li className="elementor-icon-list-item">
                          <span className="elementor-icon-list-icon">
                            <svg aria-hidden="true" className="e-font-icon-svg e-fas-check" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg"><path d="M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z"></path></svg>
                          </span>
                          <span className="elementor-icon-list-text">Activities &amp; Honors</span>
                        </li>
                      <li className="elementor-icon-list-item">
                          <span className="elementor-icon-list-icon">
                            <svg aria-hidden="true" className="e-font-icon-svg e-fas-check" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg"><path d="M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z"></path></svg>
                          </span>
                          <span className="elementor-icon-list-text">Additional Info</span>
                        </li>
                  </ul>
                  <hr style={{color:'#4D88F0', width:'100%', height:'2px', opacity:'1' }} ></hr>
                 
                  <h3>Colleges</h3>
                  <ul className="elementor-icon-list-items">
                    <li className="elementor-icon-list-item">
                            <span className="elementor-icon-list-icon">
                    <svg aria-hidden="true" className="e-font-icon-svg e-fas-check" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg"><path d="M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z"></path></svg>						</span>
                          <span className="elementor-icon-list-text">College Search</span>
                        </li>
                      <li className="elementor-icon-list-item">
                            <span className="elementor-icon-list-icon">
                    <svg aria-hidden="true" className="e-font-icon-svg e-fas-check" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg"><path d="M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z"></path></svg>						</span>
                          <span className="elementor-icon-list-text">Create Multiple College Lists</span>
                        </li>
                  </ul>                
                  <hr style={{color:'#4D88F0', width:'100%', height:'2px', opacity:'1' }} ></hr>
                  <h3>Summary</h3>
                  <ul className="elementor-icon-list-items">
                    <li className="elementor-icon-list-item">
                        <span className="elementor-icon-list-icon"><svg aria-hidden="true" className="e-font-icon-svg e-fas-check" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg"><path d="M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z"></path></svg></span>
                        <span className="elementor-icon-list-text">Page-by-page view mode</span>
                    </li>
                  </ul>
                  <hr style={{color:'#4D88F0', width:'100%', height:'2px', opacity:'1' }} ></hr>
                  
                  <h2>Optimize</h2>
                  <h3>AI PUSH Chatbot Guidance</h3>
                  <ul className="elementor-icon-list-items">
                    <li className="elementor-icon-list-item">
                      <span className="elementor-icon-list-icon"><svg aria-hidden="true" className="e-font-icon-svg e-fas-check" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg"><path d="M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z"></path></svg></span>
                      <span className="elementor-icon-list-text">Expertly trained by college counselors</span>
                    </li>
                    <li className="elementor-icon-list-item">
                        <span className="elementor-icon-list-icon"><svg aria-hidden="true" className="e-font-icon-svg e-fas-check" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg"><path d="M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z"></path></svg></span>
                        <span className="elementor-icon-list-text">Profile building guidance</span>
                      </li>
                    <li className="elementor-icon-list-item">
                        <span className="elementor-icon-list-icon"><svg aria-hidden="true" className="e-font-icon-svg e-fas-check" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg"><path d="M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z"></path></svg></span>
                        <span className="elementor-icon-list-text">Passion project ideas and outlines</span>
                      </li>
                    <li className="elementor-icon-list-item">
                        <span className="elementor-icon-list-icon"><svg aria-hidden="true" className="e-font-icon-svg e-fas-check" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg"><path d="M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z"></path></svg></span>
                        <span className="elementor-icon-list-text">High school activity support</span>
                      </li>
                    <li className="elementor-icon-list-item">
                        <span className="elementor-icon-list-icon"><svg aria-hidden="true" className="e-font-icon-svg e-fas-check" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg"><path d="M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z"></path></svg></span>
                        <span className="elementor-icon-list-text">College application support</span>
                    </li>
                  </ul>
                  <hr style={{color:'#4D88F0', width:'100%', height:'2px', opacity:'1' }} ></hr>
  
                  <h3>Counselor</h3>
                  <ul className="elementor-icon-list-items">
                      <li className="elementor-icon-list-item">
                        <span className="elementor-icon-list-icon"><svg aria-hidden="true" className="e-font-icon-svg e-fas-check" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg"><path d="M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z"></path></svg></span>
                        <span className="elementor-icon-list-text">Add your trusted counselor</span>
                      </li>
                      <li className="elementor-icon-list-item">
                        <span className="elementor-icon-list-icon"><svg aria-hidden="true" className="e-font-icon-svg e-fas-check" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg"><path d="M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z"></path></svg></span>
                        <span className="elementor-icon-list-text">Chat with your counselor</span>
                      </li>
                      <li className="elementor-icon-list-item">
                        <span className="elementor-icon-list-icon"><svg aria-hidden="true" className="e-font-icon-svg e-fas-check" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg"><path d="M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z"></path></svg></span>
                        <span className="elementor-icon-list-text">Suggested List Access w/ Likelihood Status</span>
                      </li>
                      <li className="elementor-icon-list-item">
                        <span className="elementor-icon-list-icon"><svg aria-hidden="true" className="e-font-icon-svg e-fas-check" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg"><path d="M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z"></path></svg></span>
                        <span className="elementor-icon-list-text">Two-Way Summary Approval</span>
                      </li>
                  </ul>
                  <hr style={{color:'#4D88F0', width:'100%', height:'2px', opacity:'1' }} ></hr>
  
                  <h3>College Planning Resources</h3>
                  <ul className="elementor-icon-list-items">
                    <li className="elementor-icon-list-item">
                        <span className="elementor-icon-list-icon red"><svg aria-hidden="true" className="e-font-icon-svg e-fas-times-circle" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg"><path d="M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8zm121.6 313.1c4.7 4.7 4.7 12.3 0 17L338 377.6c-4.7 4.7-12.3 4.7-17 0L256 312l-65.1 65.6c-4.7 4.7-12.3 4.7-17 0L134.4 338c-4.7-4.7-4.7-12.3 0-17l65.6-65-65.6-65.1c-4.7-4.7-4.7-12.3 0-17l39.6-39.6c4.7-4.7 12.3-4.7 17 0l65 65.7 65.1-65.6c4.7-4.7 12.3-4.7 17 0l39.6 39.6c4.7 4.7 4.7 12.3 0 17L312 256l65.6 65.1z"></path></svg></span>
                        <span className="elementor-icon-list-text">Search/Access Knowledge Base </span>
                      </li>
                      <li className="elementor-icon-list-item">
                        <span className="elementor-icon-list-icon red"><svg aria-hidden="true" className="e-font-icon-svg e-fas-times-circle" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg"><path d="M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8zm121.6 313.1c4.7 4.7 4.7 12.3 0 17L338 377.6c-4.7 4.7-12.3 4.7-17 0L256 312l-65.1 65.6c-4.7 4.7-12.3 4.7-17 0L134.4 338c-4.7-4.7-4.7-12.3 0-17l65.6-65-65.6-65.1c-4.7-4.7-4.7-12.3 0-17l39.6-39.6c4.7-4.7 12.3-4.7 17 0l65 65.7 65.1-65.6c4.7-4.7 12.3-4.7 17 0l39.6 39.6c4.7 4.7 4.7 12.3 0 17L312 256l65.6 65.1z"></path></svg></span>
                        <span className="elementor-icon-list-text">Major-based Suggestions</span>
                      </li>
                      <li className="elementor-icon-list-item">
                        <span className="elementor-icon-list-icon red"><svg aria-hidden="true" className="e-font-icon-svg e-fas-times-circle" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg"><path d="M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8zm121.6 313.1c4.7 4.7 4.7 12.3 0 17L338 377.6c-4.7 4.7-12.3 4.7-17 0L256 312l-65.1 65.6c-4.7 4.7-12.3 4.7-17 0L134.4 338c-4.7-4.7-4.7-12.3 0-17l65.6-65-65.6-65.1c-4.7-4.7-4.7-12.3 0-17l39.6-39.6c4.7-4.7 12.3-4.7 17 0l65 65.7 65.1-65.6c4.7-4.7 12.3-4.7 17 0l39.6 39.6c4.7 4.7 4.7 12.3 0 17L312 256l65.6 65.1z"></path></svg></span>
                        <span className="elementor-icon-list-text">Parent &amp; Student Educational Video Library</span>
                      </li>
                  </ul>
                  <hr style={{color:'#4D88F0', width:'100%', height:'2px', opacity:'1' }} ></hr>
  
                  <h2>Automate</h2>
                  <ul className="elementor-icon-list-items">
                    <li className="elementor-icon-list-item">
                        <span className="elementor-icon-list-icon"><svg aria-hidden="true" className="e-font-icon-svg e-fas-check" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg"><path d="M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z"></path></svg></span>
                        <span className="elementor-icon-list-text">Chrome Extension Access to Auto-fill CSU app</span>
                      </li>
                      <li className="elementor-icon-list-item">
                        <span className="elementor-icon-list-icon"><svg aria-hidden="true" className="e-font-icon-svg e-fas-check" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg"><path d="M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z"></path></svg></span>
                        <span className="elementor-icon-list-text">Chrome Extension Access to Auto-fill UC app</span>
                      </li>
                      <li className="elementor-icon-list-item">
                        <span className="elementor-icon-list-icon"><svg aria-hidden="true" className="e-font-icon-svg e-fas-check" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg"><path d="M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z"></path></svg></span>
                        <span className="elementor-icon-list-text">Chrome Extension Access to Auto-fill Common App</span>
                      </li>
                  </ul>
                  <hr style={{color:'#4D88F0', width:'100%', height:'2px', opacity:'1' }} ></hr>
  
                  <h2>Expert Chat Support</h2>
                  <ul className="elementor-icon-list-items">
                    <li className="elementor-icon-list-item">
                      <span className="elementor-icon-list-icon red"><svg aria-hidden="true" className="e-font-icon-svg e-fas-times-circle" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg"><path d="M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8zm121.6 313.1c4.7 4.7 4.7 12.3 0 17L338 377.6c-4.7 4.7-12.3 4.7-17 0L256 312l-65.1 65.6c-4.7 4.7-12.3 4.7-17 0L134.4 338c-4.7-4.7-4.7-12.3 0-17l65.6-65-65.6-65.1c-4.7-4.7-4.7-12.3 0-17l39.6-39.6c4.7-4.7 12.3-4.7 17 0l65 65.7 65.1-65.6c4.7-4.7 12.3-4.7 17 0l39.6 39.6c4.7 4.7 4.7 12.3 0 17L312 256l65.6 65.1z"></path></svg></span>
                      <span className="elementor-icon-list-text">Expert Chat Support</span>
                    </li>
                  </ul>
  
                </div>
              </div>
  
          </div>
        </div>
    );
  
}
  