import React from 'react';
import { Col, Row } from 'react-bootstrap';
import GroupRadioButton from 'components/input/GroupRadioButton';
import YesNoSwitch from 'components/input/YesNoSwitch';
import DatePickerInput from 'components/input/DatePicker';
import { useAppSelector } from 'app/hooks';
interface Props {
  formik: any;
}

const EnrolimentStatus = (props: Props) => {
  const studentAdditionalInfo = useAppSelector(
    (store) => store.authorization.studentAdditionalInfo
  );
  const myCitizenship = useAppSelector((store) => store.basicInformation.myCitizenship);

  React.useEffect(() => {
    if (
      studentAdditionalInfo?.year_plan_college_studies &&
      !props.formik.values?.year_plan_college_studies &&
      !myCitizenship
    ) {
      props.formik.setFieldValue(
        'year_plan_college_studies',
        studentAdditionalInfo?.year_plan_college_studies
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <Row className="m-0">
      <div className="fs-5 p-1">Enrollment Status</div>
      <Col md={12}>
        <GroupRadioButton
          onClear={() => props.formik.setFieldValue('current_enrollment_status', '')}
          className="coallition-flag"
          label="What is your current enrollment status?*"
          mdCol={6}
          name="current_enrollment_status"
          values={props.formik.values.current_enrollment_status}
          onChange={props.formik.handleChange}
          options={[
            { value: 'Middle School / Junior High' },
            { value: 'High School / Secondary School' },
            { value: '2-year College (after high school graduation)' },
            { value: '4-year College (after high school graduation)' },
            { value: 'Not Currently Enrolled in School' },
          ]}
        />
      </Col>
      <DatePickerInput
        className="text-nowrap coallition-flag"
        name="when_graduate_high_school"
        values={props.formik.values.when_graduate_high_school}
        onChange={(value) => props.formik.setFieldValue('when_graduate_high_school', value)}
        label="When will you graduate from high school or receive high school equivalency? *"
        mdCol={'12'}
      />
      <Row className="mx-n1 my-2 p-0 coallition-flag">
        <GroupRadioButton
          label="Year entering college"
          mdCol={2}
          values={props.formik.values.year_plan_college_studies}
          name="year_plan_college_studies"
          onChange={props.formik.handleChange}
          onClear={() => props.formik.setFieldValue('year_plan_college_studies', null)}
          options={[
            { value: 2025, label: '2025' },
            { value: 2026, label: '2026' },
            { value: 2027, label: '2027' },
            { value: 2028, label: '2028' },
          ]}
        />
      </Row>
      <GroupRadioButton
        mdCol={12}
        logo="span-csu-logo"
        options={[
          `First Bachelor's Degree (Seeking your first bachelor's degree and have not earned a prior bachelor's degree)`,
          `Second Bachelor's Degree and Beyond (e.g. Master's, Teaching Credential, Certificate, Doctoral)`,
        ]}
        label="What degree, credential or certificate are you applying for?"
        values={props.formik.values.csu_info?.degree_applying_for}
        name="csu_info.degree_applying_for"
        onChange={props.formik.handleChange}
      />
      {props.formik.values.csu_info?.degree_applying_for ===
        `First Bachelor's Degree (Seeking your first bachelor's degree and have not earned a prior bachelor's degree)` && (
        <>
          <GroupRadioButton
            logo="span-csu-logo"
            mdCol={12}
            options={[
              `Graduating High School Senior or equivalent (with or without college coursework prior to High School graduation)`,
              `Transferring with an Associate Degree for Transfer (AA-T/ AS-T) awarded by a California Community College`,
              `Transferring from a community college or four-year institution`,
            ]}
            label="Which of the following best describes your current educational status?"
            values={props.formik.values.csu_info?.educational_status}
            name="csu_info.educational_status"
            onChange={props.formik.handleChange}
          />
          {props.formik.values.csu_info?.educational_status ===
          'Graduating High School Senior or equivalent (with or without college coursework prior to High School graduation)' ? (
            <YesNoSwitch
              logo="span-csu-logo"
              values={props.formik.values.csu_info?.is_take_college_credit}
              name="csu_info.is_take_college_credit"
              onChange={props.formik.handleChange}
              label="Have you taken and earned college credits through a community college or university?"
              onClear={() => props.formik.setFieldValue('csu_info.is_take_college_credit', '')}
            />
          ) : (
            <div className="span-csu-logo">
              GRAD MAP is currently only available to Graduating High School Seniors or equivalent
              seeking their First Bachelor's Degree
            </div>
          )}
        </>
      )}
      {props.formik.values.csu_info?.degree_applying_for ===
        `Second Bachelor's Degree and Beyond (e.g. Master's, Teaching Credential, Certificate, Doctoral)` && (
        <div className="span-csu-logo">
          GRAD MAP is currently only available to Graduating High School Seniors or equivalent
          seeking their First Bachelor's Degree
        </div>
      )}
    </Row>
  );
};

export default EnrolimentStatus;
