/* eslint-disable react-hooks/exhaustive-deps */
import * as React from 'react';
import { styled } from '@mui/material/styles';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Collapse from '@mui/material/Collapse';
import IconButton, { IconButtonProps } from '@mui/material/IconButton';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Col, Row, Button } from 'react-bootstrap';
import { useFormik } from 'formik';
import TextInput from 'components/input/textInput';
import SelectInput from 'components/input/SelectInput';
import { subjectOptions } from 'screen/course/courseConstant';
import AutocompleteInput from 'components/input/AutoCompleteInput';
interface ExpandMoreProps extends IconButtonProps {
  expand: boolean;
}

const ExpandMore = styled((props: ExpandMoreProps) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
  marginLeft: 'auto',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest,
  }),
}));

interface Props {
  handleSave: (value: any) => void;
  item: any;
  index: number;
  total: number;
}

const levelOptions = [
  'Regular/Standard',
  'Accelerated',
  'Advanced',
  'Advanced Placement',
  'AS/A-level/International A-level, Cambridge AICE',
  'College Prep',
  'Dual Enrollment',
  'GCSE, IGCSE',
  'Gifted',
  'High Honors',
  'Honors',
  'Intensive',
  'International Baccalaureate (IB)',
  'Pre-IB',
  'Regents',
  'N/A',
];
const CourseWork = (props: Props) => {
  const [scheduleOptions, setScheduleOptions] = React.useState(['Full Year']);
  const formItem = useFormik({
    initialValues: {
      course_scheduling_system_is_using: '',
      title: '',
      level: 'Regular/Standard',
      schedule: ['Full Year'],
      subject: '',
    },
    onSubmit: (values) => {},
  });
  const [expanded, setExpanded] = React.useState(false);
  const handleExpandClick = () => {
    setExpanded(!expanded);
  };
  React.useEffect(() => {
    if (props.item)
      formItem.resetForm({
        values: {
          ...formItem.values,
          ...props.item,
          schedule: Array.isArray(props.item?.schedule) ? props.item?.schedule : [],
        },
      });
  }, []);
  React.useEffect(() => {
    if (formItem.dirty) {
      switch (formItem.values.course_scheduling_system_is_using) {
        case 'Semester':
          setScheduleOptions(['Full Year', 'First Semester', 'Second Semester']);
          break;

        case 'Trimester':
          setScheduleOptions([
            'Full Year',
            'First Trimester',
            'Second Trimester',
            'Third Trimester',
          ]);
          break;
        case 'Quarters':
          setScheduleOptions([
            'Full Year',
            'First Quarter',
            'Second Quarter',
            'Third Quarter',
            'Fourth Quarter',
          ]);
          break;
        default:
          setScheduleOptions(['Full Year']);
          break;
      }
      props.handleSave({ ...formItem.values });
    }
  }, [formItem.values]);
  return (
    <div className="m-0">
      <Card className="w-100 my-3 px-0">
        <CardActions
          disableSpacing
          onClick={handleExpandClick}
          sx={{
            backgroundColor: expanded ? '#AAEBF7' : 'none',
            borderBottom: expanded ? '2px solid #4D88F0' : 'none',
          }}
        >
          <div className="w-100 d-flex justify-content-between">
            <span className="p-1">{formItem.values.title}</span>
            <strong className="text-field-light">
              {props.index}/{props.total}
            </strong>
          </div>
          <ExpandMore
            expand={expanded}
            onClick={handleExpandClick}
            aria-expanded={expanded}
            aria-label="show more"
          >
            <ExpandMoreIcon />
          </ExpandMore>
        </CardActions>
        <Collapse in={expanded} timeout="auto" unmountOnExit>
          <CardContent>
            <Row>
              <SelectInput
                form={formItem}
                mdCol="12"
                label="Please select the course scheduling system your institution is using.*"
                name="course_scheduling_system_is_using"
                values={formItem.values.course_scheduling_system_is_using}
                onChange={formItem.handleChange}
                option={['Semester', 'Trimester', 'Quarter', 'Yearly', 'Other']}
              />
              <TextInput
                mdCol="12"
                label="Course Title"
                name="title"
                countText={100}
                values={formItem.values.title}
                onChange={formItem.handleChange}
              />
              <SelectInput
                form={formItem}
                option={levelOptions}
                label="Course Level"
                name="level"
                values={formItem.values.level}
                onChange={formItem.handleChange}
              />
              <AutocompleteInput
                multiple={true}
                onChange={(val: any) => {
                  formItem.setFieldValue('schedule', val);
                }}
                option={scheduleOptions}
                label="Course Schedule*"
                name="schedule"
                values={formItem.values.schedule}
              />
              <SelectInput
                mdCol="12"
                form={formItem}
                option={subjectOptions}
                label="Subject*"
                values={formItem.values.subject}
                name="subject"
                onChange={formItem.handleChange}
                feedback={`Please choose the closest matching name or "Other' if your class is not on the list.`}
              />

              <Col xs={6} md={9} />
              <Col xs={6} md={3} className="p-3">
                <Button
                  className="w-100"
                  onClick={() => {
                    props.handleSave(formItem.values);
                    setExpanded(false);
                  }}
                >
                  Save Info
                </Button>
              </Col>
            </Row>
          </CardContent>
        </Collapse>
      </Card>
    </div>
  );
};

export default CourseWork;
