import React from 'react';
import { Col, Row, Image, Container } from 'react-bootstrap';

interface Props {
  children: any;
}

const ReuseLoginLayout = (props: Props) => {
  return (
    <Row className="vh-100 vw-100 overflow-hidden p-0 m-0 ">
      <Col
        xs={0}
        md={4}
        className="h-100 d-none d-md-flex flex-wrap justify-content-center align-items-center background-login"
      >
        <Image
          src="/assets/images/login-logo-blue.png"
          height={120}
          // style={{ left: '16%' }}
          className="position-absolute"
        />
      </Col>
      <Col xs={12} md={0} className="d-none d-md-none p-0">
        <Image src="/assets/images/Topbar.png" width="100%" />
      </Col>
      <Col xs={12} md={8} className="d-flex h-100 p-0 pb-5 mb-5">
        <Container className="h-100 overflow-auto p-0  mb-5 px-md-5">{props.children}</Container>
      </Col>

      <style scoped>{`
      .background-login {
        background-image: url("/assets/images/bg-login.png");
        background-size: cover;
      }
      iframe#userpilot-checklist {
        display: none !important;
      }
      `}</style>
    </Row>
  );
};

export default ReuseLoginLayout;
