import TextIconInput from 'components/input/TextIconInput';
import { useFormik } from 'formik';
import React from 'react';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import { Form, Row, Button, Image, Col } from 'react-bootstrap';
import { useAppDispatch } from 'app/hooks';
import {
  sendEmailForgotPassword,
  setMyAuthorizationEmail,
} from 'app/authorization/authorizationSlice';
import * as Yup from 'yup';

interface Props {
  setStep: (value: any) => void;
}

const FormForgotPassword = (props: Props) => {
  const dispatch = useAppDispatch();
  const [emailInputed, setEmailInputed] = React.useState(false);
  const formik = useFormik({
    initialValues: {
      email: '',
    },
    validationSchema: Yup.object({
      email: Yup.string().email('Invalid email').required('Required'),
    }),
    onSubmit: async (values) => {
      const res = await dispatch(sendEmailForgotPassword(values));
      if (res.payload) {
        dispatch(setMyAuthorizationEmail(values.email));
        setEmailInputed(true);
      }
    },
  });

  return (
    <>
      {emailInputed ? (
        <>
          <div className="d-flex flex-column justify-content-center align-items-center">
            <Image src={'/assets/images/image-11.png'} />
            <p className="h3 mt-5">Verification sent</p>
            <p className="h6">Check your email to restore your password</p>
            <Button
              className="p-3 m-3"
              variant="primary"
              onClick={() => {
                props.setStep('inputCode');
              }}
            >
              <span className="h5 fw-bold">Restore my password</span>
            </Button>
          </div>
        </>
      ) : (
        <>
          <h2 className="my-3">Forgot your password?</h2>
          <div className="my-3 d-flex justify-content-between align-items-center">
            <h5>
              Enter the email associated with your account and we’ll send an instruction email to
              reset your password
            </h5>
          </div>
          <Col sm={12} md={6} className="p-0">
            <Form onSubmit={formik.handleSubmit}>
              <Row className="mb-1 mx-0">
                <TextIconInput
                  icon={<MailOutlineIcon />}
                  mdCol={'12'}
                  label={'Email'}
                  name={'email'}
                  values={formik.values.email}
                  onChange={formik.handleChange}
                  feedback={
                    formik.touched.email &&
                    formik.errors.email && (
                      <span className="text-danger">{formik.errors.email}</span>
                    )
                  }
                />
              </Row>

              <Button
                className="w-100 p-2 my-3"
                variant="primary"
                onClick={() => formik.handleSubmit()}
              >
                <span className="h5 fw-bold">Send Instructions</span>
              </Button>
            </Form>
          </Col>
        </>
      )}
    </>
  );
};

export default FormForgotPassword;
