import * as React from 'react';
import { styled } from '@mui/material/styles';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Collapse from '@mui/material/Collapse';
import IconButton, { IconButtonProps } from '@mui/material/IconButton';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Col, Row, Button } from 'react-bootstrap';
import { useFormik } from 'formik';
import SelectInput from 'components/input/SelectInput';
import TextInput from 'components/input/textInput';
import YesNoSwitch from 'components/input/YesNoSwitch';
import TextAreaInput from 'components/input/TextAreaInput';
import GroupCheckbox from 'components/input/GroupCheckbox';
import { BoolToString, formatBoolean, optionFromArray } from 'components/helper/helper';
import DatePickerInput from 'components/input/DatePicker';
import CheckboxInput from 'components/input/CheckboxInput';
import {
  activityNumberOfMonthAfter12,
  activityNumberOfYearAfter12,
  sportOptions,
} from 'components/helper/appConfig';

interface ExpandMoreProps extends IconButtonProps {
  expand: boolean;
}

const ExpandMore = styled((props: ExpandMoreProps) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
  marginLeft: 'auto',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest,
  }),
}));

interface Props {
  handleSave: (value: any) => void;
  item: any;
  indexNumber: String;
}
const ActivitiesConfig = (props: Props) => {
  // const [countForm, setCountForm] = React.useState(0);

  const formItem = useFormik({
    initialValues: {
      category: 'Academic Activity',
      categoryUC: '',
      activityType: 'Academic',
      programName: '',
      activityNameUC: '',
      activityExperienceProgramName: '',
      isInvolvedLeadershipRole: '',
      positionDescription: '',
      isCurrentlyParticipatingActivity: false,
      timmingOfParticipation: '',
      timmingOfParticipation_duringYear: false,
      timmingOfParticipation_duringBreak: false,
      timmingOfParticipation_allYear: false,
      hoursPerWeek: 0,
      weeksPerYear: 0,
      isIntendParticipateSimilarActivity: false,
      listIndividualDistinctions: '',
      organizationVolunteeredFor: '',
      describeActivity: '',
      whatDidYouDo: '',
      programNameForEducationPreparation: '',
      programNameForEducationPreparationSpecify: '',
      descriptionExperience: '',
      describeOrganization: '',
      describeCompany: '',
      isStillWork: '',
      startDate: '',
      endDate: '',
      hoursPerWeekLowEnd: '',
      hoursPerWeekHighEnd: '',
      otherCoursewordName: '',
      isGrade9ParticipationLevels: false,
      isGrade10ParticipationLevels: false,
      isGrade11ParticipationLevels: false,
      isGrade12ParticipationLevels: false,
      isPostGraduateParticipationLevels: false,
      // usersnap 592
      sportOrTeam: '',
      // usersnap 540
      workExperience_whereDidYouWork: '',
      workExperience_jobTitle: '',
      workExperience_jobResponsibilities: '',
      workExperience_workWhenNinthGrade: false,
      workExperience_workWhenTenthGrade: false,
      workExperience_workWhenEleventhGrade: false,
      workExperience_workWhenTwelfthGrade: false,
      workExperience_ninthGradeSchoolYear: false,
      workExperience_ninthGradeSchoolYearHoursPerWeek: '',
      workExperience_ninthGradeSummer: false,
      workExperience_ninthGradeSummerHoursPerWeek: '',
      workExperience_tenthGradeSchoolYear: false,
      workExperience_tenthGradeSchoolYearHoursPerWeek: '',
      workExperience_tenthGradeSummer: false,
      workExperience_tenthGradeSummerHoursPerWeek: '',
      workExperience_eleventhGradeSchoolYear: false,
      workExperience_eleventhGradeSchoolYearHoursPerWeek: '',
      workExperience_eleventhGradeSummer: false,
      workExperience_eleventhGradeSummerHoursPerWeek: '',
      workExperience_twelfthGradeSchoolYear: false,
      workExperience_twelfthGradeSchoolYearHoursPerWeek: '',
      workExperience_twelfthGradeSummer: false,
      workExperience_twelfthGradeSummerHoursPerWeek: '',
      workExperience_workAfterTwelfthGrade: false,
      workExperience_afterTwelfthGradeSchoolYear: false,
      workExperience_afterTwelfthGradeSchoolYearHoursPerWeek: '',
      workExperience_afterTwelfthGradeSummer: false,
      workExperience_afterTwelfthGradeSummerHoursPerWeek: '',
      workExperience_afterTwelfthGradeSummerYear: '',
      workExperience_afterTwelfthGradeSummerMonth: '',
      //usersnap 632
      howHaveYouUsedYourEarnings: '',
      //usersnap 972
      isIncludeIntoCommonApp: false,
      isIncludeIntoUCApp: false,
      isIncludeIntoCSUApp: false,
    },
    onSubmit: (values) => {
      console.log('ActivitiesConfig values:', values);
    },
  });
  const renderFillAppStatus = () => {
    return (
      <span>
        {formItem.values.isIncludeIntoCommonApp && (
          <img
            alt="cm"
            src="/assets/icons/icon-cm-5.svg"
            style={{ marginTop: '-8px', marginLeft: '4px' }}
          />
        )}
        {formItem.values.isIncludeIntoUCApp && (
          <img
            alt="uc"
            src="/assets/icons/icon-uc-5.svg"
            style={{ marginTop: '-8px', marginLeft: '4px' }}
          />
        )}
        {formItem.values.isIncludeIntoCSUApp && (
          <img
            alt="csu"
            src="./assets/icons/icon-csu-5.svg"
            style={{ marginTop: '-8px', marginLeft: '4px' }}
          />
        )}
      </span>
    );
  };
  const [expanded, setExpanded] = React.useState(false);
  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  React.useEffect(() => {
    if (props.item?.categoryUC) {
      formItem.setValues({
        ...formItem.values,
        ...props.item,
        isInvolvedLeadershipRole: BoolToString(props.item.isInvolvedLeadershipRole),
        isCurrentlyParticipatingActivity: BoolToString(props.item.isCurrentlyParticipatingActivity),
        isIntendParticipateSimilarActivity: BoolToString(props.item.isIntendParticipateSimilarActivity),
      });
    } else {
      // setIsShowMore(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  React.useEffect(() => {
    if (formItem.dirty) {
      props.handleSave({ ...formItem.values });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formItem.values]);
  // const [isShowAllForm, setIsShowMore] = React.useState(false);
  const [labelWhatDoYouDo, setLabelWhatDoYouDo] = React.useState<any>({
    label: 'What did you do? *',
    feedback: <></>,
  });
  React.useEffect(() => {
    switch (formItem.values.categoryUC) {
      case 'Educational Preparation Program':
        setLabelWhatDoYouDo({
          label: 'Briefly Describe the Program',
          feedback: (
            <div>
              <div>
                Think about the program's main focus, your experience, and what you accomplished and
                learned while participating in the program.
              </div>
              <div>
                *The UC application does not ask for your position or title, consider including it
                in your description if appropriate.
              </div>
            </div>
          ),
        });
        break;
      case 'Other Coursework':
        setLabelWhatDoYouDo({
          label: 'Briefly Describe the course *',
          feedback: `What program or school offered the course? Also, think about describing the major themes or topics the course covered, as well as what knowledge or skills you learned.`,
        });
        break;
      case 'Work Experience':
        setLabelWhatDoYouDo({
          label: 'What were your job responsibilities?',
          feedback: ``,
        });
        break;
      default:
        setLabelWhatDoYouDo({
          label: 'What did you do? *',
          feedback: '',
        });
        break;
    }
  }, [formItem.values.categoryUC]);
  return (
    <div className="m-0">
      <Card className="w-100 my-3 px-0">
        <CardActions
          disableSpacing
          onClick={handleExpandClick}
          sx={{
            backgroundColor: expanded ? '#AAEBF7' : 'none',
            borderBottom: expanded ? '2px solid #4D88F0' : 'none',
          }}
        >
          <div className="w-100 d-flex justify-content-between">
            {formItem.values.categoryUC === 'Work Experience' ? (
              <span className="p-1">
                {[
                  formItem.values.categoryUC,
                  formItem.values.workExperience_whereDidYouWork,
                  formItem.values.workExperience_jobTitle,
                ]
                  .filter((v) => !!v)
                  .join(' - ')}
                {renderFillAppStatus()}
              </span>
            ) : (
              <span className="p-1">
                {formItem.values.categoryUC}
                {formItem.values.programName ? ` - ${formItem.values.programName}` : ''}
                {formItem.values.categoryUC === 'Volunteer/Community Service'
                  ? ` - ${formItem.values.organizationVolunteeredFor}`
                  : ''}
                {formItem.values.positionDescription
                  ? ` - ${formItem.values.positionDescription}`
                  : ''}
                {renderFillAppStatus()}
              </span>
            )}
            <span className="p-1 text-field-light fw-bold">{props.indexNumber}</span>
          </div>
          <ExpandMore
            expand={expanded}
            onClick={handleExpandClick}
            aria-expanded={expanded}
            aria-label="show more"
          >
            <ExpandMoreIcon />
          </ExpandMore>
        </CardActions>
        <Collapse in={expanded} timeout="auto" unmountOnExit>
          <CardContent>
            <Row className="px-3">
              <SelectInput
                logo="span-uc-logo"
                form={formItem}
                option={[
                  'Educational Preparation Program',
                  'Extracurricular Activity',
                  'Other Coursework',
                  'Volunteer/Community Service',
                  'Work Experience',
                ]}
                label="Category *"
                values={formItem.values.categoryUC}
                name="categoryUC"
                onChange={formItem.handleChange}
              />
              <SelectInput
                logo="span-cm-logo"
                form={formItem}
                option={[
                  'Academic',
                  'Athletics: Club',
                  'Athletics: JV/Varsity',
                  'Career Oriented',
                  'Community Service (Volunteer)',
                  'Computer/Technology',
                  'Cultural',
                  'Dance',
                  'Debate/Speech',
                  'Environmental',
                  'Family Responsibilites',
                  'Foreign Exchange',
                  'Foreign Language',
                  'Internship',
                  'Journalism/Publication',
                  'Junior R.O.T.C.',
                  'LGBT',
                  'Music: Instrumental',
                  'Music: Vocal',
                  'Religious',
                  'Research',
                  'Robotics',
                  'School Spirit',
                  'Science/Math',
                  'Social Justice',
                  'Student Govt./Politics',
                  'Theater/Drama',
                  'Work (Paid)',
                  'Other Club/Activity',
                ]}
                label="Activity Type"
                values={formItem.values.activityType}
                name="activityType"
                onChange={formItem.handleChange}
              />
              <SelectInput
                className="d-none"
                form={formItem}
                option={optionFromArray([
                  'Academic Activity',
                  'Arts',
                  'Club/Organization',
                  'Family Responsibiltes',
                  'Sports',
                  'Volunteer Work',
                  'Work',
                  'Other',
                ])}
                label="Category *"
                values={formItem.values.category}
                name="category"
                onChange={formItem.handleChange}
              />

              {(formItem.values.activityType === 'Athletics: Club' ||
                formItem.values.activityType === 'Athletics: JV/Varsity') && (
                <Row className="bg-light rounded p-2 m-2">
                  <div>Based on Activity type Athetics</div>
                  <SelectInput
                    logo="span-cm-logo"
                    mdCol="12"
                    form={formItem}
                    option={sportOptions}
                    label="Sport/team*"
                    name="sportOrTeam"
                    values={formItem.values.sportOrTeam}
                    onChange={formItem.handleChange}
                  />
                  {/* <TextInput
                    countText={50}
                    label="Position/Leadership description"
                    name="positionDescription"
                    values={formItem.values.positionDescription}
                    onChange={formItem.handleChange}
                  /> */}
                </Row>
              )}
              {formItem.values.categoryUC === 'Extracurricular Activity' && (
                <Row className="bg-light rounded p-2  mx-2">
                  <div>Based on Extracurricular Activity</div>
                  <TextInput
                    logo="span-uc-logo"
                    label="What's the name of the activity?"
                    name="activityExperienceProgramName"
                    values={formItem.values.activityExperienceProgramName}
                    onChange={formItem.handleChange}
                    countText={60}
                    mdCol={12}
                  />
                </Row>
              )}

              {formItem.values.categoryUC === 'Other Coursework' && (
                <Row className="bg-light rounded p-2 mx-2">
                  <div>Based on Other Coursework</div>
                  <TextInput
                    logo="span-uc-logo"
                    countText={60}
                    label="What was the course name?"
                    values={formItem.values.otherCoursewordName}
                    onChange={formItem.handleChange}
                    name="otherCoursewordName"
                  />
                </Row>
              )}
              {formItem.values.categoryUC === 'Educational Preparation Program' && (
                <Row className="bg-light rounded p-2 mx-2">
                  <div>Based on Educational Preparation Program</div>
                  <SelectInput
                    logo="span-uc-logo"
                    label="What was the program name? *"
                    name="programNameForEducationPreparation"
                    onChange={formItem.handleChange}
                    values={formItem.values.programNameForEducationPreparation}
                    option={[
                      '- Select -',
                      'ATDP (Academic Talent Development Program)',
                      'Cal-SOAP',
                      'CAMP (California Alliance for Minority Participationin Science)',
                      'CBOP (Career Based Outreach Program)',
                      'COSMOS',
                      'C-STEM',
                      'Early Academic Outreach Program (EAOP)',
                      'Educational Guidance Center (EGC)',
                      'Educational Talent Search',
                      'GEAR UP',
                      'MESA (Mathematics, Engineering, Science Achievement) including Mesa Schools Programs',
                      'PDP (Professional Development Program)',
                      'Puente Program',
                      'UC Mered Transfer Initiative Programs',
                      'UC Scholars',
                      'UCLA Center for Community College Partnerships CCCP/SITE',
                      'UCLA VIP Scholars Program',
                      'UCSC SAGE (Students Acquiring "A-G" Expectations)',
                      'UCSF Internships',
                      'Upward Bound',
                      'Young Entrepreneurs at Haas',
                      'Other',
                    ]}
                  />
                  {formItem.values.programNameForEducationPreparation === 'Other' && (
                    <TextInput
                      logo="span-uc-logo"
                      countText={30}
                      label="Program Name *"
                      values={formItem.values.programNameForEducationPreparationSpecify}
                      onChange={formItem.handleChange}
                      name="programNameForEducationPreparationSpecify"
                    />
                  )}
                </Row>
              )}
              {formItem.values.categoryUC === 'Volunteer/Community Service' && (
                <Row className="bg-light rounded p-2  mx-2">
                  <div>Based on Volunteer/Community Service</div>
                  <TextInput
                    logo="span-cm-uc-logo"
                    label="What's the name of the organization, program, school or group you volunteered for?"
                    mdCol="12"
                    countText={60}
                    name="organizationVolunteeredFor"
                    values={formItem.values.organizationVolunteeredFor}
                    onChange={formItem.handleChange}
                  />
                  <TextAreaInput
                    className="uc-flag"
                    mdCol="12"
                    countText={250}
                    label="Please describe the organization, program, school or group. *"
                    name="describeOrganization"
                    values={formItem.values.describeOrganization}
                    onChange={formItem.handleChange}
                  />
                </Row>
              )}
              {formItem.values.categoryUC === 'Work Experience' && (
                <Row className="bg-light rounded p-2 mx-2">
                  <div>Based on Work Experience</div>
                  <TextInput
                    logo="span-cm-uc-logo"
                    mdCol="12"
                    countText={60}
                    label="Where did you work?"
                    name="workExperience_whereDidYouWork"
                    values={formItem.values.workExperience_whereDidYouWork}
                    onChange={formItem.handleChange}
                    feedback={<span>Please tell us the name of the place where you worked.</span>}
                  />
                  <TextAreaInput
                    logo="span-uc-logo"
                    mdCol="12"
                    countText={250}
                    label="Please briefly describe the company or organization where you worked. *"
                    name="describeCompany"
                    values={formItem.values.describeCompany}
                    onChange={formItem.handleChange}
                  />
                  <TextInput
                    logo="span-cm-uc-logo"
                    mdCol="12"
                    countText={60}
                    label=" What was your job title/position"
                    name="workExperience_jobTitle"
                    values={formItem.values.workExperience_jobTitle}
                    onChange={formItem.handleChange}
                  />
                  <div className="text-dark span-uc-logo">When did you work at this job? *</div>
                  <div className="text-dark fs-7">
                    We'd like to know when you worked at this job and the number of hours per week
                    you worked. It's ok to estimate, but try to be as accurate as possible. If you
                    worked during the summer, select the grade year before that summer.
                  </div>

                  <Card className="bg-body my-1 pb-2">
                    <Row className="px-2">
                      <Col md={12}>
                        <CheckboxInput
                          name="workExperience_workWhenNinthGrade"
                          values={formItem.values.workExperience_workWhenNinthGrade}
                          onChange={formItem.handleChange}
                          label="9th Grade"
                        />
                      </Col>
                      {formItem.values.workExperience_workWhenNinthGrade && (
                        <>
                          <Col md={6}>
                            <CheckboxInput
                              name="workExperience_ninthGradeSchoolYear"
                              values={formItem.values.workExperience_ninthGradeSchoolYear}
                              onChange={formItem.handleChange}
                              label="School Year"
                            />
                            <TextInput
                              hidden={!formItem.values.workExperience_ninthGradeSchoolYear}
                              mdCol={12}
                              type="number"
                              label="Hours Per week"
                              name="workExperience_ninthGradeSchoolYearHoursPerWeek"
                              onChange={formItem.handleChange}
                              values={
                                formItem.values.workExperience_ninthGradeSchoolYearHoursPerWeek
                              }
                              min={0}
                              max={999}
                              decimalStep={0.1}
                            />
                          </Col>

                          <Col md={6}>
                            <CheckboxInput
                              name="workExperience_ninthGradeSummer"
                              values={formItem.values.workExperience_ninthGradeSummer}
                              onChange={formItem.handleChange}
                              label="Summer"
                            />
                            <TextInput
                              hidden={!formItem.values.workExperience_ninthGradeSummer}
                              mdCol={12}
                              label="Hours Per week"
                              type="number"
                              name="workExperience_ninthGradeSummerHoursPerWeek"
                              values={formItem.values.workExperience_ninthGradeSummerHoursPerWeek}
                              onChange={formItem.handleChange}
                              min={0}
                              max={999}
                              decimalStep={0.1}
                            />
                          </Col>
                        </>
                      )}
                    </Row>
                  </Card>
                  <Card className="bg-body my-1 pb-2">
                    <Row className="px-2">
                      <Col md={12}>
                        <CheckboxInput
                          name="workExperience_workWhenTenthGrade"
                          values={formItem.values.workExperience_workWhenTenthGrade}
                          onChange={formItem.handleChange}
                          label="10th Grade"
                        />
                      </Col>
                      {formItem.values.workExperience_workWhenTenthGrade && (
                        <>
                          <Col md={6}>
                            <CheckboxInput
                              name="workExperience_tenthGradeSchoolYear"
                              values={formItem.values.workExperience_tenthGradeSchoolYear}
                              onChange={formItem.handleChange}
                              label="School Year"
                            />
                            <TextInput
                              hidden={!formItem.values.workExperience_tenthGradeSchoolYear}
                              mdCol="12"
                              type="number"
                              label="Hours Per week"
                              name="workExperience_tenthGradeSchoolYearHoursPerWeek"
                              onChange={formItem.handleChange}
                              values={
                                formItem.values.workExperience_tenthGradeSchoolYearHoursPerWeek
                              }
                              min={0}
                              max={999}
                              decimalStep={0.1}
                            />
                          </Col>

                          <Col md={6}>
                            <CheckboxInput
                              name="workExperience_tenthGradeSummer"
                              values={formItem.values.workExperience_tenthGradeSummer}
                              onChange={formItem.handleChange}
                              label="Summer"
                            />
                            <TextInput
                              hidden={!formItem.values.workExperience_tenthGradeSummer}
                              mdCol="12"
                              label="Hours Per week"
                              type="number"
                              name="workExperience_tenthGradeSummerHoursPerWeek"
                              values={formItem.values.workExperience_tenthGradeSummerHoursPerWeek}
                              onChange={formItem.handleChange}
                              min={0}
                              max={999}
                              decimalStep={0.1}
                            />
                          </Col>
                        </>
                      )}
                    </Row>
                  </Card>

                  <Card className="bg-body my-1 pb-2">
                    <Row className="px-2">
                      <Col md={12}>
                        <CheckboxInput
                          name="workExperience_workWhenEleventhGrade"
                          values={formItem.values.workExperience_workWhenEleventhGrade}
                          onChange={formItem.handleChange}
                          label="11th Grade"
                        />
                      </Col>
                      {formItem.values.workExperience_workWhenEleventhGrade && (
                        <>
                          <Col md={6}>
                            <CheckboxInput
                              name="workExperience_eleventhGradeSchoolYear"
                              values={formItem.values.workExperience_eleventhGradeSchoolYear}
                              onChange={formItem.handleChange}
                              label="School Year"
                            />
                            <TextInput
                              hidden={!formItem.values.workExperience_eleventhGradeSchoolYear}
                              mdCol="12"
                              type="number"
                              label="Hours Per week"
                              name="workExperience_eleventhGradeSchoolYearHoursPerWeek"
                              onChange={formItem.handleChange}
                              values={
                                formItem.values.workExperience_eleventhGradeSchoolYearHoursPerWeek
                              }
                              min={0}
                              max={999}
                              decimalStep={0.1}
                            />
                          </Col>

                          <Col md={6}>
                            <CheckboxInput
                              name="workExperience_eleventhGradeSummer"
                              values={formItem.values.workExperience_eleventhGradeSummer}
                              onChange={formItem.handleChange}
                              label="Summer"
                            />
                            <TextInput
                              mdCol="12"
                              hidden={!formItem.values.workExperience_eleventhGradeSummer}
                              label="Hours Per week"
                              type="number"
                              name="workExperience_eleventhGradeSummerHoursPerWeek"
                              values={
                                formItem.values.workExperience_eleventhGradeSummerHoursPerWeek
                              }
                              onChange={formItem.handleChange}
                              min={0}
                              max={999}
                              decimalStep={0.1}
                            />
                          </Col>
                        </>
                      )}
                    </Row>
                  </Card>

                  <Card className="bg-body my-1 pb-2">
                    <Row className="px-2">
                      <Col md={12}>
                        <CheckboxInput
                          name="workExperience_workWhenTwelfthGrade"
                          values={formItem.values.workExperience_workWhenTwelfthGrade}
                          onChange={formItem.handleChange}
                          label="12th Grade"
                        />
                      </Col>
                      {formItem.values.workExperience_workWhenTwelfthGrade && (
                        <>
                          <Col md={6}>
                            <CheckboxInput
                              name="workExperience_twelfthGradeSchoolYear"
                              values={formItem.values.workExperience_twelfthGradeSchoolYear}
                              onChange={formItem.handleChange}
                              label="School Year"
                            />
                            <TextInput
                              mdCol="12"
                              hidden={!formItem.values.workExperience_twelfthGradeSchoolYear}
                              type="number"
                              label="Hours Per week"
                              name="workExperience_twelfthGradeSchoolYearHoursPerWeek"
                              onChange={formItem.handleChange}
                              values={
                                formItem.values.workExperience_twelfthGradeSchoolYearHoursPerWeek
                              }
                              min={0}
                              max={999}
                              decimalStep={0.1}
                            />
                          </Col>

                          <Col md={6}>
                            <CheckboxInput
                              name="workExperience_twelfthGradeSummer"
                              values={formItem.values.workExperience_twelfthGradeSummer}
                              onChange={formItem.handleChange}
                              label="Summer"
                            />
                            <TextInput
                              mdCol="12"
                              hidden={!formItem.values.workExperience_twelfthGradeSummer}
                              label="Hours Per week"
                              type="number"
                              name="workExperience_twelfthGradeSummerHoursPerWeek"
                              values={formItem.values.workExperience_twelfthGradeSummerHoursPerWeek}
                              onChange={formItem.handleChange}
                              min={0}
                              max={999}
                              decimalStep={0.1}
                            />
                          </Col>
                        </>
                      )}
                    </Row>
                  </Card>
                  <Card className="bg-body my-1 pb-2">
                    <Row className="px-2">
                      <Col md={12}>
                        <CheckboxInput
                          name="workExperience_workAfterTwelfthGrade"
                          values={formItem.values.workExperience_workAfterTwelfthGrade}
                          onChange={formItem.handleChange}
                          label="After 12th"
                        />
                      </Col>
                      {formItem.values.workExperience_workAfterTwelfthGrade && (
                        <>
                          <SelectInput
                            option={activityNumberOfYearAfter12}
                            name="workExperience_afterTwelfthGradeSummerYear"
                            values={formItem.values.workExperience_afterTwelfthGradeSummerYear}
                            onChange={formItem.handleChange}
                            label="Number of Years *"
                          />
                          <SelectInput
                            option={activityNumberOfMonthAfter12}
                            name="workExperience_afterTwelfthGradeSummerMonth"
                            values={formItem.values.workExperience_afterTwelfthGradeSummerMonth}
                            onChange={formItem.handleChange}
                            label="Number of Months *"
                          />
                          <Col md={6}>
                            <CheckboxInput
                              name="workExperience_afterTwelfthGradeSchoolYear"
                              values={formItem.values.workExperience_afterTwelfthGradeSchoolYear}
                              onChange={formItem.handleChange}
                              label="School Year"
                            />
                            <TextInput
                              mdCol="12"
                              hidden={!formItem.values.workExperience_afterTwelfthGradeSchoolYear}
                              type="number"
                              label="Hours Per week"
                              name="workExperience_afterTwelfthGradeSchoolYearHoursPerWeek"
                              onChange={formItem.handleChange}
                              values={
                                formItem.values
                                  .workExperience_afterTwelfthGradeSchoolYearHoursPerWeek
                              }
                              min={0}
                              max={999}
                              decimalStep={0.1}
                            />
                          </Col>

                          <Col md={6}>
                            <CheckboxInput
                              name="workExperience_afterTwelfthGradeSummer"
                              values={formItem.values.workExperience_afterTwelfthGradeSummer}
                              onChange={formItem.handleChange}
                              label="Summer"
                            />
                            <TextInput
                              hidden={!formItem.values.workExperience_afterTwelfthGradeSummer}
                              mdCol="12"
                              label="Hours Per week"
                              type="number"
                              name="workExperience_afterTwelfthGradeSummerHoursPerWeek"
                              values={
                                formItem.values.workExperience_afterTwelfthGradeSummerHoursPerWeek
                              }
                              onChange={formItem.handleChange}
                              min={0}
                              max={999}
                              decimalStep={0.1}
                            />
                          </Col>
                        </>
                      )}
                    </Row>
                  </Card>
                  <YesNoSwitch
                    values={formItem.values.isStillWork}
                    onChange={formItem.handleChange}
                    name="isStillWork"
                    className="my-3"
                    label="Do you still work at this job? *"
                  />
                  <DatePickerInput
                    label="Start date"
                    name="startDate"
                    values={formItem.values.startDate}
                    onChange={(value) => formItem.setFieldValue('startDate', value)}
                  />
                  {formItem.values.isStillWork === 'false' && (
                    <DatePickerInput
                      label="End date"
                      logo="span-uc-logo"
                      name="endDate"
                      values={formItem.values.endDate}
                      onChange={(value) => formItem.setFieldValue('endDate', value)}
                    />
                  )}
                </Row>
              )}
              <div />
              <TextInput
                logo="span-cm-logo"
                hidden={formItem.values.categoryUC === 'Work Experience'}
                label={
                  formItem.values.categoryUC === 'Work Experience'
                    ? 'What is your Job Title/Position'
                    : 'Position/Leadership description'
                }
                name="positionDescription"
                values={formItem.values.positionDescription}
                onChange={formItem.handleChange}
                countText={50}
                mdCol={12}
              />
              <TextInput
                hidden={
                  formItem.values.categoryUC === 'Volunteer/Community Service' ||
                  formItem.values.categoryUC === 'Work Experience'
                }
                logo="span-cm-logo"
                label="Organization Name*"
                name="programName"
                values={formItem.values.programName}
                onChange={formItem.handleChange}
                countText={100}
                mdCol={12}
              />
              {/* {formItem.values.categoryUC !== 'Educational Preparation Program' &&
                formItem.values.categoryUC !== 'Extracurricular Activity' && (
                  <TextInput
                    logo="span-uc-logo"
                    label={
                      formItem.values.categoryUC === 'Volunteer/Community Service'
                        ? "What's the name of the organization, program, school or group you volunteered for? *"
                        : formItem.values.categoryUC === 'Work Experience'
                        ? 'Where did you work? Please tell us the name of the place you worked.'
                        : 'What was the course name?'
                    }
                    name="activityNameUC"
                    values={formItem.values.activityNameUC}
                    onChange={formItem.handleChange}
                    countText={60}
                  />
                )} */}
              <TextAreaInput
                // hidden={formItem.values.categoryUC === 'Work Experience'}
                // Yêu cầu của CM App
                logo="span-cm-logo"
                mdCol="12"
                // countText={
                //   formItem.values.describeActivity.length
                // }
                label="Please describe this activity, including what you accomplished and any recognition you received, etc."
                name="describeActivity"
                values={formItem.values.describeActivity}
                onChange={formItem.handleChange}
              />
              {/* <Col>
                <YesNoSwitch
                  label="Are you involved in a leadership role?*"
                  className="common-coallition-flag"
                  name="isInvolvedLeadershipRole"
                  values={formItem.values.isInvolvedLeadershipRole}
                  onChange={formItem.handleChange}
                />
              </Col> */}

              {/* <TextAreaInput
                className="coallition-flag"
                mdCol="12"
                countText={255}
                label="Please provide a description of your experience"
                name="descriptionExperience"
                values={formItem.values.descriptionExperience}
                onChange={formItem.handleChange}
              /> */}
              <TextAreaInput
                logo="span-uc-logo"
                mdCol="12"
                // countText={0}
                label={labelWhatDoYouDo.label}
                name="whatDidYouDo"
                values={formItem.values.whatDidYouDo}
                onChange={formItem.handleChange}
                feedback={labelWhatDoYouDo.feedback}
              />
              {/* <div
                className={`text-field-light px-2 ${
                  formItem.values?.categoryUC !== 'Work Experience' && 'd-none'
                }`}
              >
                Please tell us how you've used your earnings from all of the jobs you've listed.
                This information will NOT be used to determine financial aid — rather, it gives us
                more context about you and your experiences.
              </div> */}
              {/* <TextAreaInput
                hidden={formItem.values.categoryUC !== 'Work Experience'}
                logo="span-uc-logo"
                mdCol="12"
                countText={250}
                label="How have you used or will you use your earnings?"
                name="howHaveYouUsedYourEarnings"
                values={formItem.values.howHaveYouUsedYourEarnings}
                onChange={formItem.handleChange}
              /> */}
              {/* {isShowAllForm && ( */}
              <>
                <GroupCheckbox
                  label="Participation grade levels*"
                  form={formItem}
                  mdCol={2}
                  options={[
                    {
                      value: 'isGrade9ParticipationLevels',
                      label: '9',
                    },
                    {
                      value: 'isGrade10ParticipationLevels',
                      label: '10',
                    },
                    {
                      value: 'isGrade11ParticipationLevels',
                      label: '11',
                    },
                    {
                      value: 'isGrade12ParticipationLevels',
                      label: '12',
                    },
                    {
                      value: 'isPostGraduateParticipationLevels',
                      label: 'Post-graduate (can select mulitple)',
                      mdCol: 12,
                    },
                  ]}
                  tooltips="If you participated in the program during the summer, select the grade you were in before that summer."
                />
                <YesNoSwitch
                  className="coallition-flag mx-1 py-3"
                  label="Are you currently participating in this activity or experience?"
                  name="isCurrentlyParticipatingActivity"
                  values={formItem.values.isCurrentlyParticipatingActivity}
                  onChange={(e: any) => {
                    formItem.handleChange(e);
                  }}
                />
                <GroupCheckbox
                  className="py-3"
                  logo="span-cm-logo"
                  label="Timing of Participation*"
                  form={formItem}
                  options={[
                    {
                      value: 'timmingOfParticipation_duringYear',
                      label: 'During school year',
                      mdCol: 4,
                    },
                    {
                      value: 'timmingOfParticipation_duringBreak',
                      label: 'During school break',
                      mdCol: 4,
                    },
                    { value: 'timmingOfParticipation_allYear', label: 'All year', mdCol: 4 },
                  ]}
                />
                <TextInput
                  logo="span-cm-logo"
                  max={168}
                  type="number"
                  label="Hours per Week"
                  name="hoursPerWeek"
                  values={formItem.values.hoursPerWeek}
                  onChange={formItem.handleChange}
                />
                <TextInput
                  logo="span-cm-logo"
                  max={52}
                  type="number"
                  label="Weeks per Year"
                  name="weeksPerYear"
                  values={formItem.values.weeksPerYear}
                  onChange={formItem.handleChange}
                />
                {/* <TextInput
                    max={52}
                    type="number"
                    label="Hours per week on the low end"
                    name="hoursPerWeekLowEnd"
                    values={formItem.values.hoursPerWeekLowEnd}
                    onChange={formItem.handleChange}
                  />
                  <TextInput
                    max={168}
                    type="number"
                    label="Hours per week on the high end"
                    name="hoursPerWeekHighEnd"
                    values={formItem.values.hoursPerWeekHighEnd}
                    onChange={formItem.handleChange}
                  /> */}

                <YesNoSwitch
                  label="I intend to participate in a similar activity in college.*"
                  className="mx-1"
                  logo="span-cm-logo"
                  name="isIntendParticipateSimilarActivity"
                  values={formItem.values.isIntendParticipateSimilarActivity}
                  onChange={(e: any) => {
                    formItem.handleChange(e);
                  }}
                />
              </>
              {/* )} */}
              <div className="mt-3" />
              <GroupCheckbox
                label="Select the applications you would like to include this activity on. *See App Notes above for activity limits"
                form={formItem}
                mdCol={3}
                options={[
                  {
                    value: 'isIncludeIntoCommonApp',
                    label: 'Common App',
                  },
                  {
                    value: 'isIncludeIntoUCApp',
                    label: 'UC App',
                  },
                  // {
                  //   value: 'isIncludeIntoCSUApp',
                  //   label: 'CSU App',
                  // },
                ]}
              />
              {/* <Col xs={12} md={9} className="text-center pt-4">
                {!isShowAllForm && (
                  <span className="text-dark">Click “Continue” to load the rest of the form.</span>
                )}
              </Col> */}
              <Col xs={6} md={9} />
              <Col xs={6} md={3} className="p-3">
                <Button
                  className="w-100"
                  onClick={() => {
                    props.handleSave({
                      ...formItem.values,
                      isInvolvedLeadershipRole: formatBoolean(
                        formItem.values.isInvolvedLeadershipRole
                      ),
                      isCurrentlyParticipatingActivity: formatBoolean(
                        formItem.values.isCurrentlyParticipatingActivity
                      ),
                      isIntendParticipateSimilarActivity: formatBoolean(
                        formItem.values.isIntendParticipateSimilarActivity
                      ),
                    });
                    setExpanded(false);
                  }}
                >
                  Save Info
                </Button>
                {/*  <Button className="w-100" onClick={() => setIsShowMore(true)}>
                    Continue
                  </Button> */}
              </Col>
            </Row>
          </CardContent>
        </Collapse>
      </Card>
    </div>
  );
};

export default ActivitiesConfig;
