import React, { useEffect } from 'react';
import { Route, Switch } from 'react-router-dom';
import { userRouters } from './router';

const UserLayout = () => {
  useEffect(() => {
    if (window.Intercom) {
      window.Intercom('shutdown');
    }
  }, []);
  return (
    <Switch>
      {Object.keys(userRouters).map((route: any, index: any) => {
        return (
          <Route
            key={index}
            path={userRouters[route].path}
            component={userRouters[route].component}
            exact
          />
        );
      })}
    </Switch>
  );
};

export default UserLayout;
