import http from './http';

const familyServices = {
  async getHousehold(student_id: any) {
    if (!student_id) return;
    const res = await http.get(`api/household/${student_id}`);
    return res;
  },

  async createHousehold(body: any): Promise<any> {
    const res = await http.post('api/household', body);
    return res;
  },

  async updateHousehold(body: any): Promise<any> {
    const res = await http.put('api/household', body);
    return res;
  },

  async getSiblings(student_id: any) {
    const res = await http.get(`api/siblings/${student_id}`);
    return res;
  },

  async createSiblings(body: any): Promise<any> {
    const res = await http.post('api/siblings', body);
    return res;
  },

  async updateSiblings(body: any): Promise<any> {
    const res = await http.put('api/siblings', body);
    return res;
  },

  async getStepParent1(student_id: any) {
    const res = await http.get(`api/step-parent-no1/${student_id}`);
    return res;
  },

  async createStepParent1(body: any): Promise<any> {
    const res = await http.post('api/step-parent-no1', body);
    return res;
  },
  async updateStepParent1(body: any): Promise<any> {
    const res = await http.put('api/step-parent-no1', body);
    return res;
  },

  async getStepParent2(student_id: any) {
    const res = await http.get(`api/step-parent-no2/${student_id}`);
    return res;
  },

  async createStepParent2(body: any): Promise<any> {
    const res = await http.post('api/step-parent-no2', body);
    return res;
  },
  async updateStepParent2(body: any): Promise<any> {
    const res = await http.put('api/step-parent-no2', body);
    return res;
  },

  async getParent1(student_id: any) {
    const res = await http.get(`api/parent-no1/${student_id}`);
    return res;
  },

  async createParent1(body: any): Promise<any> {
    const res = await http.post('api/parent-no1', body);
    return res;
  },
  async updateParent1(body: any): Promise<any> {
    const res = await http.put('api/parent-no1', body);
    return res;
  },

  async getParent2(student_id: any) {
    const res = await http.get(`api/parent-no2/${student_id}`);
    return res;
  },

  async createParent2(body: any): Promise<any> {
    const res = await http.post('api/parent-no2', body);
    return res;
  },
  async updateParent2(body: any): Promise<any> {
    const res = await http.put('api/parent-no2', body);
    return res;
  },
};

export default familyServices;
