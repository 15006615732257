import { optionValues } from 'components/helper/helper';

export const collegeDetailEarnedOption = [
  '',
  'Artist Diploma',
  'Associate of Arts',
  'Associate of Liberal Arts',
  'Associate of Applied Science',
  'Associate of Arts and Sciences',
  'Associate of Arts for Transfer',
  'Associate of Science',
  'Associate of Science for Transfer',
  'Bachelor of Applied Science',
  'Bachelor of Architecture (5yr)',
  'Bachelor of Arts',
  'Bachelor of Arts and Science',
  'Bachelor of Business Administration',
  'Bachelor of Chemical Engineering',
  'Bachelor of Commerce',
  'Bachelor of Dental Science',
  'Bachelor of Dental Surgery ',
  'Bachelor of Economics',
  'Bachelor of Education',
  'Bachelor of Engineering',
  'Bachelor of Engineering Technology',
  'Bachelor of Fine Arts',
  'Bachelor of Fine Arts with Teaching Certification',
  'Bachelor of Journalism',
  'Bachelor of Landscape Architecture (5yr)',
  'Bachelor of Laws',
  'Bachelor of Liberal Arts',
  'Bachelor of Liberal Studies',
  'Bachelor of Mathematics',
  'Bachelor of Medicine',
  'Bachelor of Medicine and Bachelor of Surgery',
  'Bachelor of Music',
  'Bachelor of Music Education',
  'Bachelor of Pharmacy',
  'Bachelor of Science',
  'Bachelor of Science in Bioengineering',
  'Bachelor of Science in Business Administration',
  'Bachelor of Science in Civil Engineering',
  'Bachelor of Science in Education',
  'Bachelor of Science in Electrical Engineering',
  'Bachelor of Science in Environmental Engineering',
  'Bachelor of Science in Mechanical Engineering',
  'Bachelor of Science in Medical Technology',
  'Bachelor of Science in Nuclear Engineering',
  'Bachelor of Science in Nursing',
  'Bachelor of Social Work',
  'Bachelor of Technology ',
  'Bachelor of Theology',
  'Certificate',
  'Certificate of Advanced Graduate Study',
  'Credential',
  'Diploma',
  'Doctor of Athletic Training',
  'Doctor of Business Administration',
  'Doctor of Chriopractic',
  'Doctor of Dental Medicine',
  'Doctor of Dental Surgery',
  'Doctor of Dentistry',
  'Doctor of Education ',
  'Doctor of Engineering',
  'Doctor of Medicine',
  'Doctor of Ministry',
  'Doctor of Nursing Practice',
  'Doctor of Occupational Therapy',
  'Doctor of Ondontology',
  'Doctor of Osteopathic Med',
  'Doctor of Pharmacy',
  'Doctor of Physical Therapy ',
  'Doctor of Planning and Development Studies',
  'Doctor of Podiatric Medicine',
  'Doctor of Science',
  'Doctor of Stomatology',
  'Doctor of Veterinary Med',
  'Doctorate in Dental Medicine',
  'Doctorate of Medicine',
  'Doctorate of Medical Arts',
  'Doctor of Theology',
  `Five-Year Bachelor's and Master's Program (BA/MA)`,
  `Five-Year Bachelor's and Master's Program (BA/MS)`,
  `Five-Year Bachelor's and Master's Program (BS/MA)`,
  `Five-Year Bachelor's and Master's Program (BS/MS)`,
  'Graduate Certificate',
  'Graduate Diploma',
  'Master of Industrial Distribution',
  'Juris Doctorate',
  'Master of Accounting ',
  'Master of Architecture',
  'Master of Arts',
  'Master of Arts in Teaching',
  'Master of Building Science',
  'Master of Business Administration',
  'Master of Business Taxation',
  'Master of Comparative Law',
  'Master of Contruction Management',
  'Master of Dental Surgery ',
  'Master of Divinity',
  'Master of Education',
  'Master of Engineering',
  'Master of Engineering Technology Management',
  'Master of Fine Arts',
  'Master of Health Administration',
  'Master of Health Care Management',
  'Master of Health Science',
  'Master of Health Science Administration',
  'Master of Historic Preservation',
  'Master of Journalism',
  'Master of Landscape Architecture',
  'Master of Laws',
  'Master of Liberal Arts',
  'Master of Long Term Care Administration',
  'Master of Management',
  'Master of Marriage and Family Therapy',
  'Master of Medical Management',
  'Master of Medical Science',
  'Master of Music',
  'Master of Music Education',
  'Master of Music Therapy',
  'Master of Occupational Therapy',
  'Master of Physician Assistant Practice',
  'Master of Planning',
  'Master of Planning and Development Studies',
  'Master of Professional Writing',
  'Master of Public Administratration',
  'Master of Public Art Studies',
  'Master of Public Diplomacy',
  'Master of Public Health',
  'Master of Public Policy',
  'Master of Public Policy and Management',
  'Master of Real Estate Development',
  'Master of Sacred Theology',
  'Master of Sacred Music',
  'Master of Science',
  'Master of Science and Health Administration',
  'Master of Science in Athletic Training',
  'Master of Science in Bioengineering',
  'Master of Science in Civil Engineering',
  'Master of Science in Dentistry  ',
  'Master of Science in Education',
  'Master of Science in Electrical Engineering',
  'Master of Science in Environmental Engineering',
  'Master of Science in Mechanical Engineering',
  'Master of Science in Nursing',
  'Science in Prosthetics and Orthotics',
  'Master of Science in Prosthetics and Orthotics',
  'Master of Social Work',
  'Master of Theological Studies',
  'Master of Theology',
  'Master of Tourism and Hospitality Management',
  'Master of Veterinary Science',
  'MS in Management Studies',
  'MS in Finance',
  'MS in Mathematical Finance',
  'Non Degree',
  'None',
  'Ph.D.',
  'Post-Baccalaureate Certificate',
  'Post-Baccalaureate Certificate in Integrated Animal Health Sciences',
  'Post-baccalaureate Non-degree Teachers Cert Prog.',
  'Postgraduate Diploma',
  `Professional Science Master's`,
  'PsyD',
  'Specialist In Education',
  'Other Bachelor',
  'Other Doctoral (Not Ph.D)',
  'Other Doctorate',
  'Other Master',
  'undefined',
];

export const minorOption = [
  '',
  'No Answer',
  'None',
  'Accounting/Finance',
  'Administration',
  'Administration of Justice',
  'Advertising',
  'Aerospace Engineering',
  'African Studies',
  'Agriculture',
  'Agriculture Animal Sciences',
  'Agriculture Business',
  'Agriculture Plant Sciences',
  'Agronomy',
  'Allied Health',
  'American Studies',
  'Anatomy',
  'Anesthesia Tech',
  'Anesthesiology',
  'Animal Science',
  'Animation and Digital Arts',
  'Anthropology',
  'Apparel Merchandising',
  'Applied Mathematics',
  'Applied Physics',
  'Applied Studies',
  'Arabic',
  'Archaeology',
  'Architectural Engineering',
  'Architecture',
  'Art',
  'Art Education',
  'Art History',
  'Asian Studies',
  'Asian-American Studies',
  'Astronautical Engineering',
  'Astronomy',
  'Athletic Training',
  'Audiology',
  'Automation',
  'Aviation',
  'Bacteriology',
  'Behavioral Science',
  'Biochemical Engineering',
  'Biochemistry',
  'Biochemistry and Molecular Biology',
  'Bioengineering',
  'Bioinformatics',
  'Biological Sciences',
  'Biology',
  'Biomathematics',
  'Biomedical Engineering',
  'Biomedical Physics',
  'Biomedical Science',
  'Biophysics',
  'Biostatistics',
  'Biotechnology',
  'Botany',
  'Broadcast Arts',
  'Business Administration',
  'Business Administration 2.0',
  'Business Information Systems',
  'Business/Management',
  'Central American Studies',
  'Chemical Engineering',
  'Chemistry',
  'Chicano/Latino Studies',
  'Child and Adolescent Development',
  'Child Development',
  'Chinese Language and Literature',
  'Chiropractic',
  'Cinematic Arts',
  'City and Regional Planning',
  'Civil Engineering',
  'Clarinet Performance',
  'Classics',
  'Clinical Psychology',
  'Coastal Sciences',
  'Cognitive Studies',
  'Communication Engineering',
  'Communication Science & Disorders',
  'Communication Studies',
  'Communications',
  'Community Development',
  'Community Health',
  'Comparative Literature',
  'Composition',
  'Computer',
  'Computer Engineering',
  'Computer Science',
  'Computer Science and Engineering',
  'Computer Science and Technology',
  'Computer Tomography',
  'Concrete Industry Management',
  'Conflict Resolution',
  'Construction Management',
  'Counseling',
  'Creative Writing',
  'Criminal Justice',
  'Criminology',
  'Culinary/Food Studies',
  'Cultural Resources Management',
  'Cybersecurity',
  'Dairy Science',
  'Dance',
  'Decision Sciences',
  'Deaf Studies',
  'Demography',
  'Dental Assistant',
  'Dental Hygiene',
  'Dentistry',
  'Dietetics',
  'Early Childhood Education',
  'Earth Sciences',
  'East Asian Studies',
  'Ecology',
  'Economic Policy',
  'Economics',
  'Education',
  'Electrical and Electronic Engineering',
  'Electrical Engineering',
  'Electrical Engineering and Automation',
  'Electronic and Computer Engineering',
  'Electronic Engineering',
  'Electronic Information Engineering',
  'Electronic Information Science and Technology',
  'Electronic Science and Technology',
  'Electronics',
  'Electronics and Communication',
  'Electronics and Communication Engineering',
  'Electronics and Instrumentation',
  'Electronics and Telecommunications',
  'Elementary Education',
  'Elementary Teacher Education',
  'Emergency Med-Tech',
  'Emergency Services Administration',
  'Energy',
  'Engineering',
  'Engineering Physics',
  'English',
  'Enology',
  'Entomology',
  'Environmental Engineering',
  'Environmental Health',
  'Environmental Management',
  'Environmental Science',
  'Environmental Studies',
  'Epidemiology',
  'Ergonomics',
  'Ethics',
  'Ethnic Studies',
  'European Studies',
  'Exercise Science',
  'Facilities Engineering Technology',
  'Family and Consumer Sciences',
  'Fashion Design',
  'Film',
  'Film, Television, and Electronic Media',
  'Finance',
  'Financial Engineering',
  'Fine Arts',
  'Fire Protection',
  'Fisheries Biology',
  'Folklore',
  'Food Science',
  'Foreign Language',
  'Forestry',
  'French',
  'Gender Studies',
  'General Studies',
  'Genetic Counseling',
  'Genetics',
  'Geographic Information Systems',
  'Geography',
  'Geology',
  'Geomatics Engineering',
  'Geophysics',
  'Geosciences',
  'Gerontology',
  'German',
  'Global Development Economics',
  'Global Studies',
  'Global Supply Chain Management',
  'Government',
  'Graphic Communication',
  'Graphic Design',
  'Health',
  'Health Administration',
  'Health Communication',
  'Health Education',
  'Health Information Management',
  'Health Science',
  'Hematology',
  'Hispanic Language & Literature',
  'Histology',
  'History',
  'History of Art & Architecture',
  'History/Preservation',
  'History Of Science, History Of Medicine',
  'Histotechnology',
  'Hospitality Management',
  'Human Biology',
  'Human Development',
  'Human Resources',
  'Human Services',
  'Human Sexuality',
  'Humanities',
  'Immunology',
  'Independent/Interdisiplinary Studies',
  'Industrial and Systems Engineering',
  'Industrial Arts',
  'Industrial Design',
  'Industrial Distribution',
  'Industrial Economics',
  'Industrial Engineering',
  'Industrial Pharmacy',
  'Industrial Psychology',
  'Industrial Technology',
  'Informatics',
  'Information Engineering',
  'Information Science & Engineering',
  'Information Sciences',
  'Information Security',
  'Information Systems',
  'Information Technology',
  'Integrated Animal Health Sciences',
  'Integrative Biology',
  'Interdisciplinary Engineering',
  'Interdisciplinary Studies',
  'Interior Design',
  'International Business',
  'International Health',
  'International Relations',
  'International Studies',
  'Internet of Things Engineering',
  'Italian',
  'Japanese',
  'Jewish Studies',
  'Journalism',
  'Kinesiology',
  'Labor Studies',
  'Laboratory Technology',
  'Landscape Architecture',
  'Latin American Studies',
  'Law',
  'Law, Public Policy, and Society',
  'Liberal Arts',
  'Liberal Studies',
  'Library Science',
  'Linguistics',
  'Literature',
  'Magnetic Resonance Imaging',
  'Livestock Systems Health',
  'Management',
  'Manufacturing Engineering',
  'Marine Biology',
  'Marine Engineering Technology',
  'Marine Science',
  'Marine Transportation',
  'Marriage and Family Therapy',
  'Marketing',
  'Massage Therapy',
  'Materials Science',
  'Mathematics',
  'Mechanical Design',
  'Mechanical Engineering',
  'Mechatronics',
  'Media Design',
  'Media Technology',
  'Medical Laboratory Tech',
  'Medical Physics',
  'Medical Product Development Management',
  'Medical Assistant',
  'Medical Tech',
  'Medicine',
  'Meteorology',
  'Microbiology',
  'Microelectronics',
  'Military Science',
  'Molecular Biology',
  'Molecular Biology, Cell Biology & Biochemistry',
  'Mortuary',
  'Multimedia',
  'Music',
  'Music Education',
  'Music Industry and Technology',
  'Music Performance',
  'Musical Theatre',
  'Museum Studies',
  'Musicology',
  'National Cybersecurity Studies',
  'National Security Studies/Homeland Security',
  'Native American Studies',
  'Natural Science',
  'Naval Engineering',
  'Neurobiology',
  'Neurology',
  'Neuropsychology',
  'Neuroscience',
  'Nuclear Engineering',
  'Nurse Practitioner',
  'Nursing',
  'Nutrition',
  'Nutrition and Dietetics',
  'Nutritional Sciences',
  'Occupational Therapy',
  'Occupational Therapy Assistant',
  'Oceanography',
  'Optometry',
  'Organismic Biology',
  'Organizational Development',
  'Osteopathic Medicine',
  'Paramedic',
  'Parasitology',
  'Pathology',
  'Performance',
  'Performing Arts',
  'Petroleum Engineering',
  'Pharmaceutical Sciences',
  'Pharmacology',
  'Pharmacy',
  'Philosophy',
  'Photography',
  'Physical Education',
  'Physical Science',
  'Physical Therapist Assistant',
  'Physical Therapy',
  'Physical Therapy - Pre-PT',
  'Physician Assistant',
  'Physics',
  'Physiology',
  'Podiatry',
  'Policy Studies',
  'Political Science',
  'Polymers and Coatings',
  'Practical Nursing',
  'Premedical',
  'Pre-Professional',
  'Preservation Studies',
  'Psychobiology',
  'Psychology',
  'Public Administration',
  'Public Health',
  'Public Health Science',
  'Public Policy',
  'Public Relations',
  'Quality Assurance',
  'Quantitative Methods, Analysis, Business Analytics',
  'Radiation Therapy',
  'Radiology',
  'Radiography',
  'Rangeland Resource Science',
  'Recreation Administration',
  'Recreational Therapy',
  'Regenerative Studies',
  'Regulatory Affairs',
  'Rehabilitation Counseling',
  'Rehabilitation Science',
  'Religion',
  'Religious Studies',
  'Remote Sensing & Geospatial Sciences',
  'Research Methods',
  'Respiratory Therapy',
  'Russian',
  'Russian and Central European Studies',
  'Science, Technology and Society',
  'Screenwriting',
  'Social Justice Studies',
  'Social Policy',
  'Social Sciences',
  'Social Welfare',
  'Social Work',
  'Social Work and Human Services',
  'Sociology',
  'Software Engineering',
  'Spanish',
  'Special Education',
  'Speech',
  'Sports Medicine',
  'Statistical Practice',
  'Statistics',
  'Structural Engineering',
  'Studio Arts',
  'Sustainable Manufacturing',
  'Taxation',
  'Teaching English to Speakers of Other Languages',
  'Technical/Professional Writing',
  'Telecommunication Engineering',
  'Theater Arts',
  'Theology',
  'Toxicology',
  'Traffic & Transportation Engineering',
  'Urban Affairs',
  'Urban Planning',
  'Veterinary Medicine',
  'Veterinary Tech',
  'Virology',
  'Visual and Performing Arts',
  'Viticulture',
  'Water Resource Management',
  'Wildlife Management',
  `Women's Studies`,
  'World Languages and Cultures',
  'Writing',
  'Zoology',
  'Other Health Sciences Major',
  'Other Life Sciences Major',
  'Other Physical Sciences Major',
  'Other Social Sciences Major',
  'Other/Not Listed',
  'undefined',
];

export const majorOption = [
  '',
  'No Answer',
  'None',
  'Accounting/Finance',
  'Administration',
  'Administration of Justice',
  'Advertising',
  'Aerospace Engineering',
  'African Studies',
  'Agriculture',
  'Agriculture Animal Sciences',
  'Agriculture Business',
  'Agriculture Plant Sciences',
  'Agronomy',
  'Allied Health',
  'American Studies',
  'Anatomy',
  'Anesthesia Tech',
  'Anesthesiology',
  'Animal Science',
  'Animation and Digital Arts',
  'Anthropology',
  'Apparel Merchandising',
  'Applied Mathematics',
  'Applied Physics',
  'Applied Studies',
  'Arabic',
  'Archaeology',
  'Architectural Engineering',
  'Architecture',
  'Art',
  'Art Education',
  'Art History',
  'Asian Studies',
  'Asian-American Studies',
  'Astronautical Engineering',
  'Astronomy',
  'Athletic Training',
  'Audiology',
  'Automation',
  'Aviation',
  'Bacteriology',
  'Behavioral Science',
  'Biochemical Engineering',
  'Biochemistry',
  'Biochemistry and Molecular Biology',
  'Bioengineering',
  'Bioinformatics',
  'Biological Sciences',
  'Biology',
  'Biomathematics',
  'Biomedical Engineering',
  'Biomedical Physics',
  'Biomedical Science',
  'Biophysics',
  'Biostatistics',
  'Biotechnology',
  'Botany',
  'Broadcast Arts',
  'Business Administration',
  'Business Administration 2.0',
  'Business Information Systems',
  'Business/Management',
  'Central American Studies',
  'Chemical Engineering',
  'Chemistry',
  'Chicano/Latino Studies',
  'Child and Adolescent Development',
  'Child Development',
  'Chinese Language and Literature',
  'Chiropractic',
  'Cinematic Arts',
  'City and Regional Planning',
  'Civil Engineering',
  'Clarinet Performance',
  'Classics',
  'Clinical Psychology',
  'Coastal Sciences',
  'Cognitive Studies',
  'Communication Engineering',
  'Communication Science & Disorders',
  'Communication Studies',
  'Communications',
  'Community Development',
  'Community Health',
  'Comparative Literature',
  'Composition',
  'Computer',
  'Computer Engineering',
  'Computer Science',
  'Computer Science and Engineering',
  'Computer Science and Technology',
  'Computer Tomography',
  'Concrete Industry Management',
  'Conflict Resolution',
  'Construction Management',
  'Counseling',
  'Creative Writing',
  'Criminal Justice',
  'Criminology',
  'Culinary/Food Studies',
  'Cultural Resources Management',
  'Cybersecurity',
  'Dairy Science',
  'Dance',
  'Decision Sciences',
  'Deaf Studies',
  'Demography',
  'Dental Assistant',
  'Dental Hygiene',
  'Dentistry',
  'Dietetics',
  'Early Childhood Education',
  'Earth Sciences',
  'East Asian Studies',
  'Ecology',
  'Economic Policy',
  'Economics',
  'Education',
  'Electrical and Electronic Engineering',
  'Electrical Engineering',
  'Electrical Engineering and Automation',
  'Electronic and Computer Engineering',
  'Electronic Engineering',
  'Electronic Information Engineering',
  'Electronic Information Science and Technology',
  'Electronic Science and Technology',
  'Electronics',
  'Electronics and Communication',
  'Electronics and Communication Engineering',
  'Electronics and Instrumentation',
  'Electronics and Telecommunications',
  'Elementary Education',
  'Elementary Teacher Education',
  'Emergency Med-Tech',
  'Emergency Services Administration',
  'Energy',
  'Engineering',
  'Engineering Physics',
  'English',
  'Enology',
  'Entomology',
  'Environmental Engineering',
  'Environmental Health',
  'Environmental Management',
  'Environmental Science',
  'Environmental Studies',
  'Epidemiology',
  'Ergonomics',
  'Ethics',
  'Ethnic Studies',
  'European Studies',
  'Exercise Science',
  'Facilities Engineering Technology',
  'Family and Consumer Sciences',
  'Fashion Design',
  'Film',
  'Film, Television, and Electronic Media',
  'Finance',
  'Financial Engineering',
  'Fine Arts',
  'Fire Protection',
  'Fisheries Biology',
  'Folklore',
  'Food Science',
  'Foreign Language',
  'Forestry',
  'French',
  'Gender Studies',
  'General Studies',
  'Genetic Counseling',
  'Genetics',
  'Geographic Information Systems',
  'Geography',
  'Geology',
  'Geomatics Engineering',
  'Geophysics',
  'Geosciences',
  'Gerontology',
  'German',
  'Global Development Economics',
  'Global Studies',
  'Global Supply Chain Management',
  'Government',
  'Graphic Communication',
  'Graphic Design',
  'Health',
  'Health Administration',
  'Health Communication',
  'Health Education',
  'Health Information Management',
  'Health Science',
  'Hematology',
  'Hispanic Language & Literature',
  'Histology',
  'History',
  'History of Art & Architecture',
  'History/Preservation',
  'History Of Science, History Of Medicine',
  'Histotechnology',
  'Hospitality Management',
  'Human Biology',
  'Human Development',
  'Human Resources',
  'Human Services',
  'Human Sexuality',
  'Humanities',
  'Immunology',
  'Independent/Interdisiplinary Studies',
  'Industrial and Systems Engineering',
  'Industrial Arts',
  'Industrial Design',
  'Industrial Distribution',
  'Industrial Economics',
  'Industrial Engineering',
  'Industrial Pharmacy',
  'Industrial Psychology',
  'Industrial Technology',
  'Informatics',
  'Information Engineering',
  'Information Science & Engineering',
  'Information Sciences',
  'Information Security',
  'Information Systems',
  'Information Technology',
  'Integrated Animal Health Sciences',
  'Integrative Biology',
  'Interdisciplinary Engineering',
  'Interdisciplinary Studies',
  'Interior Design',
  'International Business',
  'International Health',
  'International Relations',
  'International Studies',
  'Internet of Things Engineering',
  'Italian',
  'Japanese',
  'Jewish Studies',
  'Journalism',
  'Kinesiology',
  'Labor Studies',
  'Laboratory Technology',
  'Landscape Architecture',
  'Latin American Studies',
  'Law',
  'Law, Public Policy, and Society',
  'Liberal Arts',
  'Liberal Studies',
  'Library Science',
  'Linguistics',
  'Literature',
  'Magnetic Resonance Imaging',
  'Livestock Systems Health',
  'Management',
  'Manufacturing Engineering',
  'Marine Biology',
  'Marine Engineering Technology',
  'Marine Science',
  'Marine Transportation',
  'Marriage and Family Therapy',
  'Marketing',
  'Massage Therapy',
  'Materials Science',
  'Mathematics',
  'Mechanical Design',
  'Mechanical Engineering',
  'Mechatronics',
  'Media Design',
  'Media Technology',
  'Medical Laboratory Tech',
  'Medical Physics',
  'Medical Product Development Management',
  'Medical Assistant',
  'Medical Tech',
  'Medicine',
  'Meteorology',
  'Microbiology',
  'Microelectronics',
  'Military Science',
  'Molecular Biology',
  'Molecular Biology, Cell Biology & Biochemistry',
  'Mortuary',
  'Multimedia',
  'Music',
  'Music Education',
  'Music Industry and Technology',
  'Music Performance',
  'Musical Theatre',
  'Museum Studies',
  'Musicology',
  'National Cybersecurity Studies',
  'National Security Studies/Homeland Security',
  'Native American Studies',
  'Natural Science',
  'Naval Engineering',
  'Neurobiology',
  'Neurology',
  'Neuropsychology',
  'Neuroscience',
  'Nuclear Engineering',
  'Nurse Practitioner',
  'Nursing',
  'Nutrition',
  'Nutrition and Dietetics',
  'Nutritional Sciences',
  'Occupational Therapy',
  'Occupational Therapy Assistant',
  'Oceanography',
  'Optometry',
  'Organismic Biology',
  'Organizational Development',
  'Osteopathic Medicine',
  'Paramedic',
  'Parasitology',
  'Pathology',
  'Performance',
  'Performing Arts',
  'Petroleum Engineering',
  'Pharmaceutical Sciences',
  'Pharmacology',
  'Pharmacy',
  'Philosophy',
  'Photography',
  'Physical Education',
  'Physical Science',
  'Physical Therapist Assistant',
  'Physical Therapy',
  'Physical Therapy - Pre-PT',
  'Physician Assistant',
  'Physics',
  'Physiology',
  'Podiatry',
  'Policy Studies',
  'Political Science',
  'Polymers and Coatings',
  'Practical Nursing',
  'Premedical',
  'Pre-Professional',
  'Preservation Studies',
  'Psychobiology',
  'Psychology',
  'Public Administration',
  'Public Health',
  'Public Health Science',
  'Public Policy',
  'Public Relations',
  'Quality Assurance',
  'Quantitative Methods, Analysis, Business Analytics',
  'Radiation Therapy',
  'Radiology',
  'Radiography',
  'Rangeland Resource Science',
  'Recreation Administration',
  'Recreational Therapy',
  'Regenerative Studies',
  'Regulatory Affairs',
  'Rehabilitation Counseling',
  'Rehabilitation Science',
  'Religion',
  'Religious Studies',
  'Remote Sensing & Geospatial Sciences',
  'Research Methods',
  'Respiratory Therapy',
  'Russian',
  'Russian and Central European Studies',
  'Science, Technology and Society',
  'Screenwriting',
  'Social Justice Studies',
  'Social Policy',
  'Social Sciences',
  'Social Welfare',
  'Social Work',
  'Social Work and Human Services',
  'Sociology',
  'Software Engineering',
  'Spanish',
  'Special Education',
  'Speech',
  'Sports Medicine',
  'Statistical Practice',
  'Statistics',
  'Structural Engineering',
  'Studio Arts',
  'Sustainable Manufacturing',
  'Taxation',
  'Teaching English to Speakers of Other Languages',
  'Technical/Professional Writing',
  'Telecommunication Engineering',
  'Theater Arts',
  'Theology',
  'Toxicology',
  'Traffic & Transportation Engineering',
  'Urban Affairs',
  'Urban Planning',
  'Veterinary Medicine',
  'Veterinary Tech',
  'Virology',
  'Visual and Performing Arts',
  'Viticulture',
  'Water Resource Management',
  'Wildlife Management',
  `Women's Studies`,
  'World Languages and Cultures',
  'Writing',
  'Zoology',
  'Other Health Sciences Major',
  'Other Life Sciences Major',
  'Other Physical Sciences Major',
  'Other Social Sciences Major',
  'Other/Not Listed',
  'undefined',
];



//#region When did you earn that degree?
const years = {
  start: 1950,
  end: 2030,
}

export const earnDegreeYears = [
  '- Select -',
  ...optionValues(Array.from({ length: (years.end + 1 - years.start)}, (_, i) => (i + years.start) )),
];
//#endregion
