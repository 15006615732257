/* eslint-disable react-hooks/exhaustive-deps */
import LoadingContent from 'components/LoadingContent';
import { useFormik } from 'formik';
import React from 'react';
import TopProcessBar from 'screen/selectCollege/TopProcessBar';
import { Container, Form, Row, Spinner, Button } from 'react-bootstrap';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import Cookies from 'js-cookie';
import StackedLineChartIcon from '@mui/icons-material/StackedLineChart';
import { useHistory } from 'react-router-dom';
import { routers } from 'router/router';
import { logout } from 'app/basicInformationSlice/basicInformationSlice';
import { authenticateSettings } from 'components/helper/appConfig';
import YesNoSwitch from 'components/input/YesNoSwitch';
import TextInput from 'components/input/textInput';
import * as Yup from 'yup';
import SelectInput from 'components/input/SelectInput';
import GroupRadioButton from 'components/input/GroupRadioButton';
import TextAreaInput from 'components/input/TextAreaInput';
import { checkEnableParseJSON } from 'components/helper/helper';
import { debounce } from 'lodash';
import {
  getEducationalProgram,
  updateEducationalProgram,
} from 'app/activitiesAndHonors/activitiesAndHonorsSlice';
const rules = Yup.object({
  year_participated_in_AVID: Yup.number().min(1, 'Invalid').max(99, 'Invalid'),
  year_participated_in_upward_bound: Yup.number().min(1, 'Invalid').max(99, 'Invalid'),
  year_participated_in_talent_search: Yup.number().min(1, 'Invalid').max(99, 'Invalid'),
  year_participated_in_puente_project: Yup.number().min(1, 'Invalid').max(99, 'Invalid'),
  year_participated_in_ilp: Yup.number().min(1, 'Invalid').max(99, 'Invalid'),
  year_participated_in_MESA_project: Yup.number().min(1, 'Invalid').max(99, 'Invalid'),
  year_participated_in_other_program: Yup.number().min(1, 'Invalid').max(99, 'Invalid'),
  number_brothers_and_sisters_k12: Yup.number().min(0, 'Invalid').integer('Invalid'),
  number_brothers_and_sisters_college: Yup.number().min(0, 'Invalid').integer('Invalid'),
  number_brothers_and_sisters_received_bachelor_degree: Yup.number()
    .min(0, 'Invalid')
    .integer('Invalid'),
  number_year_received_income: Yup.number().min(1, 'Invalid').max(99, 'Invalid').integer('Invalid'),
});
interface Props {}

const EducationalPrograms = (props: Props) => {
  const activitiesAndHonors = useAppSelector((store) => store.activitiesAndHonors);

  const [loading, setLoading] = React.useState(false);
  let studentId = Cookies.get('userId');
  let token = Cookies.get(authenticateSettings.tokenName);
  const dispatch = useAppDispatch();
  const history = useHistory();
  const formik = useFormik({
    initialValues: {
      avid: '',
      year_participated_in_AVID: '',
      upward_bound: '',
      year_participated_in_upward_bound: '',
      talent_search: '',
      year_participated_in_talent_search: '',
      puente_project: '',
      year_participated_in_puente_project: '',
      ilp: '',
      year_participated_in_ilp: '',
      MESA_project: '',
      year_participated_in_MESA_project: '',
      other_program: '',
      year_participated_in_other_program: '',
      average_hours_worked_per_week: '',
      is_more_25_percent_work_related_major: '',
      average_hours_activities_per_week: '',
      is_leadership_positions: '',
      wish_to_apply_EOP: '',
      is_enrolled_EOP: '',
      where_plan_to_live: '',
      number_brothers_and_sisters_k12: '',
      number_brothers_and_sisters_college: '',
      number_brothers_and_sisters_received_bachelor_degree: '',
      languages_spoken_in_home: '',
      is_received_income_from_public_assistance_program: '',
      number_year_received_income: '',
      type_of_aid: '',
      is_participated_in_publicly_funded_programs: '',
      publicly_funded_programs: '',
      is_work_primarily_to_contribute: '',
      work_primarily_to_contribute: '',
      campus_enrolled_EOP: '',
    },
    validationSchema: rules,
    onSubmit: async (values) => {
      studentId = Cookies.get('userId');
      token = Cookies.get(authenticateSettings.tokenName);
      if (token && studentId) {
        const newValues = {
          csu_info: JSON.stringify(values),
          student_id: studentId,
        };
        await dispatch(updateEducationalProgram(newValues));        
      }
      else {
        handleLogout();
      }
    },
  });

  const handleLogout = () => {
    dispatch(logout());
    Cookies.remove(authenticateSettings.tokenName);
    Cookies.remove('userId');
    history.push(routers.login.path);
  };  

  const handleContinue = () => {
    formik.handleSubmit();
    history.push(routers.additionalInfo.path);
  };

  const getDetail = async () => {
    studentId = Cookies.get('userId');
    token = Cookies.get(authenticateSettings.tokenName);
    if (token && studentId) {
      setLoading(true);
      let res = await dispatch(getEducationalProgram(studentId));
      const dataLoaded: any = res.payload;
      if (dataLoaded) {
        const values: any = checkEnableParseJSON(dataLoaded.csu_info);
        formik.setValues({ ...formik.values, ...values });
      }
      setLoading(false);
      formik.setStatus('idle');
    }
    else {
      handleLogout();
    }
  };

  React.useEffect(() => {
    getDetail();
  }, []);
  // Auto save function ==========
  React.useEffect(() => {
    debounceSaving();
  }, [formik.values]);
  const debounceSaving = React.useCallback(
    debounce(() => {
      if (!loading) formik.handleSubmit();
    }, 1500),
    []
  );
  // Auto save function ==========
  return loading ? (
    <LoadingContent />
  ) : (
    <>
      <TopProcessBar
        stepProcess={[{ percentOnProcess: 30, title: '' }]}
        title="Educational Programs Participation"
        process={1}
        icon={<StackedLineChartIcon />}
      />

      <Container className="main-container">
        <div className="px-2">
          <Form style={{ maxWidth: '900px' }} onSubmit={formik.handleSubmit}>
            {/* <Row className="m-0">
              <div className="text-field-mid fs-3 mb-4">Educational Programs Participation</div>
              <div className="text-dart">
                Have you participated in any of these programs in high school and beyond?
              </div>
              <YesNoSwitch
                logo="span-csu-logo"
                label="AVID"
                values={formik.values.avid}
                name="avid"
                onChange={formik.handleChange}
              />
              {formik.values.avid === 'true' ? (
                <TextInput
                  logo="span-csu-logo"
                  type="number"
                  mdCol={12}
                  label="Number of years you participated in AVID during High School:"
                  values={formik.values.year_participated_in_AVID}
                  name="year_participated_in_AVID"
                  onChange={formik.handleChange}
                  feedback={
                    <span className="text-danger">{formik.errors.year_participated_in_AVID}</span>
                  }
                />
              ) : (
                <></>
              )}
              <YesNoSwitch
                logo="span-csu-logo"
                label="Upward Bound"
                values={formik.values.upward_bound}
                name="upward_bound"
                onChange={formik.handleChange}
              />
              {formik.values.upward_bound === 'true' ? (
                <TextInput
                  logo="span-csu-logo"
                  type="number"
                  mdCol={12}
                  label="Number of years you participated in Upward Bound during High School:"
                  values={formik.values.year_participated_in_upward_bound}
                  name="year_participated_in_upward_bound"
                  onChange={formik.handleChange}
                  feedback={
                    <span className="text-danger">
                      {formik.errors.year_participated_in_upward_bound}
                    </span>
                  }
                />
              ) : (
                <></>
              )}
              <YesNoSwitch
                logo="span-csu-logo"
                label="Talent Search or Educational Opportunity Center (EOC)"
                values={formik.values.talent_search}
                name="talent_search"
                onChange={formik.handleChange}
              />
              {formik.values.talent_search === 'true' ? (
                <TextInput
                  logo="span-csu-logo"
                  type="number"
                  mdCol={12}
                  label="Number of years you participated in Talent Search or Educational Opportunity Center (EOC) during high school"
                  values={formik.values.year_participated_in_talent_search}
                  name="year_participated_in_talent_search"
                  onChange={formik.handleChange}
                  feedback={
                    <span className="text-danger">
                      {formik.errors.year_participated_in_talent_search}
                    </span>
                  }
                />
              ) : (
                <></>
              )}
              <YesNoSwitch
                logo="span-csu-logo"
                label="Puente Project"
                values={formik.values.puente_project}
                name="puente_project"
                onChange={formik.handleChange}
              />
              {formik.values.puente_project === 'true' ? (
                <TextInput
                  logo="span-csu-logo"
                  type="number"
                  mdCol={12}
                  label="Number of years you participated in Puente Project"
                  values={formik.values.year_participated_in_puente_project}
                  name="year_participated_in_puente_project"
                  onChange={formik.handleChange}
                  feedback={
                    <span className="text-danger">
                      {formik.errors.year_participated_in_puente_project}
                    </span>
                  }
                />
              ) : (
                <></>
              )}
              <YesNoSwitch
                logo="span-csu-logo"
                label="Independent Living Project (ILP)"
                values={formik.values.ilp}
                name="ilp"
                onChange={formik.handleChange}
              />
              {formik.values.ilp === 'true' ? (
                <TextInput
                  logo="span-csu-logo"
                  type="number"
                  mdCol={12}
                  label="Number of years you participated in Independent Living Project (ILP)"
                  values={formik.values.year_participated_in_ilp}
                  name="year_participated_in_ilp"
                  onChange={formik.handleChange}
                  feedback={
                    <span className="text-danger">{formik.errors.year_participated_in_ilp}</span>
                  }
                />
              ) : (
                <></>
              )}
              <YesNoSwitch
                logo="span-csu-logo"
                label="MESA Project"
                values={formik.values.MESA_project}
                name="MESA_project"
                onChange={formik.handleChange}
              />
              {formik.values.MESA_project === 'true' ? (
                <TextInput
                  logo="span-csu-logo"
                  type="number"
                  mdCol={12}
                  label="Number of years you participated in MESA Project"
                  values={formik.values.year_participated_in_MESA_project}
                  name="year_participated_in_MESA_project"
                  onChange={formik.handleChange}
                  feedback={
                    <span className="text-danger">
                      {formik.errors.year_participated_in_MESA_project}
                    </span>
                  }
                />
              ) : (
                <></>
              )}
              <YesNoSwitch
                logo="span-csu-logo"
                label="Other (CAMP, EAOP, EOP&S/CARE GEAR-UP, TRIO, or Foster youth support programs)"
                values={formik.values.other_program}
                name="other_program"
                onChange={formik.handleChange}
              />
              {formik.values.other_program === 'true' ? (
                <TextInput
                  logo="span-csu-logo"
                  type="number"
                  mdCol={12}
                  label="Number of years you participated in Gear-up, CAMP, Foster youth support programs, Student Support Services Programs (SSSP)"
                  values={formik.values.year_participated_in_other_program}
                  name="year_participated_in_other_program"
                  onChange={formik.handleChange}
                  feedback={
                    <span className="text-danger">
                      {formik.errors.year_participated_in_other_program}
                    </span>
                  }
                />
              ) : (
                <></>
              )}

              <div className="border w-100 my-3" />
            </Row> */}

            <Row className="m-0">
              <div className="text-dart fs-5 p-1">Work Information</div>
              <SelectInput
                logo="span-csu-logo"
                mdCol="12"
                option={[
                  'Select',
                  '0 hours',
                  '1-5 hours',
                  '6-10 hours',
                  '11-15 hours',
                  '16-20 hours',
                  '21 or more hours',
                ]}
                label="What was the average number of hours worked per week over the most recent 36 months?"
                name="average_hours_worked_per_week"
                onChange={formik.handleChange}
                values={formik.values.average_hours_worked_per_week}
              />
              <YesNoSwitch
                logo="span-csu-logo"
                hidden={
                  formik.values.average_hours_worked_per_week == 'Select' 
                }
                label="Was more than 25% of your work hours related to your major of choice?"
                name="is_more_25_percent_work_related_major"
                onChange={formik.handleChange}
                values={formik.values.is_more_25_percent_work_related_major}
              />
              <div className="border w-100 my-3" />
            </Row>
            <Row className="m-0">
              <div className="text-dart fs-5 p-1">
                Extracurricular Programs Hours of Participation and Leadership
              </div>
              <div className="text-field-light">
                Extracurricular Programs and leadership participation includes activities such as
                arts, Asian American Club, Boy Scouts/Girl Scouts, Boy’s Week/Girl’s Week,
                cheerleading, drama/theater, inter-scholastic athletics, marching band, MEChA, Pride
                Club, science club, student government, yearbook, Young Democrats/Young Republicans
                and all other extracurricular activities.
              </div>
              <SelectInput
                logo="span-csu-logo"
                mdCol="12"
                option={[
                  'Select',
                  '0 hours',
                  '1-5 hours',
                  '6-10 hours',
                  '11-15 hours',
                  '16-20 hours',
                  '21 or more hours',
                ]}
                label="Select the average number of hours per week you are/were involved in activities during the last 36 months."
                name="average_hours_activities_per_week"
                onChange={formik.handleChange}
                values={formik.values.average_hours_activities_per_week}
              />
              <YesNoSwitch
                logo="span-csu-logo"
                hidden={
                  formik.values.average_hours_worked_per_week === 'Select' ||
                  formik.values.average_hours_worked_per_week === '0 hours' ||
                  formik.values.average_hours_worked_per_week === ''
                }
                label="Did you hold or have you held any leadership positions in those activities, e.g. class president, club president, team captain, yearbook editor, etc.?"
                name="is_leadership_positions"
                onChange={formik.handleChange}
                values={formik.values.is_leadership_positions}
              />
              <div className="border w-100 my-3" />
            </Row>

            <Row className="m-0">
              <div className="text-dart fs-5 p-1">Important Reminder</div>
              <div className="text-field-light my-2">
                Applicants may complete and submit their Cal State Apply application and then later
                return to complete the EOP portion of the application. To ensure that you submit
                your EOP application on time, please refer to the EOP Admission by Term page for
                campus specific information.
              </div>

              <div className="text-field-light my-2">
                If you are applying to EOP after you have already submitted your main application,
                you must return to the dashboard and click on the blue 'submit my changes' button in
                order for your updates to be received by Cal State Apply staff. Only updates to your
                EOP application will be considered after your initial submission.
              </div>

              <div className="text-dart">Educational Opportunity Program (EOP)</div>
              <div className="text-field-light my-2">
                The primary goal of the CSU Educational Opportunity Program is to improve access of
                low-income, first-generation, and historically disadvantaged students from
                California with potential for academic success by making higher education a
                possibility.
              </div>
              <div className="text-field-light my-2">
                Visit the EOP Admissions site to learn more about EOP, eligibility criteria, and to
                view EOP application deadlines.
              </div>
              <div className="text-field-light my-2">
                Select Yes to apply to EOP. Your EOP application is not considered complete until
                you complete the three EOP sections and provide two recommenders.
              </div>
              <div className="text-field-light my-2">
                Visit the Applicant Help Center for more information.
              </div>
              <div className="text-field-light my-2">
                If you have yet to submit any applications for fall 2023, you must complete all EOP
                sections below before submitting your application. You cannot return later to
                complete these EOP questions. EOP recommendations are due two-weeks after the campus
                deadline.
              </div>
              <div className="text-field-light my-2">
                If you already submitted a campus application and would like to apply to an EOP
                program that is still accepting EOP applications, contact the campus EOP office
                directly for instructions on how to apply.
              </div>
              <GroupRadioButton
                logo="span-csu-logo"
                mdCol={12}
                options={['Yes', 'Yes, I will return later to complete these EOP questions', 'No']}
                label="Do you wish to apply to the Educational Opportunity Program (EOP)?"
                name="wish_to_apply_EOP"
                onChange={formik.handleChange}
                values={formik.values.wish_to_apply_EOP}
              />
              <div className="text-field-light">
                Selecting No after you have saved responses on this page will result in the loss of
                your saved responses.
              </div>
              {formik.values.wish_to_apply_EOP === 'Yes' && (
                <>
                  <GroupRadioButton
                    logo="span-csu-logo"
                    mdCol={12}
                    options={['Yes', 'No', 'Not Applicable']}
                    label="If you attended a CSU were you enrolled in an EOP program?"
                    name="is_enrolled_EOP"
                    onChange={formik.handleChange}
                    values={formik.values.is_enrolled_EOP}
                  />
                  {formik.values.is_enrolled_EOP === 'Yes' && (
                    <SelectInput
                      logo="span-csu-logo"
                      option={[
                        'Bakersfield',
                        'Channel Islands',
                        'Chico',
                        'Dominguez Hills',
                        'East Bay',
                        'Fresno',
                        'Fullerton',
                        'Humboldt',
                        'Long Beach',
                        'Los Angeles',
                        'Maritime Academy',
                        'Monterey',
                        'Northridge',
                        'Pomona',
                        'Sacramento',
                        'San Bernardino',
                        'San Diego',
                        'San Francisco',
                        'San Jose',
                        'San Luis Obispo',
                        'San Marcos',
                        'Sonoma',
                        'Stanislaus',
                      ]}
                      label="Select the campus:"
                      name="campus_enrolled_EOP"
                      onChange={formik.handleChange}
                      values={formik.values.campus_enrolled_EOP}
                    />
                  )}
                  <SelectInput
                    logo="span-csu-logo"
                    option={[
                      'Select',
                      'On-Campus Housing',
                      'With Parents',
                      'Off-campus apartment or house',
                      'Other',
                    ]}
                    label={`Where do you plan to live while attending college? (Select one): ${ formik.values.is_enrolled_EOP !== 'Yes' ? "&nbsp;&nbsp;" : ""}`}
                    name="where_plan_to_live"
                    onChange={formik.handleChange}
                    values={formik.values.where_plan_to_live}
                  />

                  <TextInput
                    logo="span-csu-logo"
                    type="number"
                    decimalStep={1}
                    label="How many brothers and sisters are currently attending a K-12 school?"
                    values={formik.values.number_brothers_and_sisters_k12}
                    name="number_brothers_and_sisters_k12"
                    onChange={formik.handleChange}
                    feedback={
                      <span className="text-danger">
                        {formik.errors.number_brothers_and_sisters_k12}
                      </span>
                    }
                  />
                  <TextInput
                    logo="span-csu-logo"
                    type="number"
                    decimalStep={1}
                    label="How many brothers and sisters are currently attending or attended college?"
                    values={formik.values.number_brothers_and_sisters_college}
                    name="number_brothers_and_sisters_college"
                    onChange={formik.handleChange}
                    feedback={
                      <span className="text-danger">
                        {formik.errors.number_brothers_and_sisters_college}
                      </span>
                    }
                  />
                  <TextInput
                    logo="span-csu-logo"
                    type="number"
                    decimalStep={1}
                    label="How many brothers and sisters received a bachelor's degree?"
                    values={formik.values.number_brothers_and_sisters_received_bachelor_degree}
                    name="number_brothers_and_sisters_received_bachelor_degree"
                    onChange={formik.handleChange}
                    feedback={
                      <span className="text-danger">
                        {formik.errors.number_brothers_and_sisters_received_bachelor_degree}
                      </span>
                    }
                  />

                  <TextAreaInput
                    logo="span-csu-logo"
                    countText={60}
                    mdCol="12"
                    label="What languages are spoken in your home?"
                    values={formik.values.languages_spoken_in_home}
                    name="languages_spoken_in_home"
                    onChange={formik.handleChange}
                  />
                  <YesNoSwitch
                    logo="span-csu-logo"
                    values={formik.values.is_received_income_from_public_assistance_program}
                    name="is_received_income_from_public_assistance_program"
                    onChange={formik.handleChange}
                    label="During the past 10 years, have you or your family received any income from a public assistance program such as CalWorks, CalFresh, welfare, AFDC, TANF, Social Security, disability, etc.?"
                  />
                  {formik.values.is_received_income_from_public_assistance_program === 'true' && (
                    <>
                      <TextInput
                        logo="span-csu-logo"
                        type="number"
                        label="If yes, how many years?"
                        values={formik.values.number_year_received_income}
                        name="number_year_received_income"
                        onChange={formik.handleChange}
                        feedback={
                          <span className="text-danger">
                            {formik.errors.number_year_received_income}
                          </span>
                        }
                      />
                      <TextInput
                        logo="span-csu-logo"
                        countText={49}
                        label="Type(s) of aid:"
                        values={formik.values.type_of_aid}
                        name="type_of_aid"
                        onChange={formik.handleChange}
                      />
                    </>
                  )}

                  <YesNoSwitch
                    logo="span-csu-logo"
                    values={formik.values.is_participated_in_publicly_funded_programs}
                    name="is_participated_in_publicly_funded_programs"
                    onChange={formik.handleChange}
                    label="Have you or your family ever participated in any publicly funded programs such as subsidized housing (public or affordable housing programs, Section 8) employment/training programs (i.e., CETA), school lunch programs, etc.?"
                  />
                  {formik.values.is_participated_in_publicly_funded_programs === 'true' && (
                    <TextInput
                      logo="span-csu-logo"
                      countText={49}
                      label="If yes, list program(s):"
                      values={formik.values.publicly_funded_programs}
                      name="publicly_funded_programs"
                      onChange={formik.handleChange}
                    />
                  )}

                  <YesNoSwitch
                    logo="span-csu-logo"
                    values={formik.values.is_work_primarily_to_contribute}
                    name="is_work_primarily_to_contribute"
                    onChange={formik.handleChange}
                    label="Do you or any other children in your family work primarily to contribute to household expenses?"
                  />
                  {formik.values.is_work_primarily_to_contribute === 'true' && (
                    <TextAreaInput
                      logo="span-csu-logo"
                      countText={2500}
                      label="If yes, explain:"
                      values={formik.values.work_primarily_to_contribute}
                      name="work_primarily_to_contribute"
                      onChange={formik.handleChange}
                    />
                  )}
                </>
              )}
              <div className="border w-100 my-3" />
            </Row>
            <div className="my-3 d-flex justify-content-end border-top pt-3">
              <div className="col col-md-6 text-center text-md-end">
                <Button variant="primary" type="submit" className="p-2 px-5 mx-2">
                  {activitiesAndHonors.status === 'loading' ? (
                    <Spinner size="sm" animation="border" variant="light" />
                  ) : (
                    'Save'
                  )}
                </Button>
                <Button variant="primary" className="p-2 px-5" onClick={() => handleContinue()}>
                  Continue
                </Button>
              </div>
            </div>
          </Form>
        </div>
      </Container>
    </>
  );
};

export default EducationalPrograms;
