import React from 'react';
import { Row } from 'react-bootstrap';
import ItemContent, { CheckboxContent } from './ItemContent';
import { Card } from '@mui/material';

interface Props {
  data: any;
  is12?: boolean;
}

const CourseGrade9Summary = ({ data, is12 }: Props) => {
  return (
    <>
      {(data || []).map((item: any, index: number) => (
        <Row key={index}>
          <ItemContent
            label="Select the school for this coursework"
            mdCol={12}
            values={item?.schoolName}
          />

          <ItemContent
            mdCol={12}
            hidden={!is12}
            values={item?.have12thGradeCourses}
            label="Do you have 12th grade courses on your transcript with official grades? (If your 12th grade courses are still in progress, report them in the “Current or Most Recent Year Courses” section of your Common App)* "
          />
          <ItemContent label="School Year*" values={item?.schoolYear} />
          <ItemContent label="Schedule*" values={item?.schedule} />

          <ItemContent label="Grading Scale" values={item?.CMGradingScale} />
          {item?.subjectDetails?.map((sub: any, index: number) => {
            return (
              <Card sx={{ backgroundColor: 'white', marginTop: '16px !important' }} key={index}>
                <Row className="m-1 mx-3">
                  <CheckboxContent label="Summer Course" values={sub?.isSummerCourse} />
                  <ItemContent label="Course Name" values={sub?.courseName} />
                  <ItemContent label="A-G Subject Area" values={sub?.AGSubjectArea} />
                  <ItemContent label="Subject/Discipline" values={sub?.subject} />

                  <ItemContent label="Type of Class *" values={sub?.typeOfClass} />
                  {sub?.subject.includes('Other') && (
                    <ItemContent label="Class Name on Transcript *" values={sub?.otherClass} />
                  )}

                  <ItemContent
                    label="During which term(s) did you take this class?"
                    values={buildSemesterData(sub, item?.schedule)}
                    hidden={item?.schedule === 'Yearly'}
                  />
                  <ItemContent
                    label="How are the grades for this class reported on your transcript?"
                    values={sub?.schedule_transcript}
                    hidden={item?.schedule === 'Yearly'}
                  />
                  <ItemContent label="Is this a weighted course?" values={sub?.isWeightedCourse} />
                  <div />
                  {item?.schedule === 'Yearly' ? (
                    <Row>
                      <ItemContent label={'Final Grade'} values={sub?.finalGrade} />
                      <ItemContent label={'Final Credit'} values={sub?.finalCredits} />
                    </Row>
                  ) : (
                    <>
                      {(sub?.grade ?? []).map((grade: any, gradeIndex: any) => {
                        let hidden =
                          (!sub?.grade[gradeIndex] && !sub?.credits[gradeIndex]) ||
                          (item?.schedule === 'Semester' && gradeIndex > 1) ||
                          (item?.schedule === 'Trimester' && gradeIndex > 2);
                        return hidden ? null : (
                          <Row key={gradeIndex}>
                            <ItemContent
                              label={[item?.schedule, gradeIndex + 1, 'Grade'].join(' ')}
                              values={sub?.grade[gradeIndex]}
                            />
                            <ItemContent
                              label={[item?.schedule, gradeIndex + 1, 'Credit'].join(' ')}
                              values={sub?.credits[gradeIndex]}
                            />
                          </Row>
                        );
                      })}
                    </>
                  )}

                  <CheckboxContent
                    label="I took this class at a college and/or I received college credit for this class."
                    values={sub?.isTakeClassAtCollege}
                  />
                  <div className="my-2" />
                  <ItemContent label="Select College *" mdCol={6} values={sub?.collegeTookClass} />
                  <ItemContent label="Grading Scale*" values={sub?.CNGradingScale} mdCol={6} />
                  <div className="my-2" />
                  {/* {sub?.CNGradingScale === 'Numbers' && (
                        <>
                          <ItemContent
                            label="What is the lowest possible number grade at this school? *"
                            values={sub?.lowestGrade}
                          />
                          <ItemContent
                            label="What is the highest possible number grade at this school? *"
                            values={sub?.highestGrade}
                          />
                        </>
                      )}
                      {sub?.CNGradingScale === 'Other' && (
                        <ItemContent label="Other Grading Scale*" values={sub?.otherGradeScale} />
                      )} */}

                  {/* <ItemContent
                    label="Final Grade"
                    values={sub?.finalGrade}
                    hidden={sub?.schedule_transcript === 'One grade per term'}
                  />
                  <ItemContent
                    label="Final Credits"
                    values={sub?.finalCredits}
                    hidden={sub?.schedule_transcript === 'One grade per term'}
                  /> */}
                </Row>
              </Card>
            );
          })}
        </Row>
      ))}
    </>
  );
};

const buildSemesterData = (item: any, schedule: string) => {
  let options: any[] = [];
  switch (schedule) {
    case 'Quarters':
      options = [
        { value: 'isFirstSemester', label: 'First Quarter', fieldIndex: 0 },
        { value: 'isSecondSemester', label: 'Second Quarter', fieldIndex: 1 },
        { value: 'isThirdSemester', label: 'Third Quarter', fieldIndex: 2 },
        { value: 'isFourthSemester', label: 'Fourth Quarter', fieldIndex: 3 },
      ];
      break;
    case 'Trimester':
      options = [
        { value: 'isFirstSemester', label: 'First Trimester', fieldIndex: 0 },
        { value: 'isSecondSemester', label: 'Second Trimester', fieldIndex: 1 },
        { value: 'isThirdSemester', label: 'Third Trimester', fieldIndex: 2 },
      ];
      break;
    case 'Semester':
      options = [
        { value: 'isFirstSemester', label: 'First Semester', fieldIndex: 0 },
        { value: 'isSecondSemester', label: 'Second Semester', fieldIndex: 1 },
      ];
      break;
    default:
      break;
  }
  let str = options
    .map((opt) => (item[opt.value] ? opt.label : ''))
    .filter((i) => !!i)
    .join(', ');
  return str ?? '-';
};

export default CourseGrade9Summary;
