/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import RemoveCircleOutlineRoundedIcon from '@mui/icons-material/RemoveCircleOutlineRounded';
import { Card } from '@mui/material';
import TextInput from 'components/input/textInput';
import { Row } from 'react-bootstrap';

type Props = {
  index: number;
  major: any;
  selectedCollege: any;
  handleDeleteMajor: any;
  formik: any;
};

export const MajorCard = (props: Props) => {
  return (
    <Card className="rounded my-3 me-5 w-100 p-3 py-3">
      <div className="w-100 d-flex align-items-center justify-content-between">
        <div className="my-1 d-flex align-items-center">
          <div className="mx-2 me-3">
            <div>{props.formik.values.major_array[props.index].collegeName}</div>
            <div className="sized70 text-field-light">
              {props.formik.values.major_array[props.index].location}
            </div>
          </div>
        </div>
        <div className="d-flex align-items-center">
          <span>{props.formik.values.major_array[props.index].major1}</span>
          <div style={{ height: 12 }} className="border mx-2" />
          <span>{props.formik.values.major_array[props.index].major2}</span>
          <RemoveCircleOutlineRoundedIcon
            className="text-field-light pointer ms-3"
            onClick={() => props.handleDeleteMajor()}
          />
        </div>
      </div>

      <div className="border w-100 my-3"></div>
      <Row>
        <TextInput
          mdCol={'6'}
          label="Major No.1"
          name={`major_array[${props.index}].major1`}
          placeholder="Example: Medicine"
          values={props.formik.values.major_array[props.index].major1}
          onChange={props.formik.handleChange}
        />
        <TextInput
          mdCol={'6'}
          label="Major No.2"
          name={`major_array[${props.index}].major2`}
          placeholder="Example: Arts"
          values={props.formik.values.major_array[props.index].major2}
          onChange={props.formik.handleChange}
        />
      </Row>
    </Card>
  );
};
