import collegeServices from './collegeServices';
import http from './http';

const authorizationServices = {
  async checkVerificationCode(body: any): Promise<any> {
    const res = await http.post('api/students/check-verification-code', body);
    return res;
  },
  async sendVerificationCode(body: any): Promise<any> {
    const res = await http.post('api/students/send-verification-code', body);
    return res;
  },

  async createStudent(body: any): Promise<any> {
    const res = await http.post('api/students/create-student', body);
    body.user_role === 'student'
      ? collegeServices.sendMailWelcome({ email: body.email, username: body.first_name })
      : collegeServices.sendMailWelcomeCounselor({ email: body.email, username: body.first_name });
    return res;
  },

  async getStudent(id: string | undefined): Promise<any> {
    if (!id) return;
    const res = await http.get(`api/students/${id}`);
    return res;
  },
  async updateStudent(id: string | undefined, body: any): Promise<any> {
    const res = await http.put(`api/students/${id}`, body);
    return res;
  },

  async login(body: any): Promise<any> {
    const res = await http.post('api/auth/login', body);
    return res;
  },

  async sendEmailForgotPassword(body: any): Promise<any> {
    const res = await http.post('api/students/forgot-password', body);
    return res;
  },

  async checkCodeForgotPassword(body: any): Promise<any> {
    const res = await http.post('api/students/check-forgot-password-code', body);
    return res;
  },

  async resetPassword(body: any): Promise<any> {
    const res = await http.post('api/students/change-password', body);
    return res;
  },
};

export default authorizationServices;
