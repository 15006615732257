/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import TopProcessBar from 'screen/selectCollege/TopProcessBar';
import PeopleAltOutlinedIcon from '@mui/icons-material/PeopleAltOutlined';
import { Col, Container, Form, Button, Spinner, Row } from 'react-bootstrap';
import { useFormik } from 'formik';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import Cookies from 'js-cookie';
import { useHistory } from 'react-router-dom';
import { routers } from 'router/router';
import { logout } from 'app/basicInformationSlice/basicInformationSlice';
import { authenticateSettings } from 'components/helper/appConfig';
import CheckboxInput from 'components/input/CheckboxInput';
import SelectInput from 'components/input/SelectInput';
import TextInput from 'components/input/textInput';
import YesNoSwitch from 'components/input/YesNoSwitch';
import { getHousehold, updateHousehold } from 'app/family/familySlice';
import {
  BoolToString,
  checkEnableParseJSON,
  formatBoolean,
  optionValues,
} from 'components/helper/helper';
import LoadingContent from 'components/LoadingContent';
import { HtmlTooltip } from 'screen/selectCollege/SelecCollegePage';
import { Typography } from '@mui/material';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import GroupRadioButton from 'components/input/GroupRadioButton';
import { debounce } from 'lodash';
import { keyDownOnlyNumber } from 'components/helper/helperInut';
import { getDemoGraphics } from 'app/basicInformationSlice/basicInformationSlice';

interface Props {}
export const householdOptions = [
  { value: 'Parents(s)/Guardian(s)', key: 'is_parent_guardian' },
  { value: 'Sibling(s)', key: 'is_sibbling' },
  { value: 'Spouse', key: 'is_spouse' },
  { value: 'Child(ren)', key: 'is_child' },
  { value: 'Other', key: 'is_other' },
  { value: 'Just me', key: 'is_just_me' },
];
const Household = (props: Props) => {
  const demographicsStore = useAppSelector((store) => store.basicInformation.myDemographics);

  const [loading, setLoading] = React.useState(false);
  const formHousehold = useFormik({
    initialValues: {
      describe_other_family_members: '',
      is_parent_guardian: false,
      is_sibbling: false,
      is_spouse: false,
      is_child: false,
      is_just_me: false,
      is_other: false,
    },
    onSubmit: () => {},
  });
  const family = useAppSelector((store) => store.family);
  const dispatch = useAppDispatch();
  const history = useHistory();  
  let studentId = Cookies.get('userId');
  let token = Cookies.get(authenticateSettings.tokenName);

  const formik = useFormik({
    initialValues: {
      is_listing_step_parents: '',
      how_many_step_parents: '',
      who_in_household_array: '',
      household_size: 0,
      whom_live_permanently: '',
      parent_martial_status: '',
      household_income: 0,
      is_have_any_children: '',
      highest_level_education_parent: '',
      bachelor_level_education_parent: false,
      how_many_children: 0,
      other_live_with_free_text: '',
      year_of_divorce: undefined,
      other_parent_martial_status: '',
      legal_guardian: '',
      csu_info: {
        household_income_information_statements: '', // One or more of the above statements apply to me: Independent, None of the above statements apply to me: Dependent status
        parent_gross_income: '',
        parent_untaxed_income: '',
        gross_income: '',
        untaxed_income: '',
        parent_1_highest_level_education: 'Select',
        parent_2_highest_level_education: 'Select',
        parent_graduate_within_the_last_five_years: '',
        people_were_supported_by_this_income: '',
        parent_1_highest_level_education_last_five_years: '',
        parent_2_highest_level_education_last_five_years: '',
        highest_level_education_parent_last_five_years: '',
      },
    },
    onSubmit: async (values) => {
      const newValues = {
        ...values,
        csu_info: JSON.stringify(values.csu_info),
        who_in_household_array: JSON.stringify(formHousehold.values),
        is_have_any_children: formatBoolean(values.is_have_any_children),
        legal_guardian: JSON.stringify(formLegalGuardian.values),
        other_live_with_free_text:
          values.whom_live_permanently === 'Other' ? values.other_live_with_free_text : '',
        student_id: parseInt(studentId || ''),
      };
      await dispatch(updateHousehold(newValues));
    },
  });
  const formLegalGuardian = useFormik({
    initialValues: {
      step_parent_is_married: '',
      step_parent_type: '',
      step_parent_is_living: '',
      prefix: '',
      first_name: '',
      middle_name: '',
      last_name: '',
      former_last_name: '',
      suffix: '',
      step_parent_relationship: '',
      step_parent_email: '',
      country_code: '',
      phone_number: '',
      phone_number_type: '',
      occupation: '',
      other_occupation_detail: '',
      employment_status: '',
      currently_employed_or_retire_from_college: '',
      college_or_university: '',
      current_employer: '',
      education_level: '',
      total_number_institutions_attended: '',
      parent_graduation_college_array: '',
    },
    onSubmit: () => {},
  });

  const handleLogout = () => {
    dispatch(logout());
    Cookies.remove(authenticateSettings.tokenName);
    Cookies.remove('userId');
    history.push(routers.login.path);
  };  

  const handleContinue = () => {
    formik.handleSubmit();
    const nextRoute = Object.keys(family.roleFamily).find((key) => family.roleFamily[key] === true);
    history.push(nextRoute ? routers?.[nextRoute]?.path : routers.enrolimentStatus.path);
  };
  const getDetail = async () => {
    studentId = Cookies.get('userId');
    token = Cookies.get(authenticateSettings.tokenName);
    if (token && studentId) {
      setLoading(true);
      let res = await dispatch(getHousehold(studentId));
      const dataLoaded: any = res.payload;
      if (dataLoaded) {
        const values: any = {
          ...formik.values,
          ...dataLoaded,
          csu_info: { ...formik.values.csu_info, ...checkEnableParseJSON(dataLoaded.csu_info) },
          is_have_any_children: BoolToString(dataLoaded.is_have_any_children),
        };
        formHousehold.setValues({
          ...formHousehold.values,
          ...JSON.parse(dataLoaded.who_in_household_array),
        });
        formLegalGuardian.setValues(checkEnableParseJSON(dataLoaded?.legal_guardian));
        formik.setValues(values);
      }
  
      setLoading(false);
      formik.setStatus('idle');
    }
    else {
      handleLogout();
    }
  };
  React.useEffect(() => {
    studentId = Cookies.get('userId');
    token = Cookies.get(authenticateSettings.tokenName);
    if (token && studentId) {
      getDetail();
      dispatch(getDemoGraphics(studentId));      
    }
    else {
      handleLogout();
    }

  }, []);
  React.useEffect(() => {
    if (formHousehold.values.is_just_me) {
      formHousehold.setValues({
        ...formHousehold.values,
        is_parent_guardian: false,
        is_sibbling: false,
        is_spouse: false,
        is_child: false,
        is_other: false,
      });
    }
  }, [formHousehold.values.is_just_me]);
  React.useEffect(() => {
    if (
      formHousehold.values.is_parent_guardian === true ||
      formHousehold.values.is_sibbling === true ||
      formHousehold.values.is_spouse === true ||
      formHousehold.values.is_child === true ||
      formHousehold.values.is_other === true
    ) {
      formHousehold.setFieldValue('is_just_me', false);
      return;
    }
  }, [
    formHousehold.values.is_parent_guardian,
    formHousehold.values.is_sibbling,
    formHousehold.values.is_spouse,
    formHousehold.values.is_child,
    formHousehold.values.is_other,
  ]);

  React.useEffect(() => {
    debounceSaving();
  }, [formik.values, formHousehold.values, formLegalGuardian.values]);
  const debounceSaving = React.useCallback(
    debounce(() => {
      if (!loading) formik.handleSubmit();
    }, 1500),
    []
  );
  // Auto save function ==========
  return loading ? (
    <LoadingContent />
  ) : (
    <div>
      <TopProcessBar
        stepProcess={[
          { title: 'General', percentOnProcess: 30 },
          { title: 'Parental Marital Status', percentOnProcess: 45 },
          { title: 'Household Income', percentOnProcess: 70 },
          { title: 'Dependents', percentOnProcess: 88 },
        ]}
        title="Family Information"
        process={1}
        icon={<PeopleAltOutlinedIcon />}
      />

      <Container className="main-container">
        <div className="px-2" id="General">
          <Form style={{ maxWidth: '900px', width: '100%' }} onSubmit={formik.handleSubmit}>
            <Row className="m-0">
              <div className="text-field-mid fs-3 mb-4">Household</div>

              <div className="text-dart fs-5">Household Income and Size Information</div>
              <div className="text-dart mt-4">
                Please review the following statements carefully to determine if any of them apply
                to you. Then, answer the question below.
              </div>
              <ul>
                <li className="text-mute m-1 ms-3">You were born before January 1, 2002.</li>

                <li className="text-mute m-1 ms-3">
                  You are currently an active duty member or a veteran of the U.S. Armed Forces.
                </li>
                <li className="text-mute m-1 ms-3">
                  As of today, you are married or you are separated, but not divorced.
                </li>
                <li className="text-mute m-1 ms-3">
                  You have or will have children or dependents who will receive more than half of
                  their support from you between July 1, 2024 and June 30, 2026.
                </li>
                <li className="text-mute m-1 ms-3">
                  Someone other than your parent(s) or stepparent(s) has legal guardianship of you,
                  as determined by a court in your state of legal residence.
                </li>
                <li className="text-mute m-1 ms-3">
                  At any time since you turned age 13, both your parents were deceased, you were in
                  foster care, or you were a dependent or ward of the court.
                </li>
                <li className="text-mute m-1 ms-3">
                  At any time on or after July 1, 2024, your high school, an emergency shelter, a
                  transitional housing program, or a homeless youth center determined you were an
                  unaccompanied youth or were self-supporting and at risk of being homeless.
                </li>
                <li className="text-mute m-1 ms-3">
                  You currently do not live with your parent(s)/stepparent(s) and have not had
                  contact or financial support from them for the last 12 months or more.
                </li>
              </ul>

              <GroupRadioButton
                mdCol={12}
                options={[
                  'None of the above statements apply to me',
                  'One or more of the above statements apply to me',
                ]}
                onChange={formik.handleChange}
                name="csu_info.household_income_information_statements"
                values={formik.values.csu_info?.household_income_information_statements}
                label="Do any of the above statements apply to you?"
              />

              <Row className="coallition-flag">
                <div>Who is in your household? (select all that apply)</div>

                {householdOptions.map((option, index) => {
                  const key = option.key;
                  const formValues: any = formHousehold.values;
                  return (
                    <Col md={4} lg={3} key={index}>
                      <CheckboxInput
                        label={<span className="text-field-light">{option.value}</span>}
                        name={key}
                        values={formValues[key]}
                        onChange={(e) => formHousehold.setFieldValue(key, e.target.checked)}
                      />
                    </Col>
                  );
                })}
              </Row>
              {formHousehold.values.is_other === true && (
                <>
                  <div className="text-dart mt-3">
                    If there are other family members who live with you, please tell us who they
                    are. For example: A grandparent or other relative.
                  </div>
                  <TextInput
                    logo="span-csu-logo"
                    label="Describe other family members*"
                    countText={50}
                    name="describe_other_family_members"
                    values={formHousehold.values.describe_other_family_members}
                    onChange={formHousehold.handleChange}
                  />
                </>
              )}
              {![
                formHousehold.values.is_parent_guardian,
                formHousehold.values.is_sibbling,
                formHousehold.values.is_spouse,
                formHousehold.values.is_child,
              ].every((i) => i === false) && (
                <>
                  <div />
                  <SelectInput
                    className="uc-coallition-flag mt-3"
                    form={formik}
                    option={[
                      { value: 1 },
                      { value: 2 },
                      { value: 3 },
                      { value: 4 },
                      { value: 5 },
                      { value: 6 },
                      { value: 7 },
                      { value: 8 },
                      { value: 9 },
                      { value: 10 },
                      { value: 11 },
                      { value: 12 },
                      { value: 13 },
                      { value: 14 },
                      { value: 15 },
                      { value: 16 },
                      { value: 17 },
                      { value: 18 },
                      { value: 19 },
                      { value: 20 },
                    ]}
                    label="Household Size *"
                    name="household_size"
                    values={formik.values.household_size}
                    onChange={formik.handleChange}
                  />
                  <div />
                </>
              )}
              <SelectInput
                logo="span-uc-logo"
                form={formik}
                option={[
                  'Select',
                  'No High School',
                  'Some High School',
                  'High School Graduate',
                  'Some College',
                  '2-year college graduate',
                  '4-year college graduate',
                  'Postgraduate',
                  'Does not apply',
                ]}
                label="What is the highest level of education earned by any of your parents? *"
                name="highest_level_education_parent"
                values={formik.values.highest_level_education_parent}
                onChange={formik.handleChange}
              />
              
              {(formik.values.highest_level_education_parent === '2-year college graduate' ||
                formik.values.highest_level_education_parent === '4-year college graduate') && (
                <Col md={6} 
                className='mt-4'>
                  <YesNoSwitch
                    label="Did this parent graduate within the last five years?"
                    name="csu_info.highest_level_education_parent_last_five_years"
                    values={formik.values.csu_info?.highest_level_education_parent_last_five_years}
                    onChange={formik.handleChange}
                  />
                </Col>
              )}

              <div />
              <SelectInput
                logo="span-csu-logo"
                form={formik}
                option={[
                  'Select',
                  'No High School',
                  'Some High School',
                  'High School Graduate',
                  'Some College',
                  '2-year college graduate',
                  '4-year college graduate',
                  'Postgraduate',
                  'Does not apply',
                ]}
                label="What is your Parent/Guardian #1 highest level of education?"
                name="csu_info.parent_1_highest_level_education"
                values={formik.values.csu_info?.parent_1_highest_level_education}
                onChange={formik.handleChange}
              />
              {(formik.values.csu_info?.parent_1_highest_level_education ===
                '2-year college graduate' ||
                formik.values.csu_info?.parent_1_highest_level_education ===
                  '4-year college graduate') && (
                <Col md={6} className='mt-2'>
                  <YesNoSwitch
                    label="Did this parent graduate within the last five years?"
                    name="csu_info.parent_1_highest_level_education_last_five_years"
                    values={
                      formik.values.csu_info?.parent_1_highest_level_education_last_five_years
                    }
                    onChange={formik.handleChange}
                  />
                </Col>
              )}
              <div />

              <SelectInput
                logo="span-csu-logo"
                form={formik}
                option={[
                  'Select',
                  'No High School',
                  'Some High School',
                  'High School Graduate',
                  'Some College',
                  '2-year college graduate',
                  '4-year college graduate',
                  'Postgraduate',
                  'Does not apply',
                ]}
                label="What is your Parent/Guardian #2 highest level of education?"
                name="csu_info.parent_2_highest_level_education"
                values={formik.values.csu_info?.parent_2_highest_level_education}
                onChange={formik.handleChange}
              />
              {(formik.values.csu_info?.parent_2_highest_level_education ===
                '2-year college graduate' ||
                formik.values.csu_info?.parent_2_highest_level_education ===
                  '4-year college graduate') && (
                <Col md={6} className='mt-2' >
                  <YesNoSwitch
                    label="Did this parent graduate within the last five years?"
                    name="csu_info.parent_2_highest_level_education_last_five_years"
                    values={
                      formik.values.csu_info?.parent_2_highest_level_education_last_five_years
                    }
                    onChange={formik.handleChange}
                  />
                </Col>
              )}

              <div className="w-100 border my-3" id="ParentalMaritalStatus" />
              <div className="fs-5 p-1">Parental Marital Status</div>

              <SelectInput
                logo="span-cm-logo"
                form={formik}
                option={[
                  { value: 'Married' },
                  { value: 'Separated' },
                  { value: 'Divorced' },
                  { value: 'Never Married' },
                  { value: 'Widowed' },
                  { value: 'Civil Union/Domestic Partners' },
                  // { value: 'Other' },
                ]}
                label="Parents'/Guardians' Marital Status (relative to each other) *"
                name="parent_martial_status"
                values={formik.values.parent_martial_status}
                onChange={(e) => {
                  formik.handleChange(e);
                  if (!['Divorced', 'Never Married', 'Widowed'].includes(e.target.value)) {
                    formik.setFieldValue('is_listing_step_parents', 'false');
                  }
                }}
              />
              {formik.values.parent_martial_status === 'Divorced' && (
                <>
                  <SelectInput
                    className="common-coallition-flag"
                    form={formik}
                    option={optionValues(Array.from({ length: 74 }, (_, i) => i + 1950))?.reverse()}
                    label="Year of divorce*"
                    name="year_of_divorce"
                    values={formik.values.year_of_divorce}
                    onChange={formik.handleChange}
                  />
                </>
              )}
              <div />
              <SelectInput
                // hidden={!formHousehold.values.is_just_me}
                logo="span-cm-logo"
                form={formik}
                option={[
                  { value: 'Both Parents' },
                  { value: 'Parent 1' },
                  { value: 'Parent 2' },
                  { value: 'Legal Guardian' },
                  { value: 'Ward of Court/State' },
                  { value: 'Other' },
                ]}
                label="With whom do you make your permanent home?*"
                name="whom_live_permanently"
                values={formik.values.whom_live_permanently}
                onChange={(e) => {
                  formik.handleChange(e);
                  if (!['Legal Guardian', 'Ward of Court/State'].includes(e.target.value)) {
                    formik.setFieldValue('is_listing_step_parents', 'false');
                  }
                }}
              />
              {formik.values.whom_live_permanently === 'Other' && (
                <TextInput
                  label="Other*"
                  countText={100}
                  name="other_live_with_free_text"
                  values={formik.values.other_live_with_free_text}
                  onChange={formik.handleChange}
                />
              )}
              {['Divorced', 'Never Married', 'Widowed', 'Separated'].includes(
                formik.values.parent_martial_status
              ) && (
                <>
                  <Col md={6}>
                    <YesNoSwitch
                      logo="span-cm-logo"
                      label="Do you wish to list any step-parents?*"
                      name="is_listing_step_parents"
                      values={formik.values.is_listing_step_parents}
                      onChange={formik.handleChange}
                    />
                  </Col>
                  {formik.values.is_listing_step_parents === 'true' && (
                    <Col md={6}>
                      <GroupRadioButton
                        logo="span-cm-logo"
                        values={formik.values.how_many_step_parents}
                        onChange={formik.handleChange}
                        name="how_many_step_parents"
                        label="How many?*"
                        options={['1', '2']}
                        mdCol={6}
                      />
                    </Col>
                  )}
                </>
              )}

              {formik.values.parent_martial_status === 'Other' && (
                <TextInput
                  label="Please specify *"
                  name="other_parent_martial_status"
                  values={formik.values.other_parent_martial_status}
                  onChange={formik.handleChange}
                  countText={81}
                />
              )}
              <div className="w-100 border my-3" id="HouseholdIncome" />
              <HtmlTooltip
                title={
                  <React.Fragment>
                    <Typography className="fs-6 text-dark my-2 fw-bold">
                      <InfoOutlinedIcon fontSize="small" className="mx-1" />
                      Household Income
                    </Typography>
                    <div className="d-flex flex-wrap text-field-light">
                      Not all colleges ask about this information and other make it optional. In any
                      case, you'll want to chat with your parents about this information. To be
                      prepared - Ask you parents what their annual adjusted gross income and untaxed
                      income is. Some schools will ask for both or just one
                    </div>
                  </React.Fragment>
                }
                placement="right-start"
              >
                <Row>
                  <div className="fs-5 p-1">Household Income</div>
                  <TextInput
                    logo="span-uc-logo"
                    type="number"
                    label={
                      checkEnableParseJSON(demographicsStore?.all_apply_array)
                        ?.is_none_above_apply_to_me
                        ? 'What was the total income earned in 2023 by your parents or legal guardians?'
                        : "What was your total income earned in 2023? (Include your spouse's income, if relevant)"
                    }
                    name="household_income"
                    values={formik.values.household_income}
                    onChange={formik.handleChange}
                  />
                  <TextInput
                    decimalStep={1}
                    type="number"
                    min={0}
                    max={9999}
                    label="How many people were supported by this income?"
                    name="csu_info.people_were_supported_by_this_income"
                    values={formik.values.csu_info?.people_were_supported_by_this_income}
                    onChange={formik.handleChange}
                  />
                  {formik.values.csu_info?.household_income_information_statements ===
                  'None of the above statements apply to me' ? (
                    <>
                      {/* Case Dependent */}
                      <TextInput
                        logo="span-csu-logo"
                        type="number"
                        label={
                          formHousehold.values.is_just_me
                            ? 'Adjusted gross income (AGI) for 2023, all income reported on your federal tax return:'
                            : "Parents' adjusted gross income (AGI) for 2023. This is the income reported on your parent(s) federal tax return:"
                        }
                        name="csu_info.parent_gross_income"
                        values={formik.values.csu_info?.parent_gross_income}
                        onChange={formik.handleChange}
                      />
                      <TextInput
                        logo="span-csu-logo"
                        type="number"
                        label={
                          formHousehold.values.is_just_me
                            ? 'Untaxed income and benefits for 2023, income not reported on your federal tax return:'
                            : "Parents' untaxed income and benefits for 2023. This is income not reported on your parent(s) federal tax return:"
                        }
                        name="csu_info.parent_untaxed_income"
                        values={formik.values.csu_info?.parent_untaxed_income}
                        onChange={formik.handleChange}
                      />
                    </>
                  ) : (
                    <>
                      {/* Case Independent */}
                      <TextInput
                        type="number"
                        decimalStep={1}
                        label="Adjusted gross income (AGI) for 2023, all income reported on your federal tax return:"
                        name="csu_info.gross_income"
                        values={formik.values.csu_info?.gross_income}
                        onChange={formik.handleChange}
                      />
                      <TextInput
                        decimalStep={1}
                        type="number"
                        label="Untaxed income and benefits for 2023, income not reported on your federal tax return"
                        name="csu_info.untaxed_income"
                        values={formik.values.csu_info?.untaxed_income}
                        onChange={formik.handleChange}
                      />
                    </>
                  )}
                  <div />
                </Row>
              </HtmlTooltip>
              <div className="w-100 border my-3" id="Dependents" />
              <div className="common-flag fs-5 p-1">Dependents</div>

              <Col md={6}>
                <YesNoSwitch
                  label="Do you have any children ?"
                  name="is_have_any_children"
                  values={formik.values.is_have_any_children}
                  onChange={formik.handleChange}
                />
                {formik.values.is_have_any_children === 'true' && (
                  <TextInput
                    type="number"
                    max={100}
                    mdCol={'12'}
                    label="How many ?"
                    name="how_many_children"
                    onChange={formik.handleChange}
                    values={formik.values.how_many_children}
                  />
                )}
              </Col>
            </Row>

            <div className="my-3 d-flex justify-content-end border-top pt-3">
              <div className="col col-md-6 text-center text-md-end">
                <Button variant="primary" type="submit" className="p-2 px-5 mx-2">
                  {family.status === 'loading' ? (
                    <Spinner size="sm" animation="border" variant="light" />
                  ) : (
                    'Save'
                  )}
                </Button>
                <Button variant="primary" className="p-2 px-5" onClick={() => handleContinue()}>
                  Continue
                </Button>
              </div>
            </div>
          </Form>
        </div>
      </Container>
    </div>
  );
};

export default Household;
