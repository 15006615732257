import React from 'react';
import { Form } from 'react-bootstrap';

interface Props {
  onChange: (value: any) => void;
}

const VerifyCodeInput = (props: Props) => {
  const [values, setValues] = React.useState<Array<number | null>>([null, null, null, null]);
  React.useEffect(() => {
    if (values.find((e) => e === null) === undefined) {
      props.onChange(values);
    }
  }, [values, props]);
  const handleInputCode = (valueIndex: number, value: string) => {
    const newValues = [...values];
    newValues.forEach((item, index) => {
      if (index === valueIndex) newValues[index] = parseInt(value);
    });
    setValues(newValues);
  };
  return (
    <div className="w-100 d-flex justify-content-between align-items-center">
      <div className="border-styled">
        <Form.Control
          id="field1"
          maxLength={1}
          type="text"
          onChange={(e) => {
            handleInputCode(0, e.target.value);
            document.getElementById('field2')?.focus();
          }}
          className="w-100 h-100 text-center border-0 fs-5"
        />
      </div>
      <div className="border-styled">
        <Form.Control
          id="field2"
          maxLength={1}
          type="text"
          onChange={(e) => {
            handleInputCode(1, e.target.value);

            document.getElementById('field3')?.focus();
          }}
          className="w-100 h-100 text-center border-0 fs-5"
        />
      </div>
      <div className="border-styled">
        <Form.Control
          id="field3"
          type="text"
          maxLength={1}
          onChange={(e) => {
            handleInputCode(2, e.target.value);

            document.getElementById('field4')?.focus();
          }}
          className="w-100 h-100 text-center border-0 fs-5"
        />
      </div>
      <div className="border-styled">
        <Form.Control
          id="field4"
          type="text"
          maxLength={1}
          onChange={(e) => {
            handleInputCode(3, e.target.value);
          }}
          className="w-100 h-100 text-center  border-0 fs-5"
        />
      </div>
      <style scoped>{`
      .border-styled {
        width: 56px !important;
        height: 56px !important;
        border: 4px solid grey;
        border-radius: 12px !important;
        font-size: 2rem !important
      }
      `}</style>
    </div>
  );
};

export default VerifyCodeInput;
