import SelectInput from 'components/input/SelectInput';
import ReuseLoginLayout from 'components/layout/ReuseLoginLayout';
import { Button, Row, Col } from 'react-bootstrap';
import { useHistory } from 'react-router';
import { routers } from 'router/router';
import FormLogin from './FormLogin';

interface Props {}

const LoginPage = (props: Props) => {
  const history = useHistory();
  return (
    <ReuseLoginLayout>
      <div className="d-flex flex-wrap pb-md-3 mx-3 mb-md-2 justify-content-between align-items-center">
        <span>
          <SelectInput
            values={'English (USA)'}
            borderless
            mdCol={'12'}
            option={['English (USA)']}
          />
        </span>
        <span className="mt-2">
          <span>Don’t have an account?</span>
          <Button
            className="p-0 mx-1 pb-1"
            variant="link"
            onClick={() => history.push(routers.register.path)}
          >
            Sign Up
          </Button>
        </span>
      </div>
      <Row className="p-0 m-0">
        <Col xs={12} md={8} className="p-0">
          <h2 className="m-3">🎓 Welcome back!</h2>
          <div className="m-3 d-flex justify-content-between align-items-center">
            <h5>Please login to your account</h5>
          </div>
          <div className="mx-3">
            <FormLogin />
          </div>
        </Col>
      </Row>
    </ReuseLoginLayout>
  );
};

export default LoginPage;
