/* eslint-disable no-empty-pattern */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import dashboardServices from 'services/dashboardServices';

export interface ActivitiesAndHonorsState {
  personal: number
  additionalInformation: number
  family: number
  education: number
  activityAndHonor: number
  courseAndGrade: number
  test: number
  status: 'idle' | 'loading' | 'failed';
}

const initialState: ActivitiesAndHonorsState = {
  personal: 0,
  additionalInformation: 0,
  family: 0,
  education: 0,
  activityAndHonor: 0,
  courseAndGrade: 0,
  test: 0,
  status: 'idle',
};
export const getPersonal: any = createAsyncThunk('dashboard/getPersonal', async (params:any, {rejectWithValue}) => {
  try {
    const response = await dashboardServices.getPersonalData();
    return response;
  }
  catch (err) {
    const error = err as AxiosError; 
    if (!error.response) {
      throw err;
    }
    return rejectWithValue(error.response.data);
  }
});
export const getEducation: any = createAsyncThunk('dashboard/getEducation', async (params:any, {rejectWithValue}) => {
  try {
    const response = await dashboardServices.getEducationData();
    return response;
  }
  catch (err) {
    const error = err as AxiosError; 
    if (!error.response) {
      throw err;
    }
    return rejectWithValue(error.response.data);
  }
});
export const getFamily: any = createAsyncThunk('dashboard/getFamily', async (params:any, {rejectWithValue}) => {
  try {
    const response = await dashboardServices.getFamilyData();
    return response;
  }
  catch (err) {
    const error = err as AxiosError; 
    if (!error.response) {
      throw err;
    }
    return rejectWithValue(error.response.data);
  }
});
export const getAdditionalInformation: any = createAsyncThunk('dashboard/getAdditionalInformation', async (params:any, {rejectWithValue}) => {
  try {  
  const response = await dashboardServices.getAdditionalInformationData();
  return response;
  }
  catch (err) {
    const error = err as AxiosError; 
    if (!error.response) {
      throw err;
    }
    return rejectWithValue(error.response.data);
  }
});
export const getActivityAndHonor: any = createAsyncThunk('dashboard/getActivityAndHonor', async (params:any, {rejectWithValue}) => {
  try {
    const response = await dashboardServices.getActivityAndHonorData();
    return response;
  }
  catch (err) {
    const error = err as AxiosError; 
    if (!error.response) {
      throw err;
    }
    return rejectWithValue(error.response.data);
  }
});
export const getCourseAndGrade: any = createAsyncThunk('dashboard/getCourseAndGrade', async (params:any, {rejectWithValue}) => {
  try {
    const response = await dashboardServices.getCourseAndGradeData();
    return response;
  }
  catch (err) {
    const error = err as AxiosError; 
    if (!error.response) {
      throw err;
    }
    return rejectWithValue(error.response.data);
  }
});
export const getTest: any = createAsyncThunk('dashboard/getTest', async (params:any, {rejectWithValue}) => {
  try {
    const response = await dashboardServices.getTestData();  
    return response;
  }
  catch (err) {
    const error = err as AxiosError; 
    if (!error.response) {
      throw err;
    }
    return rejectWithValue(error.response.data);
  }
});

export const dashboardSlice = createSlice({
  name: 'dashboard',
  initialState,
  reducers: {},
  extraReducers: {
    [getPersonal.pending]: (state) => {
      state.status = 'loading';
    },
    [getPersonal.rejected]: (state) => {
      state.status = 'failed';
    },
    [getPersonal.fulfilled]: (state, action) => {
      state.personal = action.payload.completedPercentage;
      state.status = 'idle';
    },

    [getEducation.pending]: (state) => {
      state.status = 'loading';
    },
    [getEducation.rejected]: (state) => {
      state.status = 'failed';
    },
    [getEducation.fulfilled]: (state, action) => {
      state.education = action.payload.completedPercentage;
      state.status = 'idle';
    },
    
    [getFamily.pending]: (state) => {
      state.status = 'loading';
    },
    [getFamily.rejected]: (state) => {
      state.status = 'failed';
    },
    [getFamily.fulfilled]: (state, action) => {
      state.family = action.payload.completedPercentage;
      state.status = 'idle';
    },
    
    [getAdditionalInformation.pending]: (state) => {
      state.status = 'loading';
    },
    [getAdditionalInformation.rejected]: (state) => {
      state.status = 'failed';
    },
    [getAdditionalInformation.fulfilled]: (state, action) => {
      state.additionalInformation = action.payload.completedPercentage;
      state.status = 'idle';
    },

    [getActivityAndHonor.pending]: (state) => {
      state.status = 'loading';
    },
    [getActivityAndHonor.rejected]: (state) => {
      state.status = 'failed';
    },
    [getActivityAndHonor.fulfilled]: (state, action) => {
      state.activityAndHonor = action.payload.completedPercentage;
      state.status = 'idle';
    },

    [getCourseAndGrade.pending]: (state) => {
      state.status = 'loading';
    },
    [getCourseAndGrade.rejected]: (state) => {
      state.status = 'failed';
    },
    [getCourseAndGrade.fulfilled]: (state, action) => {
      state.courseAndGrade = action.payload.completedPercentage;
      state.status = 'idle';
    },

    [getTest.pending]: (state) => {
      state.status = 'loading';
    },
    [getTest.rejected]: (state) => {
      state.status = 'failed';
    },
    [getTest.fulfilled]: (state, action) => {
      state.test = action.payload.completedPercentage;
      state.status = 'idle';
    },
  },
});

export const {} = dashboardSlice.actions;
