/* eslint-disable no-empty-pattern */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import toast from 'react-hot-toast';
import activitiesAndHonorsServices from 'services/activitiesAndHonorsServices';

export interface ActivitiesAndHonorsState {
  myActivityAndHonor: any;
  myActivities: any;
  myHonors: any;
  myEducationalProgram: any;
  myAdditionalInfo: any;
  status: 'idle' | 'loading' | 'failed';
}

const initialState: ActivitiesAndHonorsState = {
  myActivityAndHonor: null,
  myEducationalProgram: null,
  myActivities: null,
  myHonors: null,
  myAdditionalInfo: null,
  status: 'idle',
};
export const getEducationalProgram: any = createAsyncThunk(
  'activitiesAndHonors/getEducationalProgram',
  async (studentId: any, {rejectWithValue}) => {
    try {
      const response = await activitiesAndHonorsServices.getEducationalProgram(studentId);
      return response;
    }
    catch (err) {
      const error = err as AxiosError; 
      if (!error.response) {
        throw err;
      }
      return rejectWithValue(error.response.data);
    }
  }
);
export const updateEducationalProgram: any = createAsyncThunk(
  'activitiesAndHonors/updateEducationalProgram',
  async (payload: any, { getState, rejectWithValue }) => {
    const state: any = getState();
    const myEducationalProgram = state.activitiesAndHonors.myEducationalProgram;
    try {
      if (myEducationalProgram) {
        const response = await activitiesAndHonorsServices.updateEducationalProgram(payload);
        return response;
      } else {
        const response = await activitiesAndHonorsServices.createEducationalProgram(payload);
        return response;
      }
    }
    catch (err) {
      const error = err as AxiosError; 
      if (!error.response) {
        throw err;
      }
      return rejectWithValue(error.response.data);
    }
  }
);
export const getActivitiesAndHonors: any = createAsyncThunk(
  'activitiesAndHonors/getGeneral',
  async (studentId: any, {rejectWithValue}) => {
    try {
      const response = await activitiesAndHonorsServices.getActivitiesAndHonors(studentId);
      return response;
    }
    catch (err) {
      const error = err as AxiosError; 
      if (!error.response) {
        throw err;
      }
      return rejectWithValue(error.response.data);
    }
  }
);
export const updateActivitiesAndHonors: any = createAsyncThunk(
  'activitiesAndHonors/updateGeneral',
  async (payload: any, { getState, rejectWithValue }) => {
    const state: any = getState();
    const myActivities = state.activitiesAndHonors.myActivities;
    const myHonors = state.activitiesAndHonors.myHonors;
    try {
      if (myActivities || myHonors) {
        const response = await activitiesAndHonorsServices.updateActivitiesAndHonors(payload);
        return response;
      } else {
        const response = await activitiesAndHonorsServices.createActivitiesAndHonors(payload);
        return response;
      }
    }
    catch (err) {
      const error = err as AxiosError; 
      if (!error.response) {
        throw err;
      }
      return rejectWithValue(error.response.data);
    }
  }
);
export const getAdditionalInfo: any = createAsyncThunk(
  'activitiesAndHonors/getAdditionalInfo',
  async (studentId: any, {rejectWithValue}) => {
    try {
    const response = await activitiesAndHonorsServices.getAdditionalInfo(studentId);
    return response;
    }
    catch (err) {
      const error = err as AxiosError; 
      if (!error.response) {
        throw err;
      }
      return rejectWithValue(error.response.data);
    }
  }
);
export const updateAdditionalInfo: any = createAsyncThunk(
  'activitiesAndHonors/updateAdditionalInfo',
  async (payload: any, { getState, rejectWithValue }) => {
    const state: any = getState();
    const myAdditionalInfo = state.activitiesAndHonors.myAdditionalInfo;
    try {
      if (myAdditionalInfo) {
        const response = await activitiesAndHonorsServices.updateAdditionalInfo(payload);
        return response;
      } else {
        const response = await activitiesAndHonorsServices.createAdditionalInfo(payload);
        return response;
      }
    }
    catch (err) {
      const error = err as AxiosError; 
      if (!error.response) {
        throw err;
      }
      return rejectWithValue(error.response.data);
    }
  }
);
export const activitiesAndHonorsSlice = createSlice({
  name: 'activitiesAndHonors',
  initialState,
  reducers: {},
  extraReducers: {
    [getEducationalProgram.pending]: (state) => {
      state.status = 'loading';
    },
    [getEducationalProgram.rejected]: (state) => {
      state.status = 'failed';
    },
    [getEducationalProgram.fulfilled]: (state, action) => {
      state.myEducationalProgram = action.payload;
      state.status = 'idle';
    },
    //
    [updateEducationalProgram.pending]: (state) => {
      state.status = 'loading';
    },
    [updateEducationalProgram.rejected]: (state) => {
      state.status = 'failed';
    },
    [updateEducationalProgram.fulfilled]: (state, action) => {
      toast.success(action.payload.message);
      state.myEducationalProgram = action.payload;
      state.status = 'idle';
    },
    //
    [getActivitiesAndHonors.pending]: (state) => {
      state.status = 'loading';
    },
    [getActivitiesAndHonors.rejected]: (state) => {
      state.status = 'failed';
    },
    [getActivitiesAndHonors.fulfilled]: (state, action) => {
      state.myActivities = action.payload.activity_array;
      state.myHonors = action.payload.honor_array;
      state.myActivityAndHonor = action.payload;
      state.status = 'idle';
    },
    //
    [updateActivitiesAndHonors.pending]: (state) => {
      state.status = 'loading';
    },
    [updateActivitiesAndHonors.rejected]: (state) => {
      state.status = 'failed';
    },
    [updateActivitiesAndHonors.fulfilled]: (state, action) => {
      toast.success(action.payload.message);
      state.myActivities = action.payload;
      state.status = 'idle';
    },

    //
    [getAdditionalInfo.pending]: (state) => {
      state.status = 'loading';
    },
    [getAdditionalInfo.rejected]: (state) => {
      state.status = 'failed';
    },
    [getAdditionalInfo.fulfilled]: (state, action) => {
      state.myAdditionalInfo = action.payload;
      state.status = 'idle';
    },
    //
    [updateAdditionalInfo.pending]: (state) => {
      state.status = 'loading';
    },
    [updateAdditionalInfo.rejected]: (state) => {
      state.status = 'failed';
    },
    [updateAdditionalInfo.fulfilled]: (state, action) => {
      toast.success(action.payload.message);
      state.myAdditionalInfo = action.payload;
      state.status = 'idle';
    },
  },
});

export const {} = activitiesAndHonorsSlice.actions;
