import React from 'react';
import { Badge, Col, Form } from 'react-bootstrap';
import RadioInputRounded from './RadioInputRounded';

interface Props {
  name?: string;
  values?: any;
  onChange?: any;
  options: any[];
  mdCol?: number;
  disabled?: boolean;
  label?: string;
  onClear?: any;
  className?: string;
  logo?: string;
}

const GroupRadioButtonRounded = (props: Props) => {
  const handleClear = () => {
    props.onClear && props.onClear();
  };
  return (
    <Form.Group
      as={Col}
      className={`m-1 px-2 ${props.className}`}
      name={props.name}
      values={props.values}
      onChange={props.onChange}
    >
      <Form.Label
        style={{
          fontSize: '1rem',
          color: '#4E5A6F',
        }}
        className={`text-field-mid d-block ${props.logo ?? ''}`.trim()}
      >
        {props.label}
      </Form.Label>
      {(props.options || []).map((opt, index) => (
        <span className="pe-2" key={index + props.values}>
          <RadioInputRounded
            handleClickToChange={props.onChange}
            isActive={props.values === opt.value || props.values === opt.label}
            value={opt.value}
            label={opt.label || opt.value}
            name={props.name || ''}
            disabled={props.disabled}
          />
        </span>
      ))}
      {props.onClear && (
        <span className="me-3">
          <Badge bg="light" text="dark" className="pointer p-3" onClick={handleClear}>
            Clear
          </Badge>
        </span>
      )}
    </Form.Group>
  );
};

export default GroupRadioButtonRounded;
