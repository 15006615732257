// import { styled } from '@mui/system';
// import { Autocomplete, CircularProgress, TextField } from '@mui/material';
import { debounce } from 'lodash';
import React from 'react';
import { Typeahead } from 'react-bootstrap-typeahead';
import { Col, Form, Spinner } from 'react-bootstrap';
import Stack from '@mui/material/Stack';
import { Typography } from '@mui/material';

interface Props {
  hidden?: boolean;
  borderless?: boolean;
  label?: string;
  option: { id: number; label: any; value: any; code: string }[];
  mdCol?: string;
  name: string;
  values?: any;
  onChangeItem?: (e: any) => void;
  isValid?: boolean;
  feedback?: any;
  defautlValues?: any;
  disabled?: boolean;
  placeholder?: string;
  form: any;
  className?: string;
  logo?: string;
  onSearch: (keyword: string) => void;
}

const SelectSearchInput = (props: Props) => {
  // const [data, setData] = React.useState<{ id: number; label: any; value: any }[]>([]);
  // React.useEffect(() => {
  //   setData(props.option ?? []);
  // }, [props.option]);
  return props.hidden ? null : (
    <Form.Group
      className={`p-1 p-md-1 ${props.className ?? ''}`.trim()}
      name={props.name}
      as={Col}
      md={props.mdCol || '6'}
      controlId="formGridState"
    >
      <Form.Label style={{ fontSize: '1rem', color: '#4E5A6F' }} className={props.logo}>
        {props.label}
      </Form.Label>
      <Typeahead
        id={props.name}
        onInputChange={debounce((e) => props.onSearch(e), 300)}
        onChange={(selected: any) => {
          try {
            props.onChangeItem && props.onChangeItem(selected[0]);

            if (selected[0]?.value) {
              props.form.setFieldValue(props.name, selected[0].value);
            }
          }
          catch(ex){
            // skip
          }
        }}
        emptyLabel={
          <Stack alignItems={'center'}>
            {props.option?.length === 0 && !!props.values.length ? (
              <>
                <Typography sx={{ color: '#0c2551' }}>Not found</Typography>
              </>
            ) : (
              <Spinner color="primary" animation="border" />
            )}
          </Stack>
        }
        labelKey="label"
        defaultSelected={props.values ? [props.values] : undefined}
        options={props.option}
        filterBy={['label', 'value', 'code']}
      />
      <div className="sized70 text-field-light w-100 d-flex justify-content-between mh-1rem">
        {props.feedback}
      </div>
    </Form.Group>
  );
};

export default SelectSearchInput;
