import { Card, Stack, Tab, Tabs, Typography } from '@mui/material';
import React from 'react';
import CustomTabPanel from './TabPanel';
import LoadingContent from 'components/LoadingContent';
import { Row } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import ItemContent, { ItemParagraph } from 'screen/summary/components/ItemContent';
import activitiesAndHonorsServices from 'services/activitiesAndHonorsServices';
import { useAppSelector } from 'app/hooks';
import { buildClassName } from './RPersonalInformation';

const RAdditionalInfo = ({ setCurrentModuleId }: { setCurrentModuleId: (id: string) => void }) => {
  const [myAdditionalInfo, setMyAdditionalInfo] = React.useState<any>();
  const [loading, setLoading] = React.useState(false);
  const params: { id?: string | number } = useParams();

  React.useEffect(() => {
    const getData = async () => {
      setLoading(true);
      const res: any = await activitiesAndHonorsServices.getAdditionalInfo(params.id);
      setMyAdditionalInfo(res);
      setLoading(false);
    };
    params.id !== ':id' && getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params.id]);
  const [value, setValue] = React.useState(1);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
    setCurrentModuleId(`7.${newValue}`);
  };
  const checkModule = useAppSelector((store) => store.basicInformation.checkModule);

  const wordCount = (myAdditionalInfo?.additional_comment_1 || "")
        .split(/\s+/)
        .filter(Boolean).length;

  const wordCount2 = (myAdditionalInfo?.additional_comment_2 || "")
        .split(/\s+/)
        .filter(Boolean).length;        
  return (
    <div>
      <Tabs value={value} onChange={handleChange} variant="scrollable" scrollButtons="auto">
        <Tab value={1} label="General" className={buildClassName('7.1', checkModule)} />
        <Tab value={2} label="Common App" className={buildClassName('7.2', checkModule)} />
        <Tab value={3} label="UC App" className={buildClassName('7.3', checkModule)} />
      </Tabs>
      <CustomTabPanel value={value} index={1}>
        <Card sx={{ backgroundColor: 'var(--ui-grey-soft, #F7F8FA)', margin: 2, padding: 2 }}>
          <Typography sx={{ fontSize: '1.75rem', fontWeight: '500', color: '#4E5A6F' }}>
            General
          </Typography>
          <div className="text-field-light p-2">
            Community disruptions such as COVID-19 and natural disasters can have deep and
            long-lasting impacts. If you need it, this space is yours to describe those impacts.
            Colleges care about the effects on your health and well-being, safety, family
            circumstances, future plans, and education, including access to reliable technology and
            quiet study spaces. For more information, check out our COVID-19 FAQ
          </div>
          <Stack justifyContent={'center'} alignItems={'center'}>
            {loading ? (
              <LoadingContent />
            ) : (
              <Row className="w-100">
                {myAdditionalInfo && (
                  <>
                    <ItemContent
                      mdCol={12}
                      values={myAdditionalInfo?.wish_to_share_option}
                      label="Do you wish to share anything on this topic?*"
                    />
                    {myAdditionalInfo?.wish_to_share_option === 'true' && (
                      <ItemContent
                        mdCol={12}
                        label="Please use this space to describe how these events have impacted you.*"
                        values={myAdditionalInfo?.additional_comment_3}
                      />
                    )}
                    <ItemContent
                      mdCol={12}
                      values={myAdditionalInfo?.wish_to_provide_detail_of_circumstances_option}
                      label="Do you wish to provide details of circumstances or qualifications not reflected in the application?*"
                    />
                  </>
                )}
              </Row>
            )}
          </Stack>
        </Card>
      </CustomTabPanel>
      <CustomTabPanel value={value} index={2}>
        <Card sx={{ backgroundColor: 'var(--ui-grey-soft, #F7F8FA)', margin: 2, padding: 2 }}>
          <Typography sx={{ fontSize: '1.75rem', fontWeight: '500', color: '#4E5A6F' }}>
            Common App
          </Typography>
          <Stack justifyContent={'center'} alignItems={'center'}>
            {loading ? (
              <LoadingContent />
            ) : (
              <Row className="w-100">
                <ItemParagraph
                  mdCol={12}
                  label="Additional Information"
                  values={myAdditionalInfo?.additional_comment_1}
                />
                <Stack direction="row" justifyContent={'end'} className="w-100">
                  <span>
                    Word count: {wordCount}/650
                  </span>
                </Stack>
              </Row>
            )}
          </Stack>
        </Card>
      </CustomTabPanel>
      <CustomTabPanel value={value} index={3}>
        <Card sx={{ backgroundColor: 'var(--ui-grey-soft, #F7F8FA)', margin: 2, padding: 2 }}>
          <Typography sx={{ fontSize: '1.75rem', fontWeight: '500', color: '#4E5A6F' }}>
            UC App
          </Typography>
          <Stack justifyContent={'center'} alignItems={'center'}>
            {loading ? (
              <LoadingContent />
            ) : (
              <Row className="w-100">
                <ItemParagraph
                  mdCol={12}
                  label="Additional Comments"
                  values={myAdditionalInfo?.additional_comment_2}
                />
                <Stack direction="row" justifyContent="end" className="w-100">
                  <span>
                    Word count: {wordCount2}/550
                  </span>
                </Stack>
              </Row>
            )}
          </Stack>
        </Card>
      </CustomTabPanel>
    </div>
  );
};

export default RAdditionalInfo;
