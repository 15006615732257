/* eslint-disable react-hooks/exhaustive-deps */
import '../../pricing-table.css';
import { Button } from 'react-bootstrap';
import { useEffect, useState } from 'react';
import PaymentServices from 'services/payment';
import { useHistory } from 'react-router';
import Cookies from 'js-cookie';
import { STRIPE_PRICE_NAME } from 'constants/common';
import { routers } from 'router/router';
import { 
  PriceGoldMonth, PriceGoldYear,
  PriceSilverPlusMonth, PriceSilverPlusYear
} from './components/StripePrices';
import { authenticateSettings } from 'components/helper/appConfig';
import { useAppDispatch } from 'app/hooks';
import { logout } from 'app/basicInformationSlice/basicInformationSlice';

interface Props {}

const Payment = (props: Props) => {
  const dispatch = useAppDispatch();
  let history = useHistory();
  const params = new URLSearchParams(history.location.search);  
  let priceName = params.get('price')  || ''; 
  const canceled = params.get('canceled')  || '';

  const [selectPrice, setSelectPrice] = useState(1);
  const [showAllPrice, setShowAllPrice] = useState(true);
  const [showMontly, setShowMonthly] = useState(true);

  let studentId = Cookies.get('request_id') || '';
  let studentEmail = Cookies.get('userEmail') || '';  
  let token = Cookies.get(authenticateSettings.tokenName);
  let isLogin:boolean = token ? true : false;
  
  if (canceled==='true') {
    let price = Cookies.get('price') || '';
    if (price) {
      priceName = price;
    }      
  }
  // console.log('studentId:', studentId);  
  // console.log('studentEmail:', studentEmail);
  // console.log('cookie price:', priceName);
  if (studentId===''){
    history.push(routers.login.path);
  }

  useEffect(() => {
      switch(priceName){
        // case 'silver_month':
        //   setShowAllPrice(false);
        //   setShowMonthly(true);
        //   setSelectPrice(1);
        //   Cookies.set("price", priceName);
        //   break;
        case 'silver_plus_month':
          setShowAllPrice(false);
          setShowMonthly(true);
          setSelectPrice(5);
          Cookies.set("price", priceName);
          break;
        case 'gold_month':
          setShowAllPrice(false);  
          setShowMonthly(true);
          setSelectPrice(2);
          Cookies.set("price", priceName);
          break;
        // case 'silver_year':
        //   setShowAllPrice(false);
        //   setShowMonthly(false);
        //   setSelectPrice(3);
        //   Cookies.set("price", priceName);
        //   break;
        case 'silver_plus_year':
          setShowAllPrice(false);
          setShowMonthly(false);
          setSelectPrice(6);
          Cookies.set("price", priceName);
          break;
        case 'gold_year':
          setShowAllPrice(false);
          setShowMonthly(false);
          setSelectPrice(4);
          Cookies.set("price", priceName);
          break;
        default:
          setShowAllPrice(true);
          Cookies.set("price", '');
      }
  }, []);


  // const onSelectSilverMonth = (n:number) => {
  //   setSelectPrice(1);
  // }

  const onSelectGoldMonth = (n:number) => {
    setSelectPrice(2);
  }

  // const onSelectSilverYear = (n:number) => {
  //   setSelectPrice(3);
  // }

  const onSelectGoldYear = (n:number) => {
    setSelectPrice(4);
  }

  const onSelectSilverPlusMonth = (n:number) => {
    setSelectPrice(5);
  }
  const onSelectSilverPlusYear = (n:number) => {
    setSelectPrice(6);
  }
  

  const handleSubmit = async (e: any) => {
    e.preventDefault();

    const domainUrl = `${window.location.origin}`;
    const trialDays = 0; // No trial
    let price_Name = '';
    switch(selectPrice) 
    {
      // case 1:
      //   price_Name  = STRIPE_PRICE_NAME.SilverMonth;
      //   break;
      case 2:
        price_Name = STRIPE_PRICE_NAME.GoldMonth;
        break;
      // case 3:
      //   price_Name  = STRIPE_PRICE_NAME.SilverYear;
      //   break;
      case 4:
        price_Name = STRIPE_PRICE_NAME.GoldYear;
        break;
      case 5:
        price_Name = STRIPE_PRICE_NAME.SilverPlusMonth;
        break;
      case 6:
        price_Name = STRIPE_PRICE_NAME.SilverPlusYear;
        break; 
      }
    if (price_Name) {
      try {
        console.log('price_Name:', price_Name);
        const checkoutUrl = await PaymentServices.createCheckoutSession(domainUrl, price_Name, trialDays, studentId, studentEmail);
        if (checkoutUrl) {
          window.location.replace(`${checkoutUrl}`);
        }
        else {
          console.log('Error creatting the Checkout Url.');
        }
      }
      catch(er){
        console.log('Error: ', er);
      }
    }
  };  
  
  const handleLogout = () => {
    if (window.Intercom) {
      window.Intercom('shutdown');
    }
    dispatch(logout());
    Cookies.remove(authenticateSettings.tokenName);
    Cookies.remove('userId');
    Cookies.remove('request_id');
    Cookies.remove('price');
    history.push(routers.login.path);
  };
    
  return (    
    <form id="payment-form" onSubmit={handleSubmit}>
<div className='row'>
  <div className='vh-100 d-none d-sm-block d-md-flex flex-wrap justify-content-center align-items-center background-login col-md-4'
    style={{ backgroundImage:'url("/assets/images/bg-login.png")'}}
  >
    <img src="../assets/images/login-logo-blue.png" alt='logo' className="position-absolute p-lg-0 p-md-3" height="120" />
  </div>
  <div className="vh-100 overflow-auto pb-5 col-md-8 col-12">
      {(!isLogin) && (
        <div className="row w-100 px-5 pt-md-3" style={{ minHeight: '58px' }}>
          <div className='col-md-12 d-flex mx-3 flex-wrap justify-content-between align-items-center'>
            <div className='d-flex'>
              <button className="btn btn-back" 
                  style={{color:"#fff", backgroundColor: "#4d88f0", position:'fixed', left: '3em', top: '2em'}}
                  onClick={()=>{window.history.back();}}>&laquo; Back</button>
            </div>
            <span className="mt-2"><span> Already a member ?</span>
            <Button
              className="p-0 mx-1 pb-1"
              onClick={() => history.push(routers.login.path)}
              variant="link"
            >
              Login
            </Button>
            
            </span>
          </div>
        </div>
      )}
      {(isLogin) && (
        <div className="row w-100 px-5 pt-md-3" style={{ minHeight: '58px' }}>
          <div className='col-md-12 d-flex mx-3 flex-wrap justify-content-between align-items-center'>
            <div className='d-flex'>
              <button className="btn btn-back" 
                  style={{color:"#fff", backgroundColor: "#4d88f0", position:'fixed', left: '3em', top: '2em'}}
                  onClick={()=>{window.history.back();}}>&laquo; Back</button>
            </div>
            <span className="mt-2"><span>Already login ?</span>
            <Button
              className="p-0 mx-1 pb-1"
              onClick={handleLogout}
              variant="link"
            >Logout</Button>                       
            </span>
          </div>
        </div>
      )}
      <div className='row'>
          <div className="col-md-12 px-5 mt-5 d-flex justify-content-left">
            <h1>Payment</h1>
          </div>
      </div>      
      <div className='row'>
          <div className="col-md-12 px-5 d-flex justify-content-left">
            <div className="radio-block">
              <input type="radio" name="monthly" id="monthly-on" checked={showMontly} onChange={()=>{
                  setShowMonthly(true);
                  switch(selectPrice){
                    // case 3:
                    //   setSelectPrice(1);
                    //   break;
                    case 4:
                      setSelectPrice(2);
                      break;
                    case 6:
                      setSelectPrice(5);
                      break;
                  }
              }} />
              <label className="on-label"  htmlFor="monthly-on">Monthly</label>
              <input type="radio" name="monthly" id="monthly-off" checked={!showMontly} onChange={()=>{
                  setShowMonthly(false);
                  switch(selectPrice){
                    // case 1:
                    //   setSelectPrice(3);
                    //   break;
                    case 2:
                      setSelectPrice(4);
                      break;
                    case 5:
                      setSelectPrice(6);
                      break;
                  }
                }} />
              <label className="off-label" htmlFor="monthly-off">Yearly</label>
              <span className="selected" aria-hidden="true"></span>
            </div>
          </div>
          <div className='col-12 '>
            <div className='price-box mt-4'>
                <div className="PricingTable is-whiteButtonText is-lightColorBackground flex-container direction-column justify-content-center align-items-center">
                    <div className="PricingTable-grid direction-row">
                      {/* {showMontly && (showAllPrice || selectPrice===1) && (
                        <PriceSilverMonth onSelect={onSelectSilverMonth} isSelected={(selectPrice===1)} isRecommended={false} />
                      )} */}
                      {showMontly && (showAllPrice || selectPrice===5) && (
                        <PriceSilverPlusMonth onSelect={onSelectSilverPlusMonth} isSelected={(selectPrice===5)} isRecommended={false} />
                      )}
                      {showMontly && (showAllPrice || selectPrice===2) && (
                        <PriceGoldMonth onSelect={onSelectGoldMonth} isSelected={(selectPrice===2)} isRecommended={true} />
                      )} 
                      {/* {!showMontly && (showAllPrice || selectPrice===3) && (
                        <PriceSilverYear onSelect={onSelectSilverYear} isSelected={(selectPrice===3)} isRecommended={false} />
                      )} */}
                      {!showMontly && (showAllPrice || selectPrice===6) && (
                        <PriceSilverPlusYear onSelect={onSelectSilverPlusYear} isSelected={(selectPrice===6)} isRecommended={false} />
                      )}
                      {!showMontly && (showAllPrice || selectPrice===4) && (
                        <PriceGoldYear onSelect={onSelectGoldYear} isSelected={(selectPrice===4)} isRecommended={true} />
                      )} 
                    </div>
                </div>
          </div>
          </div>
      </div>
  </div>
</div>
    </form>   
  );
}

export default Payment;