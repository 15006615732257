/* eslint-disable react-hooks/exhaustive-deps */
import * as React from 'react';
import { styled } from '@mui/material/styles';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Collapse from '@mui/material/Collapse';
import IconButton, { IconButtonProps } from '@mui/material/IconButton';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Row, Button } from 'react-bootstrap';
import { useFormik } from 'formik';
import SelectInput from 'components/input/SelectInput';
import TextInput from 'components/input/textInput';
import GroupRadioButtonRounded from 'components/input/GroupRadioButtonRounded';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import Cookies from 'js-cookie';
import { useHistory } from 'react-router-dom';
import { routers } from 'router/router';
import { logout } from 'app/basicInformationSlice/basicInformationSlice';
import { authenticateSettings } from 'components/helper/appConfig';
import {
  checkEnableParseJSON,
  optionValues,
  removeDuplicate,
  reorder,
} from 'components/helper/helper';
import { getCollege } from 'app/highSchoolSlice/highSchoolSlice';
import GroupRadioButton from 'components/input/GroupRadioButton';
import { collegeCourseSubjectOption } from './collegeCourseContant';
import CheckboxInput from 'components/input/CheckboxInput';
import CardConfig from 'components/CardConfig';
import { CollegeCourseConfigInitialType } from './collegeCourse.type';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import SelectSearchInput from 'components/input/SelectSearchInput';

interface ExpandMoreProps extends IconButtonProps {
  expand: boolean;
}

const ExpandMore = styled((props: ExpandMoreProps) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
  marginLeft: 'auto',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest,
  }),
}));

interface Props {
  handleSave: (value: any) => void;
  item: any;
  index: number;
  total: number;
}
const CollegeCourseConfig = (props: Props) => {
  const MAX_COURSES = 9;
  const [termOptions, setTermOptions] = React.useState<any[]>([
    'Fall',
    'Winter',
    'Spring',
    'Summer',
  ]);
  const [yearOptions, setYearOptions] = React.useState<any[]>(
    optionValues(Array.from({ length: 78 }, (_, i) => i + 1950)).reverse()
  );
  const [collegeOptions, setCollegeOptions] = React.useState<any[]>([]);
  const myColleges = useAppSelector((store) => store.highSchool.myCollege);
  const dispatch = useAppDispatch();
  const history = useHistory();
  let studentId = Cookies.get('userId');
  let token = Cookies.get(authenticateSettings.tokenName);
  const handleLogout = () => {
    dispatch(logout());
    Cookies.remove(authenticateSettings.tokenName);
    Cookies.remove('userId');
    history.push(routers.login.path);
  };

  React.useEffect(() => {
    studentId = Cookies.get('userId');
    token = Cookies.get(authenticateSettings.tokenName);
    if (token && studentId) {
      if (!myColleges) {
        dispatch(getCollege(studentId));
      }
      const colleges_taken_coursework_array = checkEnableParseJSON(
        myColleges?.colleges_taken_coursework_array
      );
      if (colleges_taken_coursework_array) {
        setCollegeOptions(colleges_taken_coursework_array);
        handlePickCollege(props.item?.collegeName, colleges_taken_coursework_array);
      }
    } else {
      handleLogout();
    }
  }, [myColleges]);
  // dept, collegeCourseSubject, courseName, courseNumber, subject, languageName , creditsPerHours, honorStatus, completionStatus, grade, transferable
  const formItem = useFormik({
    initialValues: {
      collegeName: '',
      collegeInformation: null as any,
      scheduleSystem: '',
      academicYear: '',
      term: '',
      academicStatus: 'Select',
      collegeCourseArray: [],
    } as CollegeCourseConfigInitialType,
    onSubmit: (values) => {},
  });
  const [expanded, setExpanded] = React.useState(false);
  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  React.useEffect(() => {
    if (props.item?.collegeName) {
      formItem.setValues({ ...formItem.values, ...props.item });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  React.useEffect(() => {
    if (formItem.dirty) {
      props.handleSave({
        ...formItem.values,
        // TODO
        // languageName: formItem.values.subject.includes('Other Than English')
        //   ? ''
        //   : formItem.values.languageName,
      });
    }
  }, [formItem.values]);
  const renderTitle = () => {
    const values: string[] = [];
    formItem.values.collegeName.length && values.push(formItem.values.collegeName);
    formItem.values.term.length && values.push(formItem.values.term);
    formItem.values.academicYear && values.push(formItem.values.academicYear);
    return <span className="p-1">{values.join(' - ')}</span>;
  };

  const [forceRenderKey, setForceRenderKey] = React.useState(0);

  const onDragEnd = async (result: any) => {
    if (!result.destination) {
      return;
    }

    if (result.destination.index === result.source.index) {
      return;
    }
    const dataList = reorder(
      formItem.values.collegeCourseArray,
      result.source.index,
      result.destination.index
    );
    formItem.setFieldValue('collegeCourseArray', [...dataList]);
    setForceRenderKey(forceRenderKey + 1);
  };

  const handleAddCourse = () => {
    if (formItem.values.collegeCourseArray.length > MAX_COURSES - 1) return;
    formItem.setFieldValue('collegeCourseArray', [
      ...formItem.values.collegeCourseArray,
      {
        dept: '',
        collegeCourseSubject: '',
        courseName: '',
        courseNumber: '',
        subject: '',
        languageName: '',
        creditsPerHours: '',
        grade: '--',
        honorStatus: '--',
        completionStatus: 'Select',
        transferable: false,
      },
    ]);
  };

  const handlePickCollege = (collegeName: string, collegeDate: any[]) => {
    const collegeInformation = collegeDate.find((item) => item.fullName === collegeName);
    formItem.setFieldValue('collegeInformation', collegeInformation);
    setYearOptions(
      removeDuplicate(
        (collegeInformation?.grades_fall ?? []).concat(
          collegeInformation?.grades_spring,
          collegeInformation?.grades_summer,
          collegeInformation?.grades_winter
        )
      )
    );
  };
  return (
    <div className="m-0">
      <Card className="w-100 my-3 px-0">
        <CardActions
          disableSpacing
          onClick={handleExpandClick}
          sx={{
            backgroundColor: expanded ? '#AAEBF7' : 'none',
            borderBottom: expanded ? '2px solid #4D88F0' : 'none',
          }}
        >
          <div className="w-100 d-flex justify-content-between">
            <span className="p-1">{renderTitle()}</span>
            <strong className="text-field-light">
              {props.index}/{props.total}
            </strong>
          </div>
          <ExpandMore
            expand={expanded}
            onClick={handleExpandClick}
            aria-expanded={expanded}
            aria-label="show more"
          >
            <ExpandMoreIcon />
          </ExpandMore>
        </CardActions>
        <Collapse in={expanded} timeout="auto" unmountOnExit>
          <CardContent>
            <Row className="px-3">
              <SelectInput
                option={collegeOptions.map((i) => i.fullName)}
                mdCol="12"
                name="collegeName"
                label="College"
                values={formItem.values.collegeName}
                onChange={(e) => {
                  formItem.handleChange(e);
                  handlePickCollege(e.target.value, collegeOptions);
                }}
              />
              <SelectInput
                className="coallition-flag"
                form={formItem}
                option={yearOptions}
                label="Academic year *"
                values={formItem.values.academicYear}
                name="academicYear"
                onChange={(e) => {
                  formItem.handleChange(e);
                  let options = [];
                  const collegeInformation = collegeOptions.find(
                    (item) => item.fullName === formItem.values.collegeName
                  );
                  if (collegeInformation) {
                    if (collegeInformation.grades_spring.includes(e.target.value)) {
                      options.push('Spring');
                    }

                    if (collegeInformation.grades_summer.includes(e.target.value)) {
                      options.push('Summer');
                    }
                    if (collegeInformation.grades_fall.includes(e.target.value)) {
                      options.push('Fall');
                    }
                    if (collegeInformation.grades_winter.includes(e.target.value)) {
                      options.push('Winter');
                    }
                  }
                  setTermOptions(options);
                }}
              />
              <SelectInput
                form={formItem}
                option={[
                  'Select',
                  'Freshman',
                  'Sophomore',
                  'Junior',
                  'Senior',
                  'Graduate',
                  'Post-Baccalaureate',
                ]}
                label="College Academic Status"
                values={formItem.values.academicStatus}
                name="academicStatus"
                onChange={formItem.handleChange}
              />
              <div />
              <GroupRadioButtonRounded
                label="Schedule System *"
                mdCol={2}
                options={[{ value: 'Semester' }, { value: 'Quarter' }]}
                name="scheduleSystem"
                values={formItem.values.scheduleSystem}
                onChange={formItem.handleChange}
              />

              <GroupRadioButton
                options={termOptions}
                label="Term *"
                values={formItem.values.term}
                name="term"
                onChange={formItem.handleChange}
              />
              <div />

              <DragDropContext onDragEnd={onDragEnd} key={forceRenderKey}>
                <Droppable droppableId="list">
                  {(provided) => (
                    <div ref={provided.innerRef} {...provided.droppableProps}>
                      {(formItem.values.collegeCourseArray ?? []).map((item, index) => (
                        <div key={index + '-' + formItem.values.collegeCourseArray.length}>
                          <Draggable
                            draggableId={index + '-' + formItem.values.collegeCourseArray.length}
                            index={index}
                          >
                            {(provided) => (
                              <div
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                              >
                                <div
                                  key={index + '-' + formItem.values.collegeCourseArray.length}
                                  className="position-relative"
                                >
                                  <CollegeCourse
                                    form={formItem}
                                    i={index}
                                    total={formItem.values.collegeCourseArray.length}
                                  />
                                  <RemoveCircleOutlineIcon
                                    className="position-absolute text-field-light pointer"
                                    style={{ right: '-24px', top: '12px' }}
                                    onClick={() => {
                                      const newData = [...formItem.values.collegeCourseArray];
                                      newData.splice(index, 1);
                                      formItem.setFieldValue('collegeCourseArray', newData);
                                    }}
                                  />
                                </div>
                              </div>
                            )}
                          </Draggable>
                        </div>
                      ))}
                      {provided.placeholder}
                    </div>
                  )}
                </Droppable>
              </DragDropContext>
              <div className="my-3 w-100 d-flex flex-column align-items-center">
                <div className="text-field-light sized70">{`You can add up to ${MAX_COURSES} courses per term`}</div>
                <Button variant="primary" className="p-2 px-5" onClick={handleAddCourse}>
                  Add Subject
                </Button>
              </div>
            </Row>
          </CardContent>
        </Collapse>
      </Card>
    </div>
  );
};

const CollegeCourse = ({ form, i, total }: any) => {
  const nameBuilded = `collegeCourseArray[${i}].`;
  return (
    <CardConfig
      handleSave={() => {}}
      headerElement={
        <div className="w-100 d-flex justify-content-between">
          <span className="p-1">
            {[
              form.values.collegeCourseArray[i]?.dept,
              form?.values?.collegeCourseArray[i]?.courseNumber,
              form?.values?.collegeCourseArray[i]?.courseName,
              form?.values?.collegeCourseArray[i]?.collegeCourseSubject,
              form?.values?.collegeCourseArray[i]?.honorStatus,
              form?.values?.collegeCourseArray[i]?.grade,
              form?.values?.collegeCourseArray[i]?.creditsPerHours,
            ]
              .filter((i) => !!i)
              .join(' - ')}
          </span>

          <strong className="text-field-light">
            {i + 1}/{total}
          </strong>
        </div>
      }
    >
      <TextInput
        countText={20}
        label="Dept. Abbreviation - Ex. ENG"
        name={`${nameBuilded}dept`}
        values={form.values.collegeCourseArray[i]?.dept}
        onChange={form.handleChange}
      />
      <TextInput
        countText={50}
        label="Course Number - Ex. 100"
        name={`${nameBuilded}courseNumber`}
        values={form.values.collegeCourseArray[i]?.courseNumber}
        onChange={form.handleChange}
      />

      <TextInput
        countText={50}
        mdCol={'12'}
        label="Course Name*"
        name={`${nameBuilded}courseName`}
        values={form.values.collegeCourseArray[i]?.courseName}
        onChange={form.handleChange}
      />

      <SelectSearchInput
        form={form}
        option={collegeCourseSubjectOption.map((e, index) => ({
          label: e,
          value: e,
          code: e,
          id: index,
        }))}
        label="College Course Subject"
        values={
          form.values.collegeCourseArray[i]?.collegeCourseSubject
            ? {
                label: form.values.collegeCourseArray[i]?.collegeCourseSubject,
                value: form.values.collegeCourseArray[i]?.collegeCourseSubject,
                code: form.values.collegeCourseArray[i]?.collegeCourseSubject,
                id: collegeCourseSubjectOption.indexOf(
                  form.values.collegeCourseArray[i]?.collegeCourseSubject
                ),
              }
            : undefined
        }
        name={`${nameBuilded}collegeCourseSubject`}
        onChangeItem={(e) => {
          form.setFieldValue(`${nameBuilded}collegeCourseSubject`, e.value);
        }}
        onSearch={(keyword: string) => {}}
      />

      <SelectInput
        option={[
          '--',
          'Civics/American Government',
          'U.S. History',
          'World History/Cultures/Hist. Geography',
          'English',
          'English-ESL/ELD',
          'Algebra I',
          'Geometry',
          'Algebra II',
          'Mathematics I (Integrated)',
          'Mathematics II (Integrated)',
          'Mathematics III (Integrated)',
          'Algebra II/Trigonometry',
          'Statistics',
          'Pre-Calculus',
          'Calculus',
          'Other Advanced Mathematics',
          'Computer Science',
          'Biology/Life Sciences',
          'Biology/Earth & Space Sciences',
          'Chemistry',
          'Chemistry/Earth & Space Sciences',
          'Physics',
          'Physics/Earth & Space Sciences',
          'Integrated Science 1',
          'Integrated Science 2',
          'Integrated Science 3',
          'Applied Science',
          'Computer Science',
          'Earth & Space Sciences',
          'Engineering',
          'Interdisciplinary Sciences',
          'Yr 1 Other Than English',
          'Yr 2 Other Than English',
          'Yr 3 Other Than English',
          'Yr 4+ Other Than English',
          'Dance ',
          'Music',
          'Theater',
          'Visual Arts',
          'Interdisciplinary Arts',
          'Elective -English',
          'Elective -ESL/ELD',
          'Elective -History/Social Science',
          'Elective -Interdisciplinary',
          'Elective -Language Other than English',
          'Elective -Mathematics',
          'Elective -Math -Computer Science',
          'Elective -Science-Biology/Life Sciences',
          'Elective -Science-Integrated Science',
          'Elective -Science-Physical Science',
          'Elective -Visual & Performing Arts',
          'Physical Education/Health',
          'Religion',
        ]}
        label="A-G Subject Area"
        name={`${nameBuilded}subject`}
        values={form.values.collegeCourseArray[i]?.subject}
        onChange={form.handleChange}
      />
      {form.values.collegeCourseArray[i]?.subject?.includes('Other Than English') && (
        <SelectInput
          option={[
            '--',
            'American Sign Language',
            'Arabic',
            'Armenian',
            'Chinese',
            'Farsi',
            'Filipino',
            'French',
            'German',
            'Greek',
            'Hebrew',
            'Hindi',
            'Hmong',
            'Italian',
            'Japanese',
            'Khmer',
            'Korean',
            'Latin',
            'Other Language',
            'Portuguese',
            'Punjabi',
            'Russian',
            'Spanish',
            'Tamil',
            'Turkish',
            'Vietnamese',
          ]}
          label="Language Name"
          name={`${nameBuilded}languageName`}
          values={form.values.collegeCourseArray[i]?.languageName}
          onChange={form.handleChange}
        />
      )}

      <TextInput
        type="number"
        decimalStep={0.1}
        label="Credits/hours"
        max={9999}
        min={1}
        name={`${nameBuilded}creditsPerHours`}
        values={form.values.collegeCourseArray[i]?.creditsPerHours}
        onChange={(e) =>
          form.setFieldValue(`${nameBuilded}creditsPerHours`, Math.round(e.target.value * 10) / 10)
        }
      />
      <SelectInput
        option={['--', 'NH', 'CL']}
        label="Honors Status"
        name={`${nameBuilded}honorStatus`}
        values={form.values.collegeCourseArray[i]?.honorStatus}
        onChange={form.handleChange}
      />

      <SelectInput
        option={['Select', 'In Progress/Planned', 'Completed']}
        label="Completion Status"
        name={`${nameBuilded}completionStatus`}
        values={form.values.collegeCourseArray[i]?.completionStatus}
        onChange={form.handleChange}
      />
      <SelectInput
        option={
          form.values.collegeCourseArray[i]?.completionStatus === 'In Progress/Planned'
            ? [
                '--',
                'IP',
                'PL',
                'A',
                'B',
                'C',
                'D',
                'F',
                'PS',
                'NP',
                'CR',
                'NC',
                'EW',
                'WI',
                'WF',
                'WU',
                'IN',
              ]
            : [
                '--',
                'A+',
                'A',
                'A-',
                'B+',
                'B',
                'B-',
                'C+',
                'C',
                'C-',
                'D+',
                'D',
                'D-',
                'F',
                'PS',
                'NP',
                'CR',
                'NC',
                'EW',
                'WI',
                'WF',
                'WU',
                'IN',
              ]
        }
        label="Grade*"
        name={`${nameBuilded}grade`}
        values={form.values.collegeCourseArray[i]?.grade}
        onChange={form.handleChange}
        // feedback={`If you're currently enrolled in this course, please write "In Progress" in this field.`}
      />
      <div className="mx-1">
        <CheckboxInput
          name={`${nameBuilded}transferable`}
          values={form.values.collegeCourseArray[i]?.transferable}
          onChange={form.handleChange}
          label="Transferable"
        />
      </div>
    </CardConfig>
  );
};

export default CollegeCourseConfig;
