import { optionValues } from 'components/helper/helper';
import DatePickerInput from 'components/input/DatePicker';
import SelectInput from 'components/input/SelectInput';
import TextInput from 'components/input/textInput';
import moment from 'moment';
import React from 'react';

interface Props {
  formik: any;
  highestLabel: string;
  highestValuesName: string;
  scoreValuesName: string;
  dateValuesName: string;
  options?: any[];
}

const TemplateScore = (props: Props) => {
  React.useEffect(() => {
    if (props.formik.values[props.highestValuesName]) {
      props.formik.setFieldValue(
        props.scoreValuesName,
        props.formik.values[props.highestValuesName]
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.formik.values[props.highestValuesName]]);
  return (
    <>
      <SelectInput
        // mdCol="5"
        form={props.formik}
        option={props.options ?? optionValues(Array.from(Array(37).keys()).reverse())}
        label={props.highestLabel}
        name={props.highestValuesName}
        onChange={props.formik.handleChange}
        values={props.formik.values[props.highestValuesName]}
      />
      <TextInput
        className="d-none"
        label="Score*"
        type="number"
        max={36}
        mdCol="3"
        name={props.scoreValuesName}
        onChange={props.formik.handleChange}
        values={props.formik.values[props.scoreValuesName]}
      />
      <DatePickerInput
        className="common-coallition-flag end-2-flag"
        // mdCol="4"
        label="Test Date*"
        name={props.dateValuesName ?? moment().format()}
        onChange={(values) => props.formik.setFieldValue(props.dateValuesName, values)}
        values={props.formik.values[props.dateValuesName]}
      />
    </>
  );
};

export default TemplateScore;
