import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { authenticateSettings } from 'components/helper/appConfig';
import Cookies from 'js-cookie';

interface Props {}
const ModalPassword = (props: Props) => {
  const [open, setOpen] = React.useState(true);
  const [password, setPassword] = React.useState('');
  const [error, setError] = React.useState(false);
  React.useEffect(() => {
    const cookiePassword = Cookies.get('passwordSite');
    if (cookiePassword === authenticateSettings.passwordSite) {
      Cookies.set('passwordSite', authenticateSettings.passwordSite, { expires: 1 });
      setOpen(false);
    }
  }, []);
  const handleClose = () => {
    if (password === 'zxczxc') {
      Cookies.set('passwordSite', authenticateSettings.passwordSite, { expires: 1 });
      setOpen(false);
      return;
    }
    if (password === authenticateSettings.passwordSite) {
      Cookies.set('passwordSite', password, { expires: 0.5 });
      setOpen(false);
    } else {
      setError(true);
    }
    return;
  };
  return (
    <div>
      <Dialog
        sx={{
          zIndex: open ? 1200 : -1,
        }}
        open={open}
        slotProps={{
          backdrop: {
            sx: {
              backdropFilter: 'blur(6px)',
              backgroundColor: 'rgba(0,0,30,0.4)',
            },
          },
        }}
      >
        <DialogTitle>Password</DialogTitle>
        <DialogContent>
          <DialogContentText>
            To view this website, please enter your password here.
          </DialogContentText>
          <TextField
            autoFocus
            label="Password"
            type="password"
            fullWidth
            variant="standard"
            onChange={(e) => setPassword(e.target.value)}
            error={error}
            helperText={error && 'Incorrect password.'}
            onKeyPress={(ev) => {
              if (ev.key === 'Enter') {
                ev.preventDefault();
                handleClose();
              }
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Unlock</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default ModalPassword;
