import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import { Collapse, List, ListItemButton, ListSubheader } from '@mui/material';
import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { counselorMenuGroups } from 'router/router';
import PeopleAltOutlinedIcon from '@mui/icons-material/PeopleAltOutlined';
import ReviewStudent from 'screen/counselor/ReviewStudent';
import { useAppSelector } from 'app/hooks';
import { removeDuplicate } from 'components/helper/helper';

type Props = {};

const CounselorListMenu = (props: Props) => {
  const [open, setOpen] = React.useState<string | null>('2024');
  let history = useHistory();
  let location = useLocation();

  const handleClick = (route: any) => {
    if (open === route.name) return setOpen(null);
    setOpen(route.name);
    if (route.isGroup === false) {
      history.push(route.route.path);
    }
  };

  const menu = useAppSelector((store) => store.counselor.studentList);
  const [menuGroups, setMenuGroups] = React.useState(counselorMenuGroups);
  React.useEffect(() => {
    if (menu?.length) {
      let menuRebuild = menu
        .filter((i) => i.student_info)
        .map((item) => ({
          name: [item.student_info?.first_name, item.student_info?.last_name].join(' '),
          id: item.student_id,
          groupYear: item?.student_info?.year_finish_high_school,
          component: ReviewStudent,
          path: `/application/counselor/${item.student_id}`,
        }))
        .sort((a:any, b:any) => {
          const aLower:string = a.name.toLowerCase();
          const bLower:string = b.name.toLowerCase();        
          if (aLower < bLower) return -1;
          if (aLower > bLower) return 1;
          return 0;
        })
        .sort((a, b) => b.groupYear - a.groupYear);

      let menuGroup = removeDuplicate(menuRebuild.map((e: any) => e.groupYear)).map((menuItem) => ({
        name: menuItem,
        isGroup: true,
        children: menuRebuild.filter((e: any) => e.groupYear === menuItem),
      }));
      setMenuGroups(menuGroup);
      menuGroup[0].name && setOpen(menuGroup[0].name);
    }
  }, [menu]);

  return (
    <div style={{ height: '100%', overflowY: 'auto' }}>
      <List
        sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}
        component="nav"
        subheader={
          <ListSubheader component="div" className="fw-bold text-dark fs-5">
            <PeopleAltOutlinedIcon className="mx-1 pb-1" />
            Students
          </ListSubheader>
        }
      >
        {menuGroups.map((route: any, index: any) => {
          return (
            <div key={index}>
              <ListItemButton
                onClick={() => handleClick(route)}
                className={`px-1 my-1 ${open === route.name && 'bg-light'}`}
              >
                <span className="text-dark fw-5 mx-2 fw-bold">{route.name}</span>
                {route.isGroup &&
                  (open === route.name ? (
                    <ExpandLess fontSize="small" className="text-field-light" />
                  ) : (
                    <ExpandMore fontSize="small" className="text-field-light" />
                  ))}
              </ListItemButton>
              {route.isGroup && (
                <Collapse in={open === route.name} timeout="auto" unmountOnExit>
                  <div style={{ height: '100%', overflowY: 'auto' }}>
                    <List component="div" disablePadding>
                      {route.children.map((child: any, i: number) => {
                        return (
                          <ListItemButton
                            key={i}
                            sx={{ pl: 2, pt: 2 }}
                            onClick={() => history.push(child?.path)}
                          >
                            <span className="text-secondary">{child?.icon}</span>
                            <span
                              className={
                                location.pathname === child?.path
                                  ? 'text-primary fw-bold'
                                  : 'text-dark'
                              }
                            >
                              {child?.name}
                            </span>
                          </ListItemButton>
                        );
                      })}
                    </List>
                  </div>
                </Collapse>
              )}
            </div>
          );
        })}
      </List>
    </div>
  );
};

export default CounselorListMenu;
