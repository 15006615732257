import React from "react";



interface Props {}

const SettingBilling = (props: Props) => {
    const portalUrl = "https://billing.stripe.com/p/session/test_YWNjdF8xTnpSNkpKSWZiZlJXRDdFLF9RRmlLQ0haWnlvOTk5SUFLRXIxY3Z1TklKVHhaYUto0100uGAZLKNH";

    return (
        <div className="tab-wrap-panel">
            <div className="tab-panel2">
            <div className="tab-panel2-content">

            </div>
            </div>
        </div>
  );
}

export default SettingBilling;