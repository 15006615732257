/* eslint-disable react-hooks/exhaustive-deps */
import LoadingContent from 'components/LoadingContent';
import { useFormik } from 'formik';
import React from 'react';
import TopProcessBar from 'screen/selectCollege/TopProcessBar';
import PeopleAltOutlinedIcon from '@mui/icons-material/PeopleAltOutlined';
import { Container, Form, Row, Spinner, Button } from 'react-bootstrap';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import Cookies from 'js-cookie';
import { useHistory } from 'react-router-dom';
import { routers } from 'router/router';
import { logout } from 'app/basicInformationSlice/basicInformationSlice';
import { authenticateSettings } from 'components/helper/appConfig';
import SelectInput from 'components/input/SelectInput';
import TextInput from 'components/input/textInput';
import {
  checkEnableParseJSON,
  fullSuffixOptions,
  occupationOptions,
} from 'components/helper/helper';
import SelectPhoneCodeInput from 'components/input/SelectPhoneCodeInput';
import TextIconInput from 'components/input/TextIconInput';
import GroupRadioButton from 'components/input/GroupRadioButton';
import PhoneAndroidIcon from '@mui/icons-material/PhoneAndroid';
import SelectSearchInput from 'components/input/SelectSearchInput';
import collegeServices from 'services/collegeServices';
import { getHousehold, updateHousehold } from 'app/family/familySlice';
import Education from '../parent/components/Education';
import { debounce } from 'lodash';
import YesNoSwitch from 'components/input/YesNoSwitch';
import Residency from '../parent/components/Residency';
import { countryOptions } from 'components/helper/countryPhoneCode';
import CheckboxInput from 'components/input/CheckboxInput';

type Props = {};

const LegalGuardian = (props: Props) => {
  const [stateOptions, setStateOptions] = React.useState<any[]>([]);
  React.useEffect(() => {
    collegeServices.getState().then((res: any) => {
      const states = res.map((item: any) => {
        return item.descr;
      });
      setStateOptions(states);
    });
  }, []);
  const [loading, setLoading] = React.useState(false);
  const dispatch = useAppDispatch();
  const history = useHistory();  
  let studentId = Cookies.get('userId');
  let token = Cookies.get(authenticateSettings.tokenName);
  const family = useAppSelector((store) => store.family);
  const formik = useFormik({
    initialValues: {
      prefix: '',
      first_name: '',
      middle_name: '',
      last_name: '',
      former_last_name: '',
      suffix: '',
      step_parent_relationship: '',
      step_parent_email: '',
      country_code: '',
      phone_number: '',
      phone_number_type: '',
      occupation: '',
      other_occupation_detail: '',
      employment_status: '',
      currently_employed_or_retire_from_college: '',
      college_or_university: '',
      current_employer: '',
      education_level: '',
      total_number_institutions_attended: '',
      parent_graduation_college_array: [],
      college_code: '',
      college_state: '',
      college_city: '',
      csu_info: {
        is_give_permission_communicate_to: '',
        is_give_permission_to_receive_information: '',
        isCurrentPermanentResidentOfCalifornia: '',
        isLivedInCaliforniaContinuouslySinceBirth: '',
        whenStayInCaliforniaBegin: '',
        priorCountry: '',
        priorState: '',
        fromWhenToWhenLiveThere: '',
        ToWhenToWhenLiveThere: '',
        stateRegardAsPermanentHome: '',
        parent_address_is: '',
        different_parent_address_country: '',
        different_parent_address_line_1: '',
        different_parent_address_line_2: '',
        different_parent_address_city: '',
        different_parent_address_state: '',
        different_parent_address_province: '',
        different_parent_address_zip_code: '',
        is_authorized_UC_to_share_contact_information: false,
      },
    },
    onSubmit: async (values) => {
      const newValues = {
        ...family.myHousehold,
        legal_guardian: JSON.stringify(values),
        student_id: parseInt(studentId || ''),
      };
      await dispatch(updateHousehold(newValues));
    },
  });

  const handleLogout = () => {
    dispatch(logout());
    Cookies.remove(authenticateSettings.tokenName);
    Cookies.remove('userId');
    history.push(routers.login.path);
  }; 

  const handleContinue = () => {
    const nextRoute = Object.keys(family.roleFamily).find(
      (key, index) =>
        index > Object.keys(family.roleFamily).indexOf('legalGuardian') &&
        family.roleFamily[key] === true
    );
    formik.handleSubmit();
    history.push(nextRoute ? routers?.[nextRoute]?.path : routers.enrolimentStatus.path);
  };

  const getDetail = async () => {
    studentId = Cookies.get('userId');
    token = Cookies.get(authenticateSettings.tokenName);
    if (token && studentId) {
      formik.resetForm();
      setLoading(true);
      let res = await dispatch(getHousehold(studentId));
      const dataLoaded: any = checkEnableParseJSON(res.payload?.legal_guardian);
      if (dataLoaded) {
        formik.setValues({
          ...formik.values,
          ...dataLoaded,
          total_number_institutions_attended:
            dataLoaded.total_number_institutions_attended?.toString(),
          parent_graduation_college_array: checkEnableParseJSON(
            dataLoaded.parent_graduation_college_array,
            []
          ),
        });
      }
  
      setLoading(false);
      formik.setStatus('idle');      
    }
    else {
      handleLogout();
    }
  };

  React.useEffect(() => {
    getDetail();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  // Auto save function ==========
  React.useEffect(() => {
    if (!!formik.status) debounceSaving();
  }, [formik.values]);
  const debounceSaving = React.useCallback(
    debounce(() => {
      if (!loading) formik.handleSubmit();
    }, 1500),
    []
  );
  // Auto save function ==========
  const [collegeData, setCollegeData] = React.useState<
    {
      id: number;
      label: any;
      value: any;
      code: string;
    }[]
  >();
  const handleSearchCollege = async (keyword: string) => {
    const res: any = await collegeServices.getCollege({ keyword: keyword.trim() });
    const data = res.map((item: any) => ({ ...item, label: item.name, value: item.name }));
    setCollegeData(data);
  };
  const handleChangeItem = (item: any) => {
    formik.setFieldValue('college_code', item?.zip_code);
    formik.setFieldValue('college_state', item?.state);
    formik.setFieldValue('college_city', item?.city);
  };
  return loading ? (
    <LoadingContent />
  ) : (
    <>
      <TopProcessBar
        stepProcess={[{ title: 'General', percentOnProcess: 25 }]}
        title="Family Information"
        process={1}
        icon={<PeopleAltOutlinedIcon />}
      />

      <Container className="main-container">
        <div className="px-2" id="General">
          <Form style={{ maxWidth: '900px' }} onSubmit={formik.handleSubmit}>
            <Row className="m-0">
              <div className="text-field-mid fs-3 mb-4">Legal Guardian</div>
              <SelectInput
                form={formik}
                option={[{ value: 'Dr.' }, { value: 'Mr.' }, { value: 'Ms.' }, { value: 'Mrs.' }]}
                label="Prefix"
                onChange={formik.handleChange}
                name="prefix"
                values={formik.values.prefix}
              />

              <TextInput
                label="First/Given name"
                name="first_name"
                values={formik.values.first_name}
                onChange={formik.handleChange}
                countText={20}
              />
              <TextInput
                label="Middle Initial"
                name="middle_name"
                values={formik.values.middle_name}
                onChange={formik.handleChange}
                countText={20}
              />
              <TextInput
                label="Last Name"
                name="last_name"
                values={formik.values.last_name}
                onChange={formik.handleChange}
                countText={20}
              />
              <TextInput
                label="Former Last Name"
                name="former_last_name"
                values={formik.values.former_last_name}
                onChange={formik.handleChange}
                countText={20}
              />

              <SelectInput
                form={formik}
                option={fullSuffixOptions}
                label="Suffix"
                name="suffix"
                values={formik.values.suffix}
                onChange={formik.handleChange}
              />
              <TextInput
                label="Relationship to you*"
                name="step_parent_relationship"
                values={formik.values.step_parent_relationship}
                onChange={formik.handleChange}
                countText={50}
                mdCol={'12'}
              />
              <TextInput
                label="Email Address *"
                name="step_parent_email"
                values={formik.values.step_parent_email}
                onChange={formik.handleChange}
                countText={50}
                mdCol={'12'}
              />
              <GroupRadioButton
                mdCol={3}
                label="Preferred phone"
                name="phone_number_type"
                onChange={formik.handleChange}
                values={formik.values.phone_number_type}
                options={[
                  { value: 'Home' },
                  { value: 'Mobile' },
                  { value: 'Work' },
                  { value: 'Other' },
                ]}
              />
              <SelectPhoneCodeInput
                label="Country Code"
                name="country_code"
                onChange={formik.handleChange}
                values={formik.values.country_code}
              />
              <TextIconInput
                type="number"
                icon={<PhoneAndroidIcon />}
                name="phone_number"
                onChange={formik.handleChange}
                values={formik.values.phone_number}
                label="Phone Number"
              />

              <GroupRadioButton
                label="Parent Address is"
                className="uc-flag mb-5 pt-3 "
                name="csu_info.parent_address_is"
                onChange={formik.handleChange}
                values={formik.values.csu_info?.parent_address_is}
                options={[
                  { value: 'The same as my home address', mdCol: 4 },
                  { value: 'A different address' },
                  { value: 'Unknown' },
                ]}
              />
              <div />
              {formik.values.csu_info?.parent_address_is === 'A different address' && (
                <>
                  <SelectInput
                    option={countryOptions}
                    label={'Country'}
                    name={'csu_info.different_parent_address_country'}
                    values={formik.values.csu_info?.different_parent_address_country}
                    onChange={formik.handleChange}
                  />
                  <TextInput
                    label={'Address Line 1'}
                    name={'csu_info.different_parent_address_line_1'}
                    values={formik.values.csu_info?.different_parent_address_line_1}
                    onChange={formik.handleChange}
                    countText={36}
                  />
                  <TextInput
                    label={'Address Line 2'}
                    name={'csu_info.different_parent_address_line_2'}
                    values={formik.values.csu_info?.different_parent_address_line_2}
                    onChange={formik.handleChange}
                  />
                  <TextInput
                    label={'City'}
                    name={'csu_info.different_parent_address_city'}
                    values={formik.values.csu_info?.different_parent_address_city}
                    onChange={formik.handleChange}
                    countText={25}
                  />
                  {formik.values.csu_info?.different_parent_address_country ===
                  'United States of America' ? (
                    <SelectInput
                      option={stateOptions}
                      label={'State'}
                      name={'csu_info.different_parent_address_state'}
                      values={formik.values.csu_info?.different_parent_address_state}
                      onChange={formik.handleChange}
                    />
                  ) : (
                    <TextInput
                      label={'Province *'}
                      name={'csu_info.different_parent_address_province'}
                      values={formik.values.csu_info?.different_parent_address_province}
                      onChange={formik.handleChange}
                      countText={80}
                    />
                  )}
                  <TextInput
                    countText={11}
                    label={'Zip Code'}
                    name={'csu_info.different_parent_address_zip_code'}
                    values={formik.values.csu_info?.different_parent_address_zip_code}
                    onChange={formik.handleChange}
                  />
                </>
              )}
              <div className="fs-5 p-1">Authorization</div>
              <YesNoSwitch
                logo="span-csu-logo"
                values={formik.values.csu_info?.is_give_permission_communicate_to}
                onChange={formik.handleChange}
                name="csu_info.is_give_permission_communicate_to"
                label="Do you give permission for the admission office to communicate with your Parent/Guardian regarding your application?"
              />
              <YesNoSwitch
                logo="span-csu-logo"
                values={formik.values.csu_info?.is_give_permission_to_receive_information}
                onChange={formik.handleChange}
                name="csu_info.is_give_permission_to_receive_information"
                label="Do you give permission for your parent/guardian to receive university communications? These may include new student and housing information, parent activities, volunteer and charitable opportunities, and general campus information."
              />
              <p>Can we share your parent's contact information with UC campuses and programs?</p>
              <p>
                If you check this option, your parent may receive information about admissions tips,
                updates and reminders from UC campuses (even ones you haven't applied to) and
                outreach programs. They may contact them before or after you've submitted the
                application.
              </p>
              <CheckboxInput
                values={formik.values.csu_info?.is_authorized_UC_to_share_contact_information}
                onChange={formik.handleChange}
                name="csu_info.is_authorized_UC_to_share_contact_information"
                label="I authorize the University of California to share my parent's contact information."
              />

              <SelectInput
                form={formik}
                option={occupationOptions}
                logo="span-cm-logo"
                label="Occupation (former occupation, if retired or deceased)"
                name="occupation"
                values={formik.values.occupation}
                onChange={formik.handleChange}
              />
              <GroupRadioButton
                logo="span-cm-logo"
                hidden={
                  formik.values.employment_status !== 'Employed' &&
                  formik.values.employment_status !== 'Retired'
                }
                label="Is parent currently employed by or retired from a college or university?"
                name="currently_employed_or_retire_from_college"
                values={formik.values.currently_employed_or_retire_from_college}
                onChange={formik.handleChange}
                mdCol={12}
                options={[
                  { value: 'Never employed at a college/university' },
                  { value: 'Currently employed at a college/university' },
                  { value: 'Retired from a college/university' },
                ]}
              />
              <TextInput
                logo="span-cm-logo"
                hidden={formik.values.occupation !== 'Other'}
                label="Other Occupation Details"
                name="other_occupation_detail"
                values={formik.values.other_occupation_detail}
                onChange={formik.handleChange}
                countText={50}
              />
              <SelectInput
                form={formik}
                logo="span-cm-logo"
                name="employment_status"
                values={formik.values.employment_status}
                option={[
                  { value: 'Employed' },
                  { value: 'Unemployed' },
                  { value: 'Retired' },
                  { value: 'Self-Employed' },
                ]}
                label="Employment status*"
                onChange={formik.handleChange}
              />

              <SelectSearchInput
                logo="span-cm-logo"
                hidden={
                  !(
                    (formik.values.employment_status === 'Employed' ||
                      formik.values.employment_status === 'Retired') &&
                    formik.values.currently_employed_or_retire_from_college !==
                      'Never employed at a college/university'
                  )
                }
                form={formik}
                values={formik.values.college_or_university}
                name="college_or_university"
                label="College/University employer lookup"
                option={collegeData ?? []}
                onSearch={(keyword) => handleSearchCollege(keyword)}
                onChangeItem={handleChangeItem}
              />
              <TextInput
                logo="span-cm-logo"
                hidden={
                  !(
                    (formik.values.employment_status === 'Employed' ||
                      formik.values.employment_status === 'Retired') &&
                    formik.values.currently_employed_or_retire_from_college !==
                      'Currently employed at a college/university'
                  )
                }
                label="Name of Current Employer"
                name="current_employer"
                countText={40}
                values={formik.values.current_employer}
                onChange={formik.handleChange}
              />
              <div />
              <Education formik={formik} />
              <Residency formik={formik} />
            </Row>
            <div className="my-3 d-flex justify-content-end border-top pt-3">
              <div className="col col-md-6 text-center text-md-end">
                <Button variant="primary" type="submit" className="p-2 px-5 mx-2">
                  {family.status === 'loading' ? (
                    <Spinner size="sm" animation="border" variant="light" />
                  ) : (
                    'Save'
                  )}
                </Button>
                <Button variant="primary" className="p-2 px-5" onClick={() => handleContinue()}>
                  Continue
                </Button>
              </div>
            </div>
          </Form>
        </div>
      </Container>
    </>
  );
};

export default LegalGuardian;
