import toast from 'react-hot-toast';
import http from './http';
import { DataArray } from '@mui/icons-material';

const collegeServices = {
  async getState() {
    const res = await http.get(`api/us-college-list/cen-state-types`);
    return res;
  },

  async getCollege(body: any) {
    const requestBody = {
      ...body,
      limit_item: body.keyword.length < 5 ? 10 : 1000,
    };
    const res = await http.post('api/us-college-list', requestBody);
    return res;
  },

  async getCollegeEx(
    body: any,
    controller?: AbortController
  ) {
    let data: any[] = [];
    const requestBody = { 
      ...body,
      limit_item: body.keyword.length < 5 ? 10 : 1000,
    };
    try {
      const res:any = await http.post('api/us-college-list', requestBody, {
        signal: controller?.signal,
      });
      data = res;
    }
    catch(ex){
      // skip
    }

    return data;
  },

///controller?: AbortController

  async getDetail(studentId: any) {
    const res = await http.get(`api/college-information/${studentId}`);
    return res;
  },
  async updateDetail(body: any) {
    const res = await http.put(`api/college-information`, body);
    return res;
  },
  async createDetail(body: any) {
    const res = await http.post(`api/college-information`, body);
    return res;
  },
  async getUSCollegeV1(body: any) {
    const res = await http.post(`api/us-colleges`, {
      ...body,
      city: body.state_name ? body.state_name : undefined,
    });
    return res;
  },
  async getUSCollegeSportV1(body: { keyword: string; college_id?: string }) {
    const res = await http.post(`api/us-colleges/us-sports`, body);
    return res;
  },
  async getUSCollegeMajorV1(body: { keyword: string; college_id?: string }) {
    const res = await http.post(`api/us-colleges/us-majors`, body);
    return res;
  },
  async getMyCollege(id: string | number) {
    const res = await http.get(`api/us-colleges/my-colleges/${id}`);
    return res;
  },
  async createMyCollege(student_id: string | number, colleges_array: string) {
    const res: any = await http.post(`api/us-colleges/my-colleges`, { student_id, colleges_array });
    toast.success(res.message);
    return res;
  },
  async updatetMyCollege(student_id: string | number, colleges_array: string) {
    const res: any = await http.put(`api/us-colleges/my-colleges`, { student_id, colleges_array });
    toast.success(res.message);
    return res;
  },

  async deleteCollegeData(id: string | number) {
    const res: any = await http.delete(`api/us-colleges/my-colleges/${id}`);
    toast.success(res.message);
    return res;
  },
  async sendMailResetPassword(body: { email: string; username: string }) {
    // const res: any = await http.post(`api/notification/email/chat`, body);
    // toast.success(res.message);
    // return res;
  },

  async sendMailChat(body: {
    email: string;
    sender: string;
    receiver: string;
    content: string;
    linkPage: string;
  }) {
    const res: any = await http.post(`api/notification/email/chat`, body);
    toast.success(res.message);
    return res;
  },
  async sendMailUserMessage(body: {
    email: string;
    sender: string;
    receiver: string;
    content: string;
    linkPage: string;
  }) {
    const res: any = await http.post(`api/notification/email/user-message`, body);
    toast.success(res.message);
    return res;
  },

  async sendMailWelcome(body: { email: string; username: string }) {
    const res: any = await http.post(`api/notification/email/registration`, body);
    toast.success(res.message);
    return res;
  },
  async sendMailWelcomeCounselor(body: { email: string; username: string }) {
    const res: any = await http.post(`api/notification/email/counselor-registration`, body);
    toast.success(res.message);
    return res;
  },
  async sendMailInvite(body: {
    email: string;
    username: string;
    studentFirstname: string;
    studentLastname: string;
  }) {
    const res: any = await http.post(`api/notification/email/counselor-invitation`, body);
    toast.success(res.message);
    return res;
  },
};

export default collegeServices;
