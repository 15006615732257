import CheckboxInput from 'components/input/CheckboxInput';
import GroupRadioButton from 'components/input/GroupRadioButton';
import YesNoSwitch from 'components/input/YesNoSwitch';
import React from 'react';
import { Row } from 'react-bootstrap';

type Props = {
  formik: any;
};

const StatementLegalResident = ({ formik }: Props) => {
  return (
    <>
      <div className="fs-5 p-1">Statement of Legal Residence</div>

      <YesNoSwitch
        className="py-3"
        onChange={formik.handleChange}
        values={
          formik.values.statement_legal_resident?.is_like_to_be_evaluated_for_tuition_purposes
        }
        label="Would you like to be evaluated for California residency for tuition purposes? *"
        name="statement_legal_resident.is_like_to_be_evaluated_for_tuition_purposes"
        logo="span-uc-logo"
      />
      {formik.values.statement_legal_resident?.is_like_to_be_evaluated_for_tuition_purposes ===
        'true' && (
        <>
          <YesNoSwitch
            className="py-3"
            onChange={formik.handleChange}
            values={formik.values.statement_legal_resident?.is_physically_present_for_3_years}
            label="Have you been physically present in California for the last 3 years? *"
            name="statement_legal_resident.is_physically_present_for_3_years"
            logo="span-uc-logo"
            helperText='If any of the following are true, you should answer "No". 1. You attended high school outside of California during the last 3 years 2. You worked outside of California during the last 3 years 3. You left California for more than 6 weeks in the last year'
          />

          <YesNoSwitch
            className="py-3"
            onChange={formik.handleChange}
            values={formik.values.statement_legal_resident?.is_attend_highschool_for_3_more_years}
            label="Did you attend high school in California for three or more years, and will you graduate or have you graduated from a high school in California? *"
            name="statement_legal_resident.is_attend_highschool_for_3_more_years"
            logo="span-uc-logo"
          />
          <GroupRadioButton
            mdCol={12}
            name="statement_legal_resident.is_established_as_permanent_home"
            onChange={formik.handleChange}
            values={formik.values.statement_legal_resident?.is_established_as_permanent_home}
            options={['Yes', 'No', 'I do not consider myself a dependent upon my parents']}
            label="Have you and your parents established California as your permanent home for the past 366 days or more? *"
            logo="span-uc-logo"
          />

          {formik.values.statement_legal_resident?.is_established_as_permanent_home !==
            'I do not consider myself a dependent upon my parents' && (
            <Row>
              <GroupRadioButton
                options={['Yes', 'No', 'Unknown']}
                label="Are your parents married to each other?"
                mdCol={4}
                name="statement_legal_resident.is_parent_1_married_to_parent_2"
                onChange={formik.handleChange}
                values={formik.values.statement_legal_resident?.is_parent_1_married_to_parent_2}
              />
              {formik.values.statement_legal_resident?.is_parent_1_married_to_parent_2 === 'No' && (
                <GroupRadioButton
                  options={['Yes', 'No', 'Unknown']}
                  label="Do your parents live together? *"
                  mdCol={4}
                  name="statement_legal_resident.is_parents_live_together"
                  onChange={formik.handleChange}
                  values={formik.values.statement_legal_resident?.is_parents_live_together}
                />
              )}

              <GroupRadioButton
                mdCol={12}
                logo="span-uc-logo"
                name="statement_legal_resident.is_parent_1_physically_present"
                onChange={formik.handleChange}
                values={formik.values.statement_legal_resident?.is_parent_1_physically_present}
                options={['Yes', 'No', 'Unknown', 'My Parent is Deceased']}
                label="Has Parent 1 been physically present in California for the last 3 years? *"
                helperText='If they have worked outside of California during the last 3 years, or if they were out of California for more than 6 weeks in the last year, you should answer "No".'
              />

              {formik.values.statement_legal_resident?.is_parent_1_physically_present &&
                formik.values.statement_legal_resident?.is_parent_1_physically_present !==
                  'My Parent is Deceased' && (
                  <GroupRadioButton
                    logo="span-uc-logo"
                    mdCol={12}
                    name="statement_legal_resident.is_parent_1_US_citizen"
                    onChange={formik.handleChange}
                    values={formik.values.statement_legal_resident?.is_parent_1_US_citizen}
                    options={['Yes', 'No', 'Unknown']}
                    label="Is Parent 1 a U.S. Citizen? *"
                  />
                )}
              <div />

              <GroupRadioButton
                hidden={
                  formik.values.statement_legal_resident?.is_parent_1_married_to_parent_2 ===
                    'No' &&
                  formik.values.statement_legal_resident?.is_parents_live_together === 'No'
                }
                logo="span-uc-logo"
                mdCol={12}
                name="statement_legal_resident.is_parent_2_physically_present"
                onChange={formik.handleChange}
                values={formik.values.statement_legal_resident?.is_parent_2_physically_present}
                options={['Yes', 'No', 'Unknown', 'My Parent is Deceased']}
                label="Has Parent 2 been physically present in California for the last 3 years? *"
                helperText='If they have worked outside of California during the last 3 years, or if they were out of California for more than 6 weeks in the last year, you should answer "No".'
              />

              {formik.values.statement_legal_resident?.is_parent_2_physically_present &&
                formik.values.statement_legal_resident?.is_parent_2_physically_present !==
                  'My Parent is Deceased' && (
                  <GroupRadioButton
                    hidden={
                      formik.values.statement_legal_resident?.is_parent_1_married_to_parent_2 ===
                        'No' &&
                      formik.values.statement_legal_resident?.is_parents_live_together === 'No'
                    }
                    logo="span-uc-logo"
                    name="statement_legal_resident.is_parent_2_US_citizen"
                    onChange={formik.handleChange}
                    values={formik.values.statement_legal_resident?.is_parent_2_US_citizen}
                    mdCol={12}
                    options={['Yes', 'No', 'Unknown']}
                    label="Is Parent 2 a U.S. Citizen? *"
                  />
                )}
            </Row>
          )}

          <CheckboxInput
            setValue={(e) =>
              formik.setFieldValue(
                'statement_legal_resident.is_declare_under_penalty_of_perjury',
                e
              )
            }
            logo="span-uc-logo"
            values={formik.values.statement_legal_resident?.is_declare_under_penalty_of_perjury}
            onChange={formik.handleChange}
            name="statement_legal_resident.is_declare_under_penalty_of_perjury"
            label="I declare under penalty of perjury under the laws governing the State of California that the statements I have provided on this Statement of Legal Residence are, and each of them is, true and correct."
          />
          <div style={{ height: '1.5rem' }} />
          <CheckboxInput
            setValue={(e) =>
              formik.setFieldValue(
                'statement_legal_resident.is_understood_to_be_asked_submit_document',
                e
              )
            }
            logo="span-uc-logo"
            values={
              formik.values.statement_legal_resident?.is_understood_to_be_asked_submit_document
            }
            onChange={formik.handleChange}
            name="statement_legal_resident.is_understood_to_be_asked_submit_document"
            label="I understand that as part of the process to issue a Residency Classification, I may be asked to submit additional documentation or information to a University of California Residence Deputy to supplement my Statement of Legal Residence. Failure to provide the requested information in a timely manner can affect my residence classification. Further, I understand that completion of the Statement of Legal Residence does not guarantee a Resident Classification for UC tuition."
          />
        </>
      )}
    </>
  );
};

export default StatementLegalResident;
